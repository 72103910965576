<script setup lang="ts">
import { computed, nextTick, ref } from 'vue';
import hash from 'object-hash';
import type { TokenSuggestion } from '@swimm/shared';
import type { CachedHighlighters } from '../../types';
import type { Theme } from 'shiki';

import BaseTruncate from '../../components/BaseTruncate/BaseTruncate.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import BaseCode from '../../components/BaseCode/BaseCode.vue';
import BaseButton from '../../components/BaseButton/BaseButton.vue';
import BaseIcon from '../../components/BaseIcon/BaseIcon.vue';

import MenuItem from '../MenuItem/MenuItem.vue';

import { getLanguageFromPath } from '../../lib/languages';
import { getTheme } from '../../lib/theme';

export type CachedTokenOffsetStyles = {
  [key: string]: {
    transform: string;
    maxWidth: string;
  };
};

const props = defineProps<{
  token: TokenSuggestion;
  query?: string;
  cachedHighlighters?: CachedHighlighters;
  cachedOffsetStyles?: CachedTokenOffsetStyles;
}>();

const emit = defineEmits<{
  offsetStylesApplied: [styles: CachedTokenOffsetStyles];
}>();

const el = ref<InstanceType<typeof MenuItem>>();
const root = computed(() => el.value?.root);
const text = computed(() => el.value?.text);
const realigned = ref(false);
const theme = ref<Theme>(getTheme() === 'dark' ? 'github-dark' : 'github-light');

const computedClasses = computed(() => ({
  'menu-item-token--realigned': realigned.value,
}));

function getOffset() {
  if (!el.value || !el.value.text) {
    return;
  }

  const textElement = el.value.text;
  const highlightSpan = textElement.querySelector('.code__highlight');

  if (!highlightSpan) {
    return;
  }

  const codeRect = textElement.getBoundingClientRect();
  const spanRect = highlightSpan.getBoundingClientRect();

  // Position of highlight is a start of code container
  if (spanRect.left === codeRect.left) {
    return undefined;
  }

  // Position of highlight is within 75% position of code width but is already visible
  if (spanRect.left - codeRect.left + spanRect.width <= codeRect.width) {
    return undefined;
  }

  // Position of highlight span relative to the code container
  const relativeLeft = spanRect.left - codeRect.left;

  // Desired position in percentage (e.g., 75% of code width)
  const desiredLeft = 0.75 * codeRect.width;

  // Calculate the required offset
  const offset = desiredLeft - (relativeLeft + spanRect.width);

  return offset < 0 ? Math.floor(offset) : undefined;
}

async function setItemStyle() {
  await nextTick();

  if (!el.value || !el.value.text) {
    return;
  }

  const offset = getOffset();
  const textElement = el.value.text;
  const cacheKey = hash(props.token);

  if (props.cachedOffsetStyles?.[cacheKey]) {
    const style = props.cachedOffsetStyles[cacheKey];
    textElement.style.transform = style.transform;
    textElement.style.maxWidth = style.maxWidth;

    realigned.value = true;
  } else if (textElement && offset && offset < 0) {
    const currentTransform = textElement.style.transform;
    const currentMaxWidth = textElement.style.maxWidth;
    const newTransform = `translateX(${offset}px)`;
    const newMaxWidth = `calc(100% + ${Math.abs(offset)}px)`;

    if (currentTransform !== newTransform) {
      textElement.style.transform = newTransform;
    }

    if (currentMaxWidth !== newMaxWidth) {
      textElement.style.maxWidth = newMaxWidth;
    }

    realigned.value = true;

    emit('offsetStylesApplied', {
      [cacheKey]: {
        transform: newTransform,
        maxWidth: newMaxWidth,
      },
    });
  }

  return;
}

defineExpose({ root, text });
</script>

<template>
  <!-- left alignment here makes sure we can accurately center the highlighted part of the line - setItemStyle
       calculates the offset then transform the menu item text and change its width - this changing of width
       would make the item have a different left offset if the alignment here was 'center', which would make our offset
       calculation invalid. -->
  <MenuItem
    ref="el"
    wrapper="div"
    data-testid="menu-item-token-path"
    class="menu-item-token"
    :class="computedClasses"
    alignment="left"
  >
    <BaseCode
      :code="token.lineData"
      :highlight-tokens="[token]"
      :query="query"
      :highlighter="cachedHighlighters && cachedHighlighters[`${getLanguageFromPath(token.position.path)}-${theme}`]"
      @highlighted="setItemStyle"
    />

    <template #additional>
      <BaseProse class="menu-item-token__path" variant="secondary" size="small">
        <BaseTruncate align="right">{{ token.position.path }}:{{ token.position.line }}</BaseTruncate>
      </BaseProse>
      <BaseButton class="add-button" size="small"
        >Add <template #rightIcon><BaseIcon name="enter" class="keyboard-icon" /></template
      ></BaseButton>
    </template>
  </MenuItem>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-item-token {
  $self: &;

  font-size: var(--font-size-xsmall);
  position: relative;

  &--realigned {
    &:before {
      background-color: var(--color-bg-default);
      box-shadow: 4px 0 8px 4px var(--color-bg-default);
      color: var(--color-text-disabled);
      display: block;
      content: '…';
      left: var(--space-xsmall);
      position: absolute;
      z-index: var(--layer-overlap);
    }

    &.menu-item--focused,
    &:hover,
    &:focus {
      &:before {
        background-color: var(--color-bg-surface-hover);
        box-shadow: 4px 0 8px 4px var(--color-bg-surface-hover);
      }
    }
  }

  &__path {
    text-align: right;
    width: 30%;
    flex-grow: 1;
  }

  &:not(.menu-item--focused):not(:hover) .add-button {
    display: none;
  }

  &:hover:not(.menu-item--focused) {
    .add-button {
      display: initial;
    }

    .keyboard-icon {
      display: none;
    }
  }
}
</style>
