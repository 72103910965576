import { sortBy } from 'lodash-es';

import type {
  Path,
  PathSuggestion,
  SmartElement,
  SmartElementWithApplicability,
  SmartElementWithApplicabilityAndNewInfo,
  Snippet,
  Token,
  TokenSuggestion,
} from '@swimm/shared';
import { SmartElementType, isSmartElementWithNewInfo } from '@swimm/shared';

export interface SnippetInfo {
  repoId: string;
  filePath: string;
  lineRange: {
    start: number;
    end: number;
  };
}

function autosyncedSnippetToSnippetInfo(
  autosyncedSnippet: SmartElementWithApplicabilityAndNewInfo<Snippet>
): SnippetInfo | undefined {
  if (autosyncedSnippet.newInfo.gitInfo == null) {
    return undefined;
  }

  return {
    repoId: autosyncedSnippet.newInfo.gitInfo.repoId,
    filePath: autosyncedSnippet.newInfo.filePath,
    lineRange: {
      start: autosyncedSnippet.newInfo.startLineNumber,
      end: autosyncedSnippet.newInfo.startLineNumber + autosyncedSnippet.newInfo.lines.length - 1,
    },
  };
}

function serializeToken(token: SmartElementWithApplicabilityAndNewInfo<Token>) {
  return `${token.gitInfo?.repoId}-${token.newInfo.symbolText}-${token.newInfo.filePath}-${token.newInfo.lineNumber}:${token.newInfo.wordIndex.start}:${token.newInfo.wordIndex.end}`;
}

function getElementRanking({
  suggestion,
  existingTokenIds,
  existingSnippets,
  repoId,
  parentSnippetInfo,
}: {
  suggestion: TokenSuggestion;
  existingTokenIds: string[];
  existingSnippets: SnippetInfo[];
  repoId?: string;
  parentSnippetInfo?: SnippetInfo;
}): number {
  if (isSuggestionInSnippet(suggestion, parentSnippetInfo)) {
    return 1;
  }
  if (
    existingTokenIds.includes(
      `${suggestion.repoId}-${suggestion.token}-${suggestion.position.path}-${suggestion.position.line}:${suggestion.position.wordStart}:${suggestion.position.wordEnd}`
    )
  ) {
    return 2;
  }
  if (existingSnippets.some((snippetInfo) => isSuggestionInSnippet(suggestion, snippetInfo))) {
    return 3;
  }
  if (suggestion.static) {
    // Favor tokens at higher indentation levels (more likely to be globals and not local variables, and thus more
    // likely to be what the user meant).
    const MAX_INDENTATION_LEVEL = 100;
    const indentationLevel = suggestion.lineData.match(/^\s*/)?.[0].length ?? 0;
    // Cap the indentation level score at less than 1 to avoid surpassing the next ranking.
    const indentationLevelScore = Math.min(indentationLevel, MAX_INDENTATION_LEVEL - 1) / MAX_INDENTATION_LEVEL;
    return 4 + indentationLevelScore;
  }
  if (suggestion.repoId === repoId) {
    return 5;
  }
  return 6;
}

function getPathRnaking({
  suggestion,
  existingPaths,
  referencedPaths,
  repoId,
  parentSnippetInfo,
}: {
  suggestion: PathSuggestion;
  existingPaths: string[];
  referencedPaths: string[];
  repoId?: string;
  parentSnippetInfo?: SnippetInfo;
}): number {
  if (isSuggestionInSnippetFile(suggestion, parentSnippetInfo)) {
    return 1;
  }
  if (existingPaths.includes(suggestion.path)) {
    return 2;
  }
  if (referencedPaths.includes(suggestion.path)) {
    return 3;
  }
  if (suggestion.repoId === repoId) {
    return 4;
  }
  return 5;
}

export function sortPathSuggestionsByFamiliarity({
  suggestions,
  docSmartElements,
  docRepoId,
  parentSnippetInfo,
}: {
  suggestions: PathSuggestion[];
  docSmartElements: (SmartElementWithApplicability<SmartElement> | SmartElementWithApplicability<Snippet>)[];
  docRepoId?: string;
  parentSnippetInfo?: SnippetInfo;
}): PathSuggestion[] {
  // Familiarity ranking:
  // 1. Path of the current snippet (if any)
  // 2. Existing paths
  // 3. Referenced paths in the document
  // 4. Doc repo static analysis paths
  // 5. Cross-repo static analysis paths

  const existingPaths = docSmartElements.reduce((paths: string[], currentElement) => {
    if (currentElement.type === SmartElementType.PATH && isSmartElementWithNewInfo(currentElement)) {
      paths.push((currentElement as SmartElementWithApplicabilityAndNewInfo<Path>).newInfo.filePath);
    }
    return paths;
  }, []);

  const referencedPaths = docSmartElements.reduce((paths: string[], currentElement) => {
    if (currentElement.type === SmartElementType.TOKEN && isSmartElementWithNewInfo(currentElement)) {
      paths.push((currentElement as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.filePath);
    }

    if (currentElement.type === SmartElementType.SNIPPET && isSmartElementWithNewInfo(currentElement)) {
      paths.push((currentElement as SmartElementWithApplicabilityAndNewInfo<Snippet>).newInfo.filePath);
    }

    return paths;
  }, []);

  return sortBy(suggestions, (suggestion) =>
    getPathRnaking({ suggestion, existingPaths, referencedPaths, repoId: docRepoId, parentSnippetInfo })
  );
}

export function sortSuggestionsByFamiliarity({
  suggestions,
  docSmartElements,
  docRepoId,
  parentSnippetInfo,
}: {
  suggestions: TokenSuggestion[];
  docSmartElements: (SmartElementWithApplicability<SmartElement> | SmartElementWithApplicability<Snippet>)[];
  docRepoId?: string;
  parentSnippetInfo?: SnippetInfo;
}): TokenSuggestion[] {
  // Familiarity ranking:
  // 1. Tokens in the current snippet (if any)
  // 2. Existing tokens
  // 3. Tokens from existing snippets (if any)
  // 4. Static analysis tokens
  // 5. Tokens from source files referenced in the document
  // 6. Tokens from cross-repo source files referenced in the document
  const existingTokenIds = docSmartElements.reduce((tokens: string[], currentElement) => {
    if (currentElement.type === SmartElementType.TOKEN && isSmartElementWithNewInfo(currentElement)) {
      tokens.push(serializeToken(currentElement as SmartElementWithApplicabilityAndNewInfo<Token>));
    }
    return tokens;
  }, []);

  const existingSnippets: SnippetInfo[] = docSmartElements
    .filter(
      (element) =>
        element.type === SmartElementType.SNIPPET &&
        isSmartElementWithNewInfo(element) &&
        element.newInfo.gitInfo != null // Guarantees autosyncedSnippetToSnippetInfo doesn't return undefined
    )
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((element) => autosyncedSnippetToSnippetInfo(element as SmartElementWithApplicabilityAndNewInfo<Snippet>)!);

  return sortBy(suggestions, (suggestion) =>
    getElementRanking({ suggestion, existingTokenIds, existingSnippets, repoId: docRepoId, parentSnippetInfo })
  );
}

export function sortSuggestions(
  suggestions: TokenSuggestion[],
  docRepoId?: string,
  parentSnippetInfo?: SnippetInfo
): TokenSuggestion[] {
  // Because the value of `true` is greater than `false`, results that satisfy a condition will appear after the ones that don't.
  // For example: static tokens (true) will appear after non-static tokens (false).
  return sortBy(
    suggestions,
    (suggestion) => suggestion.static,
    // TODO: Prioritize existing tokens?
    (suggestion) => !isSuggestionInSnippet(suggestion, parentSnippetInfo),
    (suggestion) => !isSuggestionInSnippetFile(suggestion, parentSnippetInfo),
    (suggestion) => suggestion.repoId !== docRepoId,
    (suggestion) => suggestion.position.path,
    (suggestion) => suggestion.position.line
  );
}

function isSuggestionInSnippet(suggestion: TokenSuggestion, snippetInfo?: SnippetInfo) {
  return (
    snippetInfo != null &&
    isSuggestionInSnippetFile(suggestion, snippetInfo) &&
    suggestion.position.line >= snippetInfo.lineRange.start &&
    suggestion.position.line <= snippetInfo.lineRange.end
  );
}

function isSuggestionInSnippetFile(suggestion: TokenSuggestion | PathSuggestion, snippetInfo?: SnippetInfo) {
  return (
    snippetInfo != null &&
    suggestion.repoId === snippetInfo.repoId &&
    ('position' in suggestion
      ? suggestion.position.path === snippetInfo.filePath
      : suggestion.path === snippetInfo.filePath)
  );
}
