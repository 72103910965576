import { MarkdownSerializer } from '@tiptap/pm/markdown';
import { type Mark, Node as ProseMirrorNode } from '@tiptap/pm/model';
import type { JSONContent } from '@tiptap/core';
import { markdownTable } from 'markdown-table';
import stringWidth from 'string-width';
import { SWMD_VERSION, type SwimmDocument } from '@swimm/shared';
import matter from 'gray-matter';
import { schema } from './extensions';
import { renderHtmlAttrs } from '@/markdownit/plugins/html';
import { serializeSwmMeta } from './swm_meta';
import { buildSwimmLink } from '..';
import { serializeSwmTokenPos } from '@/markdownit/plugins/swm_token';
import { generatePlainMermaidText, serializeSwimmMermaidText } from './mermaid';
import { serializeSwmPathShort } from '@/markdownit/plugins/swm_path';

declare module '@tiptap/pm/markdown' {
  interface MarkdownSerializerState {
    inAutolink?: boolean;
  }
}

interface SerializerEnv {
  baseUrl: string;
  /** @deprecated Unused */
  workspaceId?: string;
  repoId?: string;
  inMermaid?: boolean;
}

let env: SerializerEnv | undefined;

/**
 * Set an environment for the `swmdSerializer` for the current sync scope.
 *
 * See https://github.com/ProseMirror/prosemirror-markdown/pull/99#issuecomment-1551387341
 */
export function withEnv<R>(newEnv: SerializerEnv, f: () => R): R {
  const prev = env;
  env = newEnv;
  try {
    return f();
  } finally {
    env = prev;
  }
}

// Based on https://github.com/ProseMirror/prosemirror-markdown/blob/5a81988d0a915216ad692c4d9566dab8aaa1556e/src/to_markdown.ts#L63
export const swmdSerializer = new MarkdownSerializer(
  {
    blockquote(state, node) {
      state.wrapBlock('> ', null, node, () => state.renderContent(node));
    },
    codeBlock(state, node) {
      const fence = fenceBackticksFor(node.textContent);

      state.write(fence + (node.attrs.language || '') + '\n');
      state.text(node.textContent, false);
      // Add a newline to the current content before adding closing marker
      state.write('\n');
      state.write(fence);
      state.closeBlock(node);
    },
    heading(state, node) {
      state.write(state.repeat('#', node.attrs.level) + ' ');
      state.renderInline(node);
      state.closeBlock(node);
    },
    horizontalRule(state, node) {
      state.write(node.attrs.markup || '---');
      state.closeBlock(node);
    },
    bulletList(state, node) {
      state.renderList(node, '  ', () => '- ');
    },
    orderedList(state, node) {
      const start = node.attrs.start || 1;
      const maxW = String(start + node.childCount - 1).length;
      const space = state.repeat(' ', maxW + 2);
      state.renderList(node, space, (i) => {
        const nStr = String(start + i);
        return state.repeat(' ', maxW - nStr.length) + nStr + '. ';
      });
    },
    listItem(state, node) {
      state.renderContent(node);
    },
    taskItem(state, node) {
      state.write(`[${node.attrs.checked ? 'x' : ' '}] `);
      state.renderContent(node);
    },
    paragraph(state, node) {
      if (node.childCount === 0) {
        state.write('&nbsp;');
      } else {
        state.renderInline(node);
      }
      state.closeBlock(node);
    },
    image(state, node) {
      state.write(
        '![' +
          state.esc(node.attrs.alt || '') +
          '](' +
          node.attrs.src.replace(/[()]/g, '\\$&') +
          (node.attrs.title ? ' "' + node.attrs.title.replace(/"/g, '\\"') + '"' : '') +
          ')'
      );
    },
    hardBreak(state, node, parent, index) {
      if (['tableCell', 'tableHeader'].includes(parent.type.name)) {
        state.write('<br>');
      } else {
        for (let i = index + 1; i < parent.childCount; i++) {
          if (parent.child(i).type !== node.type) {
            state.write('\\\n');
            return;
          }
        }
      }
    },
    table(state, node, _parent) {
      const schema = node.type.schema;
      const table: string[][] = [];
      for (let i = 0; i < node.childCount; i++) {
        const rowNode = node.child(i);

        const row: string[] = [];
        for (let j = 0; j < rowNode.childCount; j++) {
          // Sadly markdown-table doesn't handle escaping of pipes in the table's contents, we handle escaping by ourselves here
          row.push(
            swmdSerializer
              .serialize(schema.node('tableRow', null, rowNode.child(j)), state.options)
              .replaceAll('|', '\\|')
          );
        }
        table.push(row);
      }

      const align: (string | null)[] = [];
      for (let i = 0; i < (node.firstChild?.childCount ?? 0); i++) {
        align.push(node.firstChild?.child(i).attrs.align);
      }

      state.write(
        markdownTable(table, {
          align,
          stringLength: stringWidth,
        })
      );
      state.closeBlock(node);
    },
    tableHeader(state, node) {
      state.renderInline(node);
      state.closeBlock(node);
    },
    tableCell(state, node) {
      state.renderInline(node);
      state.closeBlock(node);
    },
    text(state, node, parent, index) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      let text = node.text!;

      if (!env?.inMermaid && (index === 0 || parent.maybeChild(index - 1)?.type.name === 'hardBreak')) {
        text = preserveLeadingWhitespaceInMarkdown(text);
      }

      if (
        !env?.inMermaid &&
        (index === parent.childCount - 1 || parent.maybeChild(index + 1)?.type.name === 'hardBreak')
      ) {
        text = preserveTrailingWhitespace(text);
      }

      state.text(text, !state.inAutolink && !env?.inMermaid);
    },

    swmPath(state, node) {
      const attrs: [string, string][] = [];

      if (node.attrs.repoId !== env?.repoId) {
        attrs.push(['repo-id', node.attrs.repoId]);
        if (node.attrs.repoName) {
          attrs.push(['repo-name', node.attrs.repoName]);
        }
        attrs.push(['path', node.attrs.href]);
      }

      if (node.attrs.short != null) {
        attrs.push(['short', node.attrs.short]);
      }
      if (node.attrs.customDisplayText) {
        attrs.push(['custom', '']);
      }

      if (node.attrs.repoId === env?.repoId) {
        let text: string;
        if (node.attrs.customDisplayText) {
          text = node.attrs.customDisplayText;
        } else {
          text = serializeSwmPathShort(node.attrs.href, node.attrs.short);
        }

        state.write(
          '<SwmPath' +
            renderHtmlAttrs(attrs) +
            '>[' +
            state.esc(text) +
            '](' +
            node.attrs.href.replace(/[()]/g, '\\$&') +
            (node.attrs.title ? ' "' + node.attrs.title.replace(/"/g, '\\"') + '"' : '') +
            ')</SwmPath>'
        );
      } else {
        let text: string;
        if (node.attrs.customDisplayText) {
          text = node.attrs.customDisplayText;
        } else if (node.attrs.short != null) {
          text = `(${node.attrs.repoName}) ${serializeSwmPathShort(node.attrs.href, node.attrs.short)}`;
        } else {
          text = `(${node.attrs.repoName}) ${decodeURI(node.attrs.href.replace(/^\//, ''))}`;
        }

        state.write(
          '<SwmPath' +
            renderHtmlAttrs(attrs) +
            '>' +
            backticksForText(text, -1) +
            text +
            backticksForText(text, 1) +
            '</SwmPath>'
        );
      }
    },

    swmLink(state, node) {
      const attrs: [string, string][] = [];

      attrs.push(['doc-title', node.attrs.docTitle]);
      if (node.attrs.repoId !== env?.repoId) {
        attrs.push(['repo-id', node.attrs.repoId]);
        if (node.attrs.repoName) {
          attrs.push(['repo-name', node.attrs.repoName]);
        }
        attrs.push(['path', node.attrs.path]);
      }
      if (node.attrs.customDisplayText) {
        attrs.push(['custom', '']);
      }

      const text = node.attrs.customDisplayText || node.attrs.docTitle;

      state.write(
        '<SwmLink' +
          renderHtmlAttrs(attrs) +
          '>[' +
          state.esc(text) +
          '](' +
          (node.attrs.repoId === env?.repoId
            ? node.attrs.path.replace(/[()]/g, '\\$&')
            : // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              buildSwimmLink(env!.baseUrl, node.attrs.repoId, node.attrs.path)) +
          (node.attrs.title ? ' "' + node.attrs.title.replace(/"/g, '\\"') + '"' : '') +
          ')</SwmLink>'
      );
    },

    swmToken(state, node) {
      const attrs: [string, string][] = [];

      attrs.push(['path', node.attrs.path]);
      attrs.push(['pos', serializeSwmTokenPos(node.attrs.pos)]);
      attrs.push(['line-data', node.attrs.lineData]);
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (node.attrs.repoId !== env?.repoId) {
        attrs.push(['repo-id', node.attrs.repoId]);
        if (node.attrs.repoName) {
          attrs.push(['repo-name', node.attrs.repoName]);
        }
      }
      if (node.attrs.customDisplayText) {
        attrs.push(['custom', '']);
        attrs.push(['token', node.attrs.token]);
      }

      let text: string;
      if (node.attrs.customDisplayText) {
        text = node.attrs.customDisplayText;
      } else {
        text = node.attrs.token;
      }

      state.write(
        '<SwmToken' +
          renderHtmlAttrs(attrs) +
          '>' +
          backticksForText(text, -1) +
          text +
          backticksForText(text, 1) +
          '</SwmToken>'
      );
    },

    swmSnippet(state, node) {
      const attrs: [string, string][] = [];

      attrs.push(['path', node.attrs.path]);
      attrs.push(['line', node.attrs.line.toString()]);
      if (node.attrs.collapsed) {
        attrs.push(['collapsed', '']);
      }
      if (node.attrs.repoId !== env?.repoId) {
        attrs.push(['repo-id', node.attrs.repoId]);
        if (node.attrs.repoName) {
          attrs.push(['repo-name', node.attrs.repoName]);
        }
      }

      state.write('<SwmSnippet' + renderHtmlAttrs(attrs) + '>');
      state.closeBlock(node);

      state.write('---');
      state.closeBlock(node);

      state.renderContent(node);

      const fence = fenceBackticksFor(node.attrs.snippet);

      state.write(fence + (node.attrs.language || '') + '\n');
      state.text(node.attrs.snippet, false);
      // Add a newline to the current content before adding closing marker
      state.write('\n');
      state.write(fence);
      state.closeBlock(node);

      state.write('---');
      state.closeBlock(node);

      state.write('</SwmSnippet>');
      state.closeBlock(node);
    },

    swmMention(state, node) {
      const attrs: [string, string][] = [];

      attrs.push(['uid', node.attrs.uid]);

      const email = node.attrs.email === '#' ? node.attrs.email : `mailto:${node.attrs.email}`;

      state.write(
        '<SwmMention' + renderHtmlAttrs(attrs) + '>[' + state.esc(node.attrs.name) + '](' + email + ')</SwmMention>'
      );
    },

    mermaid(state, node) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const swimmMermaidText = withEnv({ ...env!, inMermaid: true }, () => {
        return swmdSerializer.serialize(node, state.options);
      });
      const plainMermaidText = generatePlainMermaidText(node);

      let mermaidText: string;
      if (swimmMermaidText !== plainMermaidText) {
        mermaidText = serializeSwimmMermaidText(swimmMermaidText, plainMermaidText);
      } else {
        mermaidText = swimmMermaidText;
      }

      const fence = fenceBackticksFor(mermaidText);

      state.write(fence + 'mermaid' + '\n');
      state.text(mermaidText, false);
      // Add a newline to the current content before adding closing marker
      state.write('\n');
      state.write(fence);
      state.closeBlock(node);
    },

    youtube(state, node) {
      state.write('<' + node.attrs.src + '>');
      state.closeBlock(node);
    },

    blockImage(state, node) {
      const attrs: [string, string][] = [];

      attrs.push(['src', node.attrs.src]);
      if (node.attrs.alt) {
        attrs.push(['alt', node.attrs.alt]);
      }
      if (node.attrs.title != null) {
        attrs.push(['title', node.attrs.title]);
      }
      if (node.attrs.width != null) {
        attrs.push(['style', `width: ${node.attrs.width}`]);
      }

      state.write('<p align="center"><img' + renderHtmlAttrs(attrs) + '></p>');
      state.closeBlock(node);
    },

    swmSnippetPlaceholder(state, node) {
      state.write('<SwmSnippetPlaceholder>');
      state.closeBlock(node);

      if (node.attrs.placeholder != null) {
        state.write(node.attrs.placeholder);
        state.closeBlock(node);
      }

      state.write('</SwmSnippetPlaceholder>');
      state.closeBlock(node);
    },

    // TODO Currently disabled
    // swmTextPlaceholder(state, node) {
    //   state.write('<SwmTextPlaceholder>' + state.renderInline(node) + '</SwmTextPlaceholder>');
    //   state.closeBlock(node);
    // },

    swmTablePlaceholder(state, node) {
      state.write('<SwmTablePlaceholder>');
      state.closeBlock(node);

      state.renderContent(node);

      state.write('</SwmTablePlaceholder>');
      state.closeBlock(node);
    },

    swmMermaidPlaceholder(state, node) {
      state.write('<SwmMermaidPlaceholder>');
      state.closeBlock(node);

      if (node.attrs.placeholder != null) {
        state.write(node.attrs.placeholder);
        state.closeBlock(node);
      }

      state.write('</SwmMermaidPlaceholder>');
      state.closeBlock(node);
    },
  },

  {
    italic: {
      open: '*',
      close: '*',
      mixable: true,
      expelEnclosingWhitespace: true,
    },
    bold: {
      open: '**',
      close: '**',
      mixable: true,
      expelEnclosingWhitespace: true,
    },
    strike: {
      open: '~~',
      close: '~~',
    },
    link: {
      open(state, mark, parent, index) {
        state.inAutolink = isPlainURL(mark, parent, index);
        return state.inAutolink ? '<' : '[';
      },
      close(state, mark, _parent, _index) {
        const { inAutolink } = state;
        state.inAutolink = undefined;
        return inAutolink
          ? '>'
          : '](' +
              mark.attrs.href.replace(/[()"]/g, '\\$&') +
              (mark.attrs.title ? ` "${mark.attrs.title.replace(/"/g, '\\"')}"` : '') +
              ')';
      },
      mixable: true,
    },
    code: {
      open(_state, _mark, parent, index) {
        return backticksFor(parent.child(index), -1);
      },
      close(_state, _mark, parent, index) {
        return backticksFor(parent.child(index - 1), 1);
      },
      escape: false,
    },
    swmTemplateInline: {
      open(_state, _mark, _parent, _index) {
        return '{{';
      },
      close(_state, _mark, _parent, _index) {
        return '}}';
      },
      escape: false,
    },
  },
  {
    hardBreakNodeName: 'hardBreak',
  }
);

function backticksFor(node: ProseMirrorNode, side: number) {
  const ticks = /`+/g;
  let m = null;
  let len = 0;

  if (node.isText) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    while ((m = ticks.exec(node.text!))) {
      len = Math.max(len, m[0].length);
    }
  }

  let result = len > 0 && side > 0 ? ' `' : '`';
  for (let i = 0; i < len; i++) {
    result += '`';
  }
  if (len > 0 && side < 0) {
    result += ' ';
  }
  return result;
}

function backticksForText(text: string, side: number) {
  const ticks = /`+/g;
  let m = null;
  let len = 0;

  while ((m = ticks.exec(text))) {
    len = Math.max(len, m[0].length);
  }

  let result = len > 0 && side > 0 ? ' `' : '`';
  for (let i = 0; i < len; i++) {
    result += '`';
  }
  if (len > 0 && side < 0) {
    result += ' ';
  }
  return result;
}

function fenceBackticksFor(text: string): string {
  // Make sure the front matter fences are longer than any dash sequence within it
  const backticks = text.match(/`{3,}/gm);
  return backticks ? backticks.sort().slice(-1)[0] + '`' : '```';
}

function isPlainURL(link: Mark, parent: ProseMirrorNode, index: number): boolean {
  if (link.attrs.title || !/^\w+:/.test(link.attrs.href)) {
    return false;
  }

  const content = parent.child(index);
  if (!content.isText || content.text !== link.attrs.href || content.marks[content.marks.length - 1] !== link) {
    return false;
  }

  return index === parent.childCount - 1 || !link.isInSet(parent.child(index + 1).marks);
}

export function preserveLeadingWhitespaceInMarkdown(text: string): string {
  return text.replace(/^[ \xA0]+/g, (match: string) => '&nbsp;'.repeat(match.length));
}

function preserveTrailingWhitespace(text: string): string {
  return text.replace(/[ \xA0]+$/g, (match: string) => '&nbsp;'.repeat(match.length));
}

export interface SwmdSerializerConfig {
  baseUrl: string;
  /** @deprecated Unused */
  workspaceId?: string;
  bumpVersion?: boolean;
  noBranding?: boolean;
}

/**
 * Serialize a SWMD v3 document.
 *
 * @param document The document
 * @param config Additional configuration needed for serialization
 * @returns The serialized document text
 */
export function serializeSwmd(document: SwimmDocument, config: SwmdSerializerConfig): string {
  const data: Record<string, unknown> = {
    ...document.frontmatter,
    ...(document.title != null && { title: document.title }),
  };

  const meta: Record<string, string> = {};
  if (config.bumpVersion ?? true) {
    meta['version'] = SWMD_VERSION;
  } else {
    if (document.version != null) {
      meta['version'] = document.version;
    }
  }
  if (document.repoId) {
    meta['repo-id'] = document.repoId;
  }
  if (document.repoName) {
    meta['repo-name'] = document.repoName;
  }

  return (
    matter.stringify(
      serializeSwmdContent(document.content, {
        baseUrl: config.baseUrl,
        workspaceId: config.workspaceId,
        repoId: document.repoId,
      }),
      data,
      {
        language: 'yaml',
      }
    ) +
    '\n' +
    serializeSwmMeta(meta, config.baseUrl, config.noBranding) +
    '\n'
  );
}

export function serializeSwmdContent(content: JSONContent, { baseUrl, workspaceId, repoId }: SerializerEnv): string {
  return withEnv(
    {
      baseUrl,
      workspaceId,
      repoId,
    },
    () => swmdSerializer.serialize(ProseMirrorNode.fromJSON(schema, content), undefined)
  );
}
