import { Editor, Extension } from '@tiptap/core';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Decoration, DecorationSet } from '@tiptap/pm/view';
import isEqual from 'lodash-es/isEqual';
import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';
import { type WatchStopHandle, watch } from 'vue';

import { SWIMMPORT_SUGGESTION_CLASS, type SwimmportDecorationSpec } from '@/services/swimmport/decoration';
import { SwimmportDecorationsHandler, type TextSuggestions } from '@/services/swimmport/docTraverse';
import { useTextSuggestions } from '@/services/swimmport/suggest';
import { showCaretSwimmportPopover } from '../caretPopover';
import { showHoverSwimmportPopover } from '../hoverPopover';
import { getSwimmEditorServices } from './Swimm';
import { productEvents, removePrefix } from '@swimm/shared';
import { SwimmDocumentSuggestionSorter } from '@/services/swimmport/SwimmDocumentSuggestionSorter';
import type { SelectedSuggestion } from '@/components/SwimmportPopover.vue';
import PopoverHandler from '@/services/swimmport/PopoverHandler';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swimmport: {
      rerunSwimmport: () => ReturnType;
    };
  }
}

export const SWIMMPORT_PLUGIN_KEY = new PluginKey<DecorationSet>('swimmport');

// TODO: Make the cases more explicit (strings).
export interface SwimmportPluginMeta {
  rerun: boolean;
}

interface SwimmportExtensionStorage {
  shown: boolean;
  currentDecorationSet: DecorationSet;
  lastCaretDecoration: Decoration | null;
  popoverHandler: PopoverHandler;
  watchStopHandles: WatchStopHandle[];
  textSuggestions: ReturnType<typeof useTextSuggestions> | null;
}

export default Extension.create<{ storage: SwimmportExtensionStorage }, SwimmportExtensionStorage>({
  name: 'swimmport',

  addStorage() {
    // seems like this is called twice and in the first time there are no options yet
    return { ...(this.options?.storage || getSwimmportStorage()), shown: false };
  },

  onCreate() {
    // We call the rerunSwimmport command asynchronously as the watches can be triggered in a transaction.
    // Calling a command during an existing transaction causes the editor to throw an error.

    const swimmEditorServices = getSwimmEditorServices(this.editor);

    this.storage.popoverHandler = new PopoverHandler();

    this.storage.watchStopHandles.push(
      watch(
        () => swimmEditorServices.autosyncOutput.value,
        async (value, oldValue) => {
          if (isEqual(value, oldValue)) {
            return;
          }
          this.editor.commands.rerunSwimmport();
        }
      )
    );

    this.storage.watchStopHandles.push(
      watch(
        () => swimmEditorServices.external.swimmportEnabled.value,
        async () => this.editor.commands.rerunSwimmport()
      )
    );

    this.storage.watchStopHandles.push(
      watch(
        () => swimmEditorServices.external.tokenSuggestionsService.suggestionsBuildTimestamp.value,
        async () => this.editor.commands.rerunSwimmport()
      )
    );

    this.storage.watchStopHandles.push(
      watch(
        () => swimmEditorServices.editable.value,
        async () => this.editor.commands.rerunSwimmport()
      )
    );

    this.storage.watchStopHandles.push(
      watch(
        () => swimmEditorServices.sourceFiles.sourceFiles.value,
        async (value, oldValue) => {
          if (isEqual(value, oldValue)) {
            return;
          }
          return this.editor.commands.rerunSwimmport();
        }
      )
    );

    // TODO: Don't initialize token suggestions if editor is not editable or Swimmport is not enabled.
    // If not initialized initially, intialize it once both the editor becomes editable and Swimmport is enabled.
    this.storage.textSuggestions = useTextSuggestions(swimmEditorServices, () => this.editor.commands.rerunSwimmport());
  },

  onDestroy() {
    this.storage.watchStopHandles.forEach((watchStopHandle) => watchStopHandle());
  },

  addProseMirrorPlugins() {
    const editor = this.editor;
    const storage: SwimmportExtensionStorage = this.storage;

    const swimmEditorServices = getSwimmEditorServices(editor);
    const suggestionSorter = new SwimmDocumentSuggestionSorter(swimmEditorServices);

    const decorationsHandler = new SwimmportDecorationsHandler(
      async (text: string, { isCode, isLink }: { isCode: boolean; isLink: boolean }) =>
        this.storage.textSuggestions?.getSuggestionsForText(text, { isCode, isLink }),
      suggestionSorter
    );

    const debouncedCalculate = debounce(async (doc) => {
      const decorationSet = await decorationsHandler.calculate(doc);
      storage.lastCaretDecoration = null;
      storage.currentDecorationSet = decorationSet;
      editor.view.dispatch(editor.state.tr.setMeta(SWIMMPORT_PLUGIN_KEY, { rerun: false } as SwimmportPluginMeta));
    }, 200);

    const debouncedUpdate = debounce(async (transactionDoc, oldStateDoc, decorationSet) => {
      const updatedDecorationSet = await decorationsHandler.update(transactionDoc, oldStateDoc, decorationSet);
      storage.lastCaretDecoration = null;
      storage.currentDecorationSet = updatedDecorationSet;
      editor.view.dispatch(editor.state.tr.setMeta(SWIMMPORT_PLUGIN_KEY, { rerun: false } as SwimmportPluginMeta));
    }, 200);

    // Throttling mouseover events to avoid showing multiple popovers or when brushing by decoration
    const handleMouseOver = throttle(
      (event, view) => {
        const target = event.target as HTMLElement;
        if (target.classList.contains(SWIMMPORT_SUGGESTION_CLASS)) {
          storage.popoverHandler.closePopover();

          const decorationElement = target;

          const from = Number(decorationElement.dataset.from);
          const to = Number(decorationElement.dataset.to);
          if (!(from >= 0) || !(to >= 0)) {
            // NaN >= 0 is false.
          }

          if (decorationElement.dataset.suggestions == null) {
            return;
          }

          try {
            const suggestions = JSON.parse(decorationElement.dataset.suggestions) as TextSuggestions;
            const text = view.state.doc.textBetween(from, to);

            const applySuggestion = (selected: SelectedSuggestion) => {
              if (selected.type === 'token') {
                editor
                  .chain()
                  .focus(to)
                  .deleteRange({ from, to })
                  .insertSwmToken(
                    selected.suggestion.token,
                    `/${removePrefix(selected.suggestion.position.path, '/')}`,
                    selected.suggestion.position,
                    selected.suggestion.lineData,
                    selected.suggestion.repoId
                  )
                  .focus(from)
                  .run();
              } else {
                if (selected?.suggestion?.repoId) {
                  editor
                    .chain()
                    .focus(to)
                    .deleteRange({ from, to })
                    .insertSwmPath(selected.suggestion.path, selected.suggestion.repoId, selected.suggestion.type)
                    .focus(from)
                    .run();
                }
              }

              getSwimmEditorServices(editor).external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_APPLIED, {
                Context: 'Swimmport',
                Source: 'mouse',
                IsPath: selected.type === 'path',
              });
            };

            storage.popoverHandler.currentPopover = showHoverSwimmportPopover(
              editor,
              decorationElement,
              applySuggestion,
              {
                text,
                suggestions,
                driver: 'cursor',
                onDismiss: () => {
                  storage.textSuggestions?.dismissText(text);
                  swimmEditorServices.external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_DISMISSED, {
                    Context: 'Swimmport',
                    Source: 'mouse',
                    IsPath: suggestions?.type === 'path',
                  });
                },
              },
              {
                onShow() {
                  storage.shown = true;
                },
                onHide() {
                  storage.shown = false;
                },
                onUntrigger() {
                  storage.popoverHandler.closePopover(decorationElement.id);
                },
              }
            );

            storage.popoverHandler.openPopover(decorationElement.id);

            swimmEditorServices.external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_SHOWN, {
              Context: 'Swimmport',
              Source: 'mouse',
              IsPath: suggestions?.type === 'path',
            });
          } catch {
            // Failed to parse the suggestions from the decoration element. Do nothing.
            return;
          }
        }
      },
      200,
      { leading: false, trailing: true }
    );

    return [
      new Plugin<DecorationSet>({
        key: SWIMMPORT_PLUGIN_KEY,
        state: {
          init: (_, state) => {
            if (
              !swimmEditorServices.isTemplate &&
              swimmEditorServices.external.swimmportEnabled.value &&
              swimmEditorServices.editable.value
            ) {
              decorationsHandler.calculate(state.doc).then((decorationSet) => {
                storage.currentDecorationSet = decorationSet;
                editor.view.dispatch(
                  editor.state.tr.setMeta(SWIMMPORT_PLUGIN_KEY, { rerun: false } as SwimmportPluginMeta | undefined)
                );

                // TODO: This analytics event isn't very useful, as:
                // 1. There are probably no paths in the initial suggestions (folder tree might not be loaded yet).
                // 2. There are probably only global tokens (as autosync might not have finished running yet).
                swimmEditorServices.external.trackEvent(productEvents.SWIMMPORT_INITIAL_SUGGESTIONS, {
                  Context: 'Swimmport',
                  Total: decorationSet.find().length,
                  Paths: decorationSet.find(
                    undefined,
                    undefined,
                    (spec: SwimmportDecorationSpec) => spec.suggestions?.type === 'path'
                  ).length,
                  Tokens: decorationSet.find(
                    undefined,
                    undefined,
                    (spec: SwimmportDecorationSpec) => spec.suggestions?.type === 'token'
                  ).length,
                });
              });
            }

            return DecorationSet.empty;
          },

          apply: (transaction, _, oldState) => {
            if (!swimmEditorServices.external.swimmportEnabled.value || !swimmEditorServices.editable.value) {
              storage.currentDecorationSet = DecorationSet.empty;
              return storage.currentDecorationSet;
            }

            const meta = transaction.getMeta(SWIMMPORT_PLUGIN_KEY) as SwimmportPluginMeta | undefined;
            if (meta != null && meta.rerun) {
              storage.popoverHandler.closePopover();
              storage.currentDecorationSet = DecorationSet.empty;
              debouncedCalculate(transaction.doc);
            } else if (transaction.docChanged) {
              storage.popoverHandler.closePopover();
              const decorationSet = storage.currentDecorationSet.map(transaction.mapping, transaction.doc);
              storage.currentDecorationSet = decorationSet;
              debouncedUpdate(transaction.doc, oldState.doc, decorationSet);
              return decorationSet;
            }

            return storage.currentDecorationSet;
          },
        },

        props: {
          decorations(state) {
            return this.getState(state);
          },
          handleKeyDown(_view, event) {
            return storage.popoverHandler.currentPopover?.onKeyDown(event) ?? false;
          },
          handleDOMEvents: {
            mouseout(view, event) {
              const target = event.target as HTMLElement;
              if (target.classList.contains(SWIMMPORT_SUGGESTION_CLASS)) {
                storage.popoverHandler.clearPendingPopovers(target.id);
              }
            },
            mouseover(view, event) {
              handleMouseOver(event, view);
            },
          },
        },
      }),
    ];
  },

  onSelectionUpdate() {
    onCaretOrDecorationsUpdate(this.editor, this.storage);
  },

  onTransaction({ transaction }) {
    const meta = transaction.getMeta(SWIMMPORT_PLUGIN_KEY) as SwimmportPluginMeta | undefined;
    if (meta == null || meta.rerun) {
      return;
    }

    onCaretOrDecorationsUpdate(this.editor, this.storage);
  },

  addCommands() {
    return {
      rerunSwimmport:
        () =>
        ({ state, dispatch }) => {
          if (dispatch) {
            const tr = state.tr.setMeta(SWIMMPORT_PLUGIN_KEY, { rerun: true } as SwimmportPluginMeta);
            dispatch(tr);
          }
          return true;
        },
    };
  },
});

const onCaretOrDecorationsUpdate = throttle(_onCaretOrDecorationsUpdate, 200, { leading: false, trailing: true });

function _onCaretOrDecorationsUpdate(editor: Editor, storage: SwimmportExtensionStorage) {
  const caretPosition = editor.state.selection.head;

  if (
    storage.lastCaretDecoration == null ||
    caretPosition < storage.lastCaretDecoration.from ||
    caretPosition > storage.lastCaretDecoration.to
  ) {
    storage.popoverHandler.closePopover();

    const newCaretDecoration = storage.currentDecorationSet.find(caretPosition, caretPosition)[0] ?? null;

    if (newCaretDecoration != null) {
      const decorationElement = editor.view.nodeDOM(newCaretDecoration.from)?.parentElement;

      if (decorationElement != null) {
        const swimmEditorServices = getSwimmEditorServices(editor);

        const text = editor.state.doc.textBetween(newCaretDecoration.from, newCaretDecoration.to);
        const suggestions = (newCaretDecoration.spec as SwimmportDecorationSpec).suggestions;

        storage.popoverHandler.currentPopover = showCaretSwimmportPopover(
          editor,
          decorationElement,
          newCaretDecoration,
          {
            text,
            suggestions,
            driver: 'caret',
            onDismiss: () => {
              storage.textSuggestions?.dismissText(text);
              swimmEditorServices.external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_DISMISSED, {
                Context: 'Swimmport',
                Source: 'caret',
                IsPath: suggestions?.type === 'path',
              });
            },
          },
          {
            onShow() {
              storage.shown = true;
            },
            onHide() {
              storage.shown = false;
            },
          }
        );
        storage.popoverHandler.openPopover(decorationElement.id);

        swimmEditorServices.external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_SHOWN, {
          Context: 'Swimmport',
          Source: 'caret',
          IsPath: suggestions?.type === 'path',
        });
      }
    }

    storage.lastCaretDecoration = newCaretDecoration;
  }
}

export function getSwimmportStorage(): SwimmportExtensionStorage {
  return {
    shown: false,
    currentDecorationSet: DecorationSet.empty,
    lastCaretDecoration: null,
    watchStopHandles: [],
    textSuggestions: null,
    popoverHandler: new PopoverHandler(),
  };
}
