import { useStore } from 'vuex';
import { type Ref, nextTick, ref, watch } from 'vue';
import { useSnippetStore } from '@/modules/snippet-studio/stores/snippetsStore';
import { pageEvents } from '@swimm/shared';
import { useRoute, useRouter } from 'vue-router';
import { WebSnippetSelection } from '@/modules/editor/tiptapEditor/compositions/webSnippetSelection';

export function useSnippetStudioComposable3(analytics?) {
  const store = useStore();
  const router = useRouter();
  const route = useRoute();
  const snippetStore = useSnippetStore();

  const snippetEditorModal = ref({
    show: false,
    heading: 'Snippet Studio',
    lastSelectedPathRepoId: null,
  });

  function stopSelection() {
    snippetStore.resetStore();
    snippetEditorModal.value.show = false;
  }

  function toggleEditorSnippetModal(getStartedOpenSnippet?: Ref<boolean>) {
    snippetEditorModal.value.show = false;
    if (getStartedOpenSnippet) {
      getStartedOpenSnippet.value = false;
    }

    snippetStore.selectedSnippets.complete();

    stopSelection();

    const { action: _action, ...restOfQuery } = route.query; // eslint-disable-line @typescript-eslint/no-unused-vars
    router.replace({ query: restOfQuery });
  }

  const tiptapEditorToggleSnippetStudioModal = ({ open = false }) => {
    if (open && analytics) {
      if (analytics) {
        analytics.pageVisit(pageEvents.VIEW_SNIPPET_STUDIO);
      }
    }
    snippetEditorModal.value.show = open;
  };

  function discardSelectedPath() {
    snippetStore.selectedSnippets.next(null);
    setSelectedFolderTreePath({ path: '', repoId: undefined });
  }

  const setSelectedFolderTreePath = ({ path = '', repoId = '' }) =>
    store.dispatch('filesystem/setSelectedFolderTreePath', {
      path: path,
      repoId: repoId,
    });

  watch(
    () => snippetStore.selectedSnippets,
    (value) => {
      tiptapEditorToggleSnippetStudioModal({ open: !!value });
    }
  );

  watch(
    () => snippetStore.snippetToEdit,
    (value) => {
      if (value) {
        snippetEditorModal.value.lastSelectedPathRepoId = { path: value.path, repoId: value.repoId };
      }
    }
  );

  function tiptapEditorHandleSnippetToggler(value: boolean, triggerSelectSnippet: () => void) {
    if (value) {
      nextTick(() => {
        triggerSelectSnippet();
      });
    } else {
      stopSelection();
    }
  }

  return {
    discardSelectedPath,
    tiptapEditorToggleSnippetStudioModal,
    toggleEditorSnippetModal,
    tiptapEditorHandleSnippetToggler,
    snippetEditorModal,
    snippetSelector: new WebSnippetSelection(snippetStore),
    selectedSnippets: snippetStore.selectedSnippets,
  };
}
