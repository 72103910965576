import { createStore } from 'vuex';
import database from '@/common/store/database';
import filesystem from '@/common/store/filesystem';
import swimmport from '@/modules/swimmport/store/swimmport';

const getDefaultState = () => ({
  status: {},
  local: true,
  preventNavigation: false,
  previousRoute: '',
});

const store = createStore({
  state: getDefaultState(),
  modules: {
    database,
    filesystem,
    swimmport,
  },
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_PREVENT_NAVIGATION(state, shouldPrevent) {
      state['preventNavigation'] = shouldPrevent;
    },
    SET_PREVIOUS_ROUTE(state, previousRoute) {
      state['previousRoute'] = previousRoute;
    },
  },
  actions: {
    resetAllStores({ commit, dispatch }) {
      commit('RESET_STATE');
      dispatch('filesystem/resetState');
      dispatch('database/resetState');
      dispatch('swimmport/resetState');
    },
    updatePreventNavigation({ commit }, shouldPrevent) {
      commit('SET_PREVENT_NAVIGATION', shouldPrevent);
    },
    storePreviousRoute({ commit }, previousRoute) {
      commit('SET_PREVIOUS_ROUTE', previousRoute);
    },
  },
  getters: {
    isLocal: (state) => {
      return state.local;
    },
    shouldPreventNavigation: (state) => {
      return state.preventNavigation;
    },
    getPreviousRoute: (state) => {
      return state.previousRoute;
    },
  },
});

export default store;
