import { GIPHY_API_KEY, isDevelopment, isStaging, isTest } from '@/config';
import WebAiClient from '@/modules/backend/WebAiClient';
import type { ImageDraft } from '@/modules/drafts3/db';
import { useRepoLinks } from '@/modules/editor3/composables/repo-links';
import { GiphyFetch } from '@giphy/js-fetch-api';
import {
  type AddNotificationParams,
  type AiContentSuggestionsService,
  type AnalyticsInterface,
  type AnalyticsTrackOptions,
  type AnalyticsTrackProperties,
  type DbWrapperInterface,
  type FsWrapperInterface,
  type GenerativeAiInterface,
  type OpenFileParam,
  type TokenSuggestionsService,
  useAiContentSuggestions,
  useMockedStaticAnalysisIndex,
  useNotificationsStore,
  useTokenSuggestionsService,
} from '@swimm/editor';
import type { Link } from '@swimm/reefui';
import {
  type AiClient,
  type AiFeatureAccess,
  type AiFeaturesAccessData,
  type FolderTree,
  type GenerateMermaidRequest,
  type GenerateMermaidResponse,
  type GenerateSnippetCommentResponse,
  type GenerateTextModifiersResponse,
  type GenerativeAiResponse,
  PLAYLIST_SUFFIX,
  QuotaExceededError,
  StiggFeatures,
  SwmSymbolLinkType,
  type TextCompletionContext,
  type ThemeOption,
  ThemeOptions,
  WorkspaceUser,
  extractResourceIdFromPath,
  getTextCompletionPrompt,
  isAccessAiFeatureAllowed,
  removePrefix,
} from '@swimm/shared';
import {
  type EditorSnippet,
  type NormalizedAutosyncOutput,
  type OpenSwimmResourceParam,
  type Repos,
  type SnippetSelection,
  type SwimmEditorExternalServices,
  type UploadedImage,
  base64ImageToFile,
} from '@swimm/swmd';
import { type Observable } from 'rxjs';
import * as uuid from 'uuid';
import { type ComputedRef, type Ref, computed, toRef } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useDrafts3Store } from '../drafts3/stores/drafts3';
import { getDismissedSuggestionsForDocument, saveDismissedSuggestion } from '../swimmport/services/swimmport-local-db';
import { useWebAppFileTokensIndex } from '../tokenSuggestions/fileTokensIndex';
import { useWebAppQueryTokensService } from '../tokenSuggestions/queryTokensService';
import { useWebAppStaticAnalysisIndex } from '../tokenSuggestions/staticAnalysisIndex';
import { createImageDraft, uploadImage } from './imageUpload';
import { useStigg } from '@/common/composables/useStigg';

export class WebSwimmEditorExternalServices implements SwimmEditorExternalServices {
  private store = useStore();
  private router = useRouter();
  private drafts3Store = useDrafts3Store();
  private notificationsStore = useNotificationsStore();
  private stiggService = useStigg();
  private repoLinks;
  private aiFeatureAccessDataByWorkspace: Record<string, AiFeaturesAccessData> = {};
  readonly aiContentSuggestionsService: AiContentSuggestionsService = null;
  readonly tokenSuggestionsService: TokenSuggestionsService;
  readonly isDarkMode: ComputedRef<boolean>;

  constructor(
    workspaceId: Ref<string>,
    private repoId: Ref<string>,
    branch: Ref<string>,
    private docId: Ref<string>,
    private docTitle: Ref<string>,
    repos: Ref<Repos>,
    readonly isWorkspaceAdmin: Ref<boolean>,
    readonly isGenAiDisabledInWorkspace: Ref<boolean>,
    readonly swimmportEnabled: Ref<boolean>,
    readonly isAutoCompleteEnabled: Ref<boolean>,
    readonly editable: Ref<boolean>,
    private fsWrapper: FsWrapperInterface,
    dbWrapper: DbWrapperInterface,
    private analytics: AnalyticsInterface,
    private snippetSelection: SnippetSelection,
    private generativeAi: GenerativeAiInterface,
    readonly isWorkspaceInLocalMode: Ref<boolean>,
    readonly shouldUploadFilesToRepo: Ref<boolean>,
    readonly theme: Ref<ThemeOption>
  ) {
    this.isDarkMode = computed(() => theme.value === ThemeOptions.DARK);
    this.aiContentSuggestionsService = useAiContentSuggestions(workspaceId, repoId, branch, docTitle, repos, {
      isAIGenerationEnabledForRepo: this.generativeAi.isAIGenerationEnabledForRepo,
      getFileContent: this.fsWrapper.getFileContent,
      trackEvent: this.trackEvent.bind(this),
      editable,
    });
    const fileTokensIndex = useWebAppFileTokensIndex(repoId.value, branch.value);
    const staticAnalysisIndex = !isWorkspaceInLocalMode.value
      ? useWebAppStaticAnalysisIndex(
          repoId.value,
          toRef(() => branch.value)
        )
      : useMockedStaticAnalysisIndex();
    const queryTokensService = useWebAppQueryTokensService();
    this.repoLinks = useRepoLinks(dbWrapper);
    this.tokenSuggestionsService = useTokenSuggestionsService({
      fileTokensIndex,
      staticAnalysisIndex,
      queryTokensService,
    });
    // this isAIGenerationEnabledForRepo function does not throw
    void this.generativeAi.isAIGenerationEnabledForRepo(this.repoId.value);
  }

  static async create(
    workspaceId: Ref<string>,
    repoId: Ref<string>,
    branch: Ref<string>,
    docId: Ref<string>,
    docTitle: Ref<string>,
    repos: Ref<Repos>,
    isWorkspaceAdmin: Ref<boolean>,
    isGenAiDisabledInWorkspace: Ref<boolean>,
    swimmportEnabled: Ref<boolean>,
    isAutoCompleteEnabled: Ref<boolean>,
    editable: Ref<boolean>,
    fsWrapper: FsWrapperInterface,
    dbWrapper: DbWrapperInterface,
    analytics: AnalyticsInterface,
    snippetSelection: SnippetSelection,
    generativeAi: GenerativeAiInterface,
    isWorkspaceInLocalMode: Ref<boolean>,
    shouldUploadFilesToRepo: Ref<boolean>,
    theme: Ref<ThemeOption>
  ): Promise<WebSwimmEditorExternalServices> {
    return new WebSwimmEditorExternalServices(
      workspaceId,
      repoId,
      branch,
      docId,
      docTitle,
      repos,
      isWorkspaceAdmin,
      isGenAiDisabledInWorkspace,
      swimmportEnabled,
      isAutoCompleteEnabled,
      editable,
      fsWrapper,
      dbWrapper,
      analytics,
      snippetSelection,
      generativeAi,
      isWorkspaceInLocalMode,
      shouldUploadFilesToRepo,
      theme
    );
  }

  readonly giphyClient = new GiphyFetch(GIPHY_API_KEY);

  async getRepoFolderTree(repoId: string, branch: string, isCrossRepo: boolean): Promise<FolderTree> {
    await this.fsWrapper.populateRepoFolderTree({ repoId: repoId, branch, isCrossRepo });
    return this.fsWrapper.getRepoFolderTree(repoId) as FolderTree;
  }

  async getRepoLinks(repoId: string, branch: string, linkType: SwmSymbolLinkType): Promise<Link[]> {
    return this.repoLinks.getRepoLinks(repoId, branch, linkType);
  }

  async getWorkspaceUsers(): Promise<WorkspaceUser[]> {
    return Object.values(this.store.getters['database/db_getWorkspaceUsers'](this.drafts3Store.workspaceId) || []);
  }

  async openFile(param: OpenFileParam): Promise<void> {
    if (param.isSwimmDoc) {
      throw new Error('Should not be called for isSwimmDoc');
    }
    await this.fsWrapper.openFile(param);
  }

  async openSwimmResource(param: OpenSwimmResourceParam) {
    const resourceId = extractResourceIdFromPath(param.path);
    const workspaceId = this.drafts3Store.workspaceId;
    const containerPart = param.path.endsWith(PLAYLIST_SUFFIX) ? 'playlists' : 'docs';
    // TODO It's better to build an object with the route name and parameters
    const link = `/workspaces/${workspaceId}/repos/${param.repoId}/branch/${encodeURIComponent(
      param.branch
    )}/${containerPart}/${resourceId}`;
    if (param.newTab) {
      const resolvedRoute = this.router.resolve(link);
      window.open(resolvedRoute.href, '_blank');
    } else {
      this.router.push(link);
    }
  }

  async getFileContent({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
  }): Promise<string | undefined> {
    return await this.fsWrapper.getFileContent({ repoId, filePath, revision });
  }

  openLink(href: string): void {
    const url = new URL(href);
    if (url.host === location.host && url.pathname === location.pathname) {
      this.router.push(`${url.pathname}${url.search}`).then(() => {
        this.router.replace(`${url.pathname}${url.search}${url.hash}`);
      });
    } else {
      window.open(href, '_blank');
    }
  }

  isDraft(unitId: string): boolean {
    return this.drafts3Store.drafts.get(unitId) != null;
  }

  async uploadImage(file: File, unitId: string): Promise<UploadedImage> {
    if (!this.shouldUploadFilesToRepo.value) {
      return uploadImage(this.drafts3Store.workspaceId, this.drafts3Store.repoId, file);
    }

    const imageDraft: ImageDraft = await createImageDraft(
      this.drafts3Store.workspaceId,
      this.drafts3Store.repoId,
      this.drafts3Store.branch,
      this.drafts3Store.userId,
      unitId,
      file
    );

    await this.drafts3Store.saveImageDraft(imageDraft);

    return { src: imageDraft.path, width: imageDraft.width, height: imageDraft.height };
  }

  async getImageFile(src: string, unitId: string): Promise<File> {
    const imageDraft: ImageDraft | undefined = await this.drafts3Store.getImageDraft(unitId, src);

    if (imageDraft) {
      return imageDraft.file;
    }

    const image = await this.fsWrapper.getFileContent({
      repoId: this.drafts3Store.repoId,
      filePath: removePrefix(decodeURI(src), '/'),
      revision: this.drafts3Store.branch,
      raw: true,
    });

    return base64ImageToFile(src, image);
  }

  trackEvent(eventName: string, properties: AnalyticsTrackProperties, options?: AnalyticsTrackOptions) {
    try {
      const docProps = {
        'Document Title': this.docTitle.value ?? 'Untitled',
        'Document ID': this.docId.value,
      };
      this.analytics.track(eventName, { ...docProps, properties }, options);
    } catch {
      // do nothing if trackEvent fails
    }
  }

  showNotification(text: string, params?: AddNotificationParams): void {
    this.notificationsStore.addNotification(text, params);
  }

  selectSnippets(): Observable<EditorSnippet | null> {
    return this.snippetSelection.selectSnippets();
  }

  editSnippet(snippet?: EditorSnippet): Observable<EditorSnippet | null> {
    return this.snippetSelection.editSnippet(snippet);
  }

  async endSnippetSelection(): Promise<void> {
    // do nothing
  }
  openGenerativeAiRepoSettings() {
    this.generativeAi.openGenerativeAiRepoSettings();
  }

  async generateTextModifier(request: {
    repoId: string;
    workspaceId: string;
    textInput: string;
    modifier: string;
  }): Promise<
    | (GenerativeAiResponse & GenerateTextModifiersResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return await this.generativeAi.generateTextModifier(request);
  }

  isAIGenerationEnabledForRepo(): boolean {
    return (
      this.store.getters['database/db_getRepoMetadata'](this.repoId.value)?.integrations?.generative_ai_enabled ?? false
    );
  }

  async generateSnippetComment(request: { repoId: string; workspaceId: string; snippetContent: string }): Promise<
    | (GenerativeAiResponse & GenerateSnippetCommentResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return await this.generativeAi.generateSnippetComment(request);
  }

  async generateMermaid(request: Omit<GenerateMermaidRequest, 'type'>): Promise<
    | (GenerativeAiResponse & GenerateMermaidResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return await this.generativeAi.generateMermaid(request);
  }

  async isQuotaExceeded(featureId: StiggFeatures): Promise<boolean> {
    return !this.stiggService.meteredFeatureAllowed(featureId);
  }

  async getDismissedSwimmportSuggestions(): Promise<string[]> {
    return [...(await getDismissedSuggestionsForDocument(this.repoId.value, this.docId.value, 0)).values()];
  }

  async dismissSwimmportSuggestion(text: string): Promise<void> {
    saveDismissedSuggestion({
      repoId: this.repoId.value,
      docId: this.docId.value,
      isTempDocId: 0,
      text,
    });
  }

  async setSmartElementCountInDB(
    _docId: string,
    _repoId: string,
    _autosyncOutput: NormalizedAutosyncOutput
  ): Promise<void> {
    // do nothing
  }

  private setAiFeatureAccessDataByWorkspace(workspaceId: string, feature: StiggFeatures, data: AiFeatureAccess) {
    if (!this.aiFeatureAccessDataByWorkspace[workspaceId]) {
      this.aiFeatureAccessDataByWorkspace[workspaceId] = {};
    }
    this.aiFeatureAccessDataByWorkspace[workspaceId][feature] = data;
  }

  async completeText(context: TextCompletionContext): Promise<{ generatedText: string; cost: number }> {
    const emptyResult = { generatedText: '', cost: 0 };
    if (isTest || window?.E2E_TEST_ENV === 'true' || !this.isAutoCompleteEnabled.value) {
      return emptyResult;
    }
    const textCompletionParams = getTextCompletionPrompt(this.docTitle.value, context);

    const workspaceId = this.drafts3Store.workspaceId;
    if (
      !isAccessAiFeatureAllowed(this.aiFeatureAccessDataByWorkspace[workspaceId], StiggFeatures.TEXT_COMPLETION_CAP)
    ) {
      return emptyResult;
    }
    const aiClient: AiClient = await WebAiClient.getInstance(workspaceId);
    try {
      const result = await aiClient.completeText({
        openAIParameters: textCompletionParams.prompt,
        workspaceId,
        requestId: uuid.v4(),
        textCompletionParams,
      });
      this.setAiFeatureAccessDataByWorkspace(workspaceId, StiggFeatures.TEXT_COMPLETION_CAP, {
        hasAccess: true,
        lastAccessCheckTimestamp: Date.now(),
      });
      return { generatedText: result.generatedText, cost: result.cost };
    } catch (err) {
      if (err instanceof QuotaExceededError) {
        this.setAiFeatureAccessDataByWorkspace(workspaceId, StiggFeatures.TEXT_COMPLETION_CAP, {
          hasAccess: false,
          lastAccessCheckTimestamp: Date.now(),
        });
      }
      return emptyResult;
    }
  }
  isPasteCodeWarningApplicable(): boolean {
    return false;
  }
  async markPasteCodeWarningDismissed(): Promise<void> {
    return;
  }
  allowDebugMermaidAi(): boolean {
    return isStaging || isDevelopment;
  }
}
