import { defineStore, storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { type WorkspaceDocumentTemplate, slugify } from '@swimm/shared';
import {
  createDocumentTemplate,
  fetchDocumentTemplate,
  fetchDocumentTemplates,
  fetchLastTemplateVersion,
} from '@/modules/core/services/workspace';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import main from '@/modules/customizations/custom-process-to-doc/ppg/main';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';

interface ProcessToDocModalOptions {
  unitId: string;
}

export const useProcessToDocStore = defineStore('processToDoc', () => {
  const store = useStore();
  const { user } = storeToRefs(useAuthStore());
  const { newDoc } = useDrafts3Store();
  const { id: workspaceId, currentUser } = storeToRefs(useWorkspaceStore());
  const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);

  const showProcessToDocModal = ref<boolean>(false);
  const showProcessToDocModalOptions = ref<ProcessToDocModalOptions>(null);
  const documentTemplates = ref<Map<string, WorkspaceDocumentTemplate>>(new Map());

  function openProcessToDocModal(options?: ProcessToDocModalOptions) {
    showProcessToDocModal.value = true;
    if (options) {
      showProcessToDocModalOptions.value = options;
    }
  }

  function closeProcessToDocModal() {
    showProcessToDocModal.value = false;
    showProcessToDocModalOptions.value = null;
  }

  async function getDocumentTemplate(templateId: string): Promise<WorkspaceDocumentTemplate> {
    if (!documentTemplates.value.has(templateId)) {
      return await fetchDocumentTemplate(workspaceId.value, templateId);
    }
    return documentTemplates.value.get(templateId);
  }

  async function refreshDocumentTemplates(): Promise<void> {
    documentTemplates.value = await fetchDocumentTemplates(workspaceId.value);
  }

  async function saveDocumentTemplate(template: string, name: string): Promise<string> {
    const currentVersion = await fetchLastTemplateVersion(workspaceId.value, name);
    const newVersion = currentVersion + 1;
    const templateId = `${slugify(name)}-${newVersion}`;

    await createDocumentTemplate(workspaceId.value, templateId, template, name, newVersion);
    void refreshDocumentTemplates();

    return templateId;
  }

  async function generate(
    document: { title: string; templateId: string; process: 'cdi' | 'cai'; filePaths: string[] },
    repoId: string,
    branch: string
  ): Promise<string> {
    const repoName: string = db_getRepoMetadata.value(repoId) ? db_getRepoMetadata.value(repoId).name : '';
    const template = (await getDocumentTemplate(document.templateId))?.text;

    const doc = await main(document.title, template, document.process, document.filePaths, {
      repoId,
      repoName,
      branch,
      user: {
        id: currentUser.value,
        name: user.value.displayName,
        email: user.value.email,
      },
    });

    return await newDoc(doc, { mergeBase: doc });
  }

  return {
    showProcessToDocModal,
    showProcessToDocModalOptions,
    openProcessToDocModal,
    closeProcessToDocModal,
    documentTemplates,
    refreshDocumentTemplates,
    getDocumentTemplate,
    saveDocumentTemplate,
    generate,
  };
});
