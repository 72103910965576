<template>
  <BaseBackgroundLayout :theme="BackgroundTheme.WAVE">
    <BaseCard class="card-container">
      <div>
        <SwText variant="headline2">Join your team on Swimm</SwText>
        <SwText variant="body-S" class="subtitle"
          >Join as member with <strong>{{ user.email }}</strong></SwText
        >
      </div>
      <WorkspaceRow :title="workspaceName">
        <Action class="action" :loading="loading" :disabled="requestSent" size="small" @click="onRequestToJoinClick">{{
          requestSent ? 'Request sent' : 'Request to join'
        }}</Action>
      </WorkspaceRow>
      <SwText variant="body-S" class="subtitle"
        >Once your request is approved by the admin, you will be able to access your workspace.</SwText
      >
      <SwText v-if="requestSent" variant="body-S" weight="bold" class="help-text">
        Admin approved your request? <router-link :to="`/workspaces/${workspaceId}`"> Go to workspace</router-link>
      </SwText>
      <SwText v-if="isUserHasWorkspaces" variant="body-S" weight="bold" class="help-text">
        <router-link to="/workspaces">Back to My Workspace</router-link>
      </SwText>
      <SwText v-else variant="body-S" weight="bold" class="help-text">
        Not part of this team?
        <router-link to="/workspaces/create">Create a new workspace</router-link>
      </SwText>
    </BaseCard>
  </BaseBackgroundLayout>
</template>

<script>
import { useAnalytics } from '@/common/composables/useAnalytics';
import WorkspaceRow from '@/common/pages/JoinWorkspace/WorkspaceRow.vue';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import BaseCard from '@/modules/core/components/BaseCard.vue';
import { BackgroundTheme } from '@/modules/onboarding/consts';
import BaseBackgroundLayout from '@/modules/onboarding/layouts/BaseBackgroundLayout.vue';
import { pageEvents, productEvents } from '@swimm/shared';
import { SwText } from '@swimm/ui';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { mapActions, mapGetters } from 'vuex';
import { requestInvite } from '@/common/utils/workspace-utils';
import { localStorageKeys } from '@/common/consts';
import LocalStorage from '@/local-storage';
import { state } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';

export default {
  components: { BaseBackgroundLayout, SwText, WorkspaceRow, BaseCard },
  props: {
    workspaceId: { type: String, required: true },
  },
  setup() {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const route = useRoute();
    const workspaceName = ref(route.query?.workspaceName ?? '');
    const pageSource = route.query?.source;
    return { user, analytics, workspaceName, pageSource, BackgroundTheme };
  },
  data() {
    return {
      loading: true,
      requestSent: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getUserWorkspaces']),
    userWorkspaces() {
      return this.db_getUserWorkspaces(this.user.uid);
    },
    isUserHasWorkspaces() {
      return !!Object.keys(this.userWorkspaces).length;
    },
  },
  async created() {
    // in case the workspaces were not fetched yet (if this is direct link)
    await this.fetchSwimmerWorkspaces();
    this.analytics.pageVisit(pageEvents.VIEW_JOIN_WORKSPACE, {
      'Workspace ID': this.workspaceId,
      ...(this.pageSource && { Source: this.pageSource }),
    });
    if (this.workspaceId && this.userWorkspaces[this.workspaceId]) {
      this.$logger.debug(`User is already a member of workspace ${this.workspaceId} - routing to workspace`, {
        service: 'join-workspace',
      });
      this.$router.push(`/workspaces/${this.workspaceId}`);
      return;
    }

    if (!this.isUserHasWorkspaces) {
      LocalStorage.set(localStorageKeys.SHOULD_OPEN_GET_STARTED, true);
      await state.set({ key: 'should_show_welcome_modal', value: true });
    }
    const response = await CloudFunctions.isRequestedToJoinWorkspace({ workspaceId: this.workspaceId });
    this.requestSent = !!response?.data?.isRequestedToJoin;
    this.workspaceName = this.workspaceName || response?.data?.workspaceName;
    this.loading = false;
  },
  methods: {
    ...mapActions('database', ['fetchSwimmerWorkspaces']),
    async onRequestToJoinClick() {
      this.requestSent = true;
      this.analytics.track(productEvents.CLICKED_REQUEST_TO_JOIN, {
        Origin: 'Join Workspace',
      });
      this.$logger.debug(`Requesting to join workspace ${this.workspaceId} for user ${this.user}`, {
        service: 'join-workspace',
      });
      await requestInvite(this.workspaceId);
    },
  },
};
</script>

<style scoped lang="postcss">
.card-container {
  margin: auto;
  gap: var(--space-md);

  .subtitle {
    color: var(--text-color-secondary);
  }

  .action {
    flex-shrink: 0;
    white-space: nowrap;
  }

  .help-text {
    a {
      color: var(--text-color-link);
    }
  }
}

.background {
  [data-theme='dark'] & {
    background: var(--color-onboarding-gradient-dark);
  }

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--color-onboarding-gradient-light);

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 420px;
    padding: 40px;
    border-radius: 8px;
    background-color: var(--color-bg);

    .section {
      margin-bottom: 24px;

      &.header {
        text-align: center;
      }

      &.description {
        margin-top: 0;
        color: var(--text-color-secondary);
      }
    }

    .action-wrapper {
      align-self: stretch;
      padding: 8px 16px;
      border-radius: 6px;
      text-align: center;
      background-color: var(--color-surface);

      .link {
        font-weight: 800;
        color: var(--text-color-link);
        cursor: pointer;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .content {
    width: 100%;
  }
}
</style>
