/**
 * swmMention node.
 *
 * Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-code/src/code.ts but adapted to a Node.
 *
 * @module
 */

import { productEvents } from '@swimm/shared';
import { type JSONContent, Node, VueNodeViewRenderer, VueRenderer, mergeAttributes } from '@tiptap/vue-3';
import SwmMentionNodeView from '../nodeViews/SwmMentionNodeView.vue';
import { isParentOfType } from '../utils';
import { PluginKey } from '@tiptap/pm/state';
import Suggestion from '@tiptap/suggestion';
import { ALLOWED_PREFIXES } from '@swimm/editor';
import type { Instance } from 'tippy.js';
import SwmMentionSelectionPopover from '@/components/SwmMentionSelectionPopover.vue';
import tippy from 'tippy.js';
import { type User, workspaceUserToUser } from '@swimm/reefui';
import { getSwimmEditorServices } from './Swimm';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmMention: {
      insertSwmMention: (uid: string, name: string, email: string) => ReturnType;
      openSwmMentionSelectionMenu: () => ReturnType;
    };
  }
}

export interface SwmMentionOptions {
  /**
   * Custom HTML attributes that should be added to the rendered HTML tag.
   */
  HTMLAttributes: Record<string, unknown>;
}

export interface SwmMentionStorage {
  shown: boolean;
}

export default Node.create<SwmMentionOptions, SwmMentionStorage>({
  name: 'swmMention',

  group: 'inline',

  inline: true,

  addOptions() {
    return {
      HTMLAttributes: {
        target: '_blank',
        class: null,
      },
    };
  },

  addStorage() {
    return {
      shown: false,
    };
  },

  addAttributes() {
    return {
      uid: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('data-uid');
        },
        renderHTML: (attributes) => {
          return { 'data-uid': attributes.path };
        },
      },
      name: {
        isRequired: true,
        parseHTML: (element) => {
          return element.textContent;
        },
        rendered: false,
      },
      email: {
        isRequired: true,
        parseHTML: (element) => {
          return element.getAttribute('href')?.replace(/^mailto:/, '');
        },
        renderHTML: (attributes) => {
          return { href: attributes.email === '#' ? attributes.email : `mailto:${attributes.email}` };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        priority: 51,
        // Based on https://github.com/ueberdosis/tiptap/blob/164eebf07ced16e29e38f13909322ce301575355/packages/extension-link/src/link.ts#L117
        tag: 'a[href]:not([href *= "javascript:" i])',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-mention') && null,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'a',
      mergeAttributes(
        {
          'data-swm-mention': '',
        },
        this.options.HTMLAttributes,
        HTMLAttributes
      ),
      node.attrs.name,
    ];
  },

  renderText({ node }) {
    return node.attrs.name;
  },

  addNodeView() {
    return VueNodeViewRenderer(SwmMentionNodeView);
  },

  addCommands() {
    return {
      insertSwmMention:
        (uid, name, email) =>
        ({ commands }) => {
          const node: JSONContent = {
            type: this.name,
            attrs: {
              uid,
              name,
              email,
            },
          };

          return commands.insertContent(node);
        },

      openSwmMentionSelectionMenu:
        () =>
        ({ commands, editor, state }) => {
          if (!isParentOfType(state.doc, state.selection.head, ['paragraph', 'tableHeader', 'tableCell', 'heading'])) {
            return false;
          }

          const swimmEditorServices = getSwimmEditorServices(editor);
          if (swimmEditorServices.isTemplate) {
            return false;
          }

          if (swimmEditorServices.isAirGap) {
            return false;
          }

          const previousChar = state.doc.textBetween(state.selection.from - 1, state.selection.from);
          if (previousChar !== '' && previousChar !== ' ') {
            return commands.insertContent(' @');
          }

          return commands.insertContent('@');
        },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion<User>({
        pluginKey: new PluginKey(this.name),
        editor: this.editor,
        char: '@',
        allowSpaces: true,
        allowedPrefixes: ALLOWED_PREFIXES,

        command: ({ editor, range, props }) => {
          editor
            .chain()
            .deleteRange(range)
            .insertSwmMention(props.id, props.name, props.email || '')
            .run();
          const swimmEditorServices = getSwimmEditorServices(editor);
          swimmEditorServices.external.trackEvent(productEvents.MENTION_ADDED, {});
        },

        items: async ({ editor, query }) => {
          const swimmEditorServices = getSwimmEditorServices(editor);
          if (swimmEditorServices.isAirGap) {
            return [];
          }
          const users = (await swimmEditorServices.external.getWorkspaceUsers()).map(workspaceUserToUser);

          return users
            .filter((user: User) => user.name)
            .filter((user: User) => user.name.toLowerCase().includes(query.toLowerCase()));
        },

        render: () => {
          let component: VueRenderer | null = null;
          let popup: Instance | null = null;
          const storage = this.storage;

          function destroy() {
            if (popup != null) {
              popup.destroy();
              component?.destroy();
              popup = null;
              component = null;
            }
          }

          return {
            onStart: (props) => {
              const swimmEditorServices = getSwimmEditorServices(props.editor);
              if (swimmEditorServices.isAirGap) {
                return false;
              }
              component = new VueRenderer(SwmMentionSelectionPopover, {
                props,
                editor: props.editor,
              });

              if (!props.clientRect) {
                return;
              }

              popup = tippy(props.editor.view.dom, {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getReferenceClientRect: () => props.clientRect!()!,
                appendTo: document.body,
                content: component.element,
                showOnCreate: true,
                interactive: true,
                trigger: 'manual',
                placement: 'bottom-start',
                theme: 'none',
                role: 'menu',
                maxWidth: 'none',
                onShow(_instance) {
                  storage.shown = true;
                },
                onHide(_instance) {
                  storage.shown = false;
                },
              });
            },

            onUpdate(props) {
              component?.updateProps(props);

              if (!props.clientRect) {
                return;
              }

              popup?.setProps({
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getReferenceClientRect: () => props.clientRect!()!,
              });
            },

            onKeyDown(props) {
              if (props.event.key === 'Escape') {
                destroy();

                return true;
              }

              return component?.ref?.onKeyDown?.(props.event);
            },

            onExit() {
              destroy();
            },
          };
        },
      }),
    ];
  },
});
