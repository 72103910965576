<script setup lang="ts">
import { BaseButton, BaseLayoutGap, BaseProse, type IconsType } from '@swimm/reefui';
import type { EditorState } from '@tiptap/pm/state';
import { type Command, type Editor, FloatingMenu } from '@tiptap/vue-3';
import { type Editor as CoreEditor } from '@tiptap/core';
import type { EditorView } from '@tiptap/pm/view';
import SwmdFloatingMenuItem from './SwmdFloatingMenuItem.vue';
import GenerateSnippetCommentMenuItem from './GenerateSnippetCommentMenuItem.vue';
import { type Component, computed, ref } from 'vue';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { productEvents } from '@swimm/shared';

interface FloatingMenuItem {
  name: string;
  icon: IconsType;
  tooltip: string;
  command?: () => Command;
  component?: Component;
}

const props = defineProps<{
  editor?: Editor;
}>();

const shown = ref(false);

const GENERATE_SNIPPET_COMMENT_MENU_ITEM: FloatingMenuItem = {
  name: 'generate-snippet-comment',
  icon: 'magic',
  tooltip: 'AI',
  component: GenerateSnippetCommentMenuItem,
};

// TODO: Uncomment when bringing back the content suggestions.
// const AI_CONTENT_SUGGESTIONS_MENU_ITEM: FloatingMenuItem = {
//   name: 'ai-content-suggestions',
//   icon: 'magic',
//   tooltip: 'AI',
//   component: AiContentSuggestionsMenuItem,
// };

const FLOATING_MENU_ITEMS: FloatingMenuItem[] = [
  {
    name: 'snippet',
    icon: 'terminal',
    tooltip: 'Code Snippet',
    command:
      () =>
      ({ commands, editor }) => {
        if (editor.isActive('swmSnippet')) {
          return false;
        }

        return commands.selectAndInsertSwmSnippets();
      },
  },
  {
    name: 'token',
    icon: 'sync-token',
    tooltip: 'Smart Token',
    command:
      () =>
      ({ chain }) => {
        return chain().focus().openSwmTokenSelectionMenu().run();
      },
  },
  {
    name: 'doc',
    icon: 'doc',
    tooltip: 'Link to Swimm doc',
    command:
      () =>
      ({ commands }) => {
        return commands.selectAndInsertSwmLinkToDoc();
      },
  },
  {
    name: 'image',
    icon: 'image',
    tooltip: 'An image',
    command:
      () =>
      ({ commands }) => {
        return commands.pickAndInsertImage();
      },
  },
  {
    name: 'table',
    icon: 'table',
    tooltip: 'A table',
    command:
      () =>
      ({ chain }) => {
        return chain().focus().insertTable({ withHeaderRow: true }).run();
      },
  },
  {
    name: 'mermaid',
    icon: 'graph-flow',
    tooltip: 'Mermaid diagram',
    command:
      () =>
      ({ chain }) => {
        return chain().focus().insertMermaid().selectParentNode().run();
      },
  },
];

const isGenAiDisabledInWorkspace = computed(() => {
  if (!props.editor) {
    return false;
  }

  const swimmEditorServices = getSwimmEditorServices(props.editor);
  return swimmEditorServices.external.isGenAiDisabledInWorkspace.value;
});

const isInSnippet = computed(() => props.editor?.state.selection.$anchor.node(-1)?.type.name === 'swmSnippet');

const AiMenuItems = computed(() => {
  if (isGenAiDisabledInWorkspace.value || !props.editor?.isEditable) {
    return [];
  }
  // TODO: if we don't want to show any menu AI action in the floating menu in snippet that GENERATE_SNIPPET_COMMENT_MENU_ITEM can be removed
  // TODO: bring back AI_CONTENT_SUGGESTIONS_MENU_ITEM
  return isInSnippet.value ? [GENERATE_SNIPPET_COMMENT_MENU_ITEM] : [];
});

const menuItems = computed(() => [...AiMenuItems.value, ...FLOATING_MENU_ITEMS]);

const isVisible = computed(() => {
  if (!props.editor) {
    return false;
  }

  return shouldShow({ editor: props.editor, view: props.editor.view, state: props.editor.state });
});

/**
 * Based on https://github.com/ueberdosis/tiptap/blob/f387ad3dd4c2b30eaea33fb0ba0b42e0cd39263b/packages/extension-floating-menu/src/floating-menu-plugin.ts#L38-L55
 */
function shouldShow({ editor, view, state }: { editor: CoreEditor; view: EditorView; state: EditorState }): boolean {
  const { selection } = state;
  const { $anchor, empty } = selection;
  const isRootDepth = $anchor.depth === 1;
  const isAllowedBelowRoot = ['swmSnippet'].includes($anchor.node(-1)?.type.name);
  const isEmptyTextBlock =
    $anchor.parent.isTextblock && !$anchor.parent.type.spec.code && $anchor.parent.content.size === 0;

  if (!view.hasFocus() || !empty || (!isRootDepth && !isAllowedBelowRoot) || !isEmptyTextBlock || !editor.isEditable) {
    return false;
  }
  return true;
}

function triggerCommand(item: FloatingMenuItem): boolean {
  if (!props.editor || !item.command) {
    return false;
  }

  const swimmEditorServices = getSwimmEditorServices(props.editor);
  swimmEditorServices.external.trackEvent(productEvents.FLOATING_MENU_CLICKED, { 'Item Name': item.name });
  return props.editor.commands.command(item.command());
}
</script>

<template>
  <FloatingMenu
    v-if="editor"
    class="floating-menu"
    data-testid="swmd-floating-menu"
    :editor="editor"
    :tippy-options="{
      appendTo: (ref: Element) => ref.parentElement!,
      duration: 100,
      theme: 'none',
      maxWidth: '100%',
      zIndex: 60,
      onShow: () => {
        shown = true;
      },
      onHidden: () => {
        shown = false;
      }
    }"
    :should-show="shouldShow"
  >
    <BaseLayoutGap v-if="shown" size="xxsmall" class="floating-menu-layout">
      <BaseProse v-if="!isInSnippet" class="placeholder" variant="placeholder" @click="editor.commands.focus()"
        >Type <span class="slash">/</span> to browse options</BaseProse
      >
      <BaseProse v-else class="placeholder" variant="placeholder" @click="editor.commands.focus()">
        <span v-if="isGenAiDisabledInWorkspace">Type <span class="slash">/</span> to browse options</span>
        <span v-else>Use <span class="slash">/ai</span> to describe this snippet, or write your own</span>
      </BaseProse>
      <template v-if="!isInSnippet">
        <BaseLayoutGap size="xxsmall" class="floating-menu-layout-actions">
          <span v-for="item of menuItems" :key="item.name" class="floating-menu-item">
            <component
              :is="item.component ?? SwmdFloatingMenuItem"
              v-if="editor.can().command(item.command != null ? item.command() : () => true)"
              :editor="editor"
              :name="item.name"
              :icon="item.icon"
              :tooltip="item.tooltip"
              :is-visible="isVisible"
              :command="item.command && (() => triggerCommand(item))"
            />
          </span>
          <div class="separator"></div>
          <BaseButton
            variant="placeholder"
            size="small"
            data-testid="floating-menu-item-more"
            class="more-button"
            @click="() => editor?.commands.openSlashCommandsMenu()"
            >More</BaseButton
          >
        </BaseLayoutGap>
      </template>
    </BaseLayoutGap>
  </FloatingMenu>
</template>

<style scoped lang="scss">
.placeholder {
  user-select: none;
  flex-shrink: 0;
}

.floating-menu-layout {
  align-items: center;
}

.floating-menu-layout-actions {
  align-items: center;
  padding-top: 2px;
}

.slash {
  display: inline-block;
  padding: 0 0.2em;
  border-radius: 4px;
  text-align: center;
  background-color: var(--color-status-default);
}

.separator {
  display: inline-block;
  border-left: 1px solid var(--color-border-default-subtle);
  height: 1.5em;
}
</style>
