import type { Editor } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-3';
import type { Instance } from 'tippy.js';
import tippy from 'tippy.js';

import { productEvents, removePrefix } from '@swimm/shared';

import type { SelectedSuggestion } from '@/components/SwimmportPopover.vue';
import SwimmportPopover from '@/components/SwimmportPopover.vue';
import { getSwimmEditorServices } from './extensions/Swimm';

export function showCaretSwimmportPopover(
  editor: Editor,
  element: HTMLElement,
  textRange: { from: number; to: number },
  props: Record<string, unknown> = {},
  popoverOptions: { onShow?: () => void; onHide?: () => void } = {}
): { open: () => void; close: () => void; onKeyDown: (event: KeyboardEvent) => boolean } {
  let component: VueRenderer | null = null;
  let popup: Instance | null = null;
  let closed = false;

  let selected: SelectedSuggestion | undefined;

  const promise = new Promise<void>((resolve) => {
    if (closed) {
      resolve();
    }

    component = new VueRenderer(SwimmportPopover, {
      props: {
        ...props,
        editor,
        hide: () => popup?.hide(),
        onSelected: (newSelected: SelectedSuggestion) => {
          selected = newSelected;
          resolve();
        },
        onPreviewShown: () => popup?.popperInstance?.update(),
      },
      editor,
    });

    const handleUpdate = () => {
      popup?.hide();
    };

    popup = tippy(editor.view.dom, {
      getReferenceClientRect: () => {
        return element.getBoundingClientRect();
      },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      appendTo: editor.view.dom.parentElement!,
      content: component.element,
      showOnCreate: true,
      interactive: true,
      trigger: 'manual',
      placement: 'bottom-start',
      theme: 'none',
      role: 'menu',
      maxWidth: 'none',
      offset: [0, 0],
      onHidden: (instance) => {
        instance.destroy();
        component?.destroy();
        component = null;
        popup = null;
        editor.off('update', handleUpdate);
        resolve();
      },
      ...popoverOptions,
    });

    editor.on('update', handleUpdate);
  });

  promise.then(() => {
    if (selected != null) {
      if (selected.type === 'token') {
        editor
          .chain()
          .focus(textRange.to)
          .deleteRange(textRange)
          .insertSwmToken(
            selected.suggestion.token,
            `/${removePrefix(selected.suggestion.position.path, '/')}`,
            selected.suggestion.position,
            selected.suggestion.lineData,
            selected.suggestion.repoId
          )
          .run();
      } else {
        if (selected?.suggestion?.repoId) {
          editor
            .chain()
            .focus(textRange.to)
            .deleteRange(textRange)
            .insertSwmPath(selected.suggestion.path, selected.suggestion.repoId, selected.suggestion.type)
            .run();
        }
      }

      getSwimmEditorServices(editor).external.trackEvent(productEvents.SWIMMPORT_SUGGESTION_APPLIED, {
        Context: 'Swimmport',
        Source: 'caret',
        IsPath: selected.type === 'path',
      });
    }
  });

  const close = () => {
    closed = true;
    if (popup == null || popup.state.isDestroyed) {
      return;
    }
    popup.hide();
  };

  const open = () => {
    closed = true;
    if (popup == null || popup.state.isDestroyed) {
      return;
    }
    popup.show();
  };

  const onKeyDown = (event: KeyboardEvent) => component?.ref?.onKeyDown?.(event) ?? false;

  return { open, close, onKeyDown };
}
