<script setup>
import { computed } from 'vue';
import { BaseButton, BaseIcon, BaseLayoutGap, BaseProse, BaseTextarea } from '@swimm/reefui';
import { Loader } from '@swimm/ui';

const emit = defineEmits(['stop-generating']);
const props = defineProps({
  generationTime: { type: Number, required: true },
  customPrompt: { type: String, required: false },
});

const aiGenerationProgress = computed(() => {
  if (!props.generationTime) {
    return null;
  }
  if (props.generationTime < 2000) {
    return 1;
  } else if (props.generationTime < 5000) {
    return 10;
  } else if (props.generationTime < 15000) {
    return 30;
  } else if (props.generationTime < 45000) {
    return 60;
  } else if (props.generationTime < 65000) {
    return 80;
  } else {
    return 95;
  }
});

const aiGenerationText = computed(() => {
  if (!aiGenerationProgress.value) {
    return '';
  }
  switch (aiGenerationProgress.value) {
    case 1: {
      return 'Fetching code snippets';
    }
    case 10: {
      return 'Sending your request';
    }
    case 30: {
      return 'Analyzing code';
    }
    case 60: {
      return 'Drafting your doc';
    }
    case 80: {
      return 'This may take a little longer ☕';
    }
    case 95: {
      return 'Almost there';
    }
    default: {
      return 'Generating a doc from your code';
    }
  }
});

function stopGenerating() {
  emit('stop-generating');
}
</script>

<template>
  <BaseLayoutGap direction="column" size="xsmall" alignment="stretch" class="container">
    <BaseLayoutGap class="header" direction="row" alignment="center" size="xxsmall">
      <Loader secondary small />
      <BaseProse weight="bold">{{ aiGenerationProgress ? aiGenerationText : 'Generating...' }}</BaseProse>
      <BaseButton variant="secondary" @click="stopGenerating" class="stop-button">
        <template #leftIcon>
          <BaseIcon name="stop" />
        </template>
        Stop
      </BaseButton>
    </BaseLayoutGap>
    <BaseTextarea
      v-if="customPrompt"
      :model-value="customPrompt"
      static
      :rows="Math.min(customPrompt.split('\n').length, 3)"
    />
    <div class="body">
      <progress class="ai-progress" :value="aiGenerationProgress" max="100">{{ aiGenerationProgress }}%</progress>
    </div>
  </BaseLayoutGap>
</template>

<style scoped lang="postcss">
.container {
  .header {
    @keyframes dots {
      0% {
        content: '.   ';
      }

      20% {
        content: '..  ';
      }

      50% {
        content: '... ';
      }

      80% {
        content: '....';
      }
    }

    .ai-loading-text::after {
      display: contents;
      animation: dots 2s ease infinite forwards;
      content: '.   ';
      position: absolute;
      left: 2px;
      z-index: 0;
      user-select: none;
      overflow: hidden;
      white-space: pre; /* Keeps the content with a constant width so that the 'beta' tag doesn't jump. */
      margin: 0 auto; /* Gives that scrolling effect as the typing happens */
      font-family: ui-monospace; /* Keep width constant. */
    }

    .ai-loading-text {
      color: var(--text-color-secondary);
    }
  }

  .body {
    progress.ai-progress {
      -webkit-appearance: none;
      appearance: none;
      height: 6px;
      width: 100%;
      margin: var(--space-xs) 0;
    }

    progress.ai-progress::-webkit-progress-bar {
      border-radius: 80px;
      background-color: var(--color-hover);
    }

    progress.ai-progress::-webkit-progress-value {
      transition: width ease 30s;
      border-radius: 20px;
      background-color: var(--text-color-magic-strong);
    }
  }

  .stop-button {
    margin-left: auto !important;
  }
}
</style>
