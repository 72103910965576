<script setup lang="ts">
import { computed, ref, toRaw, toRef, watch } from 'vue';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { useMermaid } from '@/composables/mermaid';
import { uniqueId } from 'lodash-es';
import { generatePlainMermaidText } from '@/swmd/mermaid';
import type { RenderResult } from 'mermaid';
import type * as _model from '@tiptap/pm/model';
import { watchDebounced } from '@vueuse/core';
import MermaidNodeViewInner from './MermaidNodeViewInner.vue';
import { getSwimmEditorServices } from '../extensions/Swimm';
import {
  ApplicabilityStatus,
  type GenerateMermaidResponse,
  type GenerativeAiResponse,
  type InvalidMermaidData,
  StiggFeatures,
  getLoggerNew,
  productEvents,
} from '@swimm/shared';
import type {
  AnalyticsTrackProperties,
  GenerateMermaidWithAiDebugData,
  GenerateMermaidWithAiResult,
  MermaidSampleOption,
} from '@swimm/editor';
import { getSwimmNodeId } from '@/swmd/swimm_node';
import { useTiptapIsSelected } from '@/composables/tiptapIsSelected';
import type { Slice } from '@tiptap/pm/model';
import { nodesToMarkdown } from '@/swmd/nodesToMarkdown';

type GenerateMermaidResultType =
  | (GenerativeAiResponse & GenerateMermaidResponse)
  | (GenerativeAiResponse & {
      status: 'error';
    });

class MermaidAbortError extends Error {}

const logger = getLoggerNew(__modulename);

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);
const { mermaid, mermaidIsReady, mermaidPackageVersion } = useMermaid({
  isDarkMode: swimmEditorServices.external.isDarkMode,
});

const { selected, highlighted } = useTiptapIsSelected(
  toRef(() => props.editor),
  toRef(() => props.node),
  toRef(() => props.getPos),
  { inclusiveEnd: true }
);

const manuallyClosed = ref(false);

const showEditor = computed(() => selected.value && isEditMode.value && !manuallyClosed.value);

const toggleEdit = () => {
  if (showEditor.value) {
    // Note - we can't move the focus outside the mermaid node because it might just be the only thing in the document,
    // so there's no after it or before it, and just blurring the editor's focus isn't enough to make 'selected' turn
    // off.
    // We therefore use this manual variable to manually close the editor, and make sure to clear this variable when
    // the cursor actually moves (see watch below).
    manuallyClosed.value = true;
  } else {
    // To show the editor, we do move the focus to the beginning of the mermaid diagram as it is both required in
    // showEditor and makes sense that the user would want to start typing inside the mermaid diagram.
    props.editor
      .chain()
      .focus(props.getPos() + 1)
      .run();
    manuallyClosed.value = false;
  }
};

// If user clicks somewhere else, reset the manually closed flag.
watch(
  () => props.editor,
  (editor, _, onCleanup) => {
    const onSelectionUpdate = () => {
      manuallyClosed.value = false;
    };
    editor.on('selectionUpdate', onSelectionUpdate);
    onCleanup(() => {
      editor.off('selectionUpdate', onSelectionUpdate);
    });
  },
  {
    immediate: true,
  }
);

const rendered = ref<RenderResult | null>(null);
const error = ref<string | null>(null);

// hold the (last) valid svg,
// this is since we want to display last svg
// if you have valid svg and inserted error
// so it is not reset when there is an error
const lastValidSvg = ref<string | null>(null);

watchDebounced(
  () => [mermaidIsReady.value, props.node, swimmEditorServices.autosyncOutput.value] as const,
  async ([mermaidIsReady, node]) => {
    if (!mermaidIsReady || mermaid.value == null) {
      return;
    }

    if (node.content.size === 0) {
      rendered.value = null;
      error.value = null;
      lastValidSvg.value = null;
      return;
    }
    // mermaid removes the element by its id from the DOM before it renders again
    // but we want to show the previous svg and nothing retriggers the inner component to re-render the svg
    // there are several ways to baypass it, for now, we just use new id everytime
    const id = uniqueId('mermaid-');
    try {
      rendered.value = await mermaid.value.render(
        id,
        generatePlainMermaidText(toRaw(node), swimmEditorServices.autosyncOutput.value?.smartElements)
      );
      lastValidSvg.value = rendered.value.svg;
      error.value = null;
    } catch (err) {
      error.value = (err as Error).toString();
      // mermaid adding div with error at the bottom
      // https://github.com/mermaid-js/mermaid/issues/4730#issuecomment-1683660202
      // https://github.com/mermaid-js/mermaid-live-editor/pull/1288
      const errorDiv = document.querySelector(`#d${id}`);
      errorDiv?.remove();
    }
  },
  { immediate: true, debounce: 500 }
);

const isEmpty = computed(() => props.node.content.size === 0);
const isEditMode = computed(() => {
  return swimmEditorServices.editable.value;
});
const diagramSupportTokens = computed(() => {
  const DIAGRAMS_WITHOUT_TOKEN_SUPPORT = ['gitGraph'];
  const diagramString = generatePlainMermaidText(toRaw(props.node)).trim();
  return !DIAGRAMS_WITHOUT_TOKEN_SUPPORT.some((type) => diagramString.startsWith(type));
});

// return applicability for current mermaid, based on the tokens
const applicability = computed<
  ApplicabilityStatus.Verified | ApplicabilityStatus.Outdated | ApplicabilityStatus.Autosyncable
>(() => {
  if (tokensStatus.value.some((s) => s === ApplicabilityStatus.Outdated)) {
    return ApplicabilityStatus.Outdated;
  }
  if (tokensStatus.value.some((s) => s === ApplicabilityStatus.Autosyncable)) {
    return ApplicabilityStatus.Autosyncable;
  }
  return ApplicabilityStatus.Verified;
});

// return list of applicabilities for the tokens inside mermaid
// skips tokens that has no applicability
const tokensStatus = computed<ApplicabilityStatus[]>(() => {
  const result: ApplicabilityStatus[] = [];
  props.node.descendants((node) => {
    if (node.type.name === 'swmToken') {
      const nodeId = getSwimmNodeId(node);
      const smartElement = swimmEditorServices.autosyncOutput.value.smartElements.get(nodeId);
      if (smartElement?.applicability) {
        result.push(smartElement.applicability);
      }
    }
  });
  return result;
});

function sampleSelected(selectedSample: MermaidSampleOption) {
  props.editor.chain().focus().replaceMermaidContent(props.getPos(), selectedSample.content).run();
  swimmEditorServices.external.trackEvent(productEvents.SELECTED_MERMAID_SAMPLE, {
    ['Sample Name']: selectedSample.label,
  });
}

function mermaidLiveClicked() {
  swimmEditorServices.external.trackEvent(productEvents.CLICKED_MERMAID_LIVE_EDITOR_LINK, {});
}

const isAiGenerationHidden = computed(() => {
  // return true if we want to disable the fetaure
  return swimmEditorServices.external.isGenAiDisabledInWorkspace.value;
});

async function getBeforeAndAfterContext(): Promise<{ before: string; after: string }> {
  const pos = props.getPos();
  const doc = props.editor.state.doc;
  // the slice should have been pos-1, but it seems that getPos() returns the position before
  const nodes: Slice = doc.slice(0, pos, true);
  const before = await nodesToMarkdown(nodes, {
    repoId: swimmEditorServices.repoId.value,
    workspaceId: swimmEditorServices.workspaceId.value,
    baseUrl: swimmEditorServices.baseUrl,
  });
  const afterPos = pos + props.node.nodeSize + 1;
  const endDocPos = props.editor.$doc.to - 1;
  let after = '';
  if (endDocPos > afterPos) {
    const afterNodes = doc.slice(afterPos);
    after = await nodesToMarkdown(afterNodes, {
      repoId: swimmEditorServices.repoId.value,
      workspaceId: swimmEditorServices.workspaceId.value,
      baseUrl: swimmEditorServices.baseUrl,
    });
  }
  return { before, after };
}

function beforeOpenAiPanel(): boolean {
  const isRepoAIGenerationEnabled = swimmEditorServices.external.isAIGenerationEnabledForRepo();
  if (!isRepoAIGenerationEnabled) {
    swimmEditorServices.setShowAiGenerationDisabledModal(true, 'Mermaid Generation');
  }
  return isRepoAIGenerationEnabled;
}

async function checkForQuotaExceeded(): Promise<boolean> {
  return swimmEditorServices.external.isQuotaExceeded(StiggFeatures.GENERATIVE_AI_CAP);
}

async function generateWithAi({
  userPrompt,
  selectedSample,
  abort,
}: {
  userPrompt: string;
  selectedSample: MermaidSampleOption | null;
  abort: AbortController;
}): Promise<GenerateMermaidWithAiResult> {
  swimmEditorServices.external.trackEvent(productEvents.CLICKED_GENERATE_MERMAID_AI, {
    'Was Empty': isEmpty.value,
    'Total Snippet Count': swimmEditorServices.snippetsInDocumentCount.value,
    'Selected Diagram Type': selectedSample?.label ?? 'Auto Detect',
  });
  let shouldRetry = true;
  let result: Awaited<ReturnType<typeof generateWithAiInner>> | undefined;
  let tryIndex = 0;
  do {
    tryIndex++;
    const prevInvalidMermaidData = result?.invalidMermaidData;
    result = await generateWithAiInner({
      userPrompt,
      selectedSample,
      abort,
      tryIndex,
      prevInvalidMermaidData,
    });
    // try again once in case of ai error
    shouldRetry = !!result.isAiError && tryIndex <= 1;
  } while (shouldRetry);
  if (result.status === 'error') {
    swimmEditorServices.external.trackEvent(productEvents.ERROR_STREAMING_MERMAID, {
      'Try Index': tryIndex,
      'Is Ai Error': !!result.isAiError,
      'Generated Invalid Mermaid': result.invalidMermaidData != null,
    });
  } else {
    const props: AnalyticsTrackProperties = {};
    if (result.status === 'regenerate' || result.status === 'stop') {
      props['Is Aborted'] = true;
      props['Try Index'] = tryIndex;
    }
    swimmEditorServices.external.trackEvent(productEvents.FINISED_GENERATING_MERMAID, props);
  }
  return result;
}

async function generateWithAiInner({
  userPrompt,
  selectedSample,
  abort,
  tryIndex,
  prevInvalidMermaidData, // previous invalid mermaid - if there is any
}: {
  userPrompt: string;
  selectedSample: MermaidSampleOption | null;
  abort: AbortController;
  tryIndex: number;
  prevInvalidMermaidData?: InvalidMermaidData;
}): Promise<
  GenerateMermaidWithAiResult & {
    invalidMermaidData?: InvalidMermaidData;
    isAiError?: boolean;
  }
> {
  const debugData: GenerateMermaidWithAiDebugData = {
    userPrompt,
    selectedSample: selectedSample?.label ?? 'auto',
    repoId: swimmEditorServices.repoId.value,
    workspaceId: swimmEditorServices.workspaceId.value,
    unitId: swimmEditorServices.unitId.value,
    startTimestamp: Date.now(),
  };
  let invalidMermaidData: InvalidMermaidData | undefined;
  let lastStep = 'start';
  try {
    Object.assign(debugData, { doc: props.editor.state.doc.toJSON(), pos: props.getPos() });
    lastStep = 'Before getBeforeAndAfterContext';
    const context = await getBeforeAndAfterContext();
    lastStep = 'After getBeforeAndAfterContext';
    Object.assign(debugData, { context });
    logger.info(
      `Mermaid generateWithAi: tryIndex=${tryIndex} prevInvalidMermaidExists: ${
        prevInvalidMermaidData != null
      } userPrompt.length=${userPrompt.length} selectedSample=${selectedSample?.label ?? 'auto'} before.length=${
        context.before.length
      } after.length=${context.after.length}`
    );
    lastStep = 'Before swimmEditorServices.external.generateMermaid';
    const resp = await Promise.race<GenerateMermaidResultType>([
      swimmEditorServices.external.generateMermaid({
        repoId: swimmEditorServices.repoId.value,
        workspaceId: swimmEditorServices.workspaceId.value,
        before: context.before,
        after: context.after,
        userPrompt,
        selectedSample,
        prevInvalidMermaidData,
      }),
      new Promise((_resolve, reject) => {
        abort.signal.onabort = () => {
          reject(new MermaidAbortError());
        };
      }),
    ]);
    lastStep = 'After swimmEditorServices.external.generateMermaid';
    Object.assign(debugData, { resp, endTimestamp: Date.now() });
    if (resp.status === 'error') {
      logger.error(`Failed calling generateMermaid, got status error`);
      return { status: 'error', debugData, isAiError: true };
    } else {
      // verify legal mermaid
      lastStep = 'Before verify if mermaid is legal';
      try {
        await mermaid.value?.parse(resp.generatedMermaid);
      } catch (err) {
        invalidMermaidData = {
          generated: resp.generatedMermaid,
          error: String(err),
        };
        throw err;
      }
      lastStep = 'After verify if mermaid is legal';
      props.editor.chain().focus().replaceMermaidContent(props.getPos(), resp.generatedMermaid).run();
      lastStep = 'Done';
      return { status: 'success', debugData };
    }
  } catch (err) {
    Object.assign(debugData, { err });
    if (err instanceof MermaidAbortError) {
      logger.info(`Mermaid generateWithAi aborted with reason ${abort.signal.reason}`);
      if (abort.signal.reason === 'stop' || abort.signal.reason === 'regenerate') {
        return { status: abort.signal.reason as 'stop' | 'regenerate', debugData };
      }
    }
    logger.error(
      { err },
      `Failed in Mermaid generateWithAi. Last step was: ${lastStep} tryIndex=${tryIndex} has invalid mermaid=${
        invalidMermaidData != null
      }`
    );
    return {
      status: 'error',
      debugData,
      invalidMermaidData,
      isAiError: invalidMermaidData != null,
    };
  }
}
</script>

<template>
  <NodeViewWrapper>
    <MermaidNodeViewInner
      :tokens-supported="diagramSupportTokens"
      :has-tokens="tokensStatus.length > 0"
      :is-edit-mode="isEditMode"
      :is-empty="isEmpty"
      :is-selected="selected"
      :show-editor="showEditor"
      :is-highlighted="highlighted"
      :svg="lastValidSvg"
      :error="error"
      :applicability="applicability"
      :mermaid-package-version="mermaidPackageVersion"
      :decorations="decorations"
      :ai-generation-hidden="isAiGenerationHidden"
      :before-open-ai-panel="beforeOpenAiPanel"
      :check-for-quota-exceeded="checkForQuotaExceeded"
      :generate-with-ai="generateWithAi"
      :allow-ai-debug="swimmEditorServices.external.allowDebugMermaidAi()"
      @sample-select="sampleSelected"
      @mermaid-live-link-clicked="mermaidLiveClicked"
      @track-event="(eventName, props) => swimmEditorServices.external.trackEvent(eventName, props)"
      @toggle-edit="toggleEdit()"
  /></NodeViewWrapper>
</template>
