<template>
  <div class="snippet-studio" data-testid="snippet-studio">
    <SideMenuLayout show-side-menu-border :save-width="false" @keydown.esc="onEscapeClick">
      <template #sideBar>
        <BaseLayoutGap direction="column" alignment="center" class="content-sidebar">
          <BaseLayoutGap v-if="loadingTree" direction="column" size="small" alignment="center">
            <Loader secondary />
            <BaseProse variant="secondary" size="small">Loading repository file list from Git server </BaseProse>
          </BaseLayoutGap>
          <TreeView
            v-else-if="repoId"
            :mark-nodes-with-units="false"
            :should-focus-on-repo="!isReplacingSnippet"
            class="user-select-disabled"
            show-search
            :selected-path="selectedTreePath"
            :repo-id="repoId"
            :workspace-repos="workspaceRepos"
            @node-selected="nodeSelected"
            @repo-selected="repoSelected"
            @repos-list-toggled="reposListToggled"
            @refresh-tree="refreshFolderTree"
            :repo-metadata="repoMetadata"
            :repo-folder-tree="repoFolderTree"
            :is-snippet-studio="true"
          />
        </BaseLayoutGap>
      </template>
      <template #content>
        <div class="studio-header">
          <SnippetTabs :tabs="snippetTabs" @tab-clicked="handleTabClicked" />
          <span data-testid="snippet-studio-close-icon" class="close-studio" @click="closeModal">
            <Icon name="arrow-down" class="close-btn" />
          </span>
        </div>
        <Loader v-if="loading" class="content-loader" />
        <div v-else-if="currentFile.readFail" class="help-box">
          <div class="help-content">
            <Icon name="warning" class="no-snippet-icon" />
            <div v-if="hasSnippetToEdit">
              <div class="help-title system-subtitle">
                The selected hunk was from a file that doesn't exist anymore. Please discard it and reselect a new
                snippet.
              </div>
              <Action secondary @click="discardUnavailableHunk()">Discard</Action>
            </div>
            <div v-else class="help-title system-subtitle">
              Cannot read the selected file, please pick a different file or try again.
            </div>
            <div class="help-title system-subtitle">
              Refer to our <a class="support-link" href="https://docs.swimm.io/faq">FAQs</a> for further help.
            </div>
          </div>
        </div>
        <SnippetStudioEmptyFilePlaceholder v-else-if="isEmptyFile" />
        <div v-else-if="!currentFile.file" class="help-box">
          <div class="help-empty-content">
            <img class="snippet-image" />
            <div>
              <template v-if="crossRepoDisabled">
                <div class="help-title system-headline">Select code snippets.</div>
                <div class="tip body-L">Add comments and reorder them in your documentation.</div>
              </template>
              <template v-else>
                <div v-if="!reposListShown" class="tip">
                  <SwText variant="headline2">Highlight code in any file to add a snippet.</SwText>
                  <SwText variant="body-L">Swimm tracks changes & auto-syncs them with your doc.</SwText>
                </div>
                <div v-else class="tip">
                  <SwText variant="body-L"
                    >With multi-repo Docs, first select a repo and then select code snippets as usual.</SwText
                  >
                  <SwText variant="body-L"
                    >Note that snippets from other repos are always Auto-synced from the default branch.</SwText
                  >
                </div>
              </template>
            </div>
          </div>
        </div>
        <transition name="fade">
          <Suspense>
            <span>
              <SnippetPicker
                v-if="!loading && currentFile.file"
                ref="snippetPicker"
                :value="snippet"
                :file="currentFile.file"
                :file-path="filePath"
                :other-snippets-in-file="otherSnippetsInFile({ filePath: filePath })"
                @selected-snippet="selectSnippet($event)"
                @clear-editable-hunk="clearSnippetToEdit()"
                @remove-snippet="removeSnippet()"
              />
            </span>
            <template #fallback>
              <Loader class="loader" />
            </template>
          </Suspense>
        </transition>
      </template>
    </SideMenuLayout>
  </div>
</template>

<script lang="ts">
import SnippetTabs from '@/common/components/organisms/SnippetTabs.vue';
import SideMenuLayout from '@/common/layouts/SideMenuLayout.vue';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import SnippetStudioEmptyFilePlaceholder from '@/modules/snippet-studio/components/SnippetStudioEmptyFilePlaceholder.vue';
import { debounce } from 'lodash-es';
import { TreeView } from '@swimm/editor';
import { useSnippetStore } from '@/modules/snippet-studio/stores/snippetsStore';
import {
  SmartElementWithApplicabilityAndNewInfo,
  Snippet,
  getGitProviderIconName,
  getLoggerNew,
  isSmartElementWithNewInfo,
  objectUtils,
  productEvents,
  state,
} from '@swimm/shared';
import { Loader } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import swal from 'sweetalert';
import { PropType, defineAsyncComponent } from 'vue';
import { useRouting } from '@/common/composables/routing';
import { mapActions, mapGetters } from 'vuex';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { type EditorSnippet, SwimmEditorServices } from '@swimm/swmd';
import { BaseLayoutGap, BaseProse } from '@swimm/reefui';

const logger = getLoggerNew(__modulename);

const SnippetPicker = defineAsyncComponent(() => import('@/common/components/organisms/SnippetPicker.vue'));

function shouldCloseByEvent({ origin, shiftKey }) {
  return origin === 'click' || (!shiftKey && origin === 'enter');
}

export default {
  components: {
    SnippetStudioEmptyFilePlaceholder,
    SnippetPicker,
    SideMenuLayout,
    TreeView,
    SnippetTabs,
    Loader,
    BaseProse,
    BaseLayoutGap,
  },
  props: {
    defaultFilePath: { type: String, default: '' },
    defaultRepoId: { type: String, default: '' },
    expanded: { type: Boolean, default: false },
    editorServices: { type: Object as PropType<SwimmEditorServices>, required: true },
  },
  emits: ['toggle-snippet-studio', 'discard-snippet', 'file-selected', 'add-repo', 'selected-snippet'],
  setup() {
    const { assertRoutingToRepo, getCurrentOrDefaultBranch } = useRouting();
    const snippetStore = useSnippetStore();
    const analytics = useAnalytics();

    const snippetToEdit = snippetStore.snippetToEdit;

    const workspaceStore = useWorkspaceStore();
    const { favoriteRepoIds, recentRepoIds } = storeToRefs(workspaceStore);

    return {
      snippetStore,
      assertRoutingToRepo,
      getCurrentOrDefaultBranch,
      snippetToEdit,
      favoriteRepoIds,
      recentRepoIds,
      analytics,
    };
  },
  data() {
    return {
      loading: false,
      // This is the current repoId, the one that we show the file tree
      // for. It can be changed using the repo selector (multi-repo docs)
      repoId: this.defaultRepoId || (this.$route.params.repoId as string),
      currentFile: {
        readFail: false,
        file: undefined,
      },
      filePath: this.defaultFilePath,
      selectedTreePath: this.defaultFilePath, // Path to folder/file
      snippet: {} as { meat?: { start: number; end: number } },
      lastReadFileDate: new Date(),
      countSnippetSelections: 0,
      loadingTree: false,
      crossRepoDisabled: false, // Set to true to disable cross repos
      keyboardShortcuts: [
        {
          text: 'Clear',
          keystroke: 'Esc',
        },
        {
          text: 'Add & continue',
          keystroke: 'Shift',
          icon: 'enter',
        },
      ],
      workspaceRepos: [],
      reposListShown: false,
    };
  },
  computed: {
    ...mapGetters('filesystem', ['fs_getSelectedFolderTreePath', 'fs_isLocalRepoExist', 'fs_getRepoFolderTree']),
    ...mapGetters('database', ['db_getRepository', 'db_getWorkspaceRepoIds', 'db_getRepoMetadata', 'db_getWorkspace']),
    workspaceId() {
      return this.$route.params.workspaceId;
    },
    docRepoId() {
      // This is the repoId of the doc
      // use it to check if we are in cross repo context or not
      return this.$route.params.repoId;
    },
    isCrossRepo() {
      return this.repoId !== this.docRepoId;
    },
    hasSnippetToEdit() {
      return objectUtils.isObject(this.snippetToEdit) && !!this.snippetToEdit;
    },
    hasPlaceholderHunk() {
      return this.snippetStore.isPlaceholder;
    },
    isSnippetSelected() {
      return !objectUtils.isEmpty(this.snippet) && !this.currentFile.readFail;
    },
    workspaceReposById() {
      const result = {};
      for (const repo of this.workspaceRepos) {
        result[repo.id] = repo;
      }
      return result;
    },
    snippetTabs() {
      const docFiles = this.editorServices.autosyncedReferencedPathsFromSnippets.value;
      const files = [...docFiles];

      if (this.filePath) {
        if (!docFiles.some(({ repoId, path }) => repoId === this.repoId && path === this.filePath)) {
          files.push({ path: this.filePath, repoId: this.repoId });
        }
      }
      // If the repo is not accessible or not in workspace- remove it from the snippet tabs
      return files
        .filter(
          (path, index) => index === files.findIndex((file) => file.repoId === path.repoId && file.path === path.path)
        )
        .map(({ repoId, path }) => {
          const repoMetadata = this.db_getRepoMetadata(repoId);
          if (!this.workspaceReposById[repoId] || this.workspaceReposById[repoId].disabled || !repoMetadata) {
            return null;
          }
          const repoName = repoMetadata?.name;
          return {
            path,
            repoId,
            repoName,
            branchName: this.workspaceReposById[repoId]?.branchName,
            isCrossRepo: repoId !== this.docRepoId,
            repoIcon: getGitProviderIconName(repoMetadata?.provider),
            isCurrent: !!this.filePath && this.filePath === path && repoId === this.repoId,
          };
        })
        .filter(Boolean);
    },
    selectSnippetActionText() {
      return this.hasSnippetToEdit ? 'Update selection' : 'Add & exit';
    },
    isReplacingSnippet() {
      return this.hasSnippetToEdit || this.hasPlaceholderHunk;
    },
    repoMetadata() {
      return this.repoId ? this.db_getRepoMetadata(this.repoId) : null;
    },
    repoFolderTree() {
      return this.repoId ? this.fs_getRepoFolderTree(this.repoId) : null;
    },
    isEmptyFile() {
      return !this.loading && this.filePath && !this.currentFile.file;
    },
  },
  watch: {
    snippetToEdit: {
      handler() {
        if (this.hasSnippetToEdit) {
          this.snippet = {
            meat: {
              start: this.snippetToEdit.line,
              end: this.snippetToEdit.line + this.snippetToEdit.snippet.split('\n').length - 1,
            },
          };
        }
      },
      deep: true,
      immediate: true,
    },
    async $route() {
      const repoAssertResult = await this.assertRoutingToRepo();
      if (!repoAssertResult) {
        return;
      }
      this.repoId = this.defaultRepoId || (this.$route.params.repoId as string);
      await this.loadTreeData();
    },
  },
  async created() {
    await this.loadTreeData();
  },
  methods: {
    ...mapActions('filesystem', ['setSelectedFolderTreePath', 'loadFolderTree', 'setRepoInRepositories']),
    ...mapActions('database', ['fetchAllWorkspaceRepos']),
    async loadTreeData() {
      this.loadingTree = true;
      if (this.repoId) {
        await Promise.all([this.getFilesData(), this.getReposData()]);
      }
      this.loadingTree = false;
    },
    async getReposData() {
      if (this.workspaceId && !this.crossRepoDisabled) {
        await this.fetchAllWorkspaceRepos(this.workspaceId);
        // add favorite and recent
        const repos = this.db_getWorkspaceRepoIds(this.workspaceId)
          .map((repoId) => this.db_getRepoMetadata(repoId))
          .filter(Boolean)
          .map((repo) => ({
            ...repo,
            isFavourite: this.favoriteRepoIds.includes(repo.id),
            isRecent: this.recentRepoIds.includes(repo.id),
          }));
        const setDisabled = async (repo) => {
          repo.branchName = await this.getCurrentOrDefaultBranch(repo.id);
          repo.disabled = !repo.branchName;
        };
        await Promise.all(repos.map((repo) => setDisabled(repo)));
        await this.sortRepos(repos);
        this.workspaceRepos = [...repos];
      }
    },
    async sortRepos(repos) {
      const repoOrder = (await state.get({ key: `${this.workspaceId}-repo-order` })) as string[];
      if (repoOrder) {
        repos.sort((firstRepo, secondRepo) => repoOrder.indexOf(firstRepo.id) - repoOrder.indexOf(secondRepo.id));
      }
    },
    async connectToCrossRepo() {
      try {
        const repo = this.db_getRepository(this.repoId);
        const parsedRepoMetadata = {
          id: repo.metadata.id,
          url: repo.metadata.url,
          name: repo.metadata.name,
          provider: repo.metadata.provider,
          owner: repo.metadata.owner,
          is_new_swimm_repo: false,
        };
        if (!this.fs_isLocalRepoExist(repo.metadata.id)) {
          logger.info(`Connecting to repo id=${repo.metadata.id} name=${repo.metadata.name}`);
          await this.setRepoInRepositories(parsedRepoMetadata);
        }
      } catch (err) {
        logger.error({ err }, err);
      }
    },
    async refreshFolderTree() {
      if (this.repoId) {
        this.loadingTree = true;
        await this.loadFolderTree({
          requestMetadata: {
            repoId: this.repoId,
            cloneUrl: this.db_getRepository(this.repoId)?.metadata?.url,
            workspaceId: this.workspaceId,
          },
          branch: await this.getCurrentOrDefaultBranch(this.repoId),
          reload: true,
        });
        this.loadingTree = false;
      }
    },
    async getFilesData() {
      if (this.isCrossRepo) {
        await this.connectToCrossRepo();
      }

      await this.loadFolderTree({
        requestMetadata: {
          repoId: this.repoId,
          cloneUrl: this.db_getRepository(this.repoId)?.metadata?.url,
          workspaceId: this.workspaceId,
        },
        branch: await this.getCurrentOrDefaultBranch(this.repoId),
      });
      if (this.filePath) {
        await this.readFile(this.filePath, new Date());
        if (!this.isCrossRepo) {
          this.setSelectedFolderTreePath({ path: this.filePath, repoId: this.repoId });
        }
      }
    },
    clearSnippetToEdit() {
      this.snippet = {};
      this.snippetStore.editedSnippetIndex = undefined;
      this.snippetStore.snippetToEdit = undefined;
    },
    selectSnippet({ snippet, origin, shiftKey }) {
      const shouldClose = this.isReplacingSnippet || shouldCloseByEvent({ origin, shiftKey });

      this.applySelection({
        shouldClose,
        shouldResetSelection: true,
        snippet,
      });

      if (!shouldClose) {
        // Sets the focus back to the entire monaco editor so that the next keydowns will work
        // adding a snippet creates a new hunk which steals the focus, so we focus back.
        (this.$refs.snippetPicker as HTMLElement).focus();
      }
    },
    reposListToggled(isShown) {
      this.reposListShown = isShown;
    },
    async repoSelected(repoId) {
      this.repoId = repoId;
      this.filePath = '';
      this.selectedTreePath = '';
      this.currentFile = {
        file: undefined,
        readFail: false,
      };
      await this.loadTreeData();
      const repo = this.workspaceReposById[repoId];
      if (repo) {
        this.analytics.track(productEvents.SELECTED_REPO_IN_MULTI_REPO_LIST, {
          'Repo ID': repo.id,
          'Repo Name': repo.name,
          'Is Current Repo': repo.id === this.docRepoId,
          'Total Repo Count': this.workspaceRepos.length,
          'Is Favorite': !!repo.isFavourite,
          'Is Recent': !!repo.isRecent,
          Context: 'Snippet studio',
        });
      }
    },
    nodeSelected(nodeClicked) {
      if (!nodeClicked) {
        return;
      }

      if (nodeClicked.type === 'file') {
        this.setFileSelected(nodeClicked.path);
      }
      this.selectedTreePath = nodeClicked.path;
      this.$emit('file-selected', { repoId: this.repoId, path: nodeClicked.path });
      if (!this.isCrossRepo) {
        this.setSelectedFolderTreePath({ path: nodeClicked.path, repoId: this.repoId });
      }
    },
    async getFileContent({
      filePath,
      repoId,
      revision,
    }: {
      filePath: string;
      repoId: string;
      revision: string;
    }): Promise<string> {
      return await this.editorServices.external.getFileContent({ filePath, repoId, revision });
    },
    async getSelectionSnippetLinesFromRange({ filePath, snippetRanges, repoId, revision }) {
      if (!filePath) {
        throw new Error('missing filePath');
      }
      const meat = snippetRanges.meat;
      if (!meat?.start || !meat?.end) {
        throw new Error('missing meat');
      }
      const fileContent = await this.getFileContent({ filePath, repoId, revision });
      const fileLines = fileContent.split('\n');
      const meatArray = fileLines.slice(meat.start - 1, meat.end);
      return `${meatArray.join(`\n`)}`;
    },
    async readFile(filePath: string, readDate: Date) {
      // Multiple calls will be called in parallel, check that it's the latest request on every step
      // so only the latest request will take affect
      if (readDate >= this.lastReadFileDate) {
        this.loading = true;
        try {
          this.currentFile.file = await this.getFileContent({
            filePath,
            repoId: this.repoId,
            revision: await this.getCurrentOrDefaultBranch(this.repoId),
          });
          this.currentFile.readFail = false;
        } catch (err) {
          logger.error({ err }, `Failure on reading the file. Details: ${err.errorMessage}`);
          if (readDate >= this.lastReadFileDate) {
            this.currentFile = { file: undefined, readFail: true };
          }
        } finally {
          if (readDate >= this.lastReadFileDate) {
            this.loading = false;
          }
        }
      }
    },
    async onEscapeClick() {
      this.closeModal();
    },
    closeModal() {
      this.$emit('toggle-snippet-studio');
    },
    async applySelection({ shouldClose = false, shouldResetSelection = false, snippet = {} }) {
      this.snippet = snippet;
      if (this.isSnippetSelected) {
        const snippetToEmit = { meat: this.snippet.meat };
        let snippetLines;
        try {
          snippetLines = await this.getSelectionSnippetLinesFromRange({
            filePath: this.filePath,
            snippetRanges: objectUtils.deepClone(this.snippet),
            repoId: this.repoId,
            revision: await this.getCurrentOrDefaultBranch(this.repoId),
          });
        } catch (err) {
          logger.error(`Could not get snippet lines from file. Details: ${err.toString()}`);
          await swal({ title: 'Could not add this snippet.', content: { element: SWAL_CONTACT_US_CONTENT() } });
          return;
        }

        const hunkToEmit: EditorSnippet = {
          snippet: snippetLines,
          path: this.filePath,
          repoId: this.repoId,
          line: snippetToEmit.meat.start,
        };
        // const hunkToEmit: SwmCellSnippet = {
        //   lines: snippetLines.split('\n'),
        //   path: this.filePath,
        //   repoId: this.repoId,
        //   firstLineNumber: snippetToEmit.meat.start,
        //   type: SwmCellType.Snippet,
        //   id: '',
        // };

        if (!this.snippetToEdit) {
          // A new snippet created
          this.countSnippetSelections++;
        }

        this.snippetStore.selectedSnippets.next(hunkToEmit);
      }
      if (shouldClose) {
        this.closeModal();
      }
      if (shouldResetSelection) {
        this.clearSnippetToEdit();
      }
    },
    otherSnippetsInFile({ filePath }) {
      const otherSnippets = [];
      let index = 0;
      const relevantAutosyncedSnippets = this.editorServices.autosyncedSnippets.value.filter((autosyncedSnippet) => {
        if (isSmartElementWithNewInfo(autosyncedSnippet)) {
          return (
            autosyncedSnippet.newInfo.filePath === filePath && autosyncedSnippet.newInfo.gitInfo?.repoId === this.repoId
          );
        }
        return false;
      }) as SmartElementWithApplicabilityAndNewInfo<Snippet>[];
      for (const autosyncedSnippet of relevantAutosyncedSnippets) {
        const shouldAddOtherSnippet = !this.hasSnippetToEdit || this.snippetStore.editedSnippetIndex !== index;
        if (shouldAddOtherSnippet) {
          otherSnippets.push({
            meat: {
              start: autosyncedSnippet.newInfo.startLineNumber,
              end: autosyncedSnippet.newInfo.startLineNumber + autosyncedSnippet.newInfo.lines.length - 1,
            },
          });
        }
        index++;
      }
      return [...otherSnippets];
    },
    removeSnippet() {
      this.$emit('discard-snippet');
      this.snippet = {};
      this.closeModal();
    },
    async discardUnavailableHunk() {
      this.removeSnippet();
      this.currentFile = {
        file: undefined,
        readFail: false,
      };
      this.snippet = {};
      this.filePath = '';
      await this.loadFolderTree({
        requestMetadata: {
          repoId: this.repoId,
          cloneUrl: this.db_getRepository(this.repoId)?.metadata?.url,
          workspaceId: this.workspaceId,
        },
        branch: await this.getCurrentOrDefaultBranch(this.repoId),
      });
      this.closeModal();
    },
    async handleTabClicked({ path, repoId }) {
      if (this.currentFile.readFail) {
        return;
      }
      this.loading = true;
      if (repoId !== this.repoId) {
        await this.repoSelected(repoId);
      }
      this.setFileSelected(path);

      if (!this.isCrossRepo) {
        // Set in store only if this is the current repo
        this.setSelectedFolderTreePath({ path: path, repoId: repoId });
      }
    },
    setFileSelected(path) {
      // Debounce the file read so it won't wait on every file when moving on files quickly with the arrow buttons
      this.lastReadFileDate = new Date();
      const currentDate = new Date();
      debounce(async () => await this.readFile(path, currentDate), 500)();
      // This second debounce is here so we won't update the SnippetPicker's filePath before we also update its fileContent.
      debounce(() => (this.filePath = path), 500)();
      this.clearSnippetToEdit();
      this.selectedTreePath = path;
      this.$emit('file-selected', { path, repoId: this.repoId });
    },
  },
};
</script>

<style scoped lang="postcss">
.content-sidebar {
  height: 100%;
  justify-content: center;
}

.content-loader {
  width: 100%;
  height: 100%;
}

.snippet-studio {
  --tabs-height: 40px;
  display: flex;
  flex-direction: column;
  max-width: inherit;
  height: 100%;
  max-height: inherit;
  background-color: var(--color-bg);
}

.snippet-studio .side-layout {
  height: 100%;
  box-shadow: 0 -4px 8px 0 var(--color-border-default-strong);
}

.help-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--code-line-height);
  padding-bottom: var(--code-line-height);
  width: 100%;
  min-height: calc(100% - var(--tabs-height));
  height: 100%;
  max-height: 80%;
  font-size: var(--body-S);
  border-bottom: 1px solid #0c283b1a;
  text-align: center;
  background-color: var(--color-bg);
  box-sizing: border-box;

  .help-empty-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.studio-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 0 0;
  height: var(--tabs-height);
  background-color: var(--color-surface);
}

.studio-header .close-btn {
  cursor: pointer;
  border-radius: 4px;
}

.studio-header .close-studio {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.studio-header .close-btn:hover,
.studio-header .close-studio:hover .close-btn {
  color: var(--text-color-on-dark);
  background: var(--text-color-secondary);
}

.no-snippet-icon {
  /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
  font-size: 65px;
}

.help-title {
  margin-top: 35px;
  margin-bottom: 20px;
}

.help-title .support-link {
  text-decoration: underline;
  color: var(--text-color-link);
}

.tip {
  margin-top: 25px;
  margin-bottom: 100px;
}

.user-select-disabled {
  user-select: none;
  background-color: var(--color-bg);
}

.skeleton-tree .skeleton-item {
  margin: 30px 0 20px 20px;
  height: 25px;
  border-radius: 4px;
  background: var(--color-surface);
}

.skeleton-tree .skeleton-item.skeleton-item-long {
  width: 85%;
}

.skeleton-tree .skeleton-item.skeleton-item-semi-long {
  width: 75%;
}

.skeleton-tree .skeleton-item.skeleton-item-short {
  width: 65%;
}

.skeleton-tree .skeleton-item.skeleton-item-medium {
  width: 55%;
}

.fake-tab-container .fake-tab {
  padding: 5px 10px;
  background: var(--color-bg);
  border-top-right-radius: 8px;
}

.fake-tab-container .fake-tab .code-icon {
  font-size: var(--system-headline);
}

.shortcut-command .keys {
  display: flex;
  align-items: center;
  border: 1px solid var(--cool-slate);
  border-radius: 4px;
  margin: 0 4px;
  padding: 2px 4px;
}

.shortcut-command .keys-plus,
.shortcut-command .keys {
  color: var(--cool-slate);
}

.shortcut-command .keys-plus {
  padding: 5px 0 0 0;
}

.loader {
  width: 100%;
  height: 100%;
}

.snippet-image {
  height: 50%;
  min-height: 260px;
  content: url('/img/snippet-studio-empty-state-light.png');

  [data-theme='dark'] & {
    content: url('/img/snippet-studio-empty-state-dark.png');
  }
}
</style>
