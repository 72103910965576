<script setup lang="ts">
import { ApplicabilityStatus } from '@swimm/shared';

import MermaidSampleSelect from './MermaidSampleSelect.vue';
import MermaidLayoutToggle from './MermaidLayoutToggle.vue';
import MermaidDiagramHeaderMessage from './MermaidDiagramHeaderMessage.vue';
import type { MermaidSampleOption } from './mermaidSamples';
import type { MermaidLayoutType } from './mermaidTypes';
import { computed } from 'vue';
import { BaseButton, BaseIcon, BaseLayoutGap } from '@swimm/reefui';

const props = defineProps<{
  isEditMode: boolean;
  editorShown: boolean;
  showTokensUnsupportedTip: boolean;
  empty: boolean;
  error: string | null;
  hasTokens: boolean;
  applicability: ApplicabilityStatus.Autosyncable | ApplicabilityStatus.Outdated | ApplicabilityStatus.Verified;
  withAiOption: boolean;
  showLayoutToggle: boolean;
}>();

const emit = defineEmits<{
  (e: 'sample-select', option: MermaidSampleOption): void;
  (e: 'layout-changed', layoutType: MermaidLayoutType): void;
  (e: 'open-ai-panel'): void;
  (e: 'toggle-edit'): void;
}>();

const isEmptyView = computed(() => props.empty && !props.editorShown);
</script>

<template>
  <div
    class="mermaid-header"
    :class="{ 'empty-view': isEmptyView, clickable: isEditMode && !editorShown }"
    v-tooltip="isEditMode && !editorShown ? 'Click to open edit mode' : null"
    data-testid="mermaid-header"
    :contenteditable="false"
    @click="
      () => {
        if (isEditMode && !editorShown) {
          emit('toggle-edit');
        }
      }
    "
  >
    <MermaidSampleSelect
      v-if="editorShown"
      :with-ai-option="withAiOption"
      :start-open="empty"
      @open-ai-panel="emit('open-ai-panel')"
      @select="(option) => emit('sample-select', option)"
      class="sample-select"
    />
    <MermaidDiagramHeaderMessage
      :is-edit-mode="isEditMode"
      :editor-shown="editorShown"
      :error="error"
      :empty="empty"
      :has-tokens="hasTokens"
      :show-tokens-unsupported-tip="showTokensUnsupportedTip"
      :applicability="applicability"
    />
    <BaseLayoutGap size="xsmall" direction="row" class="mermaid-controls-layout">
      <MermaidLayoutToggle
        v-if="showLayoutToggle"
        @layout-changed="(type: MermaidLayoutType) => $emit('layout-changed', type)"
      />
      <template v-if="isEditMode">
        <BaseButton
          v-if="!editorShown"
          variant="secondary"
          class="mermaid-edit-button"
          @click.stop="emit('toggle-edit')"
        >
          <template #leftIcon>
            <BaseIcon name="edit-outline" />
          </template>
          Edit
        </BaseButton>
        <BaseButton v-else variant="secondary" @click.stop="emit('toggle-edit')" class="mermaid-edit-button">
          <template #leftIcon>
            <BaseIcon name="check" />
          </template>
          Done
        </BaseButton>
      </template>
    </BaseLayoutGap>
  </div>
</template>

<style scoped lang="postcss">
.mermaid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-sm);
  background: var(--color-surface);
  border-bottom: 1px solid var(--color-border-default);
  height: 48px;

  .sample-select {
    margin-right: var(--space-base);
  }

  &.empty-view {
    border-bottom: none;
  }

  &.clickable {
    cursor: pointer;
  }

  .mermaid-controls-layout {
    display: flex;
    margin-left: auto;
  }

  .mermaid-edit-button {
    /* To match the layout buttons */
    background-color: var(--color-bg-default) !important;
    border: 1px solid var(--color-label-tag);
    box-shadow: none !important;
    height: 30px !important;

    &:hover {
      background-color: var(--color-bg-default-hover) !important;
    }
  }
}
</style>
