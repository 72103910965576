import { load } from '@/remote-adapters/load';
import { getChangeFilesForBranch } from '@/remote-adapters/local_repo';
import { listSwmFiles } from '@/remote-adapters/local_swms';
import { getPendingDocumentationsForBranch } from '@/remote-adapters/pulls';
import axios from 'axios';
import { StatusCodes } from 'http-status-codes';
import { getRepoBranches } from '@/remote-adapters/local_repo';
import Cache from 'lru-cache';

import { config, getLoggerNew, gitwrapper, objectUtils } from '@swimm/shared';
import {
  ApplicabilityStatus,
  generateSwmdFileName,
  getSwimmIdFromSwimmFileName,
  parseSwmFilename,
} from '@swimm/shared';
import { parsePlaylistContentFromSwm } from '../utils/playlist-utils';
import { storeToRefs } from 'pinia';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { useFolderTreeStore } from '@/modules/core/stores/folder-tree-store';

const logger = getLoggerNew(__modulename);

const loadFolderTreePromiseCache = new Cache({
  ttl: 45_000,
  ttlResolution: 45_000 / 5, // We can manage with entries staying ~20% above their TTL time.
  ttlAutopurge: true,
});

export const getDefaultRepoState = () => ({
  metadata: { id: '', url: '', name: '', owner: '', provider: '', isNewSwimmRepo: '', isPrivate: '' },
  swimms: {},
  playlists: {},
  rules: {},
  autosync: { unitId: '', result: '' },
  folderTree: { selectedPath: '', tree: {} },
  isBranchProtected: false,
  pendingDocumentations: null,
  coverage: {},
  coveredFiles: [],
  branches: [],
  changedFilesInBranch: [],
});

const getDefaultState = () => ({
  repositories: {},
  reposLocalSwimmLists: {},
  currentCreatedPlaylist: {},
});

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    RESET_STATE(state) {
      Object.assign(state, getDefaultState());
    },
    SET_REPO_METADATA(state, args) {
      state.repositories[args.id]['metadata'] = args;
    },
    SET_LOCAL_SWIMM(state, args) {
      state.repositories[args.repoId].swimms[args.unitId] = args.content;
    },
    SET_LOCAL_RULE(state, args) {
      state.repositories[args.repoId].rules[args.ruleId] = args.content;
    },
    SET_LOCAL_PLAYLIST(state, args) {
      state.repositories[args.repoId].playlists[args.playlistId] = {
        content: args.content,
        swmContent: args.swmContent,
      };
    },
    REMOVE_LOCAL_SWIMM(state, args) {
      delete state.repositories[args.repoId].swimms[args.unitId];
    },
    REMOVE_LOCAL_PLAYLIST(state, args) {
      if (
        state.repositories &&
        state.repositories[args.repoId] &&
        state.repositories[args.repoId].playlists &&
        state.repositories[args.repoId].playlists[args.playlistId]
      ) {
        delete state.repositories[args.repoId].playlists[args.playlistId];
      }
    },
    SET_FOLDER_TREE(state, args) {
      const tree = state.repositories[args.repoId].folderTree;
      tree.tree = args.tree;
    },
    SET_FOLDER_TREE_SELECTED_PATH(state, args) {
      state.repositories[args.repoId].folderTree['selectedPath'] = args.path;
    },
    SET_REPO_IN_REPOSITORIES(state, args) {
      state.repositories[args.id] = args.repo;
    },
    REMOVE_LOCAL_REPO(state, args) {
      delete state.repositories[args.repoId];
    },
    CLEAN_REPO_LOADED_DATA(state, args) {
      if (!state.repositories[args.repoId]) {
        return;
      }
      state.repositories[args.repoId]['swimms'] = {};
      state.repositories[args.repoId]['pendingDocumentations'] = {};
      state.repositories[args.repoId]['rules'] = {};
      state.repositories[args.repoId]['playlists'] = {};
      state.repositories[args.repoId].folderTree['tree'] = {};
      state.repositories[args.repoId]['coverage'] = {};
      state.repositories[args.repoId]['coveredFiles'] = [];
      delete state.reposLocalSwimmLists[args.repoId];
    },
    SET_LOCAL_SWIMM_LIST(state, args) {
      state.reposLocalSwimmLists[args.repoId] = args.listSwmFiles;
    },
    SET_SWIMM_TO_LOCAL_SWIMM_LIST(state, args) {
      if (!state.reposLocalSwimmLists[args.repoId]) {
        state.reposLocalSwimmLists[args.repoId] = {};
      }
      // Handle doc and doc rule files with the same ID - store the rule in a rule prop under the doc
      if (
        state.reposLocalSwimmLists[args.repoId][args.swimmId] &&
        state.reposLocalSwimmLists[args.repoId][args.swimmId].type !== args.type
      ) {
        if (args.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
          state.reposLocalSwimmLists[args.repoId][args.swimmId].rule = {
            name: args.swimmFileName,
            type: args.type,
            path: args.path,
          };
        } else {
          state.reposLocalSwimmLists[args.repoId][args.swimmId] = {
            name: args.swimmFileName,
            type: args.type,
            path: args.path,
            rule: state.reposLocalSwimmLists[args.repoId][args.swimmId],
          };
        }
      } else {
        state.reposLocalSwimmLists[args.repoId][args.swimmId] = {
          name: args.swimmFileName,
          type: args.type,
          path: args.path,
        };
      }
    },
    SET_PLAYLIST_TO_LOCAL_SWIMM_LIST(state, args) {
      state.reposLocalSwimmLists[args.repoId][args.swimmId] = {
        name: args.swimmFileName,
        type: config.SWIMM_FILE_TYPES.PLAYLIST,
        path: args.path,
      };
    },
    REMOVE_SWIMM_FROM_LOCAL_SWIMM_LIST(state, args) {
      delete state.reposLocalSwimmLists[args.repoId][args.swimmId];
    },
    SET_REPO_PENDING_DOCUMENTATIONS(state, args) {
      state.repositories[args.repoId]['pendingDocumentations'] = args.pendingDocumentations;
    },
    SET_CURRENT_CREATED_PLAYLIST(state, args) {
      if (!args) {
        state.currentCreatedPlaylist = {};
      } else {
        state.currentCreatedPlaylist['sequence'] = args.newSequence;
        if (args.description) {
          state.currentCreatedPlaylist['sequence'] = args.description;
        }
        if (args.summary) {
          state.currentCreatedPlaylist['sequence'] = args.summary;
        }
      }
    },
    SET_REPO_MERGED_PRS(state, args) {
      if (!state.repositories[args.repoId]) {
        return;
      }

      state.repositories[args.repoId]['mergedPrs'] = args.mergedPrs;
    },
    SET_CHANGED_FILES_IN_BRANCH(state, args) {
      if (state.repositories[args.repoId] != null) {
        if (args.reset) {
          state.repositories[args.repoId].changedFilesInBranch = [];
        }
        state.repositories[args.repoId].changedFilesInBranch.push(...args.changedFiles);
      }
    },
    SET_LOCAL_SWIMM_APPLICABILITY(state, args) {
      if (state.repositories[args.repoId]?.swimms[args.resourceId]) {
        state.repositories[args.repoId].swimms[args.resourceId].applicabilityStatus = args.applicability;
      }
    },
    SET_REPO_BRANCHES(state, args) {
      if (state.repositories[args.repoId]) {
        state.repositories[args.repoId].branches = args.branches;
        state.repositories[args.repoId].branchesError = args.err;
      }
    },
    UPDATE_REPO_BRANCHES(state, args) {
      if (state.repositories[args.repoId]) {
        const currentBranches = state.repositories[args.repoId].branches;
        state.repositories[args.repoId].branches = [...currentBranches, ...args.branches];
      }
    },
    UPDATE_REPO_BRANCHES_FETCH_STATE(state, args) {
      if (state.repositories[args.repoId]) {
        state.repositories[args.repoId].loadingBranches = args.loadingBranches;
      }
    },
    SET_REPO_BRANCHES_ERROR(state, args) {
      if (state.repositories[args.repoId]) {
        state.repositories[args.repoId].branchesError = args.err;
      }
    },
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    setRepoInRepositories({ commit, state }, localRepo) {
      if (!state.repositories[localRepo.id]) {
        const defaultRepo = getDefaultRepoState();
        commit('SET_REPO_IN_REPOSITORIES', { id: localRepo.id, repo: defaultRepo });
        const repoMetadata = {
          id: localRepo.id,
          url: localRepo.url,
          name: localRepo.name,
          owner: localRepo.owner,
          provider: localRepo.provider,
          isNewSwimmRepo: localRepo.is_new_swimm_repo,
          isPrivate: localRepo.is_private,
          ...(localRepo.api_url ? { api_url: localRepo.api_url, tenant_id: localRepo.tenant_id ?? undefined } : {}),
        };
        commit('SET_REPO_METADATA', repoMetadata);
      }
    },
    deleteLocalSwimm({ commit }, args) {
      const { swmId, repoId, createPR, isPlaylist = false } = args;
      if (!createPR) {
        if (isPlaylist) {
          commit('REMOVE_LOCAL_PLAYLIST', { playlistId: swmId, repoId });
        } else {
          commit('REMOVE_LOCAL_SWIMM', { swmId, repoId });
        }
        commit('REMOVE_SWIMM_FROM_LOCAL_SWIMM_LIST', { swimmId: swmId, repoId });
      }
    },

    async loadLocalPlaylist(
      { commit, state, getters },
      args: {
        fileName: string;
        repoId: string;
        branch: string;
        path: string;
        reload: boolean;
      }
    ) {
      const repoId = args.repoId;
      const resourceFileName = args.fileName;
      const resourceId = getSwimmIdFromSwimmFileName(resourceFileName);
      const resourceType = config.SWIMM_FILE_TYPES.PLAYLIST;
      // Load the swm if not loaded or load and autosync if not already attempted to autosync before or if reload is specifically set
      if (args.reload || !getters.fs_isSwimmAlreadyLoaded(repoId, resourceId)) {
        try {
          const response = await load({
            swmId: resourceFileName,
            shouldAutoSync: false,
            shouldGetApplicabilityStatus: false,
            repoId: args.repoId,
            type: resourceType,
            currentBranch: args.branch,
            path: args.path,
          });
          if (response.code === config.SUCCESS_RETURN_CODE) {
            const playlist = parsePlaylistContentFromSwm(response.unit, args.repoId);
            commit('SET_LOCAL_PLAYLIST', {
              repoId: repoId,
              playlistId: resourceId,
              content: playlist,
              swmContent: response.unit,
            });
            // Add the swimm to reposLocalSwimmLists if the repo swimms list already fetched(specifically when creating new doc), otherwise it will be fetched when needed.
            if (args.repoId in state.reposLocalSwimmLists && !state.reposLocalSwimmLists[args.repoId][resourceId]) {
              state.reposLocalSwimmLists[args.repoId][resourceId] = {
                name: resourceFileName,
                type: resourceType,
                path: args.path,
              };
            }
          } else {
            logger.error(
              `Resource (${resourceId}) couldn't be loaded. ${(response.errorMessage && response.errorMessage) || ''}`
            );
            if (response.applicabilityStatus && response.applicabilityStatus !== ApplicabilityStatus.Unavailable) {
              commit('SET_LOCAL_SWIMM', {
                repoId: repoId,
                unitId: resourceId,
                content: { applicabilityStatus: response.applicabilityStatus },
              });
            } else if (
              response.applicabilityStatus &&
              response.applicabilityStatus === ApplicabilityStatus.Unavailable &&
              getters.fs_isUnitFileInRepoPath(resourceId, repoId)
            ) {
              // If the resource is marked as "unavailable" although the file does in the repository, that means the file is invalid.
              // A scenario like that can happen when the file is a ".swm" file while the repo is a "SWMD" repo.
              // noinspection ExceptionCaughtLocallyJS
              throw new Error(
                `Cannot load resource ${resourceId}. The file with the associated ID exists in the .swm folder in the repo but load & parse it failed.`
              );
            }
          }
        } catch (err) {
          logger.error({ err }, `Error getting local playlist file: ${err}`);
          if (resourceType === config.SWIMM_FILE_TYPES.SWMD) {
            commit('SET_LOCAL_SWIMM', {
              repoId: repoId,
              unitId: resourceId,
              content: { applicabilityStatus: ApplicabilityStatus.Invalid },
            });
          }
        }
      } else {
        logger.debug(`Not reloading SWM ${resourceId} (already loaded)`);
      }
    },
    handleLoadResponse({ commit }, event) {
      try {
        if (!event.data) {
          logger.error(`Event data is undefined. Received event: ${event}`);
        }
        if (event.data.code === 'error') {
          logger.error(`Error running load: ${event.data.error}`);
          return;
        }
        if (event.data.type !== 'load') {
          // Irrelevant response for this callback
          logger.debug(`Received message with a type that isn't handled here: ${event.data.type}`);
          return;
        }
        if (!event.data.result) {
          logger.error(`Load response is undefined. Receieved data: ${event.data}`);
          return;
        }
        const response = event.data.result;
        const repoId = response.repoId;
        const shouldAutoSync = response.shouldAutoSync;
        const resourceFileName = response.unitId;
        const parsedSwmFileName = parseSwmFilename(resourceFileName);
        const resourceId = parsedSwmFileName ? parsedSwmFileName.swmId : response.unitId;
        logger.debug(`Load response received for resource:"${resourceId}" `);
        if (response.code !== config.SUCCESS_RETURN_CODE) {
          logger.error(`Resource (${resourceId}) couldn't be loaded: ${response.errorMessage}`);
          if (response.applicabilityStatus && response.applicabilityStatus !== ApplicabilityStatus.Unavailable) {
            if (response.type === config.SWIMM_FILE_TYPES.PLAYLIST) {
              commit('SET_LOCAL_PLAYLIST', {
                repoId,
                playlistId: resourceId,
                content: { applicabilityStatus: response.applicabilityStatus },
              });
            } else if (response.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
              commit('SET_LOCAL_RULE', {
                repoId,
                ruleId: resourceId,
                content: { applicabilityStatus: response.applicabilityStatus },
              });
            } else {
              commit('SET_LOCAL_SWIMM', {
                repoId,
                unitId: resourceId,
                content: { applicabilityStatus: response.applicabilityStatus },
              });
            }
          }
        } else {
          if (response.type === config.SWIMM_FILE_TYPES.PLAYLIST) {
            const playlist = parsePlaylistContentFromSwm(response.unit, repoId);
            commit('SET_LOCAL_PLAYLIST', {
              repoId: repoId,
              playlistId: resourceId,
              content: playlist,
              swmContent: response.unit,
            });
          } else if (response.type === config.SWIMM_FILE_TYPES.SWM_RULE) {
            commit('SET_LOCAL_RULE', {
              repoId,
              ruleId: resourceId,
              content: {
                ...response.unit,
              },
            });
          } else {
            commit('SET_LOCAL_SWIMM', {
              repoId: repoId,
              unitId: resourceId,
              content: {
                ...response.unit,
                path: response.path,
                applicabilityStatus: response.applicabilityStatus,
                isAutosyncChecked: shouldAutoSync,
              },
            });
          }
        }
      } catch (err) {
        logger.error({ err }, `Failed in handleLoadResponse: ${err}`);
      }
    },
    async readAsset({ dispatch }, args) {
      const { filePath, repoId, revision } = args;
      const isAssetOnline = filePath.startsWith('http://') || filePath.startsWith('https://');
      if (isAssetOnline) {
        return dispatch('readRemoteAsset', filePath);
      } else {
        return dispatch('readLocalRepoAsset', { filePath, repoId, revision });
      }
    },
    async readRemoteAsset(_, url) {
      try {
        const response = await axios.get(url);
        if (response.status !== StatusCodes.OK) {
          return false;
        }
        return response.data;
      } catch (err) {
        logger.error({ err }, `Error getting local file from repo: ${err}`);
        return false;
      }
    },
    async readLocalRepoAsset(_, args) {
      try {
        const { filePath, repoId, revision } = args;
        const fileContent = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision });
        return fileContent;
      } catch (err) {
        logger.error({ err }, `Error getting local file from repo: ${err}`);
        return false;
      }
    },
    async loadFolderTree({ commit, getters }, args) {
      if (!args?.requestMetadata?.repoId) {
        return undefined;
      }
      if (!args.branch) {
        throw new TypeError(`loadFolderTree requires 'branch' argument`);
      }
      const repositoryData = getters.fs_getRepository(args.requestMetadata.repoId);
      if (!repositoryData) {
        return undefined;
      }

      const cacheKey = `RID${args.requestMetadata.repoId}-B${args.branch}`;
      const cacheHit = loadFolderTreePromiseCache.get(cacheKey);
      if (cacheHit && !args.reload) {
        return cacheHit;
      }

      const loadFolderTreeLogic = async () => {
        if (args.reload || Object.keys(repositoryData.folderTree.tree).length === 0) {
          const { getFolderTree } = useFolderTreeStore();
          const treeResult = await getFolderTree({
            treeRequestMetadata: args.requestMetadata,
            branch: args.branch,
            sort: true,
            reload: true,
          });
          if (treeResult.code === config.SUCCESS_RETURN_CODE) {
            commit('SET_FOLDER_TREE', { tree: treeResult.folderTree, repoId: args.requestMetadata.repoId });
          }
        }
      };
      const operation = loadFolderTreeLogic();
      loadFolderTreePromiseCache.set(cacheKey, operation);
      return operation;
    },
    setSelectedFolderTreePath({ commit }, args) {
      if (args.repoId) {
        commit('SET_FOLDER_TREE_SELECTED_PATH', args);
      }
    },
    removeLocalRepo({ commit, state }, repoId) {
      if (repoId in state.repositories) {
        commit('REMOVE_LOCAL_REPO', { repoId });
      }
    },
    cleanLoadedRepoData({ commit }, repoId) {
      commit('CLEAN_REPO_LOADED_DATA', { repoId });
    },
    async getReposSwmsLists({ dispatch }, args) {
      await Promise.all(args.reposList.map(({ repoId, branch }) => dispatch('getRepoSwmsLists', { repoId, branch })));
    },
    async getRepoSwmsLists({ commit, state }, args) {
      const { repoId, branch, forceRefresh } = args;
      if (
        forceRefresh ||
        !(state.reposLocalSwimmLists[repoId] && !objectUtils.isEmpty(state.reposLocalSwimmLists[repoId]))
      ) {
        commit('SET_LOCAL_SWIMM_LIST', {
          repoId: repoId,
          listSwmFiles: await listSwmFiles(repoId, branch),
        });
      }
    },
    async setSwimmToRepoSwmsList({ commit }, args) {
      commit('SET_SWIMM_TO_LOCAL_SWIMM_LIST', {
        repoId: args.repoId,
        swimmId: args.swimmId,
        swimmFileName: args.swimmFileName,
        type: args.type,
        path: args.path,
      });
    },
    async removeSwimmFromRepoSwmsList({ commit }, args) {
      const { repoId, swimmId } = args;
      commit('REMOVE_SWIMM_FROM_LOCAL_SWIMM_LIST', { repoId, swimmId });
    },
    async setRepoPendingDocumentations({ commit, state }, args) {
      const { repoId, branch } = args;
      if (!state.repositories[repoId]) {
        logger.debug(`Skipping setRepoPendingDocumentations as the repoId ${repoId} is not in the store`);
        return;
      }
      const { reposStateData } = storeToRefs(useReposStore());
      // Get the branch from local state
      const repo = reposStateData.value[repoId];
      if (!repo) {
        commit('SET_REPO_PENDING_DOCUMENTATIONS', { repoId, pendingDocumentations: {} });
        return;
      }

      const pendingDocumentationsResult = await getPendingDocumentationsForBranch(repoId, branch);
      if (pendingDocumentationsResult.code === config.SUCCESS_RETURN_CODE) {
        const { pendingDocumentations } = pendingDocumentationsResult;
        commit('SET_REPO_PENDING_DOCUMENTATIONS', { repoId, pendingDocumentations });
      } else {
        commit('SET_REPO_PENDING_DOCUMENTATIONS', { repoId, pendingDocumentations: {} });
      }
    },
    async addStepToPlaylist({ commit }, args) {
      commit('SET_CURRENT_CREATED_PLAYLIST', args);
    },
    async setOnlyDBPlaylistToFilesystem({ commit }, args) {
      commit('SET_LOCAL_PLAYLIST', args);
    },
    resetCurrentCreatedPlaylist({ commit }) {
      commit('SET_CURRENT_CREATED_PLAYLIST');
    },
    savePlaylist({ commit }, args) {
      commit('SET_PLAYLIST_TO_LOCAL_SWIMM_LIST', {
        repoId: args.repoId,
        swimmId: args.playlistId,
        swimmFileName: generateSwmdFileName(args.playlistId, args.playlistName),
        path: args.swmContent.path,
      });
      commit('SET_CURRENT_CREATED_PLAYLIST');
      commit('SET_LOCAL_PLAYLIST', {
        repoId: args.repoId,
        playlistId: args.playlistId,
        content: args.content,
        swmContent: args.swmContent,
      });
    },
    setChangedFileInBranch({ commit }, { changedFile, repoId }) {
      commit('SET_CHANGED_FILES_IN_BRANCH', { changedFiles: [changedFile], repoId });
    },
    resetChangedFilesForBranch({ commit }, { repoId }) {
      commit('SET_CHANGED_FILES_IN_BRANCH', { changedFiles: [], repoId, reset: true });
    },
    async loadChangedFilesForBranch({ commit }, { defaultBranch, branch, repoId }) {
      // handle default branch to return empty list without
      // calling gitwrapper
      const changedFiles =
        defaultBranch === branch
          ? []
          : await getChangeFilesForBranch({
              repoId,
              defaultBranch,
              branch,
            });
      commit('SET_CHANGED_FILES_IN_BRANCH', { changedFiles, repoId, reset: true });
    },
    async fetchRepoBranches({ commit }, repoId) {
      try {
        commit('UPDATE_REPO_BRANCHES_FETCH_STATE', { repoId, loadingBranches: true });
        commit('SET_REPO_BRANCHES', { repoId, branches: [], err: null });
        for await (const { branches } of getRepoBranches({ repoId })) {
          commit('UPDATE_REPO_BRANCHES', { repoId, branches: [...branches] });
        }
      } catch (err) {
        commit('SET_REPO_BRANCHES_ERROR', { repoId, err });
      } finally {
        commit('UPDATE_REPO_BRANCHES_FETCH_STATE', { repoId, loadingBranches: false });
      }
    },
  },
  getters: {
    fs_getRepository: (state) => (repoId) => state.repositories[repoId],
    fs_getRepoFolderTree: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId).folderTree.tree : undefined,
    fs_getSelectedFolderTreePath: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId).folderTree.selectedPath : undefined,
    fs_getLocalRepoUnits: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId).swimms : {},
    fs_getLocalRepoRules: (_, getters) => (repoId) => getters.fs_getRepository(repoId)?.rules,
    fs_getUnitFromLocalRepo: (state, getters) => (unitId, repoId) =>
      getters.fs_isUnitExist(unitId, repoId)
        ? objectUtils.deepClone(state.repositories[repoId].swimms[unitId])
        : undefined,
    fs_getRuleFromLocalRepo: (state, getters) => (repoId, ruleId) =>
      getters.fs_isRuleExist(ruleId, repoId)
        ? objectUtils.deepClone(state.repositories[repoId].rules[ruleId])
        : undefined,
    fs_getPlaylistFromLocalRepo: (state, getters) => (playlistId, repoId) =>
      getters.fs_isPlaylistExist(playlistId, repoId)
        ? objectUtils.deepClone(state.repositories[repoId].playlists[playlistId]).content
        : state.currentCreatedPlaylist,
    fs_getPlaylistSwmFromLocalRepo: (state, getters) => (playlistId, repoId) =>
      getters.fs_isPlaylistExist(playlistId, repoId)
        ? objectUtils.deepClone(state.repositories[repoId].playlists[playlistId]).swmContent
        : {},
    fs_getLocalRepoMetadata: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? objectUtils.deepClone(getters.fs_getRepository(repoId).metadata) : undefined,
    fs_isLocalRepoExist: (state) => (repoId) =>
      objectUtils.isObject(state.repositories[repoId]) && !objectUtils.isEmpty(state.repositories[repoId]),
    fs_isUnitExist: (_, getters) => (unitId, repoId) =>
      getters.fs_getRepository(repoId) && unitId in getters.fs_getRepository(repoId).swimms,
    fs_isRuleExist: (_, getters) => (ruleId, repoId) => {
      if (getters.fs_getRepository(repoId)) {
        return ruleId in (getters.fs_getRepository(repoId)?.rules || []);
      }
      return false;
    },
    fs_isPlaylistExist: (_, getters) => (playlistId, repoId) =>
      getters.fs_getRepository(repoId) && playlistId in getters.fs_getRepository(repoId).playlists,
    fs_isUnitFileInRepoPath: (state) => (unitId, repoId) =>
      state.reposLocalSwimmLists[repoId] && unitId in state.reposLocalSwimmLists[repoId],
    fs_getUnitApplicabilityState: (state, getters) => (unitId, repoId) => {
      if (getters.fs_isUnitExist(unitId, repoId)) {
        return state.repositories[repoId].swimms[unitId].applicabilityStatus; // Avoid deep clone in fs_getUnitFromLocalRepo
      }
      return ApplicabilityStatus.Unavailable;
    },
    fs_isUnitInvalid: (_, getters) => (unitId, repoId) => {
      return getters.fs_getUnitApplicabilityState(unitId, repoId) === ApplicabilityStatus.Invalid;
    },
    fs_isSwimmAlreadyLoaded: (_, getters) => (repoId, unitId) =>
      !!getters.fs_getRepository(repoId) &&
      (!!getters.fs_getRepository(repoId).swimms[unitId] ||
        !!getters.fs_getRepository(repoId).rules[unitId] ||
        !!getters.fs_getRepository(repoId).playlists[unitId]),
    fs_isSwimmAlreadyAutosynced: (_, getters) => (repoId, unitId) =>
      getters.fs_isSwimmAlreadyLoaded(repoId, unitId) &&
      getters.fs_getRepository(repoId).swimms[unitId].isAutosyncChecked === true,
    fs_getRepoCoverage: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId).coverage : undefined,
    fs_getRepoCoveredFiles: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId).coveredFiles : undefined,
    fs_getRepoLocalFilesLists: (state) => (repoId) => state.reposLocalSwimmLists[repoId],
    fs_getSwmsIdsFromRepoFolder: (state, getters) => (repoId) => {
      if (state.reposLocalSwimmLists[repoId]) {
        return Object.keys(state.reposLocalSwimmLists[repoId]).filter((swimmId) => {
          return [config.SWIMM_FILE_TYPES.SWMD, config.SWIMM_FILE_TYPES.SWM].includes(
            getters.fs_getSwmdFileTypeInRepo(repoId, swimmId)
          );
        });
      }
      return [];
    },
    fs_getRepoPendingDocumentations: (_, getters) => (repoId) =>
      getters.fs_getRepository(repoId) ? getters.fs_getRepository(repoId)?.pendingDocumentations : '',
    fs_getSwmdFileNameInRepo: (state) => (repoId, swimmId) => {
      return state.reposLocalSwimmLists[repoId] && state.reposLocalSwimmLists[repoId][swimmId]
        ? state.reposLocalSwimmLists[repoId][swimmId].name
        : '';
    },
    fs_getSwmRuleFileNameInRepo: (state) => (repoId, swimmId) => {
      if (state.reposLocalSwimmLists[repoId] && state.reposLocalSwimmLists[repoId][swimmId]) {
        if (state.reposLocalSwimmLists[repoId][swimmId].type === config.SWIMM_FILE_TYPES.SWM_RULE) {
          return state.reposLocalSwimmLists[repoId][swimmId].name;
        } else if (state.reposLocalSwimmLists[repoId][swimmId].rule) {
          return state.reposLocalSwimmLists[repoId][swimmId].rule.name;
        }
      }
      return '';
    },
    fs_getSwmdPathInRepo: (state) => (repoId, swimmId) => {
      return state.reposLocalSwimmLists[repoId] && state.reposLocalSwimmLists[repoId][swimmId]
        ? state.reposLocalSwimmLists[repoId][swimmId].path
        : '';
    },
    fs_getSwmdFileTypeInRepo: (state) => (repoId, swimmId) =>
      state.reposLocalSwimmLists[repoId] && state.reposLocalSwimmLists[repoId][swimmId]
        ? state.reposLocalSwimmLists[repoId][swimmId].type
        : '',
    fs_getChangedFilesForBranch: (state) => (repoId) => {
      return state.repositories[repoId] ? state.repositories[repoId].changedFilesInBranch : [];
    },
    fs_getRepoBranches: (state) => (repoId) => {
      return state.repositories[repoId]?.branches;
    },
    fs_getRepoBranchesAreLoading: (state) => (repoId) => {
      return state.repositories[repoId]?.loadingBranches;
    },
    fs_getRepoBranchesError: (state) => (repoId) => {
      return state.repositories[repoId]?.branchesError;
    },
  },
};
