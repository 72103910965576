import upperFirst from 'lodash-es/upperFirst';
import { gitwrapper } from '@swimm/shared';
import { CDI_FILE_TYPES } from './types';
import { RawProcessFile } from '../source-types';
import { Connection, ObjectRef, ProcessFile, ValueObject } from '../output-types';
import {
  EMPTY_VALUE_OBJECT,
  NEW_LINE_REGEX,
  composeValueObject,
  parseDescriptionField,
  parseObjectRefs,
  parseOperation,
} from '../source-json-parser';
import type { Language, SyntaxNode } from '@/common/tree-sitter';
import { captureKey, captureStringValue, captureValue, getFileTree } from '../FileParser';

export const CDI_FILE_NAME_PATTERN =
  /\.?(?<namePrefix>m_|mt_|tf_|fl_|ltf_|BS_)(?<objectName>.+?)\.(?<fileType>DTEMPLATE|MTT|TASKFLOW|MI_FILE_LISTENER|WORKFLOW|BSERVICE){1}\.(?<fileFormat>json|dat)$/;

type FileMetadata = {
  prefix: string;
  name: string;
  type: CDI_FILE_TYPES;
  data: 'schema' | 'connections';
  filePath: string;
};

export function validate(filePaths: string[]): boolean {
  return filePaths.every((filePath) => {
    return filePath.endsWith('.json') && filePath.match(CDI_FILE_NAME_PATTERN);
  });
}

export function filter(filePaths: string[]): string[] {
  return filePaths.filter((filePath) => filePath.match(CDI_FILE_NAME_PATTERN));
}

export function toFileMetadata(filePaths: string[]): Array<FileMetadata> {
  return filePaths
    .filter((filePath) => filePath.match(CDI_FILE_NAME_PATTERN))
    .map((filePath) => {
      const match = filePath.match(CDI_FILE_NAME_PATTERN);
      return {
        prefix: match.groups['namePrefix'],
        name: match.groups['objectName'],
        type: match.groups['fileType'] as CDI_FILE_TYPES,
        data: match.groups['fileFormat'] === 'json' ? 'schema' : 'connections',
        filePath,
      };
    });
}

/**
 * Parse nested object refs - we want to be able to move from each object to the objects it points to
 * Most commonly here taskflow -> MTT objects -> DTEMPLATE objects
 * We also make sure all the nested objects are available on the parent object
 * So taskflow will contain all its own MTT child elements, but also all of its children's DTEMPLATE elements
 * Allowing us to loop for MTTs an DTEMPLATEs on the taskflow level
 * @param objectRefs - a map of object name to its content
 * @param objectFiles - an array of all input files
 * @param repoId
 * @param branch
 */
async function parseNestedObjectRefs(
  objectRefs: { [name: string]: ObjectRef },
  objectFiles: Array<FileMetadata>,
  repoId: string,
  branch: string
) {
  // The parent object is returned all its original objectRefs
  let extendedRefs: { [name: string]: ObjectRef } = { ...objectRefs };

  for (const objectRef of Object.values(objectRefs)) {
    const objectFile = objectFiles.find(
      (file) =>
        `${file.prefix}${file.name}` === objectRef.name.value &&
        file.type === objectRef.type.value &&
        file.data === 'schema'
    );

    if (objectFile) {
      const objectFileText = await gitwrapper.getFileContentFromRevision({
        filePath: objectFile.filePath,
        repoId,
        revision: branch,
      });
      const objectFileTextLines = objectFileText.split(NEW_LINE_REGEX);
      const fileTree = await getFileTree(objectFileText);

      const objectFileObjectRefs = parseObjectRefs(fileTree, objectFileTextLines, 'name', objectFile.filePath);

      objectRef.objectRefs = Object.values(objectFileObjectRefs).map((objectRef) => {
        objectRef.filePath = objectFile.filePath;
        return objectRef;
      });

      if (objectRef.objectRefs.length) {
        const nestedRefsByName = await parseNestedObjectRefs(objectFileObjectRefs, objectFiles, repoId, branch);
        objectRef.objectRefs = Object.values(nestedRefsByName);
        // Add nested refs to the parent object
        extendedRefs = { ...extendedRefs, ...nestedRefsByName };
      }

      // Each object contains its references objects both as an array of objectRefs and mapped by their type
      const objectRefsByType = getObjectRefsBy(objectRef.objectRefs, 'type');
      for (const key of Object.keys(objectRefsByType)) {
        objectRef[key] = objectRefsByType[key];
      }
    }
  }
  return extendedRefs;
}

function getObjectRefsBy(objectRefs: ObjectRef[], by: string) {
  return Object.values(objectRefs).reduce((acc, objectRef) => {
    if (!acc[objectRef[by].value]) {
      acc[objectRef[by].value] = [objectRef];
    } else {
      acc[objectRef[by].value].push(objectRef);
    }
    return acc;
  }, {});
}

function parseRules(textLines: string[], rootNode: SyntaxNode, language: Language, filePath: string) {
  const rules = [];

  const capturedValue = captureValue(rootNode, language, 'rules', 'array')?.node;

  // If the rules array is empty it will still have 2 nodes - for opening and closing braces
  if (capturedValue && capturedValue.childCount > 2) {
    for (const ruleNode of capturedValue.children) {
      // Skip no content nodes (opening and closing braces and commas)
      if (['string', '[', ']'].includes(ruleNode.type)) {
        continue;
      }

      const rule = ['folderPath', 'patternType', 'filePattern', 'postAction', 'recursive'].reduce((acc, key) => {
        const capturedKey = captureValue(ruleNode, language, key)?.node;
        acc[key] = composeValueObject(textLines, capturedKey, key);
        return acc;
      }, {});

      rules.push({ ...rule, filePath });
    }
  }
  return rules;
}

async function parseConnections(
  objectRefs: ObjectRef[],
  objectFiles: FileMetadata[],
  repoId: string,
  branch: string
): Promise<void> {
  for (const objectRef of objectRefs) {
    const connectionsFile = objectFiles.find(
      (file) =>
        `${file.prefix}${file.name}` === objectRef.name.value &&
        file.type === objectRef.type.value &&
        file.data === 'connections'
    );

    if (connectionsFile) {
      const fileText = await gitwrapper.getFileContentFromRevision({
        filePath: connectionsFile.filePath,
        repoId,
        revision: branch,
      });
      const textLines = fileText.split(NEW_LINE_REGEX);
      const fileTree = await getFileTree(fileText);

      let listenerEventKey: string;
      const listenerEventKeys = ['arrive', 'update', 'delete'];

      for (const key of listenerEventKeys) {
        const capturedValue = captureValue(fileTree.rootNode, fileTree.getLanguage(), key, 'true')?.node;

        // The first value set to true is the listener event
        if (capturedValue) {
          listenerEventKey = key;
        }
      }

      let listenerEvent = {
        ...EMPTY_VALUE_OBJECT,
        lineText: 'listenerEvents',
        value: `Missing value for listenerEvents`,
      };

      if (listenerEventKey) {
        listenerEvent = composeValueObject(
          textLines,
          captureKey(fileTree.rootNode, fileTree.getLanguage(), listenerEventKey)?.node,
          listenerEventKey
        );
      }

      const connection = [
        'stopWhenRulesMet',
        'stabilityCheckInterval',
        'notifyExistingFiles',
        'triggerDefinition.type',
        'triggerDefinition.startDate',
        'triggerDefinition.runIndefinitely',
        'triggerDefinition.startsAt',
        'triggerDefinition.endsAt',
        'triggerDefinition.frequency',
        'triggerDefinition.timezone',
        'triggerDefinition.daysToRun',
        'triggerDefinition.monthlyMode',
        'triggerDefinition.dayOfMonth',
        'triggerDefinition.weekOfMonth',
        'triggerDefinition.dayOfWeek',
      ].reduce((acc, key) => {
        if (key.includes('.')) {
          const [parentKey, childKey] = key.split('.');
          const objectNode = captureValue(fileTree.rootNode, fileTree.getLanguage(), parentKey, 'object')?.node;
          const capturedValue = captureValue(objectNode, fileTree.getLanguage(), childKey)?.node;
          acc[`trigger${upperFirst(childKey)}`] = composeValueObject(textLines, capturedValue, key);
        } else {
          const capturedValue = captureValue(fileTree.rootNode, fileTree.getLanguage(), key)?.node;
          acc[key] = composeValueObject(textLines, capturedValue, key);
        }
        return acc;
      }, {}) as Connection;

      const connectionObjectNode = captureValue(
        fileTree.rootNode,
        fileTree.getLanguage(),
        'connection',
        'object'
      )?.node;
      const capturedName = captureStringValue(connectionObjectNode, fileTree.getLanguage(), 'name');
      const capturedType = captureStringValue(connectionObjectNode, fileTree.getLanguage(), 'type');
      connection.name = composeValueObject(textLines, capturedName, 'name');
      connection.type = composeValueObject(textLines, capturedType, 'type');

      objectRef.connection = {
        filePath: connectionsFile.filePath,
        rules: parseRules(textLines, fileTree.rootNode, fileTree.getLanguage(), connectionsFile.filePath),
        notifyWhenFile: listenerEvent,
        ...connection,
      };
    }
  }
}

async function parseTaskFlow(
  filePath: string,
  objectFiles: Array<FileMetadata>,
  repoId: string,
  branch: string
): Promise<ProcessFile> {
  const fileText = await gitwrapper.getFileContentFromRevision({ filePath, repoId, revision: branch });
  const textLines = fileText.split(NEW_LINE_REGEX);
  const fileJson = JSON.parse(fileText) as RawProcessFile;
  const fileTree = await getFileTree(fileText);

  let objectRefsByName = parseObjectRefs(fileTree, textLines, 'name', filePath);

  objectRefsByName = await parseNestedObjectRefs(objectRefsByName, objectFiles, repoId, branch);

  const objectRefs = Object.values(objectRefsByName);

  // Enrich objectRefs with connections
  await parseConnections(objectRefs, objectFiles, repoId, branch);

  const relatedObjects = getObjectRefsBy(objectRefs, 'type');

  const capturedObjectInfo = captureValue(fileTree.rootNode, fileTree.getLanguage(), 'objectInfo', 'object')?.node;
  const capturedDescription = captureValue(capturedObjectInfo, fileTree.getLanguage(), 'description', 'string')?.node;

  const additionalInfo = parseDescriptionField(
    textLines,
    capturedDescription,
    fileJson.objectInfo?.name,
    objectRefsByName
  );

  const metadataKeys = ['id', 'name', 'type', 'path', 'repoHandle'] as const;
  type FileMetadataType = (typeof metadataKeys)[number];
  const fileMetadata = metadataKeys.reduce((acc, key) => {
    const capturedValue = captureStringValue(capturedObjectInfo, fileTree.getLanguage(), key);
    acc[key] = composeValueObject(textLines, capturedValue, key);
    return acc;
  }, {} as { [key in FileMetadataType]: ValueObject });

  const operation = parseOperation(capturedObjectInfo, fileTree.getLanguage(), textLines);

  return {
    ...fileMetadata,
    operation,
    ...additionalInfo,
    ...relatedObjects,
    objectRefs,
    filePath,
  };
}

export async function parse(objectFiles: Array<FileMetadata>, repoId: string, branch: string) {
  return Promise.all(
    objectFiles
      .filter((objectFile) => ['TASKFLOW', 'WORKFLOW'].includes(objectFile.type) && objectFile.data === 'schema')
      .map(({ filePath }) => {
        return parseTaskFlow(filePath, objectFiles, repoId, branch);
      }, Array<Promise<ProcessFile>>())
      .filter((result) => !!result)
  );
}
