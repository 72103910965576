import type { ResultWithReturnCode } from './common-types';

export type GetCurrentName = ResultWithReturnCode<
  { exists: false } | { exists: true; isRenamed: boolean; currentName: string; isDirectory: boolean }
>;

export enum PathType {
  File = 'blob',
  Folder = 'tree',
}

export interface RemoteBranchInfo {
  name: string;
  sha: string;
  protected: boolean;
  lastUpdated: string;
}

export enum GitCloudProviderName {
  GitHub = 'github',
  GitLab = 'gitlab',
  Bitbucket = 'bitbucket',
  AzureDevops = 'azure_devops',
}

export enum GitProviderName {
  Testing = 'testing',
  GitHub = GitCloudProviderName.GitHub,
  GitHubEnterprise = 'github_enterprise', // will use github gitwrapper
  GitLab = GitCloudProviderName.GitLab,
  GitLabEnterprise = 'gitlab_enterprise',
  Bitbucket = GitCloudProviderName.Bitbucket,
  BitbucketDc = 'bitbucket_dc',
  AzureDevops = GitCloudProviderName.AzureDevops,
}

export type GitProviderNameType =
  | GitProviderName.GitHub
  | GitProviderName.GitHubEnterprise
  | GitProviderName.GitLab
  | GitProviderName.GitLabEnterprise
  | GitProviderName.Bitbucket
  | GitProviderName.BitbucketDc
  | GitProviderName.AzureDevops
  | GitProviderName.Testing;

export const GitProviderInfo = {
  [GitProviderName.Testing]: { name: GitProviderName.Testing, displayName: 'Testing' },
  [GitProviderName.GitHub]: { name: GitProviderName.GitHub, displayName: 'GitHub' },
  [GitProviderName.GitHubEnterprise]: { name: GitProviderName.GitHubEnterprise, displayName: 'GitHub Enterprise' },
  [GitProviderName.GitLab]: { name: GitProviderName.GitLab, displayName: 'GitLab' },
  [GitProviderName.GitLabEnterprise]: {
    name: GitProviderName.GitLabEnterprise,
    displayName: 'GitLab Enterprise Edition',
  },
  [GitProviderName.Bitbucket]: { name: GitProviderName.Bitbucket, displayName: 'Bitbucket' },
  [GitProviderName.BitbucketDc]: { name: GitProviderName.BitbucketDc, displayName: 'Bitbucket Data Center' },
  [GitProviderName.AzureDevops]: { name: GitProviderName.AzureDevops, displayName: 'Azure DevOps' },
};

export const GitProviderIcons: Record<GitProviderName, 'github' | 'gitlab' | 'bitbucket' | 'azure'> = {
  [GitProviderName.GitHub]: 'github',
  [GitProviderName.GitHubEnterprise]: 'github',
  [GitProviderName.GitLab]: 'gitlab',
  [GitProviderName.GitLabEnterprise]: 'gitlab',
  [GitProviderName.Testing]: 'gitlab',
  [GitProviderName.Bitbucket]: 'bitbucket',
  [GitProviderName.BitbucketDc]: 'bitbucket',
  [GitProviderName.AzureDevops]: 'azure',
} as const;

export const gitUnknownProviderIcon = 'repo-provider' as const;

export type GitIconType = typeof gitUnknownProviderIcon | (typeof GitProviderIcons)[keyof typeof GitProviderIcons];

export type GitAuthType = 'oauth_app' | 'github_app';

// Notify head of demand generation from marketing before changing, otherwise, salesforce will break
const GIT_HOSTING_TITLES = {
  GITHUB: 'GitHub.com',
  GITHUB_ENTERPRISE: 'GitHub Enterprise Server',
  BITBUCKET_CLOUD: 'Bitbucket Cloud',
  BITBUCKET_DATA_CENTER: 'Bitbucket Data Center',
  GITLAB_CLOUD: 'GitLab Cloud',
  GITLAB_ENTERPRISE: 'GitLab Enterprise Edition',
  AZURE_DEVOPS_CLOUD: 'Azure DevOps Cloud',
  AZURE_DEVOPS_SERVER: 'Azure DevOps Server',
  OTHER: 'Other',
};

export const SELF_SERVED_SUPPORTED_GIT_HOSTINGS = [
  GIT_HOSTING_TITLES.GITHUB,
  GIT_HOSTING_TITLES.GITLAB_CLOUD,
  GIT_HOSTING_TITLES.BITBUCKET_CLOUD,
  GIT_HOSTING_TITLES.AZURE_DEVOPS_CLOUD,
];

export const SUPPORTED_GIT_HOSTINGS_REQUIRES_HELP = [
  GIT_HOSTING_TITLES.GITHUB_ENTERPRISE,
  GIT_HOSTING_TITLES.GITLAB_ENTERPRISE,
  GIT_HOSTING_TITLES.BITBUCKET_DATA_CENTER,
];
export const GIT_HOSTING_OPTIONS = Object.values(GIT_HOSTING_TITLES);

export const HOSTING_NAME_TO_PROVIDER = {
  [GIT_HOSTING_TITLES.GITHUB]: GitProviderName.GitHub,
  [GIT_HOSTING_TITLES.GITHUB_ENTERPRISE]: GitProviderName.GitHubEnterprise,
  [GIT_HOSTING_TITLES.BITBUCKET_CLOUD]: GitProviderName.Bitbucket,
  [GIT_HOSTING_TITLES.BITBUCKET_DATA_CENTER]: GitProviderName.BitbucketDc,
  [GIT_HOSTING_TITLES.GITLAB_CLOUD]: GitProviderName.GitLab,
  [GIT_HOSTING_TITLES.GITLAB_ENTERPRISE]: GitProviderName.GitLabEnterprise,
  [GIT_HOSTING_TITLES.AZURE_DEVOPS_CLOUD]: GitProviderName.AzureDevops,
  [GIT_HOSTING_TITLES.AZURE_DEVOPS_SERVER]: '',
  [GIT_HOSTING_TITLES.OTHER]: '',
};
