import { CLOUD_FUNCTIONS_HOST } from '@/config';
import { type SSOConfig, config, getLoggerNew, state } from '@swimm/shared';
import { BrowserCacheLocation, PublicClientApplication } from '@azure/msal-browser';
import { OktaAuth } from '@okta/okta-auth-js';
import { jwtDecode } from 'jwt-decode';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import 'firebase/compat/auth';
import LocalStorage from '@/local-storage';

const logger = getLoggerNew(__modulename);

export async function fetchSSOSetup(email: string): Promise<SSOConfig | null> {
  try {
    if (!email) {
      return null;
    }

    const ssoPayload = await fetch(`${CLOUD_FUNCTIONS_HOST}/isSSOEnabled`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    });
    const ssoResponse = ssoPayload.ok ? await ssoPayload.json() : null;
    return ssoResponse && ssoResponse.isSSOEnabled ? ssoResponse : null;
  } catch (err) {
    logger.error(`Failed fetching for user ${email.split('@')?.[1]} ${err.message}`);
    return null;
  }
}

export async function setSSOProvider(email: string, uid: string): Promise<boolean> {
  try {
    if (!email) {
      return null;
    }

    const ssoPayload = await fetch(`${CLOUD_FUNCTIONS_HOST}/setSSOProvider`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, uid }),
    });
    const ssoResponse = ssoPayload.ok ? await ssoPayload.json() : null;
    return !!(ssoResponse && ssoResponse.setSSOProvider);
  } catch (err) {
    logger.error(`Failed setting provider for user ${email.split('@')?.[1]} ${err.message}`);
    return false;
  }
}

export async function isSSOActive(ssoType: string, email: string, ssoConfig: SSOConfig, user): Promise<boolean> {
  switch (ssoType) {
    case 'azure':
      return await isAzureSessionActive(ssoConfig, user);
    case 'jumpcloud':
      return await isJumpCloudSessionActive(ssoConfig);
    case 'okta':
    default:
      return await isOktaSessionActive(email, ssoConfig);
  }
}

async function isAzureSessionActive(ssoConfig: SSOConfig, user): Promise<boolean> {
  const clientId = ssoConfig.clientId;
  const authority = ssoConfig.issuer;
  const azureClient = await PublicClientApplication.createPublicClientApplication({
    auth: {
      clientId,
      authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
  });

  const accountId = user.value.providerUid;
  if (!accountId) {
    logger.error(`Failed fetching provider user id for Azure`);
    return false;
  }

  const accounts = azureClient.getAllAccounts();
  if (!accounts?.length) {
    logger.error(`Failed fetching connected azure accounts`);
    return false;
  }
  const account = accounts[0];
  let tokens = null;
  try {
    tokens = await azureClient.acquireTokenSilent({ account, scopes: ['User.Read', 'email', 'openid'] });
  } catch (err) {
    logger.error(`Failed fetching azure token: ${err.message}`);
    return false;
  }

  if (!tokens?.accessToken) {
    logger.error(`Failed acquiring azure tokens`);
    return false;
  }
  const bearer = 'Bearer ' + tokens.accessToken;
  const options = {
    method: 'GET',
    headers: {
      Authorization: bearer,
    },
  };
  const graphEndpoint = `https://graph.microsoft.com/beta/me/appRoleAssignments?$filter=resourceId%20eq%20${ssoConfig.objectId}`;

  const respo = await fetch(graphEndpoint, options);
  if (respo.status === 401) {
    logger.error(`Failed finding Azure App assignments because of invalid token`);
    return false;
  }
  if (!respo.ok) {
    logger.error(`Failed fetching user data from Azure, ${respo.statusText}`);
    // throw new Error(`Failed fetching user data from Azure, ${respo.statusText}`);
  }

  const responseJSON = await respo.json();
  const currentApp = responseJSON.value;
  if (!currentApp?.length) {
    logger.error('Failed finding assingments for Azure user');
  }

  return true;
}

async function isOktaSessionActive(email: string, ssoConfig: SSOConfig) {
  const requireSession = async (oktaAuth, authState) => {
    if (!authState.isAuthenticated) {
      return authState;
    }
    let oktaUser;
    try {
      oktaUser = await oktaAuth.token.getUserInfo(); // extra requirement: user must have valid Okta SSO session
    } catch (err) {
      logger.error(`Failed fetching user information from Okta, ${err.message}`);
    }
    authState.isAuthenticated = !!oktaUser; // convert to boolean
    authState.users = oktaUser; // also store user object on authState
    return authState;
  };
  const clientId = ssoConfig.clientId;
  const issuer = ssoConfig.issuer;
  const authClient = new OktaAuth({
    issuer,
    clientId,
    redirectUri: config.BASE_URL + '/setOktaToken',
    transformAuthState: requireSession,
    storageManager: {
      token: {
        storageTypes: ['localStorage', 'sessionStorage', 'memory'],
      },
    },
    tokenManager: {
      autoRenew: true,
    },
  });
  await authClient.authStateManager.updateAuthState();

  const authState = authClient.authStateManager.getAuthState();
  if (!authState?.isAuthenticated) {
    logger.error(`User ${email.slice(email.indexOf('@'))} failed fetching user info - redirecting to logout`);
    return false;
  }

  try {
    const accessTokenResponse = await authClient.tokenManager.get('accessToken');
    if (!accessTokenResponse) {
      logger.error(`User ${email.slice(email.indexOf('@'))} does not have an access token - redirecting to logout`);
      return false;
    }
    // @ts-ignore
    const introspectResult = await authClient.token.introspect('accessToken', accessTokenResponse);
    if (!introspectResult?.active) {
      logger.error(`User ${email.slice(email.indexOf('@'))} has an invalid token - redirecting to logout`);
      return false;
    }
  } catch (err) {
    logger.error(`Failed checking token for user ${email.slice(email.indexOf('@'))}, ${err.message}`);
  }
  return true;
}

async function isJumpCloudSessionActive(ssoConfig: SSOConfig) {
  const { api_key } = ssoConfig;

  // By default, logging the user out after 24 hours unless the customer gives us
  // give us an API key we can use to check if the user has access on every refresh
  if (api_key) {
    return await checkJumpcloud();
  } else {
    return checkIfLoginWasInTheLast24Hours();
  }
}

async function checkJumpcloud() {
  try {
    logger.info(`Checking user permissions in JumpCloud using a Jumpcloud API key`);
    const oidcData = (await state.get({ key: 'oidc_data', defaultValue: null })) as {
      user_email: string;
      user_id: string;
    } | null;

    const { data: userHasAccess } = await CloudFunctions.doesJumpCloudUserHaveAccess({
      email: oidcData.user_email,
      jumpCloudId: oidcData.user_id,
    });

    if (!userHasAccess) {
      await state.deleteKey({ key: 'oidc_data', isGlobalKey: true });
    }

    return userHasAccess;
  } catch (err) {
    logger.error(`Failed checking user permissions for JumpCloud, ${err}`);
    return false;
  }
}

function checkIfLoginWasInTheLast24Hours(): boolean {
  logger.info(`Checking if the user logged in within the last 24 hours.`);
  const lastSignInTime = LocalStorage.get('lastSignInTime');
  const currentTime = Date.now();

  // Comment out this line if you need to debug locally and have the sign out happen
  // after 24 seconds instead of 24 hours:
  // const hoursDifference = (currentTime - lastSignInTime) / (1000);
  const hoursDifference = (currentTime - lastSignInTime) / (1000 * 60 * 60);

  if (hoursDifference < 24) {
    logger.info(
      `Last sign-in time is within the last 24 hours, letting the user stay. Time is ${timeInMillisToUTC(
        lastSignInTime
      )}`
    );
    return true;
  } else {
    logger.info(
      `Last sign-in time is more than 24 hours ago, kicking the user out. Time is ${timeInMillisToUTC(lastSignInTime)}`
    );
    return false;
  }
}

function timeInMillisToUTC(timeInMillis: number) {
  const date = new Date(timeInMillis);
  const options = { timeZone: 'UTC', hour12: false };
  const dateInUTC = date.toLocaleString('en-US', options);
  return dateInUTC;
}

export async function authenticateWithJumpCloud(requestOptions): Promise<boolean> {
  try {
    const tokenResponse = await fetch('https://oauth.id.jumpcloud.com/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(requestOptions),
    });
    const tokenData = await tokenResponse.json();

    if (!tokenData.access_token) {
      logger.error(`Couldn't authenticate with JumpCloud: ${tokenData.error_description}`);
      return false;
    }

    const decodedToken = jwtDecode(tokenData.id_token);

    await state.set({
      key: `oidc_data`,
      value: {
        id_token: tokenData.id_token,
        user_id: decodedToken.sub,
        // @ts-ignore - it does exist
        user_email: decodedToken.email,
      },
    });
    return true;
  } catch (err) {
    logger.error({ err }, `Failed when authenticating with JumpCloud: ${err}`);
    return false;
  }
}
