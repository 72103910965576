<template>
  <TopMenuLayout>
    <template #topBar>
      <TopBar :show-route-back="false" />
    </template>
    <template #content>
      <PageContainer>
        <div class="home-view">
          <section class="workspace-header" data-testid="workspace-header">
            <SwAvatar :text="workspace.name" :src="workspace.logo" size="huge" square hide-tooltip />
            <div class="title-wrapper">
              <div class="info-row">
                <div class="tag-wrapper">
                  <Tag v-if="userLicense" class="tag" :text="userLicenseTagText" :tag-style="userLicense" />
                </div>
                <template v-if="isFreeWorkspace">
                  <TextSkeleton
                    v-if="!hasCheckedEligibleForTrialPlan"
                    class="skeleton"
                    variant="headline2"
                  ></TextSkeleton>
                  <div
                    v-else-if="isEligibleForTrialPlan"
                    class="suggest-trial body-S"
                    @click="openBillingTabOnWorkspaceSettings"
                  >
                    Try {{ FREE_TRIAL_LENGTH }}-day Small teams plan for free
                  </div>
                </template>
                <template v-else>
                  <TextSkeleton
                    v-if="!hasCheckedEligibleForTrialPlan"
                    class="skeleton"
                    variant="headline2"
                  ></TextSkeleton>
                  <div v-if="isOnTrialPlan && trialDaysLeft" class="trial-header">
                    <SwText
                      variant="body-S"
                      component="span"
                      :class="[{ expiring: trialDaysLeft <= EXPIRING_TRIAL_LENGTH }]"
                      >{{ trialDaysLeft }} days left on your trial -</SwText
                    >
                    <SwText
                      variant="body-S"
                      component="span"
                      class="suggest-trial link"
                      @click="openBillingTabOnWorkspaceSettings"
                    >
                      Upgrade now
                    </SwText>
                  </div>
                </template>
                <div class="actions">
                  <SwText
                    v-if="isAdmin"
                    class="clickable"
                    @click="openUsersTabOnWorkspaceSettings"
                    variant="subtitle-L"
                    data-testid="top-invite-button"
                  >
                    Add users
                  </SwText>
                  <CreateButton :disabled="!workspaceDataEnabled" @click="handleNewDocClick" options-disabled />
                </div>
              </div>
              <SwText variant="headline1" class="workspace-name" data-testid="workspace-name"
                >{{ trimedWorkspaceName }} home</SwText
              >
              <SwText v-if="workspace.description" variant="body-L" weight="light" class="workspace-description">{{
                workspace.description
              }}</SwText>
            </div>
          </section>
          <Ribbon v-if="isPendingManualSetup" hide-icon mode="web" :ribbon-description="pendingManualSetup.text">
            <Action secondary size="small" @click="pendingManualSetup.callToAction.action">{{
              pendingManualSetup.callToAction.buttonText
            }}</Action>
          </Ribbon>
          <WorkspaceBanners v-else />
          <div class="tabs-container">
            <Tabs :tabs="tabs" :model-value="currentTabIndex" @update:model-value="handleTabSelection" />
          </div>
          <Suspense v-if="isOnTab('statistics')">
            <StatisticsTab :workspace-id="workspaceId" />
            <template #fallback>
              <Loader class="fallback-loader" />
            </template>
          </Suspense>
          <div v-else>
            <template v-if="workspace.id">
              <SuggestionCards v-if="workspaceDataEnabled" />
              <SwText variant="subtitle-L">Workspace overview</SwText>
              <div class="authorize-section" v-if="!isLoading && !isAuthorized && workspaceHasUserRepo">
                <Ribbon mode="warning" hide-icon class="ribbon">
                  <SwText variant="body-S" class="content">
                    <span class="emoji">😬</span>
                    <strong>Authorization required</strong>
                    To access code-coupled content, make sure to authorize {{ providerDisplayName }} first.
                    <a :href="DOCUMENTATION_AS_CODE_LINK" target="_blank">Learn more</a>
                  </SwText>
                  <GitAuthorizeBanner context="Home" class="authorize-button" />
                </Ribbon>
              </div>
              <OverviewCards
                :loading="isAuthorized && isLoading"
                :sidebar-open="isSidebarExpanded"
                v-bind="overviewTotals"
                @add-repo="openAddReposModal({ multiSelect: true })"
                @invite="openUsersTabOnWorkspaceSettings"
                @create="handleNewDocClick"
                @view-all-click="openSubMenu"
              />
              <div class="shared-docs-section" v-if="!isAuthorized && shouldShowSharedDocs">
                <Icon name="info-outline" no-padding />
                <SwText variant="body-S">
                  Don't have code access?
                  <router-link :to="`/workspaces/${workspaceId}/shared-docs`" class="link">
                    View Shared docs
                  </router-link>
                </SwText>
              </div>
              <AdminArea v-if="isAdmin" :workspace-id="workspaceId" />
            </template>
          </div>
        </div>
        <ReleaseNotesModal :show="showReleaseNotesModal" @close="showReleaseNotesModal = false" />
      </PageContainer>
    </template>
  </TopMenuLayout>
</template>

<script>
import _ from 'lodash-es';
import { Tabs } from '@swimm/ui';
import ReleaseNotesModal from '@/common/components/Workspace/Modals/ReleaseNotesModal.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { CreationHubSections } from '@/modules/core/creation-hub/CreationHubConsts';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import WorkspaceBanners from '@/modules/core/components/banners/WorkspaceBanners.vue';
import {
  EXPIRING_TRIAL_LENGTH,
  FREE_TRIAL_LENGTH,
  billingPlanTypes,
  config,
  getLoggerNew,
  gitProviderUtils,
  isRepoIdDummyRepo,
  pageEvents,
  productEvents,
  settingsTypes,
  state,
  workspaceSettingsBillingTabPhases,
  workspaceSettingsTabs,
} from '@swimm/shared';
import { mapActions, mapGetters } from 'vuex';
import { HomePageRouteNames, UserRole } from '@/common/consts';
import { trimedWorkspaceName } from '@/common/utils/workspace-utils';
import { usePageTitle } from '@/common/composables/pageTitle';
import { measurePerformance } from '@/common/utils/sentry-measurements';
import { SwAvatar, SwText, Tag, TextSkeleton } from '@swimm/ui';
import OverviewCards from '../components/OverviewCards.vue';
import AdminArea from '@/modules/core/workspace/home/admin-section/AdminArea.vue';
import StatisticsTab from '@/modules/core/workspace/home/admin-section/charts/StatisticsTab.vue';
import path from 'path-browserify';
import { useInitData } from '@/common/composables/initData';
import { useRouting } from '@/common/composables/routing';
import { useNavigate } from '@/common/composables/navigate';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { getRepoPath, getRouteToNewDocURL, getRouteToNewPlaylistURL } from '@/router/router-utils';
import CreateButton from '@/modules/core/creation-hub/CreateButton.vue';
import PageContainer from '@/common/layouts/PageContainer.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import SuggestionCards from '@/modules/core/workspace/home/components/SuggestionCards.vue';
import { PageRoutesNames } from '@/common/consts';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';
import { computed } from 'vue';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import swal from 'sweetalert';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { useCreationHubStore } from '@/modules/core/creation-hub/store/creation-hub';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import GitAuthorizeBanner from '@/common/components/Auth/GitAuthorizeBanner.vue';
import { useProcessToDocStore } from '@/modules/customizations/custom-process-to-doc/stores/processToDoc';

const logger = getLoggerNew(__modulename);

const RECENTLY_CREATED_LIMIT = 5;

export default {
  components: {
    GitAuthorizeBanner,
    WorkspaceBanners,
    SwText,
    SwAvatar,
    TextSkeleton,
    AdminArea,
    ReleaseNotesModal,
    OverviewCards,
    Tag,
    Tabs,
    StatisticsTab,
    CreateButton,
    PageContainer,
    TopMenuLayout,
    TopBar,
    SuggestionCards,
  },
  props: {
    workspaceId: { type: String, required: true },
    currentRoute: { type: String, required: true },
    modalToOpenOnMount: { type: String, default: null },
    modalTabOnMount: { type: String, default: null },
  },
  setup() {
    const DOCUMENTATION_AS_CODE_LINK = 'https://docs.swimm.io/new-to-swimm/documentation-as-code';
    const workspaceStore = useWorkspaceStore();
    const { forceOpenSubmenu } = workspaceStore;
    const { isPendingManualSetup, isSidebarExpanded, providerDisplayName, providerLongDisplayName } =
      storeToRefs(workspaceStore);
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
    const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
    const { setWorkspaceData } = useInitData();
    const { assertRouting } = useRouting();
    const { navigateToPageAndTerminateWorker } = useNavigate();
    const { pageTitle } = usePageTitle();
    const { isOnTrialPlan, isEligibleForTrialPlan, hasCheckedEligibleForTrialPlan, trialDaysLeft } = storeToRefs(
      useBillingStore()
    );
    const { openAddReposModal } = useAppModalsStore();
    const { openProcessToDocModal } = useProcessToDocStore();
    const creationHubStore = useCreationHubStore();
    const { creationHubModalRef, showCreationHubModal } = storeToRefs(creationHubStore);
    const { openCreationHubModal, closeCreationHubModal } = creationHubStore;
    const { isSharingInternallyEnabledInWorkspace } = useSharingInternally();
    const shouldShowSharedDocs = computed(() => {
      return isSharingInternallyEnabledInWorkspace(workspaceStore.id);
    });

    function openSubMenu() {
      forceOpenSubmenu();
      analytics.track(productEvents.CLICKED_VIEW_ALL_DOCS, {});
    }
    const pendingManualSetup = computed(() => gitProviderUtils.getPendingSetupMessage(providerLongDisplayName.value));
    return {
      DOCUMENTATION_AS_CODE_LINK,
      openSubMenu,
      user,
      analytics,
      UserRole,
      RECENTLY_CREATED_LIMIT,
      isCurrentWorkspaceAuthorized,
      setWorkspaceData,
      assertRouting,
      navigateToPageAndTerminateWorker,
      pageTitle,
      workspaceSettingsTabs,
      isOnTrialPlan,
      openAddReposModal,
      openProcessToDocModal,
      isEligibleForTrialPlan,
      hasCheckedEligibleForTrialPlan,
      trialDaysLeft,
      EXPIRING_TRIAL_LENGTH,
      PageRoutesNames,
      shouldShowSharedDocs,
      openWorkspaceSettingsModal,
      creationHubModalRef,
      showCreationHubModal,
      openCreationHubModal,
      closeCreationHubModal,
      isPendingManualSetup,
      isSidebarExpanded,
      pendingManualSetup,
      providerDisplayName,
      providerLongDisplayName,
    };
  },
  data() {
    return {
      FREE_TRIAL_LENGTH,
      isLoading: true,
      showReleaseNotesModal: false,
    };
  },
  computed: {
    ...mapGetters('database', [
      'db_getWorkspace',
      'db_getSwimmerRepos',
      'db_getPlaylists',
      'db_getWorkspaceRepoIds',
      'db_getWorkspaceRepos',
      'db_isWorkspaceAdmin',
      'db_getWorkspaceUsers',
      'db_getWorkspaceResources',
      'db_isWorkspaceSidebarCollapsedByDefault',
    ]),
    ...mapGetters('filesystem', ['fs_getRepoLocalFilesLists']),
    tabs() {
      const tabs = [
        {
          key: HomePageRouteNames.SUMMARY,
          label: `Summary`,
          route: '',
        },
      ];
      if (this.isAdmin && this.overviewTotals.documentationCount > 2) {
        tabs.push({
          key: HomePageRouteNames.STATISTICS,
          label: `Statistics`,
          route: '/statistics',
          tag: 'NEW',
          hideForLaunch: true,
        });
      }
      return tabs.filter((t) => !t.hideForLaunch);
    },
    currentTabIndex() {
      return Math.max(
        0,
        this.tabs.findIndex((tab) => tab.key === this.currentRoute)
      );
    },
    trimedWorkspaceName() {
      // Remove the word "workspace" from the name (if it exists at the end)
      return trimedWorkspaceName(this.workspace.name);
    },
    isAdmin() {
      return this.db_isWorkspaceAdmin(this.workspace.id, this.user.uid);
    },
    userRole() {
      return this.isAdmin ? UserRole.ADMIN : UserRole.MEMBER;
    },
    userLicense() {
      return this.workspace.license;
    },
    isFreeWorkspace() {
      return !this.workspace.license || this.workspace.license === billingPlanTypes.FREE;
    },
    userLicenseTagText() {
      return `${_.capitalize(this.userLicense).replace('-', ' ')}`;
    },
    isAuthorized() {
      return this.isCurrentWorkspaceAuthorized;
    },
    workspaceHasDummyRepo() {
      return this.workspaceRepoIds.some(isRepoIdDummyRepo);
    },
    workspaceHasUserRepo() {
      // if workspace has dummy repo, indication to connected repo is more than one repo
      return this.workspaceRepoIds.length > (this.workspaceHasDummyRepo ? 1 : 0);
    },
    workspaceRepoIds() {
      return this.db_getWorkspaceRepoIds(this.workspaceId);
    },
    isEmptyWorkspace() {
      return this.workspaceRepoIds.length === 0;
    },
    subscribedRepos() {
      const swimmerRepos = this.db_getSwimmerRepos(this.user.uid);
      return this.workspaceRepos.filter(
        (repo) => repo?.metadata?.id && (swimmerRepos[repo.metadata.id] || isRepoIdDummyRepo(repo.metadata.id))
      );
    },
    workspaceDocumentation() {
      return this.workspaceRepoIds
        .map((repoId) => this.fs_getRepoLocalFilesLists(repoId))
        .flatMap((item) => (item ? Object.values(item) : []));
    },
    workspaceDocsMetadata() {
      return this.workspaceDocumentation.filter((resource) => resource.type === config.SWIMM_FILE_TYPES.SWMD);
    },
    reposPlaylists() {
      return this.workspaceDocumentation.filter((resource) => resource.type === config.SWIMM_FILE_TYPES.PLAYLIST);
    },
    workspace() {
      const emptyWorkspace = { name: '', description: '', logo: '', repositories: [] };
      return this.db_getWorkspace(this.workspaceId) || emptyWorkspace;
    },
    workspaceRepos() {
      return this.db_getWorkspaceRepos(this.workspaceId);
    },
    overviewTotals() {
      if (!this.isAuthorized && !this.workspaceHasDummyRepo) {
        return {};
      }
      return {
        documentationCount: this.db_isWorkspaceSidebarCollapsedByDefault(this.workspaceId)
          ? 'disabled'
          : this.workspaceDocsMetadata.length + this.reposPlaylists.length,
        userCount: this.workspace.counter_workspace_users,
        repoCount: this.workspaceRepos.length,
      };
    },
    users() {
      const workspace = this.db_getWorkspaceUsers(this.workspace.id);
      return Object.values(workspace || {}).map((user) => ({
        name: user.nickname || user.name || user.email,
      }));
    },
    shouldOpenImportModal() {
      return this.modalToOpenOnMount === 'import';
    },
    shouldOpenWorkspaceSettingsModal() {
      return this.modalToOpenOnMount === settingsTypes.WORKSPACE;
    },
    workspaceDataEnabled() {
      return (this.isAuthorized || this.isEmptyWorkspace || this.workspaceHasDummyRepo) && !this.isPendingManualSetup;
    },
  },

  async created() {
    await this.setShowReleaseNotesModal();
    this.$watch(
      'workspaceId',
      async () => {
        this.pageTitle = this.workspace.name || 'Workspace';
        await measurePerformance({ name: 'home-init' }, async () => {
          this.setCurrentWorkspaceData();
        });
        this.analytics.cloudPageViewed({
          identity: this.user.uid,
          event: pageEvents.VIEW_HOME,
          payload: {
            'Workspace ID': this.workspaceId,
            'Total Playlists': this.reposPlaylists.length,
            'Total Docs': this.workspaceDocsMetadata.length,
            'Total Users': this.workspace.counter_workspace_users,
            'Total Repos': this.workspaceRepos.length,
          },
        });
      },
      { immediate: true }
    );

    this.$watch('isAuthorized', async () => {
      if (!this.isEmptyWorkspace) {
        this.setCurrentWorkspaceData();
      }
    });

    this.showModalOnMount();
  },
  mounted() {
    this.reportPageViewed();
  },
  methods: {
    ...mapActions('filesystem', ['getReposSwmsLists']),
    ...mapActions('database', ['fetchWorkspaceEvents']),
    reportPageViewed() {
      const payload = {
        'Page Name': 'Workspace Page',
      };
      this.analytics.cloudPageViewed({
        identity: this.user.uid,
        event: pageEvents.LOADED_A_PAGE,
        payload,
      });
      this.analytics.track(pageEvents.LOADED_A_PAGE_MARKETING, payload);
    },
    async setCurrentWorkspaceData() {
      this.isLoading = true;
      try {
        await this.setWorkspaceData(this.workspaceId);

        if (this.isAdmin) {
          this.fetchWorkspaceEvents({ workspaceId: this.workspaceId }); // No await
        }

        const assertResult = await this.assertRouting();
        if (!assertResult) {
          return;
        }

        if (
          (this.isAuthorized || this.workspaceHasDummyRepo) &&
          !this.db_isWorkspaceSidebarCollapsedByDefault(this.workspaceId)
        ) {
          await this.getReposSwmsLists({
            // We are not in the context of any specific branch, so we use the default one.
            reposList: this.subscribedRepos.map((repo) => ({ repoId: repo.metadata.id, branch: repo.branch })),
          });
        }
      } catch (err) {
        logger.error({ err }, `Failed to set workspace ${this.workspaceId} data. details: ${err.toString()}`);
        await swal({
          title: 'Something went wrong, please refresh the page.',
          content: SWAL_CONTACT_US_CONTENT(),
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleTabSelection(tabIndex) {
      const tabSelected = this.tabs[tabIndex];
      if (Object.values(HomePageRouteNames).includes(tabSelected.key)) {
        this.openPageLink(tabSelected.route);
        this.analytics.track(pageEvents.VIEW_HOME_PAGE_TAB_SELECTED(tabSelected.label), {
          'Workspace ID': this.workspaceId,
          Type: 'Page',
        });
      }
    },
    openPageLink(tabRoute) {
      const workspaceroute = `/workspaces/${this.workspaceId}`;
      const tabPath = path.join(workspaceroute, tabRoute);
      if (!this.$route.path.endsWith(`/${tabRoute}`)) {
        this.$router.push(tabPath);
      }
    },
    getCurrentTabIndex() {
      return Math.max(
        0,
        this.tabs.findIndex((tab) => tab.key === this.currentRoute)
      );
    },
    isOnTab(tabName) {
      return this.tabs[this.getCurrentTabIndex()].key === tabName;
    },

    async setShowReleaseNotesModal() {
      const shouldShowVersionModalCheck = await state.get({ key: 'should_show_version_modal', defaultValue: false });
      if (shouldShowVersionModalCheck) {
        this.showReleaseNotesModal = true;
        await state.deleteKey({ key: 'should_show_version_modal' });
      }
    },
    openUsersTabOnWorkspaceSettings({ openedFromQuery = false } = {}) {
      this.openWorkspaceSettingsModal({ initialTabCode: workspaceSettingsTabs.MEMBERS, openedFromQuery });
    },
    openBillingTabOnWorkspaceSettings() {
      this.openWorkspaceSettingsModal({
        initialTabCode: workspaceSettingsTabs.BILLING,
        initialTabPhase: workspaceSettingsBillingTabPhases.CHANGE_PLAN,
      });

      if (this.isOnTrialPlan) {
        this.analytics.track(productEvents.CLICKED_UPGRADE_NOW_LINK, {
          Context: 'Workspace Page',
          'Workspace ID': this.workspaceId,
          'Trial Days Left': this.trialDaysLeft,
        });
      } else {
        this.analytics.track(productEvents.CLICKED_START_FREE_TRIAL_LINK, {
          Context: 'Workspace Page',
          'Workspace ID': this.workspaceId,
        });
      }
    },
    showModalOnMount() {
      if (this.shouldOpenWorkspaceSettingsModal) {
        if (this.modalTabOnMount === workspaceSettingsTabs.MEMBERS) {
          this.openUsersTabOnWorkspaceSettings({ openedFromQuery: true });
        } else if (this.modalTabOnMount !== workspaceSettingsTabs.BILLING) {
          this.openWorkspaceSettingsModal({
            initialTabCode: this.modalTabOnMount,
            openedFromQuery: true,
          });
        }

        this.removeModalQueryFromRoute();
      } else if (this.shouldOpenImportModal) {
        this.toggleCreationHub({ creationHubSection: CreationHubSections.IMPORT, fromQuery: true });
        this.removeModalQueryFromRoute();
      }

      if (this.modalTabOnMount === workspaceSettingsTabs.BILLING) {
        this.$watch(
          'isAdmin',
          (isAdmin) => {
            if (isAdmin) {
              this.openWorkspaceSettingsModal({
                initialTabCode: this.modalTabOnMount,
                openedFromQuery: true,
              });
              this.removeModalQueryFromRoute();
            }
          },
          { immediate: true }
        );
      }
    },
    removeModalQueryFromRoute() {
      const query = {
        ...(this.$route?.query || {}),
        modal: undefined,
        tab: undefined,
      };
      this.$router.replace({ query });
    },
    async onCreateDocClick(clickContext) {
      this.analytics.track(productEvents.CLICKED_CREATE_DOC, {
        'Workspace ID': this.workspaceId,
        Context: clickContext,
        Origin: 'Workspace page',
      });
      this.openAddReposModal({ multiSelect: true });
    },
    routeToNewDoc(options) {
      const url = getRouteToNewDocURL({ ...options, workspaceId: this.workspaceId });
      this.navigateToPageAndTerminateWorker({ newRoute: url, query: options.query });
    },
    routeToNewPlaylist(options) {
      const url = getRouteToNewPlaylistURL({ ...options, workspaceId: this.workspaceId });
      this.navigateToPageAndTerminateWorker({ newRoute: url });
    },
    routeToRepo(options) {
      const url = getRepoPath(this.workspaceId, options.repoId, options.branch);
      this.navigateToPageAndTerminateWorker({ newRoute: url });
      this.openProcessToDocModal();
    },
    toggleCreationHub({ creationHubSection = null, fromQuery = false } = {}) {
      if (this.showCreationHubModal) {
        this.closeCreationHubModal();
        this.analytics.track(productEvents.CLOSED_CREATION_HUB, {});
      } else {
        this.openCreationHubModal({
          showRepoDropdown: true,
          keepAfterRouteChange: fromQuery,
          newDocClick: this.routeToNewDoc,
          newPlaylistClick: this.routeToNewPlaylist,
          newProcessClick: this.routeToRepo,
        });
        // Override current section by ref
        this.creationHubModalRef.onSectionChange(creationHubSection ? creationHubSection : 0);
        this.analytics.track(productEvents.CLICKED_NEW, {});
      }
    },
    handleNewDocClick() {
      if (this.isEmptyWorkspace) {
        this.onCreateDocClick('Top New Doc button');
      } else {
        this.toggleCreationHub();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.home-view {
  margin: 20px auto 100px;
}

.workspace-header {
  display: flex;
  align-items: flex-start;
  margin: var(--space-lg) 0;

  .title-wrapper {
    margin-inline-start: 16px;
    flex: 1;

    .workspace-name {
      padding: 0;
      word-break: break-all;
    }

    .info-row {
      display: flex;
      justify-content: space-between;
    }

    .workspace-description {
      margin-top: var(--space-base);
      font-weight: 300;
    }
  }
}

.tabs-container {
  border-bottom: 1px solid var(--color-border-default);
  margin-bottom: var(--space-sm);

  .tabs {
    margin-bottom: 4px;

    & :deep(.tab) {
      font-size: var(--subtitle-XL);
    }
  }
}

section {
  margin: 40px 0;
}

.tag-wrapper {
  display: flex;
  margin-bottom: 0.5rem;
  font-weight: bold;

  .tag {
    margin-right: 15px;
  }
}

.suggest-trial {
  color: var(--text-color-link);
  cursor: pointer;
  line-height: 1.5rem;
  align-self: baseline;
}

.skeleton {
  width: 220px;
  height: 24px;
}

.fallback-loader {
  height: 100%;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  gap: var(--space-sm);
}

.authorize-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-sm) 0;

  .authorize-button {
    margin-left: auto;
    padding: 0;
  }

  .ribbon {
    flex: 1;

    :deep(.message) {
      flex-grow: 0;
    }
  }

  .content a {
    color: var(--text-color-warning);
    text-decoration: underline;

    &:hover {
      color: var(--text-color-warning-strong);
    }
  }

  .emoji {
    margin-right: var(--space-xs);
  }
}

.expiring {
  color: var(--color-border-danger);
}

.link {
  color: var(--text-color-link);
  cursor: pointer;
}

.shared-docs-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--space-xs);
  color: var(--text-color-secondary);
  margin: var(--space-md) 0;
}
</style>
