import { useSnippetStore } from '@/modules/snippet-studio/stores/snippetsStore';
import { removePrefix } from '@swimm/shared';
import type { EditorSnippet, SnippetSelection } from '@swimm/swmd';
import { Observable, Subscriber } from 'rxjs';

export class WebSnippetSelection implements SnippetSelection {
  constructor(private snippetStore: ReturnType<typeof useSnippetStore>) {}

  editSnippet(initialSnippet?: EditorSnippet | null) {
    return this.selectImpl(initialSnippet);
  }

  selectSnippets(): Observable<EditorSnippet> {
    return this.selectImpl();
  }

  private selectImpl(initialSnippet?: EditorSnippet) {
    return new Observable((subscriber: Subscriber<EditorSnippet | null>) => {
      this.snippetStore.selectedSnippets = subscriber;

      if (initialSnippet) {
        this.snippetStore.snippetToEdit = { ...initialSnippet, path: removePrefix(initialSnippet.path, '/') };
      }
    });
  }
}
