import {
  collectionNames,
  createDocInSubCollection,
  getDocFromCollection,
  getDocFromSubCollection,
  getSubCollectionRef,
  setValuesInDoc,
} from '@/adapters-common/firestore-wrapper';
import { swimmApi } from '@/common/swimm-backend-client';
import { type WorkspaceDocumentTemplate, type WorkspaceUser, config, getLoggerNew } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function getWorkspaceFromDB(workspaceId: string) {
  const response = await getDocFromCollection(collectionNames.WORKSPACES, workspaceId);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error getting workspace ${workspaceId} from DB, error: ${response.errorMessage}`);
    return {};
  }
  return response.data;
}

export async function getWorkspaceUserFromDB(workspaceId: string, userId: string): Promise<WorkspaceUser | null> {
  try {
    const response = await swimmApi.getWorkspaceUser(workspaceId, userId);
    return response.data;
  } catch (err) {
    logger.error({ err }, `Failed in getWorkspaceUserFromDB for workspaceId=${workspaceId} userId=${userId}`);
    return null;
  }
}

export async function fetchDocumentTemplates(workspaceId: string): Promise<Map<string, WorkspaceDocumentTemplate>> {
  const response = getSubCollectionRef(collectionNames.WORKSPACES, workspaceId, collectionNames.DOCUMENT_TEMPLATES);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error getting document templates ref on workspace ${workspaceId} from DB, error: ${response.errorMessage}`
    );
    return new Map();
  }
  const templatesMap = new Map<string, WorkspaceDocumentTemplate>();
  const querySnapshot = await response.data.orderBy('version', 'desc').get();
  if (querySnapshot.docs.length === 0) {
    return templatesMap;
  }
  querySnapshot.docs.forEach((doc) => {
    const data = doc.data();
    if (!templatesMap.has(data.name)) {
      templatesMap.set(data.name, {
        id: doc.id,
        format: data.format,
        name: data.name,
        text: data.text,
      });
    }
  });

  return templatesMap;
}

export async function fetchLastTemplateVersion(workspaceId: string, name: string): Promise<number> {
  const response = getSubCollectionRef(collectionNames.WORKSPACES, workspaceId, collectionNames.DOCUMENT_TEMPLATES);
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error getting document templates ref on workspace ${workspaceId} from DB, error: ${response.errorMessage}`
    );
    throw new Error(response.errorMessage);
  }

  const querySnapshot = await response.data.where('name', '==', name).orderBy('version', 'desc').limit(1).get();

  if (querySnapshot.docs.length === 0) {
    return 0;
  }
  return querySnapshot.docs[0].data().version;
}

export async function fetchDocumentTemplate(
  workspaceId: string,
  templateId: string
): Promise<WorkspaceDocumentTemplate> {
  const response = await getDocFromSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.DOCUMENT_TEMPLATES,
    templateId
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error getting document template ${templateId} on workspace ${workspaceId} from DB, error: ${response.errorMessage}`
    );
    throw new Error(response.errorMessage);
  }

  return response.data as WorkspaceDocumentTemplate;
}

export async function createDocumentTemplate(
  workspaceId: string,
  templateId: string,
  template: string,
  name: string,
  version: number
) {
  const response = await createDocInSubCollection(
    collectionNames.WORKSPACES,
    workspaceId,
    collectionNames.DOCUMENT_TEMPLATES,
    templateId,
    {
      format: '.t.sw.md',
      name,
      text: template,
      version,
    }
  );

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(
      `Error creating document template ${templateId} on workspace ${workspaceId} in DB, error: ${response.errorMessage}`
    );
    throw new Error(response.errorMessage);
  }
}

export async function addRepoToWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  try {
    await swimmApi.updateWorkspaceUserFavorites(workspaceId, userId, 'add', { repoIds: [repoId] });
  } catch (err) {
    logger.error({ err }, `Failed in addRepoToWorkspaceUserFavorites`);
  }
}

export async function removeRepoFromWorkspaceUserFavorites(workspaceId: string, userId: string, repoId: string) {
  try {
    await swimmApi.updateWorkspaceUserFavorites(workspaceId, userId, 'remove', { repoIds: [repoId] });
  } catch (err) {
    logger.error({ err }, `Failed in removeRepoFromWorkspaceUserFavorites`);
  }
}

export async function updateWorkspaceUserRecentRepos(workspaceId: string, userId: string, repoIds: string[]) {
  try {
    await swimmApi.updateWorkspaceUserRecentRepoIds(workspaceId, userId, { repoIds });
  } catch (err) {
    logger.error({ err }, `Failed in removeRepoFromWorkspaceUserFavorites`);
  }
}

export async function updateWorkspaceOnFirestore(
  workspaceId: string,
  data: { [key: string]: string | boolean | number | object }
): Promise<boolean> {
  const response = await setValuesInDoc(collectionNames.WORKSPACES, workspaceId, data, { merge: true });

  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error updating workspace ${workspaceId} in DB, error: ${response.errorMessage}`);
    return false;
  }
  return true;
}
