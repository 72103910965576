<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import { Checkbox, SwModal } from '@swimm/ui';
import { BaseButton, BaseLayoutGap, BaseProse } from '@swimm/reefui';
import type { Editor } from '@tiptap/vue-3';
import { productEvents } from '@swimm/shared';

const props = defineProps<{
  editor: Editor;
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

async function handleContinue() {
  swimmEditorServices.external.trackEvent(productEvents.CLICKED_CONTINUE_ANYWAY_IN_COPY_PASTE_WARNING, {
    "Don't Show Again": doNotShowAgain.value,
  });
  await close();
}
async function handleUndo() {
  swimmEditorServices.external.trackEvent(productEvents.CLICKED_ADD_CODE_SNIPPET_INSTEAD_IN_COPY_PASTE_WARNING, {
    "Don't Show Again": doNotShowAgain.value,
  });
  props.editor.commands.undo();
  props.editor.commands.selectAndInsertSwmSnippets();
  await close();
}

async function close() {
  if (doNotShowAgain.value) {
    await swimmEditorServices.external.markPasteCodeWarningDismissed();
  }
  swimmEditorServices.showPasteCodeWarningModal.value = false;
}

const shouldShow = computed(() => swimmEditorServices.showPasteCodeWarningModal.value);

const doNotShowAgain = ref(false);

watch(
  shouldShow,
  (value) => {
    if (value) {
      swimmEditorServices.external.trackEvent(productEvents.SHOWN_COPY_PASTE_CODE_WARNING, {});
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <SwModal
    :show-modal="shouldShow"
    :show-border="true"
    heading="Use right click > Add to doc instead of copy & paste"
    :close-on-backdrop-click="false"
    @close="handleContinue"
  >
    <BaseLayoutGap class="paste-code-warning" alignment="stretch" direction="column">
      <BaseProse>
        Add as a code snippet using the right click menu instead of using copy & paste so that your doc will be kept up
        to date when the code changes.
      </BaseProse>
      <Checkbox v-model="doNotShowAgain" size="small" class="do-not-show-again"
        ><BaseProse size="small">Do not show this message again</BaseProse></Checkbox
      >
      <BaseLayoutGap class="buttons" alignment="right">
        <BaseButton class="continue-action" variant="tertiary" @click="handleContinue">Continue anyway</BaseButton>
        <BaseButton class="undo-action" vairant="primary" @click="handleUndo">Add as code snippet instead</BaseButton>
      </BaseLayoutGap>
    </BaseLayoutGap>
  </SwModal>
</template>

<style scoped lang="postcss">
.paste-code-warning {
  .buttons {
    margin-top: var(--space-sm);
  }

  .do-not-show-again {
    margin-top: var(--space-sm);
  }
}
</style>
