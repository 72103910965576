<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { type TokenSuggestion, trimStartSpacesFromString } from '@swimm/shared';
import { highlightCodeLine } from '@/components/EditorComponents/code-snippet-utils';
import CodeSnippetContentLineOverlay from './CodeSnippetContentLineOverlay.vue';
import { type OverlayToken, getOverlayTokens } from '../GenericText/tokenizer';

const props = defineProps<{
  origin: 'fileA' | 'fileB';
  language: string;
  lineDisplay: string; // can be empty string or number as string
  content: string;
  amountOfSpacesToTrim: number;
  queryDefinitions?: ((token: string) => Promise<TokenSuggestion[]>) | null;
}>();

const emit = defineEmits<{
  (event: 'add-def-snippet', suggestion: TokenSuggestion): void;
  (event: 'token-interacted', op: 'hover' | 'click', tok: OverlayToken): void;
}>();

const codeWithCorrectSpacesAmount = computed(() =>
  trimStartSpacesFromString(props.content, props.amountOfSpacesToTrim)
);

const formattedContent = computed<string>(() => {
  return highlightCodeLine(codeWithCorrectSpacesAmount.value, props.language);
});

function addDefSnippet(suggestion: TokenSuggestion) {
  emit('add-def-snippet', suggestion);
}

function tokenInteracted(op: 'hover' | 'click', tok: OverlayToken) {
  emit('token-interacted', op, tok);
}

const overlayTokens = ref<OverlayToken[]>([]);

watchEffect(async () => {
  if (props.queryDefinitions != null) {
    overlayTokens.value = await getOverlayTokens(props.queryDefinitions, codeWithCorrectSpacesAmount.value);
  } else {
    overlayTokens.value = [];
  }
});

const hasDefTokens = computed(() => {
  return overlayTokens.value.some((token) => token.isDefinition);
});
</script>

<template>
  <!--  TODO You are supposed to highlight the entire code, not a line at a time-->
  <template v-if="hasDefTokens">
    <div class="data-highlight" data-testid="diff-snippet" :id="origin" :data-line-number="lineDisplay">
      <pre
        class="system-body main"
        data-testid="content-pre-wrapper"
      ><code class="code-span data-highlight-code hljs main" data-testid="content" :class="`language-${language}`" v-html="formattedContent" /><CodeSnippetContentLineOverlay
      :overlay-tokens="overlayTokens"
      @token-interacted="tokenInteracted"
      @add-def-snippet="addDefSnippet"/></pre>
    </div>
  </template>
  <template v-else>
    <div class="data-highlight" data-testid="diff-snippet" :id="origin" :data-line-number="lineDisplay">
      <pre
        class="system-body main"
        data-testid="content-pre-wrapper"
      ><code class="code-span data-highlight-code hljs main" data-testid="content" :class="`language-${language}`" v-html="formattedContent" /></pre>
    </div>
  </template>
</template>

<style scoped lang="postcss">
.data-highlight {
  width: 100%;
  line-height: 23px;
  overflow-wrap: break-word;
  word-break: break-word;
  display: flex;
  align-items: center;
  overflow-x: unset;
  white-space: pre;

  &::before {
    content: attr(data-line-number);
    font-size: var(--body-S);
    font-family: var(--fontfamily-secondary);
    color: var(--text-color-secondary);
    user-select: none;
    margin: 0 var(--space-sm);
    min-width: calc(var(--min-char-amount) * 1ch);
    background-color: inherit;
  }

  pre {
    margin: 0;
    white-space: pre-wrap;
    position: relative;

    code {
      padding: 0;
      background: transparent;
    }
  }
}
</style>
