import type { Observable } from 'rxjs';
import type { GiphyClient } from '@/components/GiphySelectionModal.vue';
import SwmLinkSelectionPopover from '@/components/SwmLinkSelectionPopover.vue';
import SwmMentionSelectionPopover from '@/components/SwmMentionSelectionPopover.vue';
import YouTubePopover from '@/components/YouTubePopover.vue';
import type { NormalizedAutosyncOutput } from '@/swmd/autosync';
import {
  type AddNotificationParams,
  type AiContentSuggestionsService,
  type AnalyticsTrackOptions,
  type AnalyticsTrackProperties,
  EditorEnvKind,
  type OpenFileParam,
  type TokenSuggestionsService,
  useSourceFilesIndex,
} from '@swimm/editor';
import type { Link, User } from '@swimm/reefui';
import {
  ApplicabilityStatus,
  type FolderTree,
  type GenerateMermaidRequest,
  type GenerateMermaidResponse,
  type GenerateSnippetCommentResponse,
  type GenerateTextModifiersResponse,
  type GenerativeAiResponse,
  type Repo,
  type SmartElement,
  SmartElementType,
  type SmartElementWithApplicability,
  type SmartElementWithApplicabilityAndNewInfo,
  type Snippet,
  StiggFeatures,
  SwmSymbolLinkType,
  type TextCompletionContext,
  type Token,
  type TokenSuggestion,
  type WorkspaceUser,
  buildRepoFullName,
  filterSmartElementsSnippets,
  filterSmartElementsWithPath,
  getFilesReferencedInAutosyncedDocument,
  getGitProviderIconName,
  isSmartElementWithNewInfo,
  productEvents,
} from '@swimm/shared';
import type { Editor } from '@tiptap/core';
import type { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { until, useEventBus } from '@vueuse/core';
import { type Ref, computed, ref } from 'vue';
import { EditorAnimations } from './EditorAnimations';
import { showEditorPopover } from '../popover';
import { ANIMATIONS_BUS_KEY } from './animations';
import type { ExtensionNamesType } from '@/swmd/extensions';
import { max } from 'lodash-es';

export interface Repos {
  loading: boolean;
  repos: Repo[];
}

export interface EditorSnippet {
  snippet: string;
  path: string;
  line: number;
  repoId: string;
  repoPath?: string; // for IDE
}

export interface SwimmEditorServicesOptions {
  swimmEditorServices?: SwimmEditorServices;
}

export interface OpenSwimmResourceParam {
  repoId: string;
  path: string;
  branch: string;
  newTab?: boolean;
  nodeId?: string;
}

export interface SwmTokenSelectionAdvancedModalResult {
  showSimple: boolean;
  lastQuery: string;
  selectedToken?: TokenSuggestion;
}

export type ExtensionConfiguration = Record<
  ExtensionNamesType,
  {
    theme?: { [classToOverride: string]: string };
    hide?: boolean;
    hideApplicability?: boolean;
    [k: string]: { [classToOverride: string]: string } | boolean | undefined;
  }
>;

export interface UploadedImage {
  src: string;
  width: number;
  height: number;
}
export interface SwimmNodeItem {
  node: ProseMirrorNode;
  pos: number;
  parent: ProseMirrorNode | null;
  index: number;
  swimmNodeId: string;
}

export interface SwimmEditorExternalServices {
  readonly giphyClient: GiphyClient;
  readonly aiContentSuggestionsService: AiContentSuggestionsService;
  readonly tokenSuggestionsService: TokenSuggestionsService;
  readonly isWorkspaceAdmin: Ref<boolean>;
  readonly isGenAiDisabledInWorkspace: Ref<boolean>;
  readonly swimmportEnabled: Ref<boolean>;
  readonly isAutoCompleteEnabled: Ref<boolean>;
  readonly isDarkMode: Ref<boolean>;
  getRepoFolderTree(repoId: string, branch: string, isCrossRepo: boolean): Promise<FolderTree | undefined>;
  getRepoLinks(repoId: string, branch: string, linkType: SwmSymbolLinkType): Promise<Link[]>;
  getWorkspaceUsers(): Promise<WorkspaceUser[]>;
  openFile(param: OpenFileParam): Promise<void>;
  getFileContent({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision: string;
  }): Promise<string | undefined>;
  openLink(href: string): void;
  openSwimmResource(param: OpenSwimmResourceParam): Promise<void>;
  isDraft(unitId: string): boolean;
  uploadImage(file: File, unitId: string): Promise<UploadedImage>;
  getImageFile(src: string, unitId: string): Promise<File>;
  trackEvent(eventName: string, properties: AnalyticsTrackProperties, options?: AnalyticsTrackOptions): void;
  showNotification(text: string, params?: AddNotificationParams): void;
  selectSnippets(): Observable<EditorSnippet | null>;
  editSnippet(snippet?: EditorSnippet | null): Observable<EditorSnippet | null>;
  endSnippetSelection(): Promise<void>;
  openGenerativeAiRepoSettings(): void;
  generateTextModifier(request: { repoId: string; workspaceId: string; textInput: string; modifier: string }): Promise<
    | (GenerativeAiResponse & GenerateTextModifiersResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  >;
  isAIGenerationEnabledForRepo(): boolean;
  generateSnippetComment(request: { repoId: string; workspaceId: string; snippetContent: string }): Promise<
    | (GenerativeAiResponse & GenerateSnippetCommentResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  >;
  generateMermaid(request: Omit<GenerateMermaidRequest, 'type'>): Promise<
    | (GenerativeAiResponse & GenerateMermaidResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  >;
  isQuotaExceeded(featureId: StiggFeatures): Promise<boolean>;
  getDismissedSwimmportSuggestions(): Promise<string[]>;
  dismissSwimmportSuggestion(text: string): Promise<void>;
  setSmartElementCountInDB(docId: string, repoId: string, autosyncOutput: NormalizedAutosyncOutput): Promise<void>;
  completeText(context: TextCompletionContext): Promise<{ generatedText: string; cost: number }>;
  isPasteCodeWarningApplicable(): boolean;
  markPasteCodeWarningDismissed(): Promise<void>;
  allowDebugMermaidAi(): boolean;
}

export class SwimmEditorServices {
  editor?: WeakRef<Editor>;

  readonly showSwmPathSelectionModal = ref(false);
  readonly swmPathSelectionSelectedNode = ref<FolderTree & { repoId: string }>();
  readonly swmPathSelectionDisableDirectorySelection = ref(false);
  readonly swmPathSelectionDialogTitle = ref<string | undefined>(undefined);

  readonly swmLinkSelection = ref<Link & { repoId: string }>();

  readonly swmMentionSelection = ref<User>();

  readonly swmYouTubeSelection = ref<string>();

  readonly showGiphySelectionModal = ref(false);
  readonly giphySelection = ref<string>();

  readonly showAiGenerationDisabledModal = ref(false);

  readonly showPasteCodeWarningModal = ref(false);

  /** @deprecated */
  readonly animations = new EditorAnimations();

  readonly animationsBus = useEventBus(ANIMATIONS_BUS_KEY);

  readonly autosyncOutput = ref<NormalizedAutosyncOutput>({
    applicability: ApplicabilityStatus.Verified,
    smartElements: new Map(),
  });

  readonly swimmNodeIdsToPositions = ref<Map<string, Set<number>>>(new Map());
  readonly nodesIdWithFilePath = ref<Set<string>>(new Set());

  readonly positionSet = computed(() => {
    const resultSet = new Set();
    for (const positionSet of this.swimmNodeIdsToPositions.value.values()) {
      resultSet.add([...positionSet]);
    }
    return resultSet;
  });

  readonly swimmSmartElements = computed(() => {
    return Array.from(this.swimmNodeIdsToPositions.value.keys())
      .map((swimmNodeId) => {
        return this.autosyncOutput.value.smartElements.get(swimmNodeId);
      })
      .filter((se) => se != null) as SmartElementWithApplicability<SmartElement>[];
  });

  readonly swmTokensWithPathRenames = computed(() =>
    this.swimmSmartElements.value.filter(
      (smartElement) =>
        smartElement.type === SmartElementType.TOKEN &&
        smartElement.applicability === ApplicabilityStatus.Verified &&
        isSmartElementWithNewInfo(smartElement) &&
        (smartElement as SmartElementWithApplicabilityAndNewInfo<Token>).filePath !==
          (smartElement as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.filePath &&
        // Exclude the tokens that arrive from referenced snippets
        // those will be handled by the snippet itself
        !this.autosyncedReferencedPathsFromSnippets.value.some(
          ({ path }) => path === (smartElement as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.filePath
        )
    )
  );

  // return all paths references by any smart element which is not LINK and folder paths
  readonly autosyncedReferencedFilePaths = computed(() => {
    const smartElements = filterSmartElementsWithPath(this.swimmSmartElements.value).filter(
      (smartElement) =>
        // filter out folder paths and doc/playlist links
        this.nodesIdWithFilePath.value.has(smartElement.id) && smartElement.type !== SmartElementType.LINK
    );
    return getFilesReferencedInAutosyncedDocument(smartElements, true);
  });

  // return all snippets paths
  readonly autosyncedReferencedPathsFromSnippets = computed(() => {
    const smartElements = filterSmartElementsSnippets(this.swimmSmartElements.value);
    return getFilesReferencedInAutosyncedDocument(smartElements);
  });

  // return only the snippets
  readonly autosyncedSnippets = computed<SmartElementWithApplicability<Snippet>[]>(() => {
    return filterSmartElementsSnippets(this.swimmSmartElements.value);
  });

  readonly mentions = ref(new Map<string, { uid: string; name: string; email: string; pos: number }>());

  readonly headings = ref<NodeListOf<Element>>();

  readonly referencedFiles = computed<Map<string, string[]>>(() => {
    return this.autosyncedReferencedFilePaths.value.reduce<Map<string, string[]>>((accumulator, currentValue) => {
      if (!accumulator.has(currentValue.repoId)) {
        accumulator.set(currentValue.repoId, []);
      }
      accumulator.get(currentValue.repoId)?.push(currentValue.path);
      return accumulator;
    }, new Map());
  });

  readonly sourceFiles = useSourceFilesIndex(this.referencedFiles);

  readonly showSwmTokenSelectionAdvancedModal = ref(false);
  readonly swmTokenSelectionAdvancedModalInitialQuery = ref('');
  readonly swmTokenSelectionAdvancedModalResult = ref<SwmTokenSelectionAdvancedModalResult>();

  readonly maxSnippetLineCount = computed(() =>
    max(
      this.swimmSmartElements.value
        .filter(
          (element: { type: SmartElementType }): element is SmartElementWithApplicability<Snippet> =>
            element.type === SmartElementType.SNIPPET
        )
        .map((element: SmartElementWithApplicability<Snippet>) => element.lines.length) ?? []
    )
  );

  readonly snippetsInDocumentCount = computed(
    () =>
      this.swimmSmartElements.value.filter(
        (element: { type: SmartElementType }) => element.type === SmartElementType.SNIPPET
      ).length
  );

  constructor(
    public readonly editable: Ref<boolean>,
    public readonly editorEnvKind: EditorEnvKind,
    public readonly external: SwimmEditorExternalServices,
    public readonly baseUrl: string,
    public readonly repos: Ref<Repos>,
    public readonly isAuthorized: Ref<boolean>,
    public readonly workspaceId: Ref<string>,
    public readonly repoId: Ref<string>,
    public readonly branch: Ref<string>,
    public readonly unitId: Ref<string>,
    public readonly isAirGapMode: boolean,
    public readonly extensionConfiguration?: ExtensionConfiguration,
    private readonly editorContentType?: 'doc' | 'template'
  ) {}

  get isIde() {
    return this.editorEnvKind === EditorEnvKind.VSCODE || this.editorEnvKind === EditorEnvKind.JETBRAINS;
  }

  get isClipboardAccessAvailable() {
    return this.editorEnvKind !== EditorEnvKind.JETBRAINS;
  }

  get isDragDropSupported() {
    // OSR doesn't implement drag & drop
    return this.editorEnvKind !== EditorEnvKind.JETBRAINS;
  }

  get isAirGap() {
    return this.isIde && this.isAirGapMode;
  }

  get hasAccessToCrossRepo() {
    return !this.isIde;
  }

  get isTemplate() {
    return this.editorContentType === 'template';
  }

  getCustomConfiguration(extensionName: ExtensionNamesType) {
    return this.extensionConfiguration?.[extensionName];
  }

  getRepo(repoId: string): Repo | undefined {
    // TODO O(n) not efficient
    return this.repos.value.repos.find((repo) => repo.id === repoId);
  }

  getRepoName(repoId: string): string | undefined {
    return this.getRepo(repoId)?.name;
  }

  getRepoIconName(repoId: string): string {
    return getGitProviderIconName(this?.getRepo(repoId)?.provider);
  }

  getRepoFullName(repoId: string): string {
    return buildRepoFullName(this?.getRepo(repoId));
  }

  async selectPath({
    disableDirectorySelection = false,
    dialogTitle,
  }: { disableDirectorySelection?: boolean; dialogTitle?: string } = {}): Promise<
    (FolderTree & { repoId: string }) | undefined
  > {
    this.swmPathSelectionDisableDirectorySelection.value = disableDirectorySelection;
    this.swmPathSelectionDialogTitle.value = dialogTitle;
    this.swmPathSelectionSelectedNode.value = undefined;
    this.showSwmPathSelectionModal.value = true;
    await until(this.showSwmPathSelectionModal).not.toBeTruthy();
    return this.swmPathSelectionSelectedNode.value;
  }

  async selectLink(linkType: SwmSymbolLinkType): Promise<(Link & { repoId: string }) | undefined> {
    this.swmLinkSelection.value = undefined;

    const editor = this.editor?.deref();
    if (editor == null) {
      return;
    }

    await showEditorPopover(editor, SwmLinkSelectionPopover, { linkType });
    return this.swmLinkSelection.value;
  }

  async selectMention(): Promise<User | undefined> {
    this.swmMentionSelection.value = undefined;

    const editor = this.editor?.deref();
    if (editor == null) {
      return;
    }

    await showEditorPopover(editor, SwmMentionSelectionPopover);
    return this.swmMentionSelection.value;
  }

  async selectYouTube(): Promise<string | undefined> {
    this.swmYouTubeSelection.value = undefined;

    const editor = this.editor?.deref();
    if (editor == null) {
      return;
    }

    await showEditorPopover(editor, YouTubePopover);
    return this.swmYouTubeSelection.value;
  }

  async selectGiphy(): Promise<string | undefined> {
    this.giphySelection.value = undefined;
    this.showGiphySelectionModal.value = true;
    await until(this.showGiphySelectionModal).not.toBeTruthy();
    return this.giphySelection.value;
  }

  setShowAiGenerationDisabledModal(open: boolean, context?: string) {
    this.showAiGenerationDisabledModal.value = open;
    if (open) {
      this.external.trackEvent(
        productEvents.SHOWN_GEN_AI_DISABLED_POPUP,
        {
          isAdmin: this.external.isWorkspaceAdmin.value,
          Platform: this.editorEnvKind,
          Context: context,
        },
        { addRouteParams: true, shouldSendCloud: true }
      );
    }
  }

  async selectTokenInAdvancedMode(initialQuery: string): Promise<SwmTokenSelectionAdvancedModalResult | undefined> {
    this.swmTokenSelectionAdvancedModalResult.value = undefined;
    this.swmTokenSelectionAdvancedModalInitialQuery.value = initialQuery;
    this.showSwmTokenSelectionAdvancedModal.value = true;
    await until(this.showSwmTokenSelectionAdvancedModal).not.toBeTruthy();
    return this.swmTokenSelectionAdvancedModalResult.value;
  }

  async addFileAsSnippet({ filePath, repoId, revision }: { filePath: string; repoId: string; revision: string }) {
    const editor = this.editor?.deref();
    if (editor == null) {
      return;
    }
    const fileContents = await this.external.getFileContent({
      repoId,
      filePath,
      revision,
    });
    if (!fileContents) {
      return;
    }
    editor.chain().focus().insertSnippetOrCodeBlock(fileContents, filePath, 1, repoId, '').run();
    this.external.trackEvent(productEvents.ADDED_FILE_AS_SNIPPET, {
      'Line Count': fileContents.split('\n').length,
      'Is Cross Repo': repoId !== this.repoId.value,
    });
  }
}

export function populateIdsToPositions(item: SwimmNodeItem, nodeIdsToPositions: Map<string, Set<number>>) {
  if (!nodeIdsToPositions.get(item.swimmNodeId)) {
    nodeIdsToPositions.set(item.swimmNodeId, new Set());
  }
  nodeIdsToPositions.get(item.swimmNodeId)?.add(item.pos);
}

export function populateIdWithFilePath(item: SwimmNodeItem, nodesIdWithFilePath: Set<string>) {
  if (item.node.attrs.path || (item.node.attrs.href && !item.node.attrs.href.endsWith('/'))) {
    // only paths can have path that is to folder and not to a file
    nodesIdWithFilePath.add(item.swimmNodeId);
  }
}
