import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Subscriber } from 'rxjs';
import { state as localState } from '@swimm/shared';
import type { EditorSnippet } from '@swimm/swmd';

export const useSnippetStore = defineStore('snippetState', () => {
  // should find a better solution for this
  const editedSnippetIndex = ref<number>();
  let selectedSnippets: Subscriber<EditorSnippet | null> | undefined;
  const snippetToEdit = ref<EditorSnippet>();
  const isPlaceholder = ref<boolean>(false);
  const snippetDrawerHeight = ref(0);

  async function setSnippetDrawerHeight({ drawerHeight }: { drawerHeight: number }) {
    await localState.set({ key: 'snippet_drawer_height', value: drawerHeight });
    snippetDrawerHeight.value = drawerHeight;
  }

  const resetStore = () => {
    selectedSnippets = undefined;
    snippetToEdit.value = undefined;
    editedSnippetIndex.value = undefined;
    isPlaceholder.value = false;
  };

  return {
    selectedSnippets,
    isPlaceholder,
    resetStore,
    snippetToEdit,
    editedSnippetIndex,
    snippetDrawerHeight,
    setSnippetDrawerHeight,
  };
});
