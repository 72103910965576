import { getStateInstance } from 'MixedLibs/local-state-instance';
import * as config from './config';
import LocalState from './local-state';

class LocalStateApp extends LocalState {
  constructor() {
    const initializedLocalStateApp = config.isWebApp
      ? getStateInstance()
      : getStateInstance({
          projectName: config.PROJECT_NAME,
          configName: config.LOCAL_STATE_FILE_NAME,
          cwd: config.LOCAL_CONFIGS_PATH,
        });
    super(initializedLocalStateApp);
  }
}

export default new LocalStateApp();
