import * as firestore from '@/adapters-common/firestore-wrapper';
import {
  NOTIFICATION_TARGETS,
  NOTIFICATION_TYPES,
  type NotificationDbItemInterface,
  config,
  getLoggerNew,
} from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function getUserNotificationsFromDB({
  user,
  limit,
}: {
  user: { email?: string; uid: string };
  limit: number;
}): Promise<Array<NotificationDbItemInterface & { id: string }>> {
  const byUidResponse = await firestore.getDocsRefWithWhereClauses(
    firestore.collectionNames.NOTIFICATIONS,
    [
      ['recipient_id', '==', user.uid],
      ['targets', 'array-contains', NOTIFICATION_TARGETS.IN_APP],
    ],
    ['created_at', 'desc'],
    limit
  );

  if (byUidResponse.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error fetching notifications by uid for user ${user.uid}: ${byUidResponse.errorMessage}`, {
      module: 'database',
    });
    return [];
  }
  const uidNotifications = byUidResponse.data.docs.map((notification) => {
    const n = notification.data() as NotificationDbItemInterface;
    return {
      id: notification.id,
      ...n,
    };
  });
  const idsFromUidSet = new Set(uidNotifications.map((n) => n.id));
  let emailNotifications = [];
  if (user.email) {
    const byEmailResponse = await firestore.getDocsRefWithWhereClauses(
      firestore.collectionNames.NOTIFICATIONS,
      [
        ['recipient_email', '==', user.email],
        ['targets', 'array-contains', NOTIFICATION_TARGETS.IN_APP],
      ],
      ['created_at', 'desc'],
      limit
    );
    if (byEmailResponse.code !== config.SUCCESS_RETURN_CODE) {
      logger.error(`Error fetching notifications by email for email ${user.email}: ${byEmailResponse.errorMessage}`);
    } else {
      // If notification is sent to user is supposed to have empty email
      // but we add extra check for the case that both fields populated
      emailNotifications = byEmailResponse.data.docs
        .filter((notificationSnapshot) => {
          const n = notificationSnapshot.data() as NotificationDbItemInterface;
          return !n.recipient_id && !idsFromUidSet.has(notificationSnapshot.id);
        })
        .map((notification) => ({
          id: notification.id,
          ...notification.data(),
        }));
    }
  }
  const combinedNotifications = uidNotifications.concat(emailNotifications);
  const notifications = combinedNotifications.filter((notification) => {
    if (!notification) {
      return false;
    } else if (!NOTIFICATION_TYPES[notification.type]) {
      logger.warn(
        `Filtering unknown notification for user: ${user.uid}. NotificationId: ${notification.id} NotificationType: ${notification.type}`
      );
      return false;
    }
    return true;
  });
  // Sort new to old and limit
  return notifications.sort((n1, n2) => (n1.created_at > n2.created_at ? -1 : 1)).slice(0, limit);
}

export async function markNotificationAsSeen(notificationId: string): Promise<void> {
  const response = await firestore.updateDocInCollection(firestore.collectionNames.NOTIFICATIONS, notificationId, {
    seen: true,
    seen_at: firestore.firestoreTimestamp(),
  });
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error marking notification ${notificationId} as seen: ${response.errorMessage}`);
  }
}

export async function markNotificationAsDismissed(notificationId: string): Promise<void> {
  const response = await firestore.updateDocInCollection(firestore.collectionNames.NOTIFICATIONS, notificationId, {
    dismissed: true,
    dismissed_at: firestore.firestoreTimestamp(),
  });
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error marking notification ${notificationId} as dismissed: ${response.errorMessage}`);
  }
}
