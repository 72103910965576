<script setup lang="ts">
import { useDocReport } from '@/modules/editor3/composables/doc-report';
import { until, watchTriggerable } from '@vueuse/core';
import { debounce, isEqual } from 'lodash-es';
import { storeToRefs } from 'pinia';
import {
  type PropType,
  computed,
  defineAsyncComponent,
  onBeforeUnmount,
  ref,
  toRaw,
  toRef,
  watch,
  watchEffect,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import * as webConfig from '@/config';

import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useDocSidebarStore } from '@/modules/doc-sidebar/stores/doc-sidebar-store';
import { useStartPollLatestCommit } from '@/modules/drafts3/composables/startPollLatestCommit';
import { type Draft, DraftType } from '@/modules/drafts3/db';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { useLatestCommitStore } from '@/modules/drafts3/stores/latestCommit';
import { useDbWrapper } from '@/modules/editor/tiptapEditor/compositions/dbWrapper';
import { useFsWrapper } from '@/modules/editor/tiptapEditor/compositions/fsWrapper';
import { useGenerativeAi } from '@/modules/editor/tiptapEditor/compositions/generativeAi';
import { useCurrentDocResources } from '@/modules/editor3/composables/current-doc-resources';
import { useSnippetStudioComposable3 } from '@/modules/editor3/composables/snippet-studio-composable3';
import { WebSwimmEditorExternalServices } from '@/modules/editor3/externalServices';
import { useReposStore } from '@/modules/repo/stores/repos-store';

import { useAnalytics } from '@/common/composables/useAnalytics';
import useSharingInternally from '@/modules/cloud-docs/composables/sharing-internally';

import {
  DocGenerateDrawer,
  EditorEnvKind,
  GenerateDoc,
  GenerateDrawerEmptyState,
  StartWithButtons,
} from '@swimm/editor';

// Legacy components or components that need DS alignment.
import DrawerModal from '@/common/components/modals/DrawerModal.vue';
import MonacoViewFileModal from '@/common/components/modals/MonacoViewFileModal.vue';
import DocFooter from '@/common/components/organisms/DocFooter.vue';
import DocSidebar from '@/modules/doc-sidebar/components/DocSidebar.vue';
import ResourceFolder from '@/modules/folders/components/ResourceFolder.vue';
import SnippetStudio3 from './snippet-studio/SnippetStudio3.vue';

import {
  BaseLoading,
  Resource,
  ResourceContributors,
  ResourceHeader,
  ResourceHeaderMeta,
  ResourceHeaderMetaItem,
  ResourceViews,
} from '@swimm/reefui';

import AutoSyncChangesBanner from './AutoSyncChangesBanner.vue';

import {
  ApplicabilityStatus,
  PageSource,
  type SwimmDocument,
  type SwimmResourceUserStatus,
  config,
  getLoggerNew,
  isRepoIdDummyRepo,
  pageEvents,
  productEvents,
} from '@swimm/shared';
import {
  type Repos,
  SwmdEditor,
  containsTextOutsideSnippets,
  isDocEmpty,
  swimmify,
  useSwimmEditorServicesFromSwmdEditor,
} from '@swimm/swmd';

import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { DocNotFoundError, useDoc } from '@/modules/drafts3/composables/doc';
import { usePageTitle } from '@/common/composables/pageTitle';

import { usePr2Doc } from '@/common/composables/pr2Doc';
import GenerateProgressAndFeedback from './GenerateProgressAndFeedback.vue';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import PaywallModal, { PaywallEntity } from '@/common/components/modals/PaywallModal.vue';
import ConfirmationDialog from '@/common/components/modals/ConfirmationDialog.vue';
import GenerateFromPullRequest from './GenerateFromPullRequest.vue';
import type { JSONContent } from '@tiptap/core';

const ImageModal = defineAsyncComponent(() => import('@/common/components/Workspace/Modals/ImageModal.vue'));

const logger = getLoggerNew(__modulename);

const props = defineProps({
  editable: { type: Boolean, required: true },
  embedded: { type: Boolean },
  workspaceId: { type: String, required: true },
  repoId: { type: String, required: true },
  unitId: { type: String, required: true },
  branch: { type: String, required: true },
  committed: { type: Boolean },
  shouldIncrementViewCount: { type: Boolean },
  shouldShowToggleDone: { type: Boolean },
  shouldForceCloseSideBar: { type: Boolean, default: false },
  isPrToDoc: { type: Boolean, default: false },
  blockEditing: { type: Boolean, default: false },
  sharedDocId: { type: String, required: false, default: null },
  isCrossRepo: { type: Boolean, required: false, default: false } /* doc of other repo inside playlist */,
  source: { type: String as PropType<PageSource>, default: null },
});

const emit = defineEmits<{
  (e: 'navigate-to-edit', preserveSource?: boolean);
  (e: 'toggle-mark', status: SwimmResourceUserStatus);
}>();

const resourceHeader = ref<InstanceType<typeof ResourceHeader>>();
const loading = ref(true);

const route = useRoute();
const { pageTitle } = usePageTitle();
const reposStore = useReposStore();
const { repos: reposData } = storeToRefs(reposStore);
const workspaceStore = useWorkspaceStore();
const { repositories: workspaceRepos } = storeToRefs(workspaceStore);
const { isCurrentRepoAuthorized, isCurrentWorkspaceAuthorized } = storeToRefs(useGitAuthorizationStore());
const { fsWrapper, fileToShow } = useFsWrapper();
const { dbWrapper } = useDbWrapper();
const generativeAi = useGenerativeAi(dbWrapper);

const store = useStore();
const drafts3Store = useDrafts3Store();
const repoDocsStore = useRepoDocsStore();
const latestCommitStore = useLatestCommitStore();
const { loadingDocs, loadingDocsOfRepoId, parseDoc, loadDoc, loadCrossRepoDoc, getDocPathInRepo } = useDoc(
  toRef(() => props.workspaceId),
  toRef(() => props.repoId)
);

const authStore = useAuthStore();
const { getSharedDocMarkdown, getSharedDocNormalizedAutosyncOutput } = useSharingInternally();
const currentDocResources = useCurrentDocResources({
  repoId: toRef(() => props.repoId),
  docId: toRef(() => props.unitId),
});
const analytics = useAnalytics();
const docReport = useDocReport();
const { userDocSidebarStateValue } = useDocSidebarStore();

const error = ref<'Not found' | 'Invalid' | 'Deleted draft' | null>(null);
const doc = ref<SwimmDocument>();
const originalTitle = ref<string>();
const editor = ref<InstanceType<typeof SwmdEditor>>();
const isSidebarOpen = ref(true);
const isNewDraft = ref(false);
const hasSentFirstDraftToBackoffice = ref(false);

const { swimmEditorServices } = useSwimmEditorServicesFromSwmdEditor(editor);

const pr2Doc = usePr2Doc({
  swimmEditorServices,
  setDocument,
  swimmifyDoc,
  getDocumentContent: () => doc.value.content,
  getDocumentTitle: () => doc.value.title,
  setDocumentContent,
  toggleSnippetStudio: () =>
    tiptapEditorHandleSnippetToggler(true, () => editor.value.editor?.commands.selectAndInsertSwmSnippets(true)),
  addWholeFile,
  reportDraftFeature: (feature) => {
    const draft = drafts3Store.drafts.get(props.unitId);
    if (!draft) {
      return;
    }
    drafts3Store.updateAttrs(props.unitId, { featuresUsed: (draft.featuresUsed ?? []).concat([feature]) });
  },
});

const hasOutdatedOrAutosyncableElements = computed(() =>
  swimmEditorServices.value.swimmSmartElements.value.some(
    (element) =>
      element.applicability === ApplicabilityStatus.Autosyncable ||
      element.applicability === ApplicabilityStatus.Outdated
  )
);

const showPr2DocOutdatedModal = computed(
  () => pr2Doc.showPr2DocOutdatedModal.value && hasOutdatedOrAutosyncableElements.value
);

watch(showPr2DocOutdatedModal, (show) => {
  if (show) {
    pr2Doc.setShownPr2DocOutdatedTipModal();
  }
});

async function setDocument(swimmDocument: SwimmDocument) {
  if (loading.value) {
    await until(() => loading.value).not.toBeTruthy();
  }

  if (isEqual(doc.value.content, swimmDocument.content)) {
    return;
  }

  doc.value.title = swimmDocument.title;
  editor.value?.editor.chain().setSourceExternal().setContent(swimmDocument.content, true).run();
}

function setDocumentContent(content: JSONContent) {
  editor.value?.editor.chain().setSourceExternal().setContent(content, true).run();
}

function swimmifyDoc() {
  if (swimmEditorServices.value && editor.value) {
    swimmify(swimmEditorServices.value, editor.value.editor);
  }
}

const canEdit = computed(() => props.editable && !(props.blockEditing || pr2Doc.isGeneratingDocFromPr.value));

const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));
const isAuthorized = computed(() => {
  if (webConfig.isTest) {
    return true;
  }

  if (isDummyRepo.value) {
    return true;
  }
  // shared doc is in workspace level so we cannot use the isCurrentRepoAuthorized
  return props.sharedDocId ? isCurrentWorkspaceAuthorized.value : isCurrentRepoAuthorized.value;
});

// TODO The app loads the entire thing upfront now and uses a cache to speed it
// up, we could still be a bit faster if it let us load while the data is still
// being fetched so we could still use the loading boolean, alternatively, we
// can just remove it
const repos = computed<Repos>(() => ({
  loading: false,
  repos: reposData.value.filter((repo) => workspaceRepos.value.includes(repo.id)),
}));

const isWorkspaceAdmin = computed<boolean>(() =>
  store.getters['database/db_isWorkspaceAdmin'](drafts3Store.workspaceId, authStore.user.uid)
);

const shouldUploadFilesToRepo = computed(
  () => !!store.getters['database/db_getRepoMetadata'](props.repoId)?.integrations?.commit_images_to_repo
);

const isGenAiDisabledInWorkspace = computed<boolean>(() => {
  const workspace = store.getters['database/db_getWorkspace'](props.workspaceId);
  return workspace?.settings?.disable_gen_ai ?? false;
});
const isWorkspaceInLocalMode = computed<boolean>(() => {
  const workspace = store.getters['database/db_getWorkspace'](props.workspaceId);
  return workspace?.settings?.is_workspace_local_mode ?? false;
});

const swimmportEnabled = computed<boolean>(() => store.getters['swimmport/isEnabled']);

const resourceFolderResourceDoc = computed(() => {
  return {
    id: props.unitId,
    isNew: isNewDraft.value,
  };
});

// shared doc
const preSetAutosyncOutput = computed(() => {
  if (props.sharedDocId && doc.value && !repos.value.loading) {
    return getSharedDocNormalizedAutosyncOutput({
      workspaceId: props.workspaceId,
      sharedDocId: props.sharedDocId,
      repoId: props.repoId,
      repos: repos.value.repos,
      doc: doc.value,
    });
  }
  return null;
});

const hasDraft = computed(() => {
  return drafts3Store.drafts?.get(props.unitId) != null;
});

const shouldShowStartWithButtons = computed(() => {
  return canEdit.value && !isGenAiDisabledInWorkspace.value && isDocEmpty(doc.value.content);
});

const showAutosyncableChanges = computed(
  () => props.source === 'github_app' && swimmEditorServices.value?.swmTokensWithPathRenames.value.length > 0
);

watch(
  () => showAutosyncableChanges.value,
  (value, oldValue) => {
    if (value && !oldValue) {
      analytics.track(productEvents.DOC_WITH_MOVED_TOKENS_ALERTED, {
        editable: props.editable,
      });
    }
  },
  { immediate: true }
);

// We might want to lift this up in the future if more components/pages need this
useStartPollLatestCommit(
  toRef(() => props.repoId),
  toRef(() => props.branch)
);

// Trigger autosync if the latest commit changes, this is not entirely correct
// as the commit might change between the time we start polling it and what
// autosync will actually run against, fixing that is left for the future (Gotta
// go fast!)
watch(
  () => latestCommitStore.latestCommit,
  (latestCommit, previousLatestCommit) => {
    if (latestCommit != null && previousLatestCommit != null) {
      editor.value?.triggerAutosync();
      externalServices?.tokenSuggestionsService?.clear();
    }
  }
);

// clear the token suggestions also when switching branches
watch(
  () => props.branch,
  () => {
    externalServices?.tokenSuggestionsService?.clear();
  }
);

const eventProps = computed(() => ({
  workspaceId: props.workspaceId,
  repoId: props.repoId,
  id: props.unitId,
  editable: props.editable,
  embedded: props.embedded,
  branch: props.branch,
  sharedDocId: props.sharedDocId,
}));

watch(
  () => [props.editable, props.committed],
  (value, oldValue) => {
    // When editable changes resend the view document event
    // Do not trigger this on the move to or from committed version - as this will be sent through triggerDocLoad
    if (oldValue[0] != null && oldValue[1] === value[1]) {
      void docReport.sendEvent({
        event: pageEvents.VIEW_DOCUMENT,
        ...eventProps.value,
        isNewDraft: isNewDraft.value,
        title: doc.value?.title ?? 'Untitled',
      });
    }
  }
);

// Fetch the document
const { trigger: triggerDocLoad } = watchTriggerable(
  () => [props.workspaceId, props.repoId, props.unitId, props.branch, props.committed, props.sharedDocId],
  async () => {
    try {
      loading.value = true;
      error.value = null;
      // reset so no last saved at of previous doc
      drafts3Store.saveEndTime = null;
      let draft: Draft | null;
      if (!props.committed && !props.sharedDocId) {
        if (drafts3Store.loading) {
          await until(() => drafts3Store.loading).not.toBeTruthy();
        }
        draft = drafts3Store.drafts?.get(props.unitId) ?? null;
        isNewDraft.value = !!draft?.isNew;
      } else {
        draft = null;
      }
      if (draft != null && draft.type === 'doc') {
        if (draft.delete) {
          error.value = 'Deleted draft';
          loading.value = false;
          return;
        }
        doc.value = draft.content;
        loading.value = false;
        originalTitle.value = draft.originalTitle;
      } else {
        let docText!: string;
        if (props.sharedDocId) {
          docText = getSharedDocMarkdown(props.workspaceId, props.sharedDocId);
        } else if (props.isCrossRepo) {
          // for cross repo docs (doc in playlist from other repo)
          // we cannot use to store data since it is with the playlist repo id
          // so we call explicit function that fetch the docs list and the doc from git directly
          const defaultBranch = repos.value.repos.find((r) => r.id === props.repoId)?.defaultBranch;
          docText = await loadCrossRepoDoc({
            crossRepoId: props.repoId,
            id: props.unitId,
            defaultBranch,
          });
        } else {
          await until(() => loadingDocsOfRepoId.value).toBe(props.repoId);
          await until(() => loadingDocs.value).not.toBeTruthy();
          docText = await loadDoc(props.unitId);
        }
        doc.value = await parseDoc(docText);
        if (doc.value) {
          loading.value = false;
        }
        originalTitle.value = doc.value.title;
      }

      void docReport.sendEvent({
        event: pageEvents.VIEW_DOCUMENT,
        ...eventProps.value,
        isNewDraft: isNewDraft.value,
        title: doc.value?.title ?? 'Untitled',
      });
    } catch (err) {
      if (err instanceof DocNotFoundError) {
        error.value = `Not found`;
        loading.value = false;
        void docReport.sendEvent({
          event: productEvents.VISITED_DOCUMENT_NOT_IN_BRANCH,
          ...eventProps.value,
          isNewDraft: isNewDraft.value,
          title: doc.value?.title ?? 'Untitled',
        });
        return;
      }

      logger.error(err, 'Error loading swmd');
      error.value = 'Invalid';
      loading.value = false;
      return;
    }
  },
  { immediate: true }
);

// Save draft on changes
watch(
  doc,
  async (value, oldValue) => {
    if (!props.editable || props.committed || oldValue == null) {
      return;
    }

    drafts3Store.saveDraft(
      props.unitId,
      { type: DraftType.DOC, content: toRaw(value) },
      { originalTitle: originalTitle.value }
    );
    drafts3Store.updateAttrs(props.unitId, {
      isAIEnabledForRepo: await generativeAi.isAIGenerationEnabledForRepo(props.repoId),
    });

    if (!hasSentFirstDraftToBackoffice.value) {
      if (!isNewDraft.value) {
        void saveDocPathInDraft();
      }

      // Send without awaiting to not hold the app
      void docReport.sendFirstDraftToBackoffice({
        repoId: props.repoId,
        repoName: repos.value.repos.find((r) => r.id === props.repoId)?.name ?? 'Repo',
        workspaceId: props.workspaceId,
        docId: props.unitId,
        docName: value?.title,
        allRepoDocs: store.getters['database/db_getSwimms'](props.repoId) ?? {},
      });
      hasSentFirstDraftToBackoffice.value = true;
    }
  },
  { deep: true }
);
watchEffect(() => {
  const docTtile = doc.value?.title ?? 'Untitled';
  const editPrefix = props.editable ? `${isNewDraft.value ? 'New document' : 'Edit'}: ` : '';
  pageTitle.value = editPrefix + docTtile;
});

// Focus the relevant area if `fix` is requested
watch(
  () => route.query,
  () => {
    if (route.query.fix) {
      // TODO We might want to refactor those checks into a function taking the doc that returns an enum
      /* if (this.swm.applicability === ApplicabilityStatus.Outdated/) {
      // this.docSidebarStore.setCurrentTab('general');
      // this.docSidebarStore.setOpen({ open: true });
    } else*/ if (doc.value.title?.trim() === '') {
        focusTitle(true);
      } else if (isDocEmpty(doc.value.content)) {
        focusEditor();
      }
    }
    pr2Doc.handleEditorOnOpenAction();
  },
  { immediate: true }
);

/**
 * Clear save indicator when we are going to save in the future
 */
function willSave() {
  // TODO This is a bit tricky, we should consider representing this in a more obvious way
  drafts3Store.saveEndTime = null;
}

async function saveDocPathInDraft() {
  const path = getDocPathInRepo(props.unitId);
  if (path) {
    await until(() => drafts3Store.savingDraft).not.toBeTruthy();
    drafts3Store.updateAttrs(props.unitId, { path });
  }
}

drafts3Store.$onAction(({ name, args, after }) => {
  switch (name) {
    case 'commit':
      editor.value?.flush();
      after(() => {
        if (args[0].includes(props.unitId)) {
          // When committing a new doc originalTitle is empty, if editing after it's committed - nothing updates the original title
          originalTitle.value = doc.value?.title;
        }
      });
      break;
    case 'discardDraft':
      if (args[0] === props.unitId) {
        after(triggerDocLoad);
      }
      break;
  }
});

const onTitleChangedDebounced = debounce((title: string | undefined) => {
  doc.value.title = title;
}, 500);

function onTitleChanged(title: string | undefined) {
  willSave();
  onTitleChangedDebounced(title);
}

// update the repoDocsStore.currentDoc used for the breadcrumbs
watchEffect(() => {
  repoDocsStore.currentDoc = { id: props.unitId, title: doc.value?.title };
});
onBeforeUnmount(() => {
  repoDocsStore.currentDoc = null;
});

function focusTitle(select = false): void {
  if (select) {
    resourceHeader.value?.title.select();
  } else {
    resourceHeader.value?.title.focus();
  }
}

function focusEditor(): void {
  editor.value?.focus();
}

function navigateToEdit(opts?: { withSource: boolean }): void {
  emit('navigate-to-edit', opts?.withSource ?? false);
}

watchEffect(async () => {
  const shouldIncrementViewCount = props.shouldIncrementViewCount;
  await currentDocResources.refreshDbData();
  if (shouldIncrementViewCount) {
    await currentDocResources.incrementViewCount();
  }
});

function togglePlaylistStepStatus(status: SwimmResourceUserStatus): void {
  emit('toggle-mark', status);
}
// snippet studio

// TODO: implement so hunk will be collapsed when dragging
const isDragInProgress = computed(() => false);

const showSnippetStudioDrawer = computed(() => {
  return props.editable && !isDragInProgress.value;
});

const showAddRepoModal = ref(false);

const snippetStudioComposable = useSnippetStudioComposable3(analytics);
const {
  snippetEditorModal,
  toggleEditorSnippetModal,
  discardSelectedPath,
  tiptapEditorHandleSnippetToggler,
  snippetSelector,
} = snippetStudioComposable;

const snippetsInDocumentCount = computed(() => swimmEditorServices.value?.snippetsInDocumentCount.value || 0);

function docSidebarToggled(isOpen: boolean) {
  isSidebarOpen.value = isOpen;
}

const { theme, isAutoCompleteEnabled } = storeToRefs(useUserConfigStore());

const initialisingServices = ref(true);
let externalServices: WebSwimmEditorExternalServices;
watch(
  () => [props.repoId], // getting generativeAi settings depends directly on repoId and is not reactive
  () => {
    initialisingServices.value = true;
    WebSwimmEditorExternalServices.create(
      toRef(props, 'workspaceId'),
      toRef(props, 'repoId'),
      toRef(props, 'branch'),
      toRef(props, 'unitId'),
      toRef(() => doc.value?.title),
      toRef(repos),
      toRef(isWorkspaceAdmin),
      toRef(isGenAiDisabledInWorkspace),
      toRef(swimmportEnabled),
      toRef(isAutoCompleteEnabled),
      toRef(() => props.editable),
      fsWrapper,
      dbWrapper,
      analytics,
      snippetSelector,
      generativeAi,
      toRef(isWorkspaceInLocalMode),
      toRef(shouldUploadFilesToRepo),
      theme
    ).then((services) => {
      externalServices = services;
      initialisingServices.value = false;
    });
  },
  { immediate: true }
);

const shouldShowGenerateDrawer = computed(() => {
  return (
    !isGenAiDisabledInWorkspace.value &&
    pr2Doc.showGenerateDrawer.value &&
    (!containsTextOutsideSnippets(doc.value.content) || pr2Doc.generateDrawerInProgress.value) &&
    !pr2Doc.generationTime.value
  );
});

const generateDocProps = computed(() => ({
  isWorkspaceAdmin: isWorkspaceAdmin.value,
  title: doc.value.title ?? '',
  ...pr2Doc.sharedGenerateProps.value,
  ...pr2Doc.generateDocProps.value,
}));

const generateFromPRProps = computed(() => ({
  isWorkspaceAdmin: isWorkspaceAdmin.value,
  ...pr2Doc.sharedGenerateProps.value,
  ...pr2Doc.generateFromPRProps.value,
}));

async function addWholeFile() {
  if (!swimmEditorServices.value) {
    return;
  }
  const location = await swimmEditorServices.value.selectPath({
    disableDirectorySelection: true,
    dialogTitle: 'Select a file to generate a document for',
  });
  if (!location) {
    return;
  }
  const branch =
    location.repoId === props.repoId
      ? props.branch
      : repos.value.repos.find((r) => r.id === location.repoId)?.defaultBranch;
  if (!branch) {
    return;
  }
  await swimmEditorServices.value.addFileAsSnippet({
    filePath: location.path,
    repoId: location.repoId,
    revision: branch,
  });
}
</script>

<template>
  <div v-if="loading || initialisingServices" class="editor-pane">
    <BaseLoading class="editor-pane__loading" size="large" />
  </div>
  <template v-else>
    <div class="editor-pane-layout">
      <div class="editor-pane">
        <!-- TODO: 🚧 Create EditorPaneError component. START -->
        <div v-if="error" class="editor-pane__error">
          <ContentErrorState type="document" :swimm-status="error" :repo-id="repoId" />
        </div>
        <!-- TODO: 🚧 Create EditorPaneError component. END -->

        <template v-else>
          <div class="editor-pane__wrapper">
            <div
              class="editor-pane__container"
              :class="{ 'editor-pane__adjusted-height-with-drawer': showSnippetStudioDrawer }"
            >
              <slot name="playlist-status" />

              <Resource class="editor-pane__resource">
                <template #header>
                  <ResourceHeader
                    ref="resourceHeader"
                    :model-value="doc.title"
                    :edit="canEdit"
                    :draft="hasDraft"
                    @update:model-value="onTitleChanged"
                    @keydown.enter="focusEditor"
                    @keydown.arrow-down="focusEditor"
                  >
                    <template #banner v-if="showAutosyncableChanges">
                      <AutoSyncChangesBanner
                        :can-edit="canEdit"
                        @apply="
                          () => {
                            editor.applyAutosync();
                            analytics.track(productEvents.DOC_WITH_MOVED_TOKENS_APPLY_CLICKED);
                          }
                        "
                        @edit="
                          () => {
                            analytics.track(productEvents.DOC_WITH_MOVED_TOKENS_EDIT_CLICKED);
                            navigateToEdit({ withSource: true });
                          }
                        "
                      />
                    </template>
                    <template #meta-items>
                      <ResourceHeaderMeta>
                        <ResourceHeaderMetaItem v-if="currentDocResources.resourceContributors.value.length">
                          <ResourceContributors :contributors="currentDocResources.resourceContributors.value" />
                        </ResourceHeaderMetaItem>

                        <ResourceHeaderMetaItem>
                          <ResourceViews :views="currentDocResources.resourceViews.value" />
                        </ResourceHeaderMetaItem>

                        <ResourceFolder
                          :embedded="embedded"
                          :resource="resourceFolderResourceDoc"
                          type="document"
                          :repo-id="repoId"
                        />
                      </ResourceHeaderMeta>
                    </template>
                  </ResourceHeader>
                </template>
                <div class="editor-pane__editor-container">
                  <SwmdEditor
                    ref="editor"
                    class="editor-pane__editor"
                    :editable="canEdit"
                    :env-kind="EditorEnvKind.WEBAPP"
                    :external-services="externalServices"
                    :base-url="config.BASE_URL"
                    :repos="repos"
                    :is-authorized="isAuthorized"
                    :workspace-id="workspaceId"
                    :repo-id="repoId"
                    :branch="branch"
                    :unit-id="unitId"
                    :hash="!route.query?.fix ? route.hash : undefined"
                    :pre-set-autosync-output="preSetAutosyncOutput"
                    v-model="doc!.content"
                    @will-update="willSave"
                    @top-arrow-up="focusTitle()"
                  />
                  <div v-if="shouldShowStartWithButtons" class="start-with">
                    <StartWithButtons
                      branch-changes-option-name="PR to doc"
                      code-start-option-name="Code to doc"
                      @start-with-branch-changes="() => pr2Doc.startWithOption('pr')"
                      @start-with-code="() => pr2Doc.startWithOption('snippets')"
                    />
                  </div>
                </div>
                <template v-if="!editable && !embedded" #footer>
                  <!-- TODO: 🚧 Refactor this legacy DocFooter into a DS align ResourceFooter in ReefUI. START -->
                  <DocFooter
                    class="editor-pane__footer"
                    :repo-id="repoId"
                    can-edit-doc
                    :swimm-id="unitId"
                    :is-edit-mode="false"
                    :should-show-toggle-done="shouldShowToggleDone"
                    @toggle-mark="togglePlaylistStepStatus"
                    @edit-doc-clicked="navigateToEdit"
                  />
                  <!-- TODO: 🚧 Refactor this legacy DocFooter into a DS align ResourceFooter in ReefUI. END -->
                </template>
              </Resource>

              <GenerateProgressAndFeedback
                :generation-time="pr2Doc.generationTime.value"
                :is-generating-doc-from-pr="pr2Doc.isGeneratingDocFromPr.value"
                :should-show-feedback="pr2Doc.shouldShowPR2DocFeedback.value"
                :is-snippet-studio-open="snippetEditorModal.show"
                :repo-id="props.repoId"
                :workspace-id="props.workspaceId"
                :custom-prompt="pr2Doc.customPrompt.value"
                @revert-genertation="pr2Doc.clickedRevert"
                @stop-generating="pr2Doc.stopGenerating"
                @close-feedback="pr2Doc.hidePR2DocFeedback"
              />
              <DocGenerateDrawer
                v-if="shouldShowGenerateDrawer"
                :show-edit-snippet-message="pr2Doc.generateDrawerInProgress.value && !!snippetsInDocumentCount"
                class="drawer-modal"
                full-border-radius
                :show-on-top="snippetEditorModal.show"
                :expanded="pr2Doc.shouldExpandGenerateDrawer.value && !snippetEditorModal.show"
                @expand-drawer="() => pr2Doc.expandGenerateDrawer(true)"
                @dismiss-drawer="pr2Doc.dismissGenerateDrawer"
              >
                <template #content>
                  <GenerateDrawerEmptyState
                    v-if="isDocEmpty(doc.content)"
                    changes-button-text="PR changes"
                    @select-snippet="() => pr2Doc.clickActionFromDrawer('snippets', 'Empty state')"
                    @start-from-branch-changes="() => pr2Doc.clickActionFromDrawer('pr', 'Empty state')"
                    @collapse="pr2Doc.collapseGenerateDrawer"
                    @add-whole-file="() => pr2Doc.clickActionFromDrawer('file', 'Empty state')"
                  />
                  <GenerateDoc
                    v-else-if="shouldShowGenerateDrawer"
                    v-bind="generateDocProps"
                    @clicked-repo-settings="pr2Doc.clickedRepoSettings"
                    @start-generate="pr2Doc.generateSnippetsToDoc(doc)"
                    @collapse="pr2Doc.collapseGenerateDrawer"
                    @update-title="(newTitle) => (doc.title = newTitle)"
                    @update-custom-prompt="(newPrompt) => (pr2Doc.customPrompt.value = newPrompt)"
                    @update-split-snippets="(newValue) => (pr2Doc.splitLongSnippets.value = newValue)"
                    @add-whole-file="() => pr2Doc.clickActionFromDrawer('file', 'Generate Doc')"
                  />
                </template>
              </DocGenerateDrawer>
            </div>

            <!-- TODO: 🚧 Rename DocSidebar to EditorPaneSidebar. -->
            <DocSidebar
              v-if="!embedded"
              :doc="{
                id: props.unitId,
                created: currentDocResources.docCreationDate.value,
                modified: currentDocResources.docModifiedDate.value,
              }"
              :repo-id="repoId"
              :paths-by-repo="[]"
              :can-remove-file="!blockEditing"
              :is-new-draft="isNewDraft"
              :is-edit-doc="editable"
              :is-pr="isPrToDoc"
              :force-close="shouldForceCloseSideBar && !userDocSidebarStateValue()"
              :is-verifying="editor?.autosyncing"
              :mentions="swimmEditorServices?.mentions?.value"
              :editor="editor?.editor"
              @doc-sidebar-toggled="docSidebarToggled"
              @naviagte-to-edit="navigateToEdit"
            />
          </div>

          <!-- TODO: 🚧 Refactor and DS alignment of whatever this is. -->
          <MonacoViewFileModal
            v-if="!!fileToShow"
            :show="!!fileToShow"
            :heading="fileToShow.path"
            :monaco-options="fileToShow.monacoOptions"
            :file-content="fileToShow?.content"
            :file-type="fileToShow.type"
            :show-error="fileToShow.showError"
            :loading="fileToShow.loading"
            :reveal-line="fileToShow.revealLine || 0"
            @close="fileToShow = null"
          />
        </template>
      </div>

      <!-- TODO: 🚧 Refactor and DS alignment of snippet studio. -->
      <DrawerModal
        v-if="showSnippetStudioDrawer"
        :class="{ bounce: editable }"
        :show="snippetEditorModal.show"
        :heading="snippetEditorModal.heading"
        :padded="false"
        data-testid="snippet-studio-bar"
        @close="
          (value) =>
            tiptapEditorHandleSnippetToggler(value, () => editor.editor?.commands.selectAndInsertSwmSnippets(true))
        "
      >
        <transition name="fade">
          <SnippetStudio3
            v-click-outside="
              () => {
                toggleEditorSnippetModal();
              }
            "
            v-if="snippetEditorModal.show && !isDragInProgress && swimmEditorServices"
            :default-file-path="snippetEditorModal.lastSelectedPathRepoId?.path"
            :default-repo-id="snippetEditorModal.lastSelectedPathRepoId?.repoId"
            :editor-services="swimmEditorServices"
            @toggle-snippet-studio="toggleEditorSnippetModal"
            @discard-snippet="discardSelectedPath()"
            @add-repo="showAddRepoModal = true"
            @file-selected="snippetEditorModal.lastSelectedPathRepoId = { ...$event }"
          />
        </transition>
      </DrawerModal>

      <ImageModal
        :show="pr2Doc.showPr2DocTipModal.value"
        description="We imported all of the changes from your pull request. You can re-order snippets using the drag menu to the left of a snippet, or delete all snippets in a specific file under the relevant file section."
        image="pr-creation-tip.png"
        header="PR Creation Tip"
        @close="pr2Doc.showPr2DocTipModal.value = false"
      />

      <ImageModal
        :show="showPr2DocOutdatedModal"
        description="We've marked the outdated snippets in the generated doc - go over them to fix any outdated references."
        image="pr-creation-tip.png"
        header="The code has changed since this PR was merged"
        @close="pr2Doc.showPr2DocOutdatedModal.value = false"
      />

      <PaywallModal
        :show="pr2Doc.showPaywallModal.value"
        :entity="PaywallEntity.GENERATIVE_AI_CAP"
        @close="pr2Doc.showPaywallModal.value = false"
      />
      <ConfirmationDialog
        v-if="pr2Doc.showRevertConfirmation.value"
        title="Delete generated content?"
        :text="'Document will be reverted back to its previous state to regenerate with a new prompt — current content will be deleted.'"
        :show="pr2Doc.showRevertConfirmation.value"
        confirm-text="Yes, revert and edit prompt"
        variant="danger"
        :show-cancel="true"
        :close-on-confirm="true"
        @close="() => (pr2Doc.showRevertConfirmation.value = false)"
        @confirm="pr2Doc.revertAIResult"
      />
      <GenerateFromPullRequest
        v-if="pr2Doc.showGenerateFromPRModal.value"
        v-bind="generateFromPRProps"
        @remove-file-snippets="pr2Doc.removeSnippetsForFilePath"
        @pr-selected="pr2Doc.setPrDetails"
        @look-for="pr2Doc.getPRDetailsFromPRNumAndSetAsSelected"
        @close="pr2Doc.showGenerateFromPRModal.value = false"
        @update-custom-prompt="(newPrompt) => (pr2Doc.customPrompt.value = newPrompt)"
        @generate-with-ai="pr2Doc.generatePrDocWithAI"
        @add-to-doc="pr2Doc.addSnippetsToDoc"
        @edit-snippets="pr2Doc.addSnippetsToDoc(true)"
        @clicked-repo-settings="pr2Doc.clickedRepoSettings(true)"
      ></GenerateFromPullRequest>
    </div>
  </template>
</template>

<style scoped lang="scss">
.editor-pane-layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.drawer-modal {
  margin-bottom: var(--space-small);
}

.editor-pane {
  --header-height: 55px;
  --min-page-height: calc(100vh - var(--header-height));

  &__wrapper {
    display: flex;
    min-width: 0;
    min-height: var(--min-page-height);
  }

  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    overflow: scroll;
    height: 100vh;
    scroll-behavior: smooth;
  }

  &__adjusted-height-with-drawer {
    height: calc(100vh - 100px);
  }

  &__resource {
    flex-grow: 1;
    min-width: 0;
    position: relative;
    z-index: 2;
  }

  &__error {
    /* TODO */
  }

  &__loading {
    height: var(--min-page-height);
  }

  &__editor :deep(.ProseMirror) {
    padding-top: calc(var(--space-md) + var(--space-xs));
  }

  &__editor-container {
    flex: 1;
    position: relative;
    width: 100%;
  }
}

.start-with {
  position: absolute;
  padding-left: var(--narrow-content-width-left-padding);
  top: var(--space-xl);
}

@media print {
  .editor-pane-layout {
    overflow: visible;
  }

  .editor-pane__container {
    overflow: visible;
  }
}
</style>
