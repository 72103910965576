import type { Editor } from '@tiptap/core';
import { type Ref, shallowRef, watch } from 'vue';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';
import type { SwimmEditorServices } from '@/tiptap/editorServices';
import type SwmdEditor from '@/components/SwmdEditor.vue';

/**
 * Like getSwimmEditorServices, but as a composable for when you might not have
 * an editor yet.
 *
 * **WARNING:** Using `computed` with `getSwimmEditorServices` causes a
 *  performance issue due to how the reactivity of `editor.storage` is
 *  implemented which will cause the computed to
 *  trigger for any change at all in the editor, and in Vue before 3.4, any
 *  change to a computed triggers all it's side effects.
 *  Use this composable instead.
 */
export function useSwimmEditorServices(editor: Ref<Editor | undefined>) {
  const swimmEditorServices = shallowRef<SwimmEditorServices | undefined>();
  watch(
    editor,
    (editor) => {
      if (editor != null) {
        swimmEditorServices.value = getSwimmEditorServices(editor);
      }
    },
    { immediate: true }
  );
  return { swimmEditorServices };
}

/**
 * {@inheritDoc useSwimmEditorServices}
 */
export function useSwimmEditorServicesFromSwmdEditor(editor: Ref<InstanceType<typeof SwmdEditor> | null | undefined>) {
  const swimmEditorServices = shallowRef<SwimmEditorServices | undefined>();
  watch(
    () => editor.value?.editor,
    (editor) => {
      if (editor != null) {
        swimmEditorServices.value = getSwimmEditorServices(editor);
      }
    },
    { immediate: true }
  );
  return { swimmEditorServices };
}
