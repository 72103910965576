<script setup lang="ts">
import AddReposModal from '@/common/components/modals/AddReposModal.vue';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import WorkspaceSettingsModal from '@/modules/workspace/modals/WorkspaceSettingsModal.vue';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import CreationHubModal from '../creation-hub/CreationHubModal.vue';
import { useCreationHubStore } from '../creation-hub/store/creation-hub';
import { useWorkspaceSidebarStore } from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import RepoSettingsModal from '@/modules/repo/settings/RepoSettingsModal.vue';
import { integrationSettingsTabs } from '@swimm/shared';
import ProcessToDocModal from '@/modules/customizations/custom-process-to-doc/components/ProcessToDocModal.vue';
import DeleteItemsModal from '@/common/components/modals/DeleteItemsModal.vue';

const route = useRoute();
const workspaceId = computed(() => route.params.workspaceId as string);
const repoId = computed(() => route.params.repoId as string);
const branch = computed(() => route.params.branch as string);

const appModalsStore = useAppModalsStore();

const {
  showAddReposModal,
  addReposModalOptions,
  showRepoSettingsModal,
  modalOrigin,
  modalCallback,
  showDeleteItemsModal,
  deleteItemsModalOptions,
} = storeToRefs(appModalsStore);

const { closeDeleteItemsModal } = appModalsStore;

const creationHubStore = useCreationHubStore();
const { showCreationHubModal, creationHubOptions, creationHubModalRef } = storeToRefs(creationHubStore);
const { handleNewDocClick, handleNewPlaylistClick, handleNewProcessClick, closeCreationHubModal } = creationHubStore;
const { workspaceSettingsOptions, showWorkspaceSettingsModal } = storeToRefs(useWorkspaceSettingsModalStore());
const { workspaceReposUpdated } = useWorkspaceSidebarStore();

async function onAddRepoComplete({ repoIds }) {
  if (repoIds && repoIds.length > 1) {
    workspaceReposUpdated();
  }
  await modalCallback?.['repos-added']?.(repoIds[0]);
}
</script>

<template>
  <Suspense>
    <template>
      <AddReposModal
        :show="showAddReposModal"
        :workspace-id="workspaceId"
        :origin="modalOrigin"
        :multi-select="!!addReposModalOptions.multiSelect"
        @close="showAddReposModal = false"
        @repos-added="onAddRepoComplete"
        @reopen-modal="showAddReposModal = true"
      />
      <WorkspaceSettingsModal
        :show="showWorkspaceSettingsModal"
        :initial-tab-code="workspaceSettingsOptions.initialTabCode"
        :initial-tab-phase="workspaceSettingsOptions.initialTabPhase"
      />
      <CreationHubModal
        ref="creationHubModalRef"
        :show="showCreationHubModal"
        :prevent-sgd-in-non-default-branch="creationHubOptions.preventSgdInNonDefaultBranch"
        :limited-shown-items="creationHubOptions.limitedShownItems"
        :show-import-section="creationHubOptions.showImportSection"
        :show-repo-dropdown="creationHubOptions.showRepoDropdown"
        :folder-id="creationHubOptions.folderId"
        @new-doc-click="handleNewDocClick"
        @new-process-click="handleNewProcessClick"
        @new-playlist-click="handleNewPlaylistClick"
        @close="closeCreationHubModal"
      />
      <RepoSettingsModal
        :show="showRepoSettingsModal"
        :repo-id="repoId"
        :initial-tab-code="integrationSettingsTabs.GENERATIVE_AI"
        @close="showRepoSettingsModal = false"
      />
      <DeleteItemsModal
        :show="showDeleteItemsModal"
        :options="deleteItemsModalOptions"
        @close="closeDeleteItemsModal"
      />
      <ProcessToDocModal v-if="repoId" :repo-id="repoId" :branch="branch" />
    </template>
    <template #fallback>
      <Loader />
    </template>
  </Suspense>
</template>
