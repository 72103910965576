import { collectionNames, setValuesInDoc } from '@/adapters-common/firestore-wrapper';
import { type ThemeOption, ThemeOptions, config, getLoggerNew, state as localState } from '@swimm/shared';

const logger = getLoggerNew(__modulename);

export async function saveThemeInDB(theme: ThemeOption, userId: string) {
  // Protect against no user id - for example in demo worksapce
  if (!userId) {
    return;
  }
  const saveResponse = await setValuesInDoc(collectionNames.USERS, userId, { theme }, { merge: true });
  if (saveResponse.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error setting theme in DB for user ${userId}, error: ${saveResponse.errorMessage}`, {
      module: 'theme',
    });
  }
}

export function setThemeInLocalState(value: ThemeOption) {
  localState.set({ key: 'theme', value });
}

export async function getThemeFromLocalState(): Promise<ThemeOption> {
  const theme = (await localState.get({ key: 'theme' })) as ThemeOption;
  return theme || getSystemTheme();
}

export function setThemeForAppInBrowser(theme: ThemeOption) {
  document.body.dataset.theme = theme === 'system' ? getSystemTheme() : theme;
}

export function getSystemTheme(): ThemeOption {
  const isSystemDarkTheme = window.matchMedia(`(prefers-color-scheme: ${ThemeOptions.DARK})`).matches;
  return isSystemDarkTheme ? ThemeOptions.DARK : ThemeOptions.LIGHT;
}
