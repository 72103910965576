import path from 'path-browserify';
import type { Language } from 'web-tree-sitter';

let Parser: typeof import('web-tree-sitter');
const grammars: Map<string, Language> = new Map();

export async function importTreeSitter(): Promise<typeof import('web-tree-sitter')> {
  // Import and init once if not already initialized
  if (Parser) {
    return Parser;
  }

  Parser = (await import('web-tree-sitter')).default;

  let treeSitterWasm: string;
  if (process.env.NODE_ENV === 'test') {
    treeSitterWasm = path.join(__dirname, '../../node_modules/web-tree-sitter/tree-sitter.wasm');
  } else {
    treeSitterWasm = (await import('web-tree-sitter/tree-sitter.wasm?url')).default;
  }
  await Parser.init({
    locateFile() {
      return treeSitterWasm;
    },
  });
  return Parser;
}

export async function loadGrammar(name: string): Promise<Language> {
  if (!Parser) {
    throw new Error("Can't load grammar before initializing parser");
  }

  // Import  the grammar
  if (grammars && grammars.has(name)) {
    return grammars.get(name);
  }

  let grammar: string;
  if (process.env.NODE_ENV === 'test') {
    grammar = path.join(__dirname, `../grammars/tree-sitter-${name}.wasm`);
  } else {
    grammar = (await import(`../grammars/tree-sitter-${name}.wasm?url`)).default;
  }

  if (grammar) {
    grammars.set(name, await Parser.Language.load(grammar));
  }

  return grammars.get(name);
}

export type { Language, default as Parser, QueryCapture, SyntaxNode, Tree } from 'web-tree-sitter';
