import { DUMMY_REPO_ID, Licenses, User, WorkspaceUser } from '@swimm/shared';
import { isRepoPrivate } from '@/remote-adapters/local_repo';
import * as firestoreWrapper from '@/adapters-common/firestore-wrapper';
import { GitProviderName, config, objectUtils } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { getLoggerNew, gitProviderUtils } from '@swimm/shared';
import firebase from 'firebase/compat/app';
import { swimmApi } from '@/common/swimm-backend-client';

const logger = getLoggerNew(__modulename);

function getCreationDetails(userAuth: User): {
  created: firebase.firestore.FieldValue;
  creator: string;
  creator_name: string;
} {
  return {
    created: firestoreWrapper.firestoreTimestamp(),
    creator: userAuth.uid,
    creator_name: userAuth.nickname,
  };
}

function getModifiedDetails(userAuth: User): {
  modified: firebase.firestore.FieldValue;
  modifier: string;
  modifier_name: string;
} {
  return {
    modified: firestoreWrapper.firestoreTimestamp(),
    modifier: userAuth.uid,
    modifier_name: userAuth.nickname,
  };
}

export function filterWorkspaceKeys(workspaceToFilter) {
  // The only fields that should be saved on the workspace document itself.
  const workspacePropertiesToSave = [
    'branch_prefix',
    'id',
    'name',
    'description',
    'logo',
    'invite_requests',
    'invites',
    'repositories',
    'counter_plans',
    'counter_workspace_users',
    'counter_workspace_admins',
    'created',
    'creator',
    'creator_name',
    'modified',
    'modifier',
    'modifier_name',
    'license',
    'is_desktop',
    'hosted_domains',
    'is_auto_join_enabled',
    'settings',
    'hide_ask_swimm',
  ];
  const filteredWorkspace = objectUtils.deepClone(workspaceToFilter);
  for (const key of Object.keys(filteredWorkspace)) {
    if (!workspacePropertiesToSave.includes(key)) {
      delete filteredWorkspace[key];
    }
  }
  return filteredWorkspace;
}

export async function saveWorkspaceToFirestore(resource, userAuth: User) {
  let workspaceId = resource.id;
  const shouldUpdateWorkspace = 'id' in resource;
  const toRet: {
    wasUpdated?: boolean;
    user?: WorkspaceUser;
    workspace?: firebase.firestore.DocumentData;
  } = {};

  if (shouldUpdateWorkspace) {
    const response = await updateWorkspace(resource, userAuth);
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: 'Got error updating workspace' };
    }
    toRet.wasUpdated = true;
  } else {
    const response = await createWorkspace(resource, userAuth);
    workspaceId = response.workspaceId;
    if (response.code !== config.SUCCESS_RETURN_CODE) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: 'Got error creating workspace' };
    }
    try {
      const userResp = await swimmApi.getWorkspaceUser(workspaceId, userAuth.uid);
      toRet.user = userResp.data;
    } catch (err) {
      logger.error({ err }, `Error fetching user ${userAuth.uid} from workspace ${workspaceId}`);
    }
  }

  const getWorkspaceResponse = await firestoreWrapper.getDocFromCollection(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceId
  );
  if (getWorkspaceResponse.code === config.SUCCESS_RETURN_CODE) {
    toRet.workspace = filterWorkspaceKeys(getWorkspaceResponse.data);
  } else {
    logger.error(`Error fetching workspace ${workspaceId}: ${getWorkspaceResponse.errorMessage}`);
  }

  return { ...toRet, workspaceId, code: config.SUCCESS_RETURN_CODE };
}

export async function updateWorkspace(workspace, userAuth: User) {
  const workspaceToSave = { ...workspace };
  // Fields as 'plans' shouldn't be created on the document by the update workspace query
  const filteredWorkspace = filterWorkspaceKeys(workspaceToSave);
  // We use mergeFields to not update fields that we don't want to override if they already exist in the DB
  const fieldsToMerge = [
    'counter_plans',
    'counter_workspace_users',
    'counter_workspace_admins',
    'created',
    'creator',
    'creator_name',
  ];
  const mergeFields = Object.keys(filteredWorkspace).filter((key) => !fieldsToMerge.includes(key));

  const response = await firestoreWrapper.setValuesInDoc(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceToSave.id,
    { ...filteredWorkspace, ...getModifiedDetails(userAuth) },
    {
      mergeFields,
    }
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error editing data in workspace ${workspaceToSave.id}: ${response.errorMessage}`);
    return { code: config.ERROR_RETURN_CODE };
  }

  return { code: config.SUCCESS_RETURN_CODE };
}

async function createWorkspace(workspace, userAuth: User) {
  const workspaceToSave = {
    counter_workspace_users: 0,
    counter_workspace_admins: 0,
    repositories: [DUMMY_REPO_ID],
    invites: [],
    invite_requests: [],
    license: Licenses.FREE,
    hide_ask_swimm: true,
    settings: {},
    ...workspace,
    ...getCreationDetails(userAuth),
    ...getModifiedDetails(userAuth),
  };

  const response = await firestoreWrapper.addDocToCollection(
    firestoreWrapper.collectionNames.WORKSPACES,
    workspaceToSave
  );
  if (response.code !== config.SUCCESS_RETURN_CODE) {
    logger.error(`Error creating new workspace: ${response.errorMessage}`);
    return { code: config.ERROR_RETURN_CODE };
  }
  const workspaceId = response.data.id;
  let code: number;
  try {
    await swimmApi.addUserToWorkspace(workspaceId, { userId: userAuth.uid, setAsAdmin: true });
    code = config.SUCCESS_RETURN_CODE;
  } catch (err) {
    logger.error({ err }, `Error adding user ${userAuth.uid} to workspace ${workspaceId}`);
    code = config.ERROR_RETURN_CODE;
  }
  return { code, workspaceId: response.data.id };
}

export async function getRepoIsPrivate(args) {
  const { repoId, repoData } = args;
  let isPrivate = repoData.is_private;
  if (
    isPrivate === undefined &&
    repoData.provider &&
    (repoData.provider === GitProviderName.GitHub ||
      repoData.provider === GitProviderName.GitHubEnterprise ||
      repoData.provider === GitProviderName.GitLab)
  ) {
    // Use the repo's URL info since the check for "is repo private" might run BEFORE saving the repo info to the local state
    const parsedUrl = gitProviderUtils.parseGitProviderURL(repoData.url);
    const res = await isRepoPrivate({
      repoId,
      provider: repoData.provider,
      repoName: parsedUrl.name,
      owner: parsedUrl.owner,
      ...(repoData.api_url ? { api_url: repoData.api_url } : {}),
    });
    if (res.code === 0) {
      isPrivate = res.isPrivate;
      try {
        const result = await CloudFunctions.updateRepoDbEntry({ isPrivate, repoId });
        if (result.data.status !== 'success') {
          logger.error(`Failed to set is_private in DB for repo ${repoId}: ${result.data.error}`, {
            module: 'database',
          });
          isPrivate = undefined;
        }
      } catch (err) {
        logger.error({ err }, `Exception while setting is_private in DB for repo ${repoId}: ${err}`);
      }
    }
  }
  return isPrivate;
}
