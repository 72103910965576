import trim from 'lodash-es/trim.js';
import path from 'path-browserify';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import type {
  Path,
  SmartElement,
  SmartElementWithApplicability,
  SmartElementWithApplicabilityAndNewInfo,
  Token,
} from '@swimm/shared';
import { ApplicabilityStatus } from '@swimm/shared';
import { getSwimmNodeId } from '@/swmd/swimm_node';
import { serializeSwmPathShort } from '@/markdownit/plugins/swm_path';

export function generatePlainMermaidText(
  node: ProseMirrorNode,
  smartElements?: Map<string, SmartElementWithApplicability<SmartElement>>
): string {
  const text: string[] = [];
  node.descendants((node) => {
    switch (node.type.name) {
      case 'text':
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        text.push(node.text!);
        break;
      case 'swmToken':
        if (smartElements != null) {
          const smartElement = smartElements.get(getSwimmNodeId(node));
          if (
            smartElement != null &&
            [ApplicabilityStatus.Verified, ApplicabilityStatus.Autosyncable].includes(smartElement.applicability)
          ) {
            text.push((smartElement as SmartElementWithApplicabilityAndNewInfo<Token>).newInfo.symbolText);
            break;
          }
        }
        text.push(node.attrs.token);
        break;
      case 'swmPath':
        if (smartElements != null) {
          const smartElement = smartElements.get(getSwimmNodeId(node));
          if (
            smartElement != null &&
            [ApplicabilityStatus.Verified, ApplicabilityStatus.Autosyncable].includes(smartElement.applicability)
          ) {
            text.push(
              trim(
                serializeSwmPathShort(
                  (smartElement as SmartElementWithApplicabilityAndNewInfo<Path>).newInfo.filePath,
                  node.attrs.short
                ),
                path.sep
              )
            );
            break;
          }
        }
        text.push(trim(serializeSwmPathShort(node.attrs.href, node.attrs.short), path.sep));
        break;
      default:
        text.push('UNKNOWN');
    }
  });

  return text.join('');
}

export function serializeSwimmMermaidText(swimm: string, plain: string): string {
  return (
    plain +
    '\n\n%% Swimm:\n' +
    swimm
      .split('\n')
      .map((s) => `%% ${s}`)
      .join('\n')
  );
}
