<script setup lang="ts">
import { computed, ref } from 'vue';
import type { ComponentExposed } from 'vue-component-type-helpers';
import { type PathSuggestion, type TokenSuggestion } from '@swimm/shared';

import SwmInlineSelectionContent from '../SwmInlineSelectionContent/SwmInlineSelectionContent.vue';
import SwmSelectionContentSwimmportHeader from '../SwmSelectionContentSwimmportHeader/SwmSelectionContentSwimmportHeader.vue';
import SwmSelectionContentSwimmportFooter, {
  type DriverType,
} from '../SwmSelectionContentSwimmportFooter/SwmSelectionContentSwimmportFooter.vue';
import MenuItemsToken from '../MenuItemsToken/MenuItemsToken.vue';
import MenuItemPath from '../MenuItemPath/MenuItemPath.vue';
import type { CachedHighlighters } from '../..';

const props = withDefaults(
  defineProps<{
    query?: string;
    suggestions?: (TokenSuggestion | PathSuggestion)[];
    suggestionsType?: 'token' | 'path';
    command: (suggestion: TokenSuggestion | PathSuggestion) => boolean;
    loading?: boolean;
    driver?: DriverType;
  }>(),
  {
    query: '',
    suggestions: undefined,
    suggestionsType: undefined,
    driver: 'caret',
  }
);

const emit = defineEmits<{
  selectSuggestion: [suggestion: TokenSuggestion | PathSuggestion];
  focusedSuggestion: [suggestion: TokenSuggestion | PathSuggestion];
  hoveredSuggestion: [suggestion: TokenSuggestion | PathSuggestion];
  dismiss: [];
}>();

const selectionInlineContent = ref<ComponentExposed<typeof SwmInlineSelectionContent>>();
const cachedHighlighters = ref<CachedHighlighters>({});
const isCaching = ref(false);
const isCachingMore = ref(false);
const isLoading = computed(() => props.loading || isCaching.value);
const isLoadingMore = computed(() => isCachingMore.value);
const onKeyDown = computed(() => selectionInlineContent.value?.onKeyDown);

function onSelectSuggestion(suggestion: TokenSuggestion | PathSuggestion) {
  emit('selectSuggestion', suggestion);
}

function onFocusedSuggestion(suggestion: TokenSuggestion | PathSuggestion) {
  emit('focusedSuggestion', suggestion);
}

function onHoveredSuggestion(suggestion: TokenSuggestion | PathSuggestion) {
  emit('hoveredSuggestion', suggestion);
}

function onDismiss() {
  emit('dismiss');
}

function onCaching(caching: boolean) {
  isCaching.value = caching;
}

function onCachingMore(cachingMore: boolean) {
  isCachingMore.value = cachingMore;
}

function onHighlightersCreated(highlighters: CachedHighlighters) {
  cachedHighlighters.value = highlighters;
}

defineExpose({ onKeyDown });
</script>

<template>
  <SwmInlineSelectionContent
    ref="selectionInlineContent"
    data-testid="swm-selection-content-swimmport"
    :query="query"
    :items="suggestionsType === 'token' ? (suggestions as TokenSuggestion[]) : (suggestions as PathSuggestion[])"
    :command="command"
    :loading="isLoading"
    :loading-more="isLoadingMore"
    @focused-item="onFocusedSuggestion"
  >
    <template #header>
      <SwmSelectionContentSwimmportHeader />
    </template>

    <template #items="{ setItemRef, paginatedItems, isFocused }">
      <template v-if="suggestionsType === 'token'">
        <MenuItemsToken
          :query="query"
          :token-suggestions="(paginatedItems as TokenSuggestion[])"
          :loading="loading"
          :is-focused="isFocused"
          :set-item-ref="setItemRef"
          @select-suggestion="onSelectSuggestion"
          @hovered-suggestion="onHoveredSuggestion"
          @caching="onCaching"
          @caching-more="onCachingMore"
          @highlighters-created="onHighlightersCreated"
        />
      </template>
      <template v-if="suggestionsType === 'path'">
        <MenuItemPath
          v-for="(item, index) in paginatedItems"
          :key="`${(item as PathSuggestion).path}-${index}`"
          :ref="(el: any) => { 
            if (el?.root) {
              setItemRef(el.root, index) 
            } 
          }"
          :query="query"
          :path="(item as PathSuggestion)"
          :focused="isFocused(index)"
          @click="onSelectSuggestion(item)"
          @keydown.enter="onSelectSuggestion(item)"
          @mouseenter="onHoveredSuggestion(item)"
        />
      </template>
    </template>

    <template #footer>
      <slot name="preview" :cached-highlighters="cachedHighlighters" />

      <SwmSelectionContentSwimmportFooter :driver="driver" @dismiss="onDismiss" />
    </template>
  </SwmInlineSelectionContent>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.swm-selection-content-swimmport {
  $self: &;

  &__list {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
  }

  &__no-results {
    margin: var(--space-small);
    text-align: center;
  }
}
</style>
