import { SwmFile } from '../types';
import * as stringUtils from './string-utils';

export function removeCRCharactersFromSwmData(swmFile: SwmFile): void {
  if (swmFile.content) {
    for (const cell of swmFile.content) {
      if (cell.type !== 'snippet') {
        continue;
      }
      cell.lines = cell.lines.map((line) => stringUtils.removeCRLFCharacters(line));
    }
  }
  if (swmFile.symbols) {
    for (const symbol of Object.values(swmFile.symbols)) {
      if (symbol.type !== 'generic_text') {
        continue;
      }
      symbol.lineData = stringUtils.removeCRLFCharacters(symbol.lineData);
    }
  }
}
