<script setup lang="ts">
import { debounce } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { computed, ref, toRef, watch } from 'vue';
import { useStore } from 'vuex';
import Ribbon from '@/common/components/atoms/Ribbon.vue';
import TopMenuLayout from '@/common/layouts/TopMenuLayout.vue';
import TopBar from '@/common/components/TopBar/TopBar.vue';
import UserProfile from '@/common/components/organisms/UserProfile.vue';
import { SwimmDocument, config } from '@swimm/shared';
import { BaseButton, BaseInput, BaseLayoutGap, BaseLoading, Resource } from '@swimm/reefui';
import { SwmdEditor, parseSwmd, serializeSwmd } from '@swimm/swmd';
import { EditorEnvKind } from '@swimm/editor';
import { WebPreviewSwimmExternalServices } from '@/modules/editor3/previewExternalServices';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import ContentErrorState from '@/common/components/organisms/unavailable-doc-states/ContentErrorState.vue';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import { useNavigate } from '@/common/composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useProcessToDocStore } from '@/modules/customizations/custom-process-to-doc/stores/processToDoc';

const props = withDefaults(
  defineProps<{
    workspaceId: string;
    repoId: string;
    templateId: string;
    branch: string;
    hash?: string;
  }>(),
  {
    hash: null,
  }
);

const store = useStore();
const { getDocumentTemplate, saveDocumentTemplate } = useProcessToDocStore();
const { theme } = storeToRefs(useUserConfigStore());
const { getResourceLink } = useResourceActions();
const { navigateToPageAndTerminateWorker } = useNavigate();
const authStore = useAuthStore();
const document = ref<SwimmDocument>();
const loading = ref(false);
const error = ref<string>();
const hasChanged = ref(false);

const isWorkspaceAdmin = computed<boolean>(() =>
  store.getters['database/db_isWorkspaceAdmin'](props.workspaceId, authStore.user.uid)
);

const editor = ref<InstanceType<typeof SwmdEditor>>();

const baseUrl = config.BASE_URL;

const externalServices = new WebPreviewSwimmExternalServices({ isDarkMode: toRef(() => theme.value === 'dark') });

watch(
  () => [props.workspaceId, props.templateId],
  async () => {
    try {
      loading.value = true;
      const template = await getDocumentTemplate(props.templateId);
      document.value = parseSwmd(template.text, { type: 'template' });
    } catch (e) {
      error.value = 'Failed getting template';
    } finally {
      loading.value = false;
    }
  },
  { immediate: true }
);

function focusEditor(): void {
  editor.value?.focus();
}

const onTitleChangedDebounced = debounce((title: string | undefined) => {
  document.value.title = title;
  hasChanged.value = true;
  if (!document.value.title) {
    error.value = 'Template name cannot be empty';
  } else {
    error.value = '';
  }
}, 200);

function onTitleChanged(title: string | undefined) {
  onTitleChangedDebounced(title);
}

function nameValidator() {
  return !!document.value?.title;
}

async function save() {
  try {
    const newTemplateId = await saveDocumentTemplate(serializeSwmd(document.value, { baseUrl }), document.value.title);

    const redirectTo = getResourceLink({
      resource: { id: newTemplateId },
      repoId: props.repoId,
      branch: props.branch,
      type: 'template',
    });
    await navigateToPageAndTerminateWorker({
      newRoute: redirectTo,
    });
  } catch {
    error.value = 'Failed saving template';
  }
}
</script>

<template>
  <TopMenuLayout
    :key="templateId"
    :should-open-keyboard-shortcut-on-start="false"
    :should-lift-bottom-icons="false"
    :show-knowledge-icon="false"
    :loading="loading"
  >
    <template #topBar>
      <TopBar>
        <UserProfile />
      </TopBar>
    </template>
    <template #content>
      <div v-if="loading" class="editor-pane">
        <BaseLoading class="editor-pane__loading" size="large" />
      </div>
      <template v-else-if="error && !document">
        <ContentErrorState type="document" swimm-status="Invalid" :repo-id="repoId" />
      </template>
      <template v-else>
        <Resource>
          <template v-if="!isWorkspaceAdmin">
            <Ribbon
              ribbon-title="Insufficient permissions"
              ribbon-description="Only admins are permitted to edit templates."
            />
          </template>
          <SwmdEditor
            ref="editor"
            v-model="document!.content"
            class="editor"
            :editable="isWorkspaceAdmin"
            :env-kind="EditorEnvKind.WEBAPP"
            :external-services="externalServices"
            :base-url="baseUrl"
            :repos="{
              loading: false,
              repos: [],
            }"
            :is-authorized="true"
            :repo-id="repoId"
            :workspace-id="workspaceId"
            :branch="branch"
            :unit-id="templateId"
            show-menu
            :hash="hash"
            content-type="template"
            @will-update="hasChanged = true"
          />
        </Resource>
        <footer>
          <template v-if="error && nameValidator()">
            <Ribbon ribbon-title="An error occurred" :ribbon-description="error" />
          </template>
          <BaseLayoutGap :alignment="computed(() => nameValidator()).value ? 'bottom' : 'center'" size="small">
            <BaseInput
              label="Template Name [View Only]"
              name="Template Name"
              placeholder="Template Name"
              required
              disabled
              :validator="() => !nameValidator()"
              :model-value="document.title"
              @update:model-value="onTitleChanged"
              invalid-message="errorMessage"
              @keydown.up="focusEditor"
            />
            <BaseButton
              :disabled="computed(() => !nameValidator()).value || !hasChanged"
              @click="save"
              @keydown.enter="save"
            >
              Save
            </BaseButton>
          </BaseLayoutGap>
        </footer>
      </template>
    </template>
  </TopMenuLayout>
</template>

<style scoped lang="postcss">
footer {
  padding: 1em;
  background-color: var(--color-bg-surface-hover);
}
</style>
