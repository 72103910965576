import { Transform } from '@tiptap/pm/transform';
import { Fragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { TableMap } from '@tiptap/pm/tables';
import { SwimmDocument, getLoggerNew } from '@swimm/shared';
import { parseSwmd, schema } from '@swimm/swmd';
import { Metadata, ObjectRef, ProcessFile, Rule, ValueObject } from '../output-types';
import { CDIFileTypes } from './types';
import {
  LOOP_END_MATCHER,
  LOOP_START_MATCHER,
  arrayAttributeToContentNode,
  composeExternalAttribute,
  createTable,
  missingDataSwmToken,
  openLoop,
  valueToSwmToken,
} from '../output-composer';
import { PLACEHOLDER_TEXT_CONTENT_MATCHER } from '@swimm/swmd';

const logger = getLoggerNew('ppg/cdi/output.ts');

function composeDataFlowMermaidContent(taskFlow: ProcessFile, metadata: Metadata) {
  const mermaidContent = [];
  mermaidContent.push(schema.text('flowchart LR;\n'));
  for (const mttObject of (taskFlow['MTT'] ?? []) as ObjectRef[]) {
    mermaidContent.push(valueToSwmToken(taskFlow.name, taskFlow.filePath, metadata));
    mermaidContent.push(schema.text(' --> '));

    let mttItemName = missingDataSwmToken('MTT object', mttObject.filePath || taskFlow.filePath, metadata);
    if (mttObject.name) {
      mttItemName = valueToSwmToken(mttObject.name, mttObject.filePath, metadata);
    }
    mermaidContent.push(mttItemName);
    mermaidContent.push(schema.text('\n'));

    for (const dtemplateObject of (mttObject['DTEMPLATE'] ?? []) as ObjectRef[]) {
      mermaidContent.push(mttItemName);

      mermaidContent.push(schema.text(' --> '));

      let dtemplateItemName = missingDataSwmToken(
        'DTEMPLATE object',
        dtemplateObject.filePath || taskFlow.filePath,
        metadata
      );
      if (dtemplateObject.name) {
        dtemplateItemName = valueToSwmToken(dtemplateObject.name, dtemplateObject.filePath, metadata);
      }

      mermaidContent.push(dtemplateItemName);
      mermaidContent.push(schema.text('\n'));

      if (dtemplateObject.objectRefs?.length) {
        mermaidContent.push(schema.text(`\nsubgraph `));
        mermaidContent.push(dtemplateItemName);
        mermaidContent.push(schema.text(`-refs\n`));
        for (const [index, objectRef] of dtemplateObject.objectRefs.entries()) {
          mermaidContent.push(dtemplateItemName);
          mermaidContent.push(schema.text(` --> ${dtemplateObject.name.value}${index}[`));
          mermaidContent.push(valueToSwmToken(objectRef.name, objectRef.filePath, metadata));
          mermaidContent.push(schema.text(']\n'));
        }

        mermaidContent.push(schema.text('end\n'));
      }
    }
  }
  return mermaidContent;
}

function composeObjectMermaidContent(objectContent: ProcessFile, metadata: Metadata) {
  // Fill mermaid
  const sources = objectContent?.sources ?? [];
  const targets = objectContent?.targets ?? [];
  const mermaidContent = [];
  if (!sources.length && !targets.length) {
    return mermaidContent;
  }

  mermaidContent.push(schema.text('flowchart LR;\n'));

  for (let i = 0; i < sources.length; i++) {
    mermaidContent.push(schema.text(`SRC${i}[SRC_`));
    mermaidContent.push(
      sources[i]
        ? valueToSwmToken(sources[i].name, objectContent.filePath, metadata)
        : missingDataSwmToken(`missing source ${i}`, objectContent.filePath, metadata)
    );
    mermaidContent.push(schema.text(']'));
    mermaidContent.push(schema.text(' --> EXP_PassThru\n'));
  }

  for (let i = 0; i < targets.length; i++) {
    mermaidContent.push(schema.text('EXP_PassThru --> '));
    mermaidContent.push(schema.text(`TGT${i}[TGT_`));
    mermaidContent.push(
      targets[i]
        ? valueToSwmToken(targets[i].name, objectContent.filePath, metadata)
        : missingDataSwmToken(`missing target ${i}`, objectContent.filePath, metadata)
    );
    mermaidContent.push(schema.text(']\n'));
  }

  return mermaidContent;
}

/**
 * Compose the actual content nodes from a placeholder node
 * This function replaces inline text placeholders with their matching source file data
 * It also preserves the hardcoded text around those placeholders
 * @param placeholderNode
 * @param sourceObject
 * @param metadata
 * @param referenceFilePath
 */
function composeContentNodes(
  placeholderNode: ProseMirrorNode,
  sourceObject: ProcessFile | ValueObject | ObjectRef | Rule,
  metadata: Metadata,
  referenceFilePath: string
): ProseMirrorNode | ProseMirrorNode[] | null {
  const templateMark = placeholderNode.marks.find((mark) => mark.type.name === 'swmTemplateInline');

  if (!templateMark) {
    return placeholderNode;
  }

  const match = placeholderNode.text.match(PLACEHOLDER_TEXT_CONTENT_MATCHER);
  const placeholderAttributes = match.groups['placeholderAttributes'].split('.');

  const optional = templateMark.attrs.optional;
  const marksToKeep = placeholderNode.marks.filter((mark) => mark.type.name !== 'swmTemplateInline');

  function composeAttributeNode(
    objectAttribute: string,
    attribute: ValueObject | ObjectRef,
    referenceAttribute: string
  ) {
    if (referenceAttribute) {
      // When referenceAttribute is provided the template is referencing a related object by its key (its type). ex. {{ MTT.AgentGroup.name }}
      if (!attribute[referenceAttribute]) {
        return missingDataSwmToken(objectAttribute, referenceFilePath, metadata).mark(marksToKeep);
      }

      if (Array.isArray(attribute[referenceAttribute])) {
        return (arrayAttributeToContentNode(attribute[referenceAttribute], referenceFilePath, metadata) ?? []).map(
          (node) => node.mark(marksToKeep)
        );
      }

      return valueToSwmToken(attribute[referenceAttribute] as ValueObject, referenceFilePath, metadata).mark(
        marksToKeep
      );
    }

    return valueToSwmToken(attribute as unknown as ValueObject, referenceFilePath, metadata).mark(marksToKeep);
  }

  // Placeholder is templating an external / configuration attribute. ex. {{ created-at }} | {{ author }}
  if (placeholderAttributes.length === 1) {
    return composeExternalAttribute(placeholderAttributes[0], optional, referenceFilePath, metadata).mark(marksToKeep);
  }

  // placeholder references a value from a file. Example: {{ DATAFLOW.description }} || {{ MTT.AgentGroup.name }} || {{ source.name }}
  const [_objectType, objectAttribute, referenceAttribute] = placeholderAttributes;

  if (sourceObject['filePath']) {
    referenceFilePath = sourceObject['filePath'];
  }

  const attribute = sourceObject[objectAttribute];
  if (!attribute) {
    if (!optional) {
      return missingDataSwmToken(objectAttribute, referenceFilePath, metadata).mark(marksToKeep);
    }
    return null;
  }

  if (Array.isArray(attribute)) {
    const contentNodes: ProseMirrorNode[] = [];
    for (const item of attribute) {
      if (item.filePath) {
        referenceFilePath = item.filePath;
      }
      const node = composeAttributeNode(objectAttribute, item, referenceAttribute);
      if (Array.isArray(node)) {
        contentNodes.push(...node);
      } else {
        contentNodes.push(node);
      }
    }
    return contentNodes;
  } else {
    if (attribute.filePath) {
      referenceFilePath = attribute.filePath;
    }

    return composeAttributeNode(objectAttribute, attribute, referenceAttribute);
  }
}

function closeLoop(
  loopStack: string[][],
  loopSlice: { start: number; end: number },
  doc: ProseMirrorNode,
  pos: number,
  allTaskFlows: ProcessFile[],
  currentSourceObject: ProcessFile | ObjectRef
) {
  // We could have multiple nested loops
  // We only want to start iterating when we reach the end of the outermost loop
  if (loopStack.length === 1) {
    const [loopObjectType, loopSubject, nestedLoop] = loopStack.pop();

    // Find the relevant array of objects to loop over for this doc segment
    let loopObjects: (ProcessFile | ObjectRef)[];
    if (loopObjectType === 'TASKFLOW') {
      loopObjects = [...allTaskFlows] as ProcessFile[];
    } else if (currentSourceObject && !loopSubject) {
      if (loopObjectType.toLowerCase().includes('mapplet')) {
        const subjectKeys = Object.keys(currentSourceObject).filter((key) =>
          key.toLowerCase().includes(loopObjectType.toLowerCase())
        );
        if (subjectKeys.length) {
          loopObjects = subjectKeys.flatMap((key) => currentSourceObject[key] as ObjectRef[]);
        }
      } else {
        loopObjects = currentSourceObject[loopObjectType] as ProcessFile[];
      }
    } else if (currentSourceObject && loopSubject) {
      // Template holds all keys in upper case format
      // But input parsing might have different casing
      // We want to defend against that
      const subjectKey = Object.keys(currentSourceObject).find(
        (key) => key.toLowerCase() === loopSubject.toLowerCase()
      );
      if (subjectKey) {
        if (nestedLoop) {
          const nestedKey = Object.keys(currentSourceObject[subjectKey]).find(
            (key) => key.toLowerCase() === nestedLoop.toLowerCase()
          );
          loopObjects = currentSourceObject[subjectKey][nestedKey] as ObjectRef[];
        } else {
          loopObjects = currentSourceObject[subjectKey] as ObjectRef[];
        }
      }
    } else {
      logger.warn({ loopObjectType, loopSubject }, `Unknown loop annotation. Skipping...`);
    }

    if (loopSlice && loopObjects) {
      // Create a slice of nodes to loop over and fill
      const subDoc = doc.cut(loopSlice.start, loopSlice.end);
      return { subDoc, loopObjects };
    }
    return null;
  } else {
    loopStack.pop();
    loopSlice.end = pos;
    return null;
  }
}

function fillDocument(
  templateDoc: ProseMirrorNode,
  tr: Transform,
  metadata: Metadata,
  allTaskFlows: ProcessFile[],
  currentSourceData?: ProcessFile | ObjectRef,
  loopingTableRows?: Rule[]
) {
  const loopStack: string[][] = [];
  let loopSlice: { start: number; end: number };
  let lastTableNode: ProseMirrorNode;
  let tableSlice: ProseMirrorNode[][];
  let tableWidth: number;
  let currentTableRow: ProseMirrorNode[];
  let currentTableRowPlaceholders: ProseMirrorNode[];
  let currentBuffer: ProseMirrorNode[];
  const currentFilePath = currentSourceData?.filePath || allTaskFlows?.[0]?.filePath;

  function applyTable() {
    if (currentTableRow) {
      tableSlice.push(currentTableRow);
      currentTableRow = null;
      if (loopingTableRows) {
        for (const tableRow of loopingTableRows) {
          const loopRow = [];
          for (const placeholderNode of currentTableRowPlaceholders) {
            if (placeholderNode.type.name === 'tableCell') {
              loopRow.push(placeholderNode);
              continue;
            }
            const contentNode = composeContentNodes(placeholderNode, tableRow, metadata, currentFilePath);
            const content = schema.node('tableCell', {}, contentNode);
            loopRow.push(content);
          }
          tableSlice.push(loopRow);
        }
      }
      currentTableRowPlaceholders = null;
    }
    tr.insert(tr.doc.content.size, createTable(tableSlice));
    tableSlice = null;
    lastTableNode = null;
    tableWidth = null;
  }

  templateDoc.descendants((placeholderNode, pos, parent, index) => {
    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_START_MATCHER)) {
      loopSlice = openLoop(placeholderNode, loopStack, loopSlice, pos);
      return true;
    }

    if (placeholderNode.type.name === 'text' && placeholderNode.text.match(LOOP_END_MATCHER)) {
      const loop = closeLoop(loopStack, loopSlice, templateDoc, pos, allTaskFlows, currentSourceData);

      if (loop) {
        loopSlice = null;
        const { subDoc, loopObjects } = loop;

        let tableRowObjects;
        if (subDoc.childCount === 1 && subDoc.firstChild.type.name === 'swmTablePlaceholder') {
          // Create table row for each loop object
          tableRowObjects = loopObjects.slice(1);
          loopObjects.splice(1, Infinity);
        }

        for (const loopObject of loopObjects) {
          fillDocument(subDoc, tr, metadata, allTaskFlows, loopObject, tableRowObjects);
        }
      }

      return true;
    }

    // Group all nodes under a loop placeholder to fill them iteratively
    if (loopStack.length) {
      if (loopSlice.start == null) {
        loopSlice.start = pos;
      }
      loopSlice.end = pos;
      return true;
    }

    if (placeholderNode.type.name === 'text') {
      const content = composeContentNodes(
        placeholderNode,
        currentSourceData,
        metadata,
        currentSourceData?.filePath || currentFilePath
      );

      // We only create the content when we reach the leaf node (text).
      // When we do we want make sure we create it within the proper context.
      // ex. text leaf inside a heading node - we want to create as a heading
      if (!['tableHeader', 'tableCell'].includes(parent.type.name)) {
        if (index === 0) {
          currentBuffer = [];
        }
        if (index < parent.childCount) {
          if (Array.isArray(content)) {
            currentBuffer.push(...content);
          } else if (content !== null) {
            currentBuffer.push(content);
          }
        }
        if (index === parent.childCount - 1) {
          tr.insert(tr.doc.content.size, schema.node(parent.type.name, parent.attrs ?? {}, currentBuffer));
        }
      } else {
        if (!currentTableRow) {
          currentTableRow = [];
          currentTableRowPlaceholders = [];
        }
        currentTableRow.push(schema.node(parent.type.name, parent.attrs ?? {}, content));
        currentTableRowPlaceholders.push(placeholderNode);
      }

      // Identity of empty cells are not unique so we need to double-check we are at the last cell to determine if we reached the end of the table's row
      if (tableSlice && currentTableRow.length === tableWidth && lastTableNode && lastTableNode.eq(parent)) {
        // This is the last text node of the last table cell - create the table
        applyTable();
      }
      return false;
    }

    if (placeholderNode.type.name === 'table') {
      tableSlice = [];

      // Store the last table cell so we can insert the table when we reach the last cell
      const tableMap = TableMap.get(placeholderNode);
      const lastTableCellPos = tableMap.positionAt(tableMap.height - 1, tableMap.width - 1, placeholderNode);
      lastTableNode = placeholderNode.nodeAt(lastTableCellPos);
      tableWidth = tableMap.width;
      return true;
    }

    if (placeholderNode.type.name === 'tableRow') {
      if (currentTableRow && tableSlice) {
        tableSlice.push(currentTableRow);
      }
      currentTableRow = [];
      currentTableRowPlaceholders = [];
      return true;
    }

    if (placeholderNode.type.name === 'tableCell') {
      // Protect against empty table cells
      if (tableSlice && !placeholderNode.content.size) {
        if (!currentTableRow) {
          currentTableRow = [];
          currentTableRowPlaceholders = [];
        }
        currentTableRow.push(placeholderNode);
        currentTableRowPlaceholders.push(placeholderNode);

        // Identity of empty cells are not unique so we need to double-check we are at the last cell to determine if we reached the end of the table's row
        if (tableSlice && currentTableRow.length === tableWidth && lastTableNode && lastTableNode.eq(placeholderNode)) {
          // This is the last table cell and it is empty - create the table
          applyTable();
        }
      }
    }

    if (placeholderNode.type.name === 'swmMermaidPlaceholder') {
      const diagramObject = placeholderNode.attrs.placeholder.split(' ')[0].toUpperCase();
      let mermaidContent;

      // Assuming here that the diagram placeholder indicates the file type the diagram is based on and the type of diagram
      if (CDIFileTypes.includes(diagramObject)) {
        if (diagramObject === 'TASKFLOW') {
          mermaidContent = composeDataFlowMermaidContent(currentSourceData as ProcessFile, metadata);
        } else {
          mermaidContent = composeObjectMermaidContent(currentSourceData as ProcessFile, metadata);
        }

        if (!mermaidContent || !mermaidContent.length) {
          mermaidContent.push(missingDataSwmToken(placeholderNode.attrs.placeholder, currentFilePath, metadata));
        }
      } else {
        logger.warn(`Unknown diagram referenced: ${diagramObject}. Skipping...`);
      }
      if (mermaidContent?.length) {
        tr.insert(tr.doc.content.size, schema.node('mermaid', {}, Fragment.fromArray(mermaidContent)));
      }
      return false;
    }

    return true;
  });
}

export async function toSwmd(metadata: Metadata, taskFlows: ProcessFile[], template: string): Promise<SwimmDocument> {
  // Use the template as the starting point for the document
  const document = parseSwmd(template, { type: 'template' });
  document.title = metadata.title;
  document.repoId = metadata.repoId;
  document.repoName = metadata.repoName;

  const templateDoc = ProseMirrorNode.fromJSON(schema, document.content);

  // Create a new document to fill while traversing the template
  const tr = new Transform(schema.topNodeType.create());
  fillDocument(templateDoc, tr, metadata, taskFlows);
  document.content = tr.doc.toJSON();

  return document;
}
