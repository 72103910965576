<template>
  <div class="preference-section">
    <div class="headline3"><b>System theme</b></div>
    <div class="section-options">
      <RadioButton v-model="selectedTheme" class="option" value="system">System</RadioButton>
      <RadioButton v-model="selectedTheme" class="option" value="light">Light</RadioButton>
      <RadioButton v-model="selectedTheme" class="option" value="dark">Dark</RadioButton>
    </div>
  </div>
  <div class="preference-section">
    <div class="headline3 preference-header"><b>Editor</b></div>
    <Checkbox v-model="selectedSwimmportEnabled" class="option checkbox-opt" value="system">
      Show me Smart Path and Smart Token suggestions while editing.
    </Checkbox>
    <Checkbox v-model="selectedAutocompleteEnabled" class="option checkbox-opt" value="system">
      Show me AI text autocompletions when editing docs.
    </Checkbox>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { type ThemeOption, state as localState, productEvents } from '@swimm/shared';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useUserConfigStore } from '@/modules/core/stores/user-config-store';
import { Checkbox, RadioButton } from '@swimm/ui';

const { user } = storeToRefs(useAuthStore());
const { setTheme, setAutocompletePreferenceInDB, getAutocompletePreference } = useUserConfigStore();
const analytics = useAnalytics();
const route = useRoute();
const store = useStore();

const selectedTheme = ref('');
const selectedSwimmportEnabled = ref(false);
const selectedAutocompleteEnabled = ref(false);

const isSwimmportEnabled = computed(() => store.getters['swimmport/isEnabled']);

selectedTheme.value = (await localState.get({ key: 'theme' })) as string;
selectedSwimmportEnabled.value = isSwimmportEnabled.value;
selectedAutocompleteEnabled.value = await getAutocompletePreference(user.value.uid);

watch(selectedTheme, async () => {
  await setTheme({ theme: selectedTheme.value as ThemeOption, userId: user.value.uid });
  await analytics.userIdentify(user.value.uid, {
    'Settings Theme': selectedTheme.value,
  });
  analytics.track(productEvents.USER_SETTINGS_SAVED, {
    Theme: selectedTheme.value,
    'Workspace ID': route.params.workspaceId,
  });
});

watch(selectedSwimmportEnabled, async () => {
  await store.dispatch('swimmport/setSwimmportEnabled', {
    userId: user.value.uid,
    enabled: selectedSwimmportEnabled.value,
  });
  analytics.track(productEvents.USER_SETTINGS_SAVED, {
    Context: 'Swimmport',
    SwimmportEnabled: selectedSwimmportEnabled.value,
    'Workspace ID': route.params.workspaceId,
  });
});

watch(selectedAutocompleteEnabled, async () => {
  await setAutocompletePreferenceInDB(user.value.uid, selectedAutocompleteEnabled.value);
  analytics.track(productEvents.USER_SETTINGS_SAVED, {
    Context: 'Autocomplete',
    AutocompleteEnabled: selectedAutocompleteEnabled.value,
    'Workspace ID': route.params.workspaceId,
  });
});
</script>

<style scoped>
.preference-section {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: 16px;
}

.section-options {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 24px;
}

.checkbox-opt {
  margin-top: 8px;
}
</style>
