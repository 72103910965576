import defaults from 'lodash-es/defaults';
import { MarkdownParser } from '@tiptap/pm/markdown';
import MarkdownIt from 'markdown-it';
import type Token from 'markdown-it/lib/token';
import matter from 'gray-matter';
import { schema } from './extensions';
import type { SwimmDocument } from '@swimm/shared';
import { parseSwmMeta } from './swm_meta';
import preserve_special from '@/markdownit/plugins/preserve_special';
import swimm from '@/markdownit/plugins/swimm';
import { parseSwmTokenPos } from '@/markdownit/plugins/swm_token';
import taskList from '@/markdownit/plugins/taskList';
import { type LegacyOptions, LegacySwmdError, convertLegacySwmFileToSwmdV3 } from './legacy';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';
import type { JSONContent } from '@tiptap/core';
import { parseSwmPathShort } from '@/markdownit/plugins/swm_path';

const md = new MarkdownIt('default', { html: false, linkify: true });
md.use(preserve_special);
md.use(swimm);
md.use(taskList);

export interface SwimmParserMeta {
  type?: 'doc' | 'template';
  repoId?: string | null;
  repoName?: string | null;
}

// https://github.com/ProseMirror/prosemirror-markdown/blob/5a81988d0a915216ad692c4d9566dab8aaa1556e/src/from_markdown.ts#L248
export const swmdParser = new MarkdownParser(schema, md, {
  blockquote: { block: 'blockquote' },
  paragraph: { block: 'paragraph' },
  list_item: { block: 'listItem' },
  task_item: {
    block: 'taskItem',
    getAttrs: (tok: Token) => ({
      checked: tok.attrGet('checked') === 'true',
    }),
  },
  bullet_list: {
    block: 'bulletList',
    getAttrs: (_: Token, tokens: Token[], i) => ({
      tight: listIsTight(tokens, i),
    }),
  },
  ordered_list: {
    block: 'orderedList',
    getAttrs: (tok: Token, tokens: Token[], i) => ({
      start: parseInt(tok.attrGet('start') ?? '1', 10),
      tight: listIsTight(tokens, i),
    }),
  },
  heading: {
    block: 'heading',
    getAttrs: (tok: Token) => ({ level: +tok.tag.slice(1) }),
  },
  code_block: { block: 'codeBlock', noCloseToken: true },
  fence: {
    block: 'codeBlock',
    getAttrs: (tok: Token) => ({ language: tok.info || '' }),
    noCloseToken: true,
  },
  hr: { node: 'horizontalRule' },
  image: {
    node: 'image',
    getAttrs: (tok: Token) => ({
      src: tok.attrGet('src'),
      title: tok.attrGet('title') || null,
      alt: tok.children?.[0]?.content || null,
    }),
  },
  hardbreak: { node: 'hardBreak' },
  table: { block: 'table' },
  tr: { block: 'tableRow' },
  th: {
    block: 'tableHeader',
    getAttrs: (tok) => ({
      align: tok.attrGet('style')?.match(/text-align:\s*([^;]*)/)?.[1] ?? null,
    }),
  },
  td: {
    block: 'tableCell',
    getAttrs: (tok) => ({
      align: tok.attrGet('style')?.match(/text-align:\s*([^;]*)/)?.[1] ?? null,
    }),
  },
  thead: { ignore: true },
  tbody: { ignore: true },

  em: { mark: 'italic' },
  strong: { mark: 'bold' },
  s: { mark: 'strike' },
  link: {
    mark: 'link',
    getAttrs: (tok) => ({
      href: tok.attrGet('href'),
      title: tok.attrGet('title') || null,
    }),
  },
  code_inline: { mark: 'code', noCloseToken: true },

  swm_path: {
    node: 'swmPath',
    getAttrs: (tok: Token) => ({
      href: tok.attrGet('href'),
      title: tok.attrGet('title'),
      repoId: tok.attrGet('repo-id'),
      repoName: tok.attrGet('repo-name'),
      short: parseSwmPathShort(tok.attrGet('short')),
      customDisplayText: tok.attrGet('custom') != null ? tok.attrGet('text') : null,
    }),
  },

  swm_link: {
    node: 'swmLink',
    getAttrs: (tok: Token) => ({
      path: tok.attrGet('path') ?? '',
      title: tok.attrGet('title'),
      docTitle: tok.attrGet('doc-title') ?? '',
      repoId: tok.attrGet('repo-id'),
      repoName: tok.attrGet('repo-name'),
      customDisplayText: tok.attrGet('custom') != null ? tok.attrGet('text') : null,
    }),
  },

  swm_token: {
    node: 'swmToken',
    getAttrs: (tok: Token) => ({
      token: tok.attrGet('custom') != null ? tok.attrGet('token') : tok.attrGet('text'),
      path: tok.attrGet('path'),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      pos: parseSwmTokenPos(tok.attrGet('pos')!),
      lineData: tok.attrGet('line-data'),
      repoId: tok.attrGet('repo-id'),
      repoName: tok.attrGet('repo-name'),
      customDisplayText: tok.attrGet('custom') != null ? tok.attrGet('text') : null,
    }),
  },

  swm_snippet: {
    block: 'swmSnippet',
    getAttrs: (tok: Token) => ({
      // Remove the trailing newline that is added by serialization & parsing.
      snippet: withoutTrailingNewline(tok.attrGet('snippet') ?? ''),
      path: tok.attrGet('path'),
      line: parseInt(tok.attrGet('line') ?? '', 10),
      collapsed: tok.attrGet('collapsed') != null,
      language: tok.attrGet('language'),
      repoId: tok.attrGet('repo-id'),
      repoName: tok.attrGet('repo-name'),
    }),
  },

  swm_mention: {
    node: 'swmMention',
    getAttrs: (tok: Token) => ({
      uid: tok.attrGet('uid'),
      name: tok.attrGet('name'),
      email: tok.attrGet('email')?.replace(/^mailto:/, ''),
    }),
  },

  mermaid: {
    block: 'mermaid',
  },

  youtube: {
    node: 'youtube',
    getAttrs: (tok: Token) => ({
      src: tok.attrGet('href'),
    }),
  },

  block_image: {
    node: 'blockImage',
    getAttrs: (tok: Token) => ({
      src: tok.attrGet('src'),
      title: tok.attrGet('title') || null,
      alt: tok.children?.[0]?.content || null,
      width: tok.attrGet('style')?.match(/width:\s*([^;]*)/)?.[1] ?? null,
    }),
  },

  swm_snippet_placeholder: {
    node: 'swmSnippetPlaceholder',
    getAttrs: (tok: Token) => ({
      placeholder: tok.attrGet('placeholder'),
    }),
  },

  // NOTE: Currently disabled
  // swm_text_placeholder: {
  //   block: 'swmTextPlaceholder',
  // },

  swm_table_placeholder: {
    block: 'swmTablePlaceholder',
  },

  swm_mermaid_placeholder: {
    node: 'swmMermaidPlaceholder',
    getAttrs: (tok: Token) => ({
      placeholder: tok.attrGet('placeholder'),
    }),
  },

  swm_template_inline: {
    mark: 'swmTemplateInline',
    noCloseToken: true,
    getAttrs: (tok: Token) => ({
      optional: tok.attrGet('optional') != null,
    }),
  },
});

function listIsTight(tokens: readonly Token[], i: number) {
  while (++i < tokens.length) {
    if (tokens[i].type !== 'list_item_open' && tokens[i].type !== 'task_item_open') {
      return tokens[i].hidden;
    }
  }
  return false;
}

function withoutTrailingNewline(str: string): string {
  return str[str.length - 1] === '\n' ? str.slice(0, str.length - 1) : str;
}

/**
 * Markdown doesn't have the concept of an empty paragraphs, so we serialize
 * empty paragraphs as a paragraphs of a single non breaking space (`&nbsp;`)
 * and turn those back into an empty paragraph on parsing.
 */
function stripSingleSpaceParagraphs(content: ProseMirrorNode): ProseMirrorNode {
  const tr = new Transform(content);
  content.descendants((node, pos, parent, _index) => {
    if (parent?.type.name === 'paragraph' && node.type.name === 'text' && node.text === '\xA0') {
      tr.delete(tr.mapping.map(pos), tr.mapping.map(pos + 1));
    }
  });

  return tr.doc;
}

export function parseSwmdContent(content: string, meta: SwimmParserMeta): JSONContent {
  return stripSingleSpaceParagraphs(
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    swmdParser.parse(content, {
      swimm: defaults({ ...meta }, { type: 'doc' }),
    })!
  ).toJSON();
}

export interface ParserOptions {
  /**
   * The type of document to parse
   * template type utilize related markdown-it plugins that are deactivated otherwise
   */
  type?: 'doc' | 'template';
  /**
   * Options required to be able to parse legacy SWMD
   */
  legacy?: LegacyOptions;
}

/**
 * Parse a SWMD v3 document.
 *
 * @param text The document to parse
 * @param options Options for the parser
 * @returns The parsed document
 */
export function parseSwmd(text: string, options?: ParserOptions): SwimmDocument {
  const file = matter(text, {
    // Only allow yaml
    engines: {
      yaml: matter.engines.yaml,
    },
  });

  if (file.data.file_version != null) {
    if (options?.legacy == null) {
      throw new LegacySwmdError('Parsing legacy SWMD requires passing legacy options');
    }

    const convertedText = convertLegacySwmFileToSwmdV3(text, options.legacy);
    return parseSwmd(convertedText, { type: options?.type });
  }

  const meta = parseSwmMeta(file.content);

  const { title, ...frontmatter } = file.data;
  return {
    version: meta?.meta.version,
    ...(title != null && { title }),
    repoId: meta?.meta['repo-id'],
    repoName: meta?.meta['repo-name'],
    frontmatter,
    content: parseSwmdContent(meta?.content ?? file.content, {
      type: options?.type,
      repoId: meta?.meta['repo-id'] ?? null,
      repoName: meta?.meta['repo-name'] ?? null,
    }),
  };
}
