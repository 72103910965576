import semver from 'semver';

// Makes sure the file version is higher/lower than an input version.
// No file version as input is considered as a swm file that was created before file_version introduced to the swm file, and therefore has no version at all (lower than even 1.0.0).
export function assertFileVersion({
  fileVersion = '0.0.9',
  operator,
  versionToCompare,
}: {
  fileVersion: string;
  operator: string;
  versionToCompare: string;
}) {
  return compareSemanticVersion({
    currentVersion: fileVersion,
    operator: operator,
    versionToCompare: versionToCompare,
  });
}

function compareSemanticVersion({
  currentVersion,
  operator,
  versionToCompare,
}: {
  currentVersion: string;
  operator: string;
  versionToCompare: string;
}) {
  const compareOperators = {
    '>=': (currentVersion: string, versionToCompare: string) => semver.gte(currentVersion, versionToCompare),
    '<': (currentVersion: string, versionToCompare: string) => semver.lt(currentVersion, versionToCompare),
    '<=': (currentVersion: string, versionToCompare: string) => semver.lte(currentVersion, versionToCompare),
    '=': (currentVersion: string, versionToCompare: string) => semver.eq(currentVersion, versionToCompare),
    compare: (currentVersion: string, versionToCompare: string) => semver.compare(currentVersion, versionToCompare),
  };
  return compareOperators[operator](currentVersion, versionToCompare);
}
