<template>
  <li
    data-testid="ellipsis-option"
    :class="[fontClass, 'option', 'ellipsis-option', 'clickable', { large }]"
    @click.prevent="preformAction()"
  >
    <Icon v-if="loading" name="lifebuoy-outline" class="loading" />
    <Icon v-else-if="icon" no-padding :name="icon" :class="['icon', { 'large-content': largeContent }]" />{{ name }}
    <slot name="appendix" />
  </li>
</template>

<script>
import { inject } from 'vue';

export default {
  props: {
    name: { type: String, required: true },
    large: { type: Boolean, default: false },
    largeContent: { type: Boolean, default: false },
    icon: { type: String, default: '' },
    handler: { type: Function, default: () => ({}) },
    fontClass: { type: String, default: 'body-S' },
    closeAfterAction: { type: Boolean, default: true },
  },
  setup() {
    const closeEllipsis = inject('closeEllipsis', () => null);
    return { closeEllipsis };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async preformAction() {
      // Fixed E2E flakiness - floating-vue does a focus() after a double requestAnimationFrame, we need to delay our
      // action after that so that it does not race with it's call to focus as many of our actions are reliant on setting focus
      await new Promise((resolve) => requestAnimationFrame(() => requestAnimationFrame(() => resolve())));
      try {
        this.loading = true;
        await this.handler();
      } catch (err) {
        this.$logger.error({ err }, `action ${this.name} failed with ${err}`);
      }
      this.loading = false;
      if (this.closeAfterAction) {
        this.closeEllipsis();
      }
    },
  },
};
</script>

<style scoped>
.loading {
  animation: spin;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  white-space: nowrap;
  color: var(--text-color-primary);
  list-style: none;
}

.option:hover {
  background: var(--color-hover);
}

.option .icon {
  padding-right: 5px;
}

.large {
  padding: 10px 5px;
}

.large-content {
  font-size: var(--fontsize-ml);
}
</style>
