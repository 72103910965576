import { Timestamp } from 'firebase/firestore';
import { updateSymbols } from '@/adapters-common/update-symbols';
import type { DraftAutosyncParameters, LoadAndProcessSwmFileResult } from '@/load-types';
import {
  ApplicabilityStatus,
  type DraftSwmFile,
  type SwmFile,
  config,
  convertAutosyncOutputToSwmObject,
  convertSwmObjectToAutosyncInput,
  decryptMessage,
  fillRepoIdInSwmSnippets,
  getLoggerNew,
  markAllUnitHunksAsStatus,
  objectUtils,
  state,
} from '@swimm/shared';
import { autosyncUnit, getResourceApplicabilityState } from '@swimm/swimmagic';

const logger = getLoggerNew(__modulename);

async function getDraftById({ draftId, repoId, branch }): Promise<DraftSwmFile> {
  const drafts = await getDraftsData({ repoId });

  return drafts.find((draft) => {
    // For the case where checking if the doc already has a draft
    const draftExists = draft.draftId === draftId || draft.id === draftId;
    return draftExists && draft.draftBranch === branch;
  });
}

export async function getDraftsData({ repoId, filterEncrypted = true }): Promise<DraftSwmFile[]> {
  const userSalt = await state.get({ key: 'salt', defaultValue: undefined });
  const encryptedDrafts = (await state.get({ key: `drafts`, repoId: repoId, defaultValue: [] })) as string[];
  return (
    encryptedDrafts
      .map((draft) => decryptDraft({ draft, userSalt, filterEncrypted }))
      // Filter out null values (encrypted results)
      .filter((draft) => !!draft)
      .map((draft) => (draft.encrypted ? draft : fillRepoIdInSwmSnippets(draft, repoId)))
      .map((draft) => {
        let modified = Timestamp.fromMillis(0);
        if (draft.modified != null) {
          modified = new Timestamp(draft.modified.seconds, draft.modified.nanoseconds);
        }
        return { ...draft, modified }; // modified is saved as object in local storage and needs to be converted back to type Timestamp
      })
  );
}

function decryptDraft({ draft, userSalt, filterEncrypted }) {
  // Before encrypting drafts we had the drafts in the state saved as an object
  // Now drafts are encrypted string, means if the draft is not a string and has draftId key in it then it's an old draft.
  if (typeof draft !== 'string' && 'draftId' in draft) {
    return draft;
  }

  if (!userSalt) {
    return null;
  }
  const decryptedDraft = decryptMessage(draft, userSalt, logger);
  // Encrypted means the decryption failed and the draft is still encrypted
  if (!decryptedDraft) {
    // Return nothing when filtering out unavailable encrypted drafts
    if (filterEncrypted) {
      return null;
    }
    // Add raw draft data when not filtering out encrypted drafts
    return { encrypted: true, data: draft };
  }
  return decryptedDraft;
}

async function getDraftWithUpdatedStepNames(unitDraft, repoId, branch) {
  return {
    ...unitDraft,
    sequence:
      unitDraft.sequence &&
      (await Promise.all(
        unitDraft.sequence.map(async (step) => {
          const stepDraft = await getDraftById({ draftId: step.id, repoId, branch });
          // The fallback name is used if the step is a draft that doesn't exist anymore (deleted draft)
          const stepName = stepDraft?.name ?? step.name;

          return {
            ...step,
            name: stepName,
          };
        })
      )),
  };
}

export async function autosyncDraft({
  draft,
  draftId,
  repoId,
  branch,
  workspaceId,
}: DraftAutosyncParameters): Promise<LoadAndProcessSwmFileResult> {
  const cleanedDraft = objectUtils.deepClone(draft);
  // Clean unused symbols for applicability check
  updateSymbols(cleanedDraft);

  return await loadAndProcessSWMFile({
    swmFile: cleanedDraft,
    originalSwmFile: objectUtils.deepClone(draft),
    shouldAutoSync: false,
    repoId,
    unitId: draftId,
    currentBranch: branch,
    workspaceId,
    path: cleanedDraft.path,
  });
}

export async function draftExists({ draftId, repoId, branch }) {
  return !!(await getDraftById({ draftId, repoId, branch }));
}

export async function loadProcessedDraftById({
  draftId,
  repoId,
  branch,
  type,
  workspaceId,
}): Promise<LoadAndProcessSwmFileResult> {
  const unitDraft = await getDraftById({ draftId, repoId, branch });
  if (!unitDraft) {
    return null;
  }

  if (type !== config.SWIMM_FILE_TYPES.PLAYLIST) {
    // Autosync draft snippets
    const result = await loadAndProcessSWMFile({
      swmFile: unitDraft,
      originalSwmFile: objectUtils.deepClone(unitDraft),
      shouldAutoSync: false,
      repoId,
      unitId: draftId,
      currentBranch: branch,
      workspaceId,
      path: config.SWM_FOLDER_IN_REPO,
    });
    (result.unit as DraftSwmFile).generatedDocumentId = unitDraft.generatedDocumentId;
    (result.unit as DraftSwmFile).rulesSwm = unitDraft.rulesSwm;
    return result;
  } else {
    // Unprocessed playlist swm draft
    const unitDraftWithUpdatedStepNames = await getDraftWithUpdatedStepNames(unitDraft, repoId, branch);

    return {
      code: config.SUCCESS_RETURN_CODE,
      unitId: draftId,
      repoId,
      shouldAutoSync: false,
      unit: unitDraftWithUpdatedStepNames,
      applicabilityStatus: ApplicabilityStatus.Verified,
      originalSwmFile: unitDraft,
      path: config.SWM_FOLDER_IN_REPO,
    };
  }
}

export async function loadAndProcessSWMFile({
  swmFile,
  repoId,
  shouldAutoSync,
  originalSwmFile,
  unitId,
  currentBranch,
  workspaceId,
  path,
}: {
  swmFile: SwmFile;
  repoId: string;
  workspaceId?: string;
  shouldAutoSync: boolean;
  originalSwmFile?: SwmFile;
  unitId: string;
  path: string;
  currentBranch: string;
}): Promise<LoadAndProcessSwmFileResult> {
  let shouldOverrideApplicabilityStatus = false;

  const autosyncInput = await convertSwmObjectToAutosyncInput({
    swm: swmFile,
    docRepoId: repoId,
    branch: currentBranch,
    shouldSyncCrossRepo: true,
  });
  // Applicability state call autosync in order to check the applicability
  const docApplicabilityState = await getResourceApplicabilityState({
    autosyncInput,
    resourceId: unitId,
    repoId,
    currentBranch,
    workspaceId,
  });
  if (shouldAutoSync) {
    try {
      // In case the unit is not applicable getResourceApplicabilityState will try to autosync and will return the autosync result otherwise autosyncResult will be empty.
      let autosyncedSwmFile: SwmFile | null = null;
      if (docApplicabilityState.autosyncResult !== null) {
        if (docApplicabilityState.autosyncResult.autosyncSuccess) {
          autosyncedSwmFile = convertAutosyncOutputToSwmObject({
            originalSwm: swmFile,
            autosyncInput,
            autosyncOutput: docApplicabilityState.autosyncResult.autosyncOutput,
            shouldSyncCrossRepo: true,
          });
        }
      } else {
        const autosyncInput = await convertSwmObjectToAutosyncInput({
          swm: swmFile,
          docRepoId: repoId,
          branch: currentBranch,
          shouldSyncCrossRepo: true,
        });
        const autosyncResult = await autosyncUnit(autosyncInput);
        if (autosyncResult.autosyncSuccess) {
          autosyncedSwmFile = convertAutosyncOutputToSwmObject({
            originalSwm: swmFile,
            autosyncInput,
            autosyncOutput: autosyncResult.autosyncOutput,
            shouldSyncCrossRepo: true,
          });
        }
      }
      if (autosyncedSwmFile) {
        swmFile = { ...autosyncedSwmFile };
      } else {
        logger.error(`failed to autosync unit: ${unitId}. Loading original unit instead`, { service: 'adapter-load' });
        shouldOverrideApplicabilityStatus = true;
      }
    } catch (err) {
      logger.error({ err }, `failed to autosync unit: ${unitId}. Loading original unit instead`);
      shouldOverrideApplicabilityStatus = true;
    }
  }
  // A unit marked as "not applicable" (outdated) we would like to add to all hunks that couldn't be verified that they are outdated as well (or to all hunks if the unit wasn't verified at all)
  if (ApplicabilityStatus.Outdated === docApplicabilityState.unitStatus) {
    markAllUnitHunksAsStatus({
      applicabilityStatus: docApplicabilityState.unitStatus,
      swmFile: swmFile,
      shouldOverrideStatus: shouldOverrideApplicabilityStatus,
    });
  }
  if ([ApplicabilityStatus.Invalid, ApplicabilityStatus.Unavailable].includes(docApplicabilityState.unitStatus)) {
    return {
      code: config.ERROR_RETURN_CODE,
      unitId,
      repoId,
      shouldAutoSync,
      applicabilityStatus: docApplicabilityState.unitStatus,
      errorMessage: 'Invalid state',
      path,
    };
  }
  return {
    code: config.SUCCESS_RETURN_CODE,
    unitId,
    repoId,
    shouldAutoSync,
    unit: swmFile,
    applicabilityStatus: docApplicabilityState.unitStatus,
    originalSwmFile: originalSwmFile,
    path,
  };
}

export function getFileName({
  swmId,
  type,
}: {
  swmId: string;
  type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
}): string {
  if (type === config.SWIMM_FILE_TYPES.PLAYLIST) {
    return swmId + config.SWMD_PLAYLIST_EXTENSION;
  } else if (type === config.SWIMM_FILE_TYPES.SWMD) {
    return swmId + config.SWMD_FILE_EXTENSION;
  } else if (type === config.SWIMM_FILE_TYPES.SWM_RULE) {
    return swmId + config.SWM_RULE_EXTENSION;
  } else {
    return swmId + config.SWM_FILE_EXTENSION;
  }
}

export function getSwmPath({
  path,
  swmId,
  type,
}: {
  path: string;
  swmId: string;
  type: (typeof config.SWIMM_FILE_TYPES)[keyof typeof config.SWIMM_FILE_TYPES];
}) {
  return `${path}/${getFileName({ swmId, type })}`;
}
