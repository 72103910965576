import localForage from 'localforage';
import { get as lodashGet, has as lodashHas, set as lodashSet, unset as lodashUnset } from 'lodash-es';
import { LOCAL_WEB_STATE_NAME, PROJECT_NAME } from '../../config';

export default class webInternalState {
  private internalState: LocalForage;
  constructor() {
    this.internalState = localForage;
    this.internalState.config({ storeName: LOCAL_WEB_STATE_NAME, name: PROJECT_NAME });
  }
  async get(key, defaultValue = undefined) {
    const { nestedPath, rootLevelKey } = splitStateKey(key);
    const storedRootValue = await this.internalState.getItem(rootLevelKey);
    if (storedRootValue !== null) {
      // If only the root level key was to be found, return its value
      if (!nestedPath) {
        return storedRootValue;
      }
      const nestedValue = lodashGet(storedRootValue, nestedPath);
      if (nestedValue !== undefined) {
        return nestedValue;
      }
    }
    return defaultValue;
  }
  async set(key, value) {
    const { rootLevelKey } = splitStateKey(key);
    const storedRootValue = await this.internalState.getItem(rootLevelKey);
    const valueToSave = { [rootLevelKey]: {} };
    if (storedRootValue !== null) {
      valueToSave[rootLevelKey] = storedRootValue;
    }
    lodashSet(valueToSave, key, value);
    await this.internalState.setItem(rootLevelKey, valueToSave[rootLevelKey]);
  }
  async delete(key) {
    const { nestedPath, rootLevelKey } = splitStateKey(key);
    if (!nestedPath) {
      await this.internalState.removeItem(key);
      return;
    }
    const newRootValue = await this.internalState.getItem(rootLevelKey);
    lodashUnset(newRootValue, nestedPath);
    await this.internalState.setItem(rootLevelKey, newRootValue);
  }
  async clear() {
    await this.internalState.clear();
  }
  async has(key) {
    if (!key) {
      return false;
    }
    const { rootLevelKey, nestedPath } = splitStateKey(key);
    const storedRootValue = await this.internalState.getItem(rootLevelKey);
    if (storedRootValue === null) {
      return false;
    }
    // If only the root level key was to be found
    if (!nestedPath) {
      return true;
    }
    return lodashHas(storedRootValue, nestedPath);
  }
}

/**
 * Gets a key and splits it into a root-level key and a nested path
 * @example - for the input 'repos.repoId.unit_state.unit_data.task.tests' the result will be:
 * { rootLevelKey: 'repos', nestedPath: 'repoId.unit_state.unit_data.task.tests' }
 * @param key - a state key to fetch
 * @returns {{nestedPath: *, rootLevelKey: *}}
 */
function splitStateKey(key: string) {
  const splitKey = key.split(/\.(.+)/).filter((item) => item !== ''); // I.e - 'repos.repoId.started' -> ["repos", "repoId.started"]
  return { rootLevelKey: splitKey[0], nestedPath: splitKey[1] };
}

export function getStateInstance(_options?: { projectName: string; configName: string; cwd: string }) {
  return new webInternalState();
}
