import { Mutex } from 'async-mutex';
import { getLoggerNew } from '#logger';
import { REPO_STATE_KEYS_TO_KEEP } from './config';
import { RepoStateData } from './types';

const logger = getLoggerNew("packages/shared/src/local-state.ts");

const GLOBAL_STATE_KEYS = [
  'repos',
  'git_tokens',
  'uid',
  'nickname',
  'verbose',
  'swimm_version',
  'redirection_data',
  'cwd',
  'dismissed_workspace_invites',
  'latest_db_metadata',
  'latest_playing_metadata',
  'should_show_version_modal',
  'latest_user_workspace_id',
  'preferred_save_method',
  'side_menu_width',
  'snippet_drawer_height',
  'doc_sidebar_expanded',
];

interface InternalStateInterface {
  get(key: string, defaultValue: unknown): Promise<unknown> | unknown;
  set(key: string, value: unknown): Promise<void> | void;
  delete(key: string): Promise<void> | void;
  has(key: string): Promise<boolean> | boolean;
}

class LocalState {
  private mutex: Mutex;
  private internalState: InternalStateInterface;

  constructor(initializedStore: InternalStateInterface) {
    this.internalState = initializedStore;
    this.mutex = new Mutex();
  }

  async get({ key, defaultValue = undefined, repoId = undefined }) {
    return await this.internalState.get(this.perRepoKey(key, repoId), defaultValue);
  }

  async set({ key, value, repoId = undefined }) {
    await this.mutex.runExclusive(async () => {
      await this.internalState.set(this.perRepoKey(key, repoId), value);
    });
  }

  async deleteKey({ key, isGlobalKey = false, repoId = undefined }) {
    !isGlobalKey ? await this.internalState.delete(this.perRepoKey(key, repoId)) : await this.internalState.delete(key);
  }

  async has({ key, repoId = undefined }) {
    return await this.internalState.has(this.perRepoKey(key, repoId));
  }

  async deleteMultipleKeys(keys, repoId) {
    await Promise.all(
      keys.map(async (key) => {
        try {
          await this.deleteKey({ key, repoId });
        } catch (ex) {
          logger.error(`Could not delete key: ${key}`);
        }
      })
    );
  }

  perRepoKey(key, repoId) {
    if (!repoId || GLOBAL_STATE_KEYS.includes(key)) {
      return key;
    }
    return `repos.${repoId}.${key}`;
  }
  async getRepoFromLocalState(repoId, forceDataFromState = false): Promise<RepoStateData | undefined> {
    const repoFromState = (await this.internalState.get(`repos.${repoId}`, undefined)) as RepoStateData | undefined;
    if (forceDataFromState || (repoFromState && !this.isRepoConsideredEmpty(repoFromState))) {
      return repoFromState;
    }
    return undefined;
  }
  /**
   * Repo is considered empty if all of the keys in it are 'keys to keep' only
   */
  isRepoConsideredEmpty(repoData) {
    return Object.keys(repoData).every((repoKey) => REPO_STATE_KEYS_TO_KEEP.includes(repoKey));
  }
}

export default LocalState;
