<template>
  <Loader v-if="!showError" class="view-loader" />
  <div v-else>Failed Authenticating with {{ gitProviderDisplayName }}</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import Cookies from 'js-cookie';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { GitAuthType, GitProviderInfo, GitProviderName, getLoggerNew, gitProviderUtils } from '@swimm/shared';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';

const logger = getLoggerNew(__modulename);

const route = useRoute();
const router = useRouter();
const showError = ref(false);
const gitProviderDisplayName = ref('Git Hosting');

async function authenticate(endpoint, body) {
  try {
    logger.info(`Sending local auth request to ${endpoint}`);
    const response = await axios.post(`${endpoint}/token`, body, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (err) {
    logger.error({ err }, `Failed to authenticate with ${endpoint}: ${err}`);
    return null;
  }
}

onMounted(async () => {
  const workspaceId = Cookies.get('swimm_workspace_id');
  const enterpriseHostname = Cookies.get('swimm_auth_name');
  const userId = Cookies.get('swimm_user_id');
  const provider = Cookies.get('swimm_auth_provider');
  const forceEndpoint = Cookies.get('swimm_auth_endpoint');
  const authType = Cookies.get('swimm_auth_type') as GitAuthType;

  gitProviderDisplayName.value = GitProviderInfo[provider]?.displayName ?? 'Git Hosting';
  const enterprise = {
    workspaceId,
    enterpriseName: enterpriseHostname,
  };

  // When authenticating with GitHub App, we need to make sure we extract the matching config
  if (authType === 'github_app') {
    enterprise['secretName'] = 'GITHUB_APP_AUTH';
  }

  const { data } = await CloudFunctions.fetchEnterpriseAuthConfig(enterprise);
  if (!data) {
    showError.value = true;
    logger.error(
      `Couldn't get config for local authentication: workspace ${workspaceId}, enterprise ${enterpriseHostname}`
    );
    return;
  }

  if (gitProviderUtils.canProviderRefreshTokenLocally(provider)) {
    // set data for refresh token
    Cookies.set('swimm_id_for_refresh', data.clientId);
    Cookies.set('swimm_string_for_refresh', data.clientSecret);
    Cookies.set('swimm_endpoints_for_refresh', JSON.stringify(data.authenticationEndpoints));
    if (data.port) {
      Cookies.set('swimm_port_for_refresh', data.port);
    }
  }

  const body = {
    clientId: data.clientId,
    clientSecret: data.clientSecret,
    baseUrl: data.baseUrl,
    workspaceId,
    userId,
    provider,
    authType,
    code: route.query.code,
    state: route.query.state,
  };

  const authEndpoints = forceEndpoint ? [forceEndpoint] : data.authenticationEndpoints;
  let response;
  if (provider !== GitProviderName.BitbucketDc) {
    for (const endpoint of authEndpoints) {
      response = await authenticate(endpoint, body);
      if (response) {
        break;
      }
    }
  } else {
    response = await gitProviderUtils.authenticateBitbucketDcWithPlugin(data.host, {
      grant_type: 'authorization_code',
      client_id: data.clientId,
      client_secret: data.clientSecret,
      code: route.query.code as string,
      state: route.query.state as string,
    });
  }

  if (response) {
    logger.info(`Authenticated successful for user ${userId} from ${enterpriseHostname}, workspace: ${workspaceId}`);
    await router.replace(`/setGitToken?${new URLSearchParams(response).toString()}&git_hosting=${enterpriseHostname}`);
  } else {
    logger.error(`Failed to locally authenticate user ${userId} from ${enterpriseHostname}, workspace: ${workspaceId}`);
    showError.value = true;
  }
});
</script>
