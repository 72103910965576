import { Observable } from 'rxjs';
import {
  type AddNotificationParams,
  type AnalyticsTrackOptions,
  type AnalyticsTrackProperties,
  type OpenFileParam,
  type TokenSuggestionsService,
  useTokenSuggestionsService,
} from '@swimm/editor';
import type { Link } from '@swimm/reefui';
import {
  type FolderTree,
  type GenerateMermaidRequest,
  type GenerateMermaidResponse,
  type GenerateSnippetCommentResponse,
  type GenerateTextModifiersResponse,
  type GenerativeAiResponse,
  type SwmSymbolLinkType,
  type TextCompletionContext,
  type WorkspaceUser,
} from '@swimm/shared';
import { type Ref, ref } from 'vue';
import type { EditorSnippet, OpenSwimmResourceParam, SwimmEditorExternalServices, UploadedImage } from '.';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { EmptyAiContentSuggestionsService } from './EmptyAiContentSuggestionsService';
import type { NormalizedAutosyncOutput } from '@/swmd/autosync';

export class EmptySwimmEditorExternalServices implements SwimmEditorExternalServices {
  readonly giphyClient = new GiphyFetch(import.meta.env.VITE_GIPHY_API_KEY);
  readonly aiContentSuggestionsService = new EmptyAiContentSuggestionsService();
  readonly isWorkspaceAdmin = ref(false);
  readonly isGenAiDisabledInWorkspace = ref(false);
  readonly swimmportEnabled = ref(false);
  readonly isAutoCompleteEnabled = ref(false);
  readonly tokenSuggestionsService: TokenSuggestionsService;
  readonly isDarkMode;

  constructor({ isDarkMode }: { isDarkMode: Ref<boolean> }) {
    this.isDarkMode = isDarkMode ?? ref(false);
    const fileTokensIndex = {
      load: async () => [],
      get: () => ({ loading: false, suggestions: [] }),
      getAsync: async () => [],
      clear: () => void 0,
    };
    const staticAnalysisIndex = {
      load: async () => [],
      get: () => ({ loading: false, suggestions: [] }),
      getAsyncMap: async () => new Map(),
      clear: () => void 0,
      suggestionsBuildTimestamp: ref(0),
    };
    const queryTokensService = {
      get: () => ({ loading: false, suggestions: [] }),
      getAsync: async () => [],
      clear: () => void 0,
    };
    this.tokenSuggestionsService = useTokenSuggestionsService({
      fileTokensIndex,
      staticAnalysisIndex,
      queryTokensService,
    });
  }

  async getRepoFolderTree(_repoId: string, _branch: string, _isCrossRepo: boolean): Promise<FolderTree> {
    return { type: 'directory', name: '', path: '', children: [] };
  }

  async getRepoLinks(_repoId: string, _branch: string, _linkType: SwmSymbolLinkType): Promise<Link[]> {
    return [];
  }

  async getWorkspaceUsers(): Promise<WorkspaceUser[]> {
    return [];
  }

  async openFile(_param: OpenFileParam): Promise<void> {
    return;
  }

  async getFileContent(): Promise<string | undefined> {
    return;
  }

  openLink(_href: string): void {
    /* Empty */
  }

  async openSwimmResource(_param: OpenSwimmResourceParam): Promise<void> {
    return;
  }

  isDraft(_unitId: string): boolean {
    return false;
  }

  async uploadImage(_file: File): Promise<UploadedImage> {
    return { src: '', width: 0, height: 0 };
  }

  async getImageFile(_src: string, _unitId: string): Promise<File> {
    return new File([], '', { type: 'image/png' });
  }

  trackEvent(
    _eventName: string,
    _properties: AnalyticsTrackProperties,
    _options?: AnalyticsTrackOptions | undefined
  ): void {
    return;
  }

  showNotification(_text: string, _params?: AddNotificationParams | undefined): void {
    return;
  }

  selectSnippets(): Observable<EditorSnippet | null> {
    return new Observable<EditorSnippet | null>();
  }

  editSnippet(_snippet?: EditorSnippet | null): Observable<EditorSnippet | null> {
    return new Observable<EditorSnippet | null>();
  }

  async endSnippetSelection(): Promise<void> {
    return;
  }

  openGenerativeAiRepoSettings(): void {
    return;
  }

  async generateTextModifier(_request: {
    repoId: string;
    workspaceId: string;
    textInput: string;
    modifier: string;
  }): Promise<
    | (GenerativeAiResponse & GenerateTextModifiersResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return { status: 'error' };
  }

  isAIGenerationEnabledForRepo(): boolean {
    return false;
  }

  async generateSnippetComment(_request: { repoId: string; workspaceId: string; snippetContent: string }): Promise<
    | (GenerativeAiResponse & GenerateSnippetCommentResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return { status: 'error' };
  }

  async generateMermaid(_request: Omit<GenerateMermaidRequest, 'type'>): Promise<
    | (GenerativeAiResponse & GenerateMermaidResponse)
    | (GenerativeAiResponse & {
        status: 'error';
      })
  > {
    return { status: 'error' };
  }

  async isQuotaExceeded(): Promise<boolean> {
    return false;
  }

  async getDismissedSwimmportSuggestions(): Promise<string[]> {
    return [];
  }

  async dismissSwimmportSuggestion(_text: string): Promise<void> {
    return;
  }

  async setSmartElementCountInDB(_docId: string, _repoId: string, _autosyncOutput: NormalizedAutosyncOutput) {
    return;
  }

  async completeText(_context: TextCompletionContext): Promise<{ generatedText: string; cost: number }> {
    return { generatedText: '', cost: 0 };
  }
  isPasteCodeWarningApplicable(): boolean {
    return false;
  }
  async markPasteCodeWarningDismissed(): Promise<void> {
    return;
  }
  allowDebugMermaidAi(): boolean {
    return false;
  }
}
