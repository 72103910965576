<script setup lang="ts">
import { useAnalytics } from '@/common/composables/useAnalytics';
import { AiFeedback, GeneratePrProgressBar } from '@swimm/editor';

defineProps<{
  generationTime: number;
  isGeneratingDocFromPr: boolean;
  shouldShowFeedback: boolean;
  isSnippetStudioOpen: boolean;
  customPrompt?: string;
  repoId: string;
  workspaceId: string;
}>();

const emit = defineEmits<{
  (e: 'close-feedback'): void;
  (e: 'stop-generating'): void;
  (e: 'revert-genertation'): void;
}>();

const analytics = useAnalytics();
</script>

<template>
  <div :class="['pr-popup', { top: isSnippetStudioOpen }]" v-if="isGeneratingDocFromPr || shouldShowFeedback">
    <GeneratePrProgressBar
      v-if="isGeneratingDocFromPr"
      :generation-time="generationTime"
      :custom-prompt="customPrompt || undefined"
      @stop-generating="emit('stop-generating')"
    />
    <AiFeedback
      v-else-if="shouldShowFeedback"
      show-close
      :show-revert-button="!isGeneratingDocFromPr"
      :custom-prompt="customPrompt || undefined"
      @revert="emit('revert-genertation')"
      :properties-for-analytics="{ Context: 'AI PR2Doc' }"
      @analytic="(analytic) => analytics.track(analytic.event, analytic.properties)"
      @close-feedback="emit('close-feedback')"
    />
  </div>
</template>

<style scoped lang="postcss">
.pr-popup {
  position: sticky;
  bottom: var(--space-base);
  border: 1px solid var(--color-border-default);
  border-radius: var(--border-radius);
  padding: var(--space-sm) var(--space-md);
  background-color: var(--color-bg);
  z-index: var(--layer-modal);
  box-shadow: var(--box-shadow-medium);
  margin-left: var(--narrow-content-width-left-padding);
  margin-right: var(--narrow-content-width-right-padding);
}

.top {
  top: var(--space-base);
  bottom: auto;
  z-index: var(--layer-modal);
}
</style>
