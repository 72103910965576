import pino from 'pino';
import { getLoggerNew } from '#logger';

export class LegacySwimmLoggerShim {
  constructor(private logger: pino.Logger) {}

  error(message: string, metadata?: Record<string, unknown>): void {
    this.logger.error(prepareMetadata(metadata, message), '%s', message);
  }

  warn(message: string, metadata?: Record<string, unknown>): void {
    this.logger.warn(prepareMetadata(metadata, message), '%s', message);
  }

  info(message: string, metadata?: Record<string, unknown>): void {
    this.logger.info(prepareMetadata(metadata, message), '%s', message);
  }

  debug(message: string, metadata?: Record<string, unknown>): void {
    this.logger.debug(prepareMetadata(metadata, message), '%s', message);
  }

  withMetadata(metadata): LegacySwimmLoggerShim {
    return new LegacySwimmLoggerShim(this.logger.child(prepareMetadata(metadata)));
  }

  getModule() {
    try {
      return this.logger.bindings()['module'];
    } catch {
      return undefined;
    }
  }
}

export type AnyLoggerType = LegacySwimmLoggerShim | pino.Logger;

export interface GetLoggerMetadata {
  module: string;
  [key: string]: unknown;
}

export function getLogger(name: string): LegacySwimmLoggerShim {
  return new LegacySwimmLoggerShim(getLoggerNew(name));
}

// TODO Huh?
function prepareMetadata(metadata: Record<string, unknown> = {}, message = 'no-message') {
  try {
    const reducedMetadata = { ...metadata };
    if (reducedMetadata.context) {
      const context = reducedMetadata.context as { auth: { uid: string } };
      if (context.auth && context.auth.uid) {
        reducedMetadata.uid = context.auth.uid;
      }
      delete reducedMetadata.context;
    }
    return reducedMetadata;
  } catch (error) {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(`Failed to prepare logger metadata, log-message: "${message}", error: ${error}`);
    }
    return {};
  }
}
