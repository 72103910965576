import type { Language, Parser, QueryCapture, SyntaxNode, Tree } from '@/common/tree-sitter';
import { importTreeSitter, loadGrammar } from '@/common/tree-sitter';
import { dedent } from 'ts-dedent';

let _instance: Parser;

async function getJsonParser(): Promise<Parser> {
  if (_instance) {
    return _instance;
  }

  const Parser = await importTreeSitter();
  const json = await loadGrammar('json');
  _instance = new Parser();
  _instance.setLanguage(json);
  return _instance;
}

export async function getFileTree(file: string): Promise<Tree> {
  const parser = await getJsonParser();
  return parser.parse(file);
}

export function findCapture(name: string, captures: QueryCapture[]): QueryCapture | null {
  return captures.find((capture) => capture.name === name);
}

function escapeQueryKey(queryKey: string): string {
  return queryKey.replace(/[\r\n\t\0"]/g, '\\$&');
}

export function captureStringValue(node: SyntaxNode, language: Language, queryKey: string): SyntaxNode | null {
  const query = language.query(
    dedent`
      ((pair key: (string (string_content) @key-name) 
      value: (string (string_content) @value)) (#eq? @key-name "${escapeQueryKey(queryKey)}"))
    `
  );
  return findCapture('value', query.captures(node))?.node;
}

export function captureValue(
  node: SyntaxNode,
  language: Language,
  queryKey: string,
  valueType = '_'
): QueryCapture | null {
  const query = language.query(
    dedent`
      ((pair key: (string (string_content) @key-name) 
      value: (${valueType}) @value) (#eq? @key-name "${escapeQueryKey(queryKey)}"))
    `
  );
  return findCapture('value', query.captures(node));
}

export function captureKey(node: SyntaxNode, language: Language, queryKey: string): QueryCapture | null {
  const query = language.query(
    dedent`
      ((pair key: (string (string_content) @key-name)) 
      (#eq? @key-name "${escapeQueryKey(queryKey)}"))
    `
  );
  return findCapture('key-name', query.captures(node));
}
