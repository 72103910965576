import trim from 'lodash-es/trim';
import { parseStringPromise } from 'xml2js';
import { getLoggerNew, gitwrapper } from '@swimm/shared';
import { CAI_FILE_TYPES } from './types';
import { RawProcessFile } from '../source-types';
import {
  DESCRIPTION_REGEX,
  EMPTY_VALUE_OBJECT,
  NEW_LINE_REGEX,
  _composeValueObject,
  _parseDescriptionField,
  _parseObjectRefs,
  computeValueObjectWithEndIndex,
  escapeRegexValue,
  getWordIndex,
} from '../source-json-parser';
import { AdditionalInfo, ObjectRef, ValueObject } from '../output-types';
import InvalidInputError from '../invalidInputError';

const logger = getLoggerNew('ppg/cai/input.ts');

interface ObjectMap<CAI_FILE_TYPES> {
  metadata: string;
  schema: string;
  type: CAI_FILE_TYPES;
}

interface SourceOption {
  name: string;
  _: string;
}

interface SourceParameterField {
  description: string;
  name: string;
  required: string;
  type: string;
  options: {
    option: {
      name: string;
      _: string;
    }[];
  };
}

interface SourceObjectField {
  label: string;
  name: string;
  nullable: string;
  required: string;
  type: string;
  options: {
    option: {
      name: string;
      _: string;
    }[];
  };
}

interface SourceBinding {
  restSimpleBinding: {
    multiUsing: string;
    url: string;
    verb: string;
    mutualAuth: {
      enabled: string;
      keyStore: {
        mode: string;
      };
      keyStorePassword: string;
    };
    httpsHeaders: {
      header: {
        name: string;
        _: string;
      }[];
    };
    body: string;
  };
}

interface SourceAction {
  category: string;
  failOnError: string;
  forSearch: string;
  label: string;
  maxRedirects: string;
  name: string;
  preemptiveAuth: string;
  responseEncoding: string;
  description: string;
  input: {
    parameter: (SourceObjectField & { testWith: string })[];
  };
  output: {
    field: SourceParameterField[];
  };
  binding: SourceBinding;
}

interface SourceProcessFlow {
  service?: { title: string };
  assignment?: { title: string }[];
  eventContainer: {
    service: {
      title: string;
    };
    flow: {
      assignment: {
        title: string;
      };
    }[];
  }[];
}

interface FieldOptions {
  required: ValueObject;
  [key: string]: ValueObject;
}

export type ParameterField = {
  filePath: string;
  description: ValueObject;
  name: ValueObject;
  required: ValueObject;
  type: ValueObject;
  [key: string]: ValueObject | string;
};

export type ObjectField = {
  filePath: string;
  label: ValueObject;
  name: ValueObject;
  nullable: ValueObject;
  required: ValueObject;
  type: ValueObject;
  [key: string]: ValueObject | string;
};

export interface Binding {
  filePath: string;
  multiUsing: ValueObject;
  url: ValueObject;
  verb: ValueObject;
  mutualAuth: {
    enabled: ValueObject;
    keyStore: {
      mode: ValueObject;
    };
    keyStorePassword: ValueObject;
  };
  body: ValueObject;
  httpHeaders: { [name: string]: ValueObject }[];
}

type Deployment = {
  type: ValueObject;
  filePath: string;
  skipIfRunning: ValueObject;
  suspendOnFault: ValueObject;
  tracingLevel: ValueObject;
  allowedGroups?: { group: ValueObject; [key: string]: ValueObject }[];
  [key: string]: ValueObject | { [key: string]: ValueObject } | { [key: string]: ValueObject }[] | string;
};

interface ProcessFlow {
  filePath: string;
  source: ValueObject;
  serviceFlows: ValueObject[];
}

interface ProcessSchema {
  filePath: string;
  tags: ValueObject;
}

export type Process = {
  name: ValueObject;
  type: ValueObject;
  filePath: string; // this will point to the metadata file path. Each nested object will have its own filePath to specify the exact source
  input: Array<ParameterField>;
  output: Array<ParameterField>;
  deployment: Deployment;
  processObjects: ProcessObject[];
  processConnectors: ProcessConnector[];
  flows: ProcessFlow[];
  schema: ProcessSchema;
} & AdditionalInfo;

export type Action = {
  filePath: string;
  category: ValueObject;
  failOnError: ValueObject;
  forSearch: ValueObject;
  label: ValueObject;
  maxRedirects: ValueObject;
  name: ValueObject;
  preemptiveAuth: ValueObject;
  responseEncoding: ValueObject;
  description: ValueObject;
  input: ObjectField[];
  output: ParameterField[];
  binding: Binding[];
};

export type ProcessObject = {
  fields: Array<ObjectField>;
} & ObjectRef;

type ServiceConnector = ObjectRef & {
  actions: Action[];
} & { [key: string]: ValueObject };

export type ProcessConnector = ObjectRef & { serviceConnectors: ServiceConnector[] };

export const CAI_FILE_NAME_PATTERN =
  /(?:\w+\s\w+\/)*\.?(?<objectName>[^\s/]+?)\.(?<fileType>AI_CONNECTION|PROCESS_OBJECT|AI_SERVICE_CONNECTOR|PROCESS){1}\.(?<fileFormat>json|xml)$/;

export function validate(filePaths: string[]): boolean {
  return filePaths.every((filePath) => {
    return filePath.match(CAI_FILE_NAME_PATTERN);
  });
}

export function filter(filePaths: string[]): string[] {
  return filePaths.filter((filePath) => filePath.match(CAI_FILE_NAME_PATTERN));
}

/**
 * Maps object names to its metadata (json) file path, its schema (xml) file path and its type
 * So the resulting map will look like:
 * {
 *   'objectName': {
 *     'metadata': 'path/to/object.json',
 *     'schema': 'path/to/object.xml',
 *     'type': CAI_FILE_TYPES
 *   }
 * }
 * @param filePaths - array of strings pointing to repo file paths
 */
export function groupByObject(filePaths: string[]): { [objectName: string]: ObjectMap<CAI_FILE_TYPES> } {
  return filePaths.reduce((acc, filePath) => {
    const match = filePath.match(CAI_FILE_NAME_PATTERN);
    if (!match) {
      return acc;
    }

    const objectName = match.groups['objectName'];
    const fileType = match.groups['fileType'];
    const fileFormat = match.groups['fileFormat'];
    if (!acc[objectName]) {
      acc[objectName] = { type: fileType };
    }

    acc[objectName][fileFormat === 'xml' ? 'schema' : 'metadata'] = filePath;

    return acc;
  }, {});
}

function extractObjectMetadata(metadata: RawProcessFile, textLines: string[]) {
  const id = _composeValueObject(textLines, 'id', metadata.objectInfo.id, 0);
  const name = _composeValueObject(textLines, 'name', metadata.objectInfo.name, 0);
  const type = _composeValueObject(textLines, 'type', metadata.objectInfo.type, 0);
  const objectMetadata = _parseDescriptionField(
    textLines,
    metadata.objectInfo?.metadata?.additionalInfo?.description,
    metadata.objectInfo?.name,
    {}
  );

  return {
    id,
    name,
    type,
    ...objectMetadata,
  };
}

function ensureArray(obj): Array<unknown> {
  return Array.isArray(obj) ? obj : [obj];
}

/**
 * Find the line index of a tag in a file
 * @param fileTextLines - the original file content split by lines
 * @param tag - the xml tag to search for
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 */
function getTagLineIndex(fileTextLines: string[], tag: string, startAt = -1) {
  // The regex will match the tag to find the file line they came from (vital for creating smart tokens later)
  const regExp = new RegExp(`\\s*${escapeRegexValue(tag)}\\s*`);

  return fileTextLines.findIndex((line, index) => {
    const match = line.match(regExp);
    return match != null && index > startAt;
  });
}

/**
 * Compose a value object from a text in a file
 * Used to identify this value as a smart token
 * @param fileTextLines
 * @param tag
 * @param value
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 */
function composeXmlValueObject(fileTextLines: string[], tag: string, value: string, startAt = -1): ValueObject {
  if (!value || value === '""') {
    return { ...EMPTY_VALUE_OBJECT, lineText: tag, value: `Missing value for ${tag}` };
  }

  // When there's a xml tag followed by a value that starts or ends with $ or @ or " or . they are grouped together into 1 word. Causing the word index to fail.
  // Example: <description>.bla</description> will be split as ['<', 'description', '>.', 'bla', '</', 'description', '>']
  // And we will try to match the value '.bla' - and fail
  // We are stripping them from the value to bypass this issue
  // TODO: find a better way - since this also changes the token set
  value = trim(value, '$@".');

  const line = getTagLineIndex(fileTextLines, tag, startAt);

  if (line === -1) {
    return { ...EMPTY_VALUE_OBJECT, lineText: tag, value: `Missing value for ${tag}` };
  }

  const wordIndex = getWordIndex(fileTextLines[line], value.split(NEW_LINE_REGEX)[0]);
  // multi line values are hard to match with the file's stringified version
  // we are taking the first line out of the value to match it with the file's line as a good enough approximation of the token
  return computeValueObjectWithEndIndex(fileTextLines[line], line + 1, wordIndex, value.split(NEW_LINE_REGEX)[0]);
}

/**
 * Take an xml options object (usally derived from the following structure:
 * <options>
 *     <option name="referenceTo">POCodeCombinationCampos</option>
 *     <option name="relationshipName">Child</option>
 * </options>
 * and output it as a record mapping option name (the key) to its value as a ValueObject
 * When translated from xml to json this can either exist or not, be constructed as an array (i.e. { options: { options: [{ name: '', _: '' }] } }
 * or as an object (i.e. { options: { option: { name: '', _: '' } } }
 *  @param {String[]} textLines - the original xml file split into text lines
 *  @param {number} lineIndex - the line number to start ValueObject match from (reduces false positives when the same annotation repeates within a single file)
 *  @param [options] - optional
 */
function parseSourceOptions(
  textLines: string[],
  lineIndex: number,
  options?: { option: SourceOption[] }
): FieldOptions {
  return ((options?.option ? ensureArray(options?.option) : []) as SourceOption[]).reduce((acc, option) => {
    acc[option.name] = composeXmlValueObject(textLines, `<option name="${option.name}"`, option._, lineIndex);
    return acc;
  }, {} as FieldOptions);
}

/**
 * Return the correct definition of the field's required option
 * It can appear on the field's definition and/or one of its options
 * <field name="CodeCombination"
 *        nullable="false"
 *        required="false"
 *        type="objectlist">
 *     <options>
 *         <option name="required">true</option>
 *         <option name="relationshipName">Child</option>
 *     </options>
 * </field>
 * We currently favor the definition set on the source field - this needs to be validated
 *  @param {String[]} textLines - the original xml file split into text lines
 *  @param {number} lineIndex - the line number to start ValueObject match from (reduces false positives when the same annotation repeates within a single file)
 *  @param options
 *  @param sourceField - a generic
 *  @param {boolean} [blockWhenMissing=false] - do we want the developer to be prevented from committing the result if this information is missing?
 */
function composeRequiredFieldOption<In>(
  textLines: string[],
  lineIndex: number,
  options: FieldOptions,
  sourceField: In,
  blockWhenMissing = false
): ValueObject | undefined {
  let required = options.required
    ? composeXmlValueObject(textLines, `<option name="required"`, options.required.value, lineIndex)
    : undefined;
  // Setting the field's required value over the options' required value
  if (sourceField['required']) {
    required = composeXmlValueObject(
      textLines,
      `required="${sourceField['required']}"`,
      sourceField['required'],
      lineIndex
    );
  }

  if (!required && blockWhenMissing) {
    required = { ...EMPTY_VALUE_OBJECT, lineText: 'required', value: `Missing value for required` };
  }

  return required;
}

/**
 * This is a generic function that can be configured to accept typed input and output
 * It takes an XML attribute and try to parse it into Record<key, ValueObject | ValueObject[] | ....>
 *   This is tricky since we do not always know the amount of nesting an attribute has,
 *   whether it is an array with a single item turned to object (i.e. <httpHeaders><header name="Accept-Encoding">gzip,deflate</header></httpHeaders>),
 *   if the attribute is defined on the tag itself (i.e. <mutualAuth enabled=true>)
 *   or as sub tags <mutualauth><keyStore mode="file"/></mutualAuth>
 *   @param sourceField - the attribute json extracted from the original xml
 *   @param {String[]} textLines - the original xml file split into text lines
 *   @param {number} lineIndex - the line number to start ValueObject match from (reduces false positives when the same annotation repeates within a single file)
 *   @param {String[]} [keysToExclude=[]] - Which attribute keys we do not want this function to parse
 */
function parseXMLAttribute<In, Out>(
  sourceField: In,
  textLines: string[],
  lineIndex: number,
  keysToExclude: Array<string> = []
): Out {
  const field = {};
  for (const key of Object.keys(sourceField).filter((k) => !keysToExclude.includes(k))) {
    if (typeof sourceField[key] === 'string') {
      // The key is either placed on the xml tag (i.e. <parameter label="in_scXMLData" />
      // Or is a value between 2 xml tags (i.e. <body>{$in_scXMLData}</body>
      // Try to convert into a value object assuming it is placed on the xml tag
      // TODO: Handle HTML escaped characters in the source file (they are un-escaped in the parsed json and fail the regex)
      //  Example - when key is "testWith"
      field[key] = composeXmlValueObject(textLines, `${key}="${sourceField[key]}"`, sourceField[key], lineIndex);

      // If you did ot succeed (getTagLineIndex produces -1, not found)
      // Check if you are able to create a value object assuming it is a value between 2 tags
      if (getTagLineIndex(textLines, `${key}="${sourceField[key]}"`, lineIndex) === -1) {
        if (getTagLineIndex(textLines, `<${key}>${sourceField[key]}`, lineIndex) > -1) {
          field[key] = composeXmlValueObject(textLines, `<${key}>${sourceField[key]}`, sourceField[key], lineIndex);
        }
      }
    } else {
      // This attribute is some form of object - wither an array or a record
      const firstKey = Object.keys(sourceField[key])[0];
      // Check if there's a plural annotation to this key (ex. httpHeaders) or if the first item within this object holds an array
      // This heuristic is trying to cover 2 cases:
      // 1. There is an actual array of values, which will be represented as { httpsHeaders: { header: [{ name: '<string value>', _: '<string value>' }] } }
      //    This happens when the xml is <httpHeaders><header name="Accept-Encoding">gzip,deflate</header><header name="Content-Type">text/plain</header></httpHeaders>
      // 2. There is a potential array of values but it currently only holds one
      //    This will be represented as { allowedGroups: { group: <value> } }
      //    And happens when the xml is: <allowedGroups><group>ServiceConsumer</group></allowedGroups>
      if (key.endsWith('s') || Array.isArray(sourceField[key][firstKey])) {
        const asArray = (sourceField[key][firstKey] ? ensureArray(sourceField[key][firstKey]) : []) as SourceOption[];
        field[key] = asArray.map((item) => {
          if (typeof item === 'string') {
            // The array item is presented in xml as: <group>ServiceConsumer</group>
            return composeXmlValueObject(textLines, `<${firstKey}>${item}`, item, lineIndex);
          }
          // The array item is presented in xml as: <header name="Accept-Encoding">gzip,deflate</header>
          return {
            [item.name]: composeXmlValueObject(textLines, `<${firstKey} name="${item.name}"`, item._, lineIndex),
          };
        });
      } else {
        field[key] = Object.keys(sourceField[key]).reduce((acc, nestedKey) => {
          const currentAttribute = sourceField[key][nestedKey];
          if (typeof currentAttribute === 'string') {
            acc[nestedKey] = composeXmlValueObject(
              textLines,
              `${nestedKey}="${currentAttribute}"`,
              currentAttribute,
              lineIndex
            );
          } else {
            // This is an object that can take on multiple forms... With each object entry being either map a key to a string, an array or another object
            // Example:
            //  <mutualAuth enabled="false">
            //    <keyStore mode="file"/>
            //    <keyStorePassword/>
            //  </mutualAuth>
            // Recursively call this function to properly parse all variants:
            acc[nestedKey] = parseXMLAttribute(currentAttribute, textLines, lineIndex);
          }

          return acc;
        }, {});
      }
    }
  }

  return field as Out;
}

// TODO: extract to actual types?
function extractInput(schema: object, textLines: string[], sourceFilePath: string): Array<ParameterField> {
  const processStructure = schema['aetgt:getResponse']['types1:Item']['types1:Entry']['process'];
  const inputFields = processStructure.input?.parameter
    ? (ensureArray(processStructure.input?.parameter) as Array<SourceParameterField>)
    : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<input>');

  return inputFields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</parameter>', lineIndex + 1);
    }

    const options = parseSourceOptions(textLines, lineIndex, sourceField.options);
    const required = composeRequiredFieldOption<SourceParameterField>(textLines, lineIndex, options, sourceField);

    let field = parseXMLAttribute<SourceParameterField, ParameterField>(sourceField, textLines, lineIndex, [
      'options',
      'required',
    ]);
    field = { ...field, ...options, required, filePath: sourceFilePath };

    return field as ParameterField;
  });
}

// TODO: extract to actual types?
function extractOutput(schema: object, textLines: string[], sourceFilePath: string): Array<ParameterField> {
  const processStructure = schema['aetgt:getResponse']['types1:Item']['types1:Entry']['process'];
  const outputFields = processStructure.output?.field
    ? (ensureArray(processStructure.output?.field) as Array<SourceParameterField>)
    : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<output>');

  return outputFields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</field>', lineIndex + 1);
    }

    const options = parseSourceOptions(textLines, lineIndex, sourceField.options);
    const required = composeRequiredFieldOption<SourceParameterField>(textLines, lineIndex, options, sourceField);

    let field = parseXMLAttribute<SourceParameterField, ParameterField>(sourceField, textLines, lineIndex, [
      'options',
      'required',
    ]);
    field = { ...field, ...options, required, filePath: sourceFilePath };

    return field as ParameterField;
  });
}

// TODO: extract to actual types?
function extractDeployment(schema: object, textLines: string[], sourceFilePath: string): Deployment {
  const processStructure = schema['aetgt:getResponse']['types1:Item']['types1:Entry']['process'];
  const deployment = processStructure.deployment;

  if (!deployment) {
    return {} as Deployment;
  }

  let deploymentConfiguration: Partial<Deployment> = {};
  deploymentConfiguration.filePath = sourceFilePath;

  const type = Object.keys(deployment).find((key) => ['rest', 'soap', 'event'].includes(key));

  deploymentConfiguration.type = composeXmlValueObject(textLines, `<${type}`, type);
  if (typeof deployment[type] === 'object') {
    const sourceField = deployment[type];
    const field = parseXMLAttribute<unknown, Deployment>(sourceField, textLines, 0);
    deploymentConfiguration = { ...field, ...deploymentConfiguration };
  }
  Object.keys(deployment).forEach((key) => {
    if (key !== type && typeof deployment[key] === 'string') {
      deploymentConfiguration[key] = composeXmlValueObject(textLines, `${key}="${deployment[key]}"`, deployment[key]);
    }
  });

  return deploymentConfiguration as Deployment;
}

function extractFlows(schema: object, textLines: string[], sourceFilePath: string): ProcessFlow[] {
  const processStructure = schema['aetgt:getResponse']['types1:Item']['types1:Entry']['process'];
  const sourceFlow: SourceProcessFlow = processStructure.flow;

  if (!sourceFlow) {
    return [];
  }

  const flows = [];

  const service = sourceFlow.service;
  const assignments = sourceFlow.assignment || [];

  if (service && assignments.length) {
    const eventFlow = {} as ProcessFlow;
    eventFlow.source = composeXmlValueObject(textLines, `<title>${service.title}`, service.title);

    eventFlow.serviceFlows = assignments.map((assignment) => {
      return composeXmlValueObject(textLines, `<title>${assignment.title}`, assignment.title);
    });

    eventFlow.filePath = sourceFilePath;

    flows.push(eventFlow);
  } else if (sourceFlow.eventContainer) {
    const eventContainers = ensureArray(sourceFlow.eventContainer) as {
      service: { title: string };
      flow: { assignment: { title: string } }[];
    }[];

    for (const eventContainer of eventContainers) {
      const eventFlow = {} as ProcessFlow;

      if (eventContainer.service) {
        eventFlow.source = composeXmlValueObject(
          textLines,
          `<title>${eventContainer.service.title}`,
          eventContainer.service.title
        );
      } else if (service) {
        eventFlow.source = composeXmlValueObject(textLines, `<title>${service.title}`, service.title);
      }

      let sourceServiceFlows = eventContainer.flow
        ? (ensureArray(eventContainer.flow) as {
            assignment: { title: string };
          }[])
        : null;

      if (!sourceServiceFlows) {
        sourceServiceFlows = assignments.map((assignment) => ({
          assignment,
        }));
      }

      eventFlow.serviceFlows = sourceServiceFlows.reduce((acc, serviceFlow) => {
        if (serviceFlow.assignment && !Array.isArray(serviceFlow.assignment)) {
          acc.push(
            composeXmlValueObject(textLines, `<title>${serviceFlow.assignment.title}`, serviceFlow.assignment.title)
          );
        }
        return acc;
      }, []);

      eventFlow.filePath = sourceFilePath;

      flows.push(eventFlow);
    }
  }

  return flows as ProcessFlow[];
}

// TODO: extract to actual types?
function extractObjectFields(schema: object, textLines: string[], sourceFilePath: string): Array<ObjectField> {
  const structure = schema['aetgt:getResponse']['types1:Item']['types1:Entry']['processObject'];
  const fields = structure.detail?.field ? (ensureArray(structure.detail?.field) as Array<SourceObjectField>) : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<detail>');

  return fields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</field>', lineIndex + 1);
    }

    const options = parseSourceOptions(textLines, lineIndex, sourceField.options);
    const required = composeRequiredFieldOption<SourceObjectField>(textLines, lineIndex, options, sourceField);

    let field = parseXMLAttribute<SourceObjectField, ObjectField>(sourceField, textLines, lineIndex, [
      'options',
      'required',
    ]);
    field = { ...field, ...options, required, filePath: sourceFilePath };

    return field as ObjectField;
  });
}

function parseActionInput(
  action: SourceAction,
  textLines: string[],
  sourceFilePath: string,
  startAt = -1
): Array<ObjectField> {
  const inputFields = action.input?.parameter
    ? (ensureArray(action.input?.parameter) as Array<SourceParameterField>)
    : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<input>', startAt);

  return inputFields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</parameter>', lineIndex + 1);
    }

    const options = parseSourceOptions(textLines, lineIndex, sourceField.options);
    const required = composeRequiredFieldOption<SourceParameterField>(textLines, lineIndex, options, sourceField, true);

    let field = parseXMLAttribute<SourceParameterField, ObjectField>(sourceField, textLines, lineIndex, [
      'options',
      'required',
    ]);
    field = { ...field, ...options, required, filePath: sourceFilePath };

    return field as ObjectField;
  });
}

function parseActionOutput(
  action: SourceAction,
  textLines: string[],
  sourceFilePath: string,
  startAt = -1
): Array<ParameterField> {
  const inputFields = action.output?.field ? (ensureArray(action.output?.field) as Array<SourceParameterField>) : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<output>', startAt);

  return inputFields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</field>', lineIndex + 1);
    }

    const options = parseSourceOptions(textLines, lineIndex, sourceField.options);
    const required = composeRequiredFieldOption<SourceParameterField>(textLines, lineIndex, options, sourceField, true);

    let field = parseXMLAttribute<SourceParameterField, ParameterField>(sourceField, textLines, lineIndex, [
      'options',
      'required',
    ]);
    field = { ...field, ...options, required, filePath: sourceFilePath };

    return field as ParameterField;
  });
}

function parseActionBinding(
  action: SourceAction,
  textLines: string[],
  sourceFilePath: string,
  startAt = -1
): Array<Binding> {
  const inputFields = action.binding?.restSimpleBinding
    ? (ensureArray(action.binding?.restSimpleBinding) as Array<SourceParameterField>)
    : [];

  // Set lineIndex to the beginning of the input array
  let lineIndex = getTagLineIndex(textLines, '<binding>', startAt);

  return inputFields.map((sourceField, index) => {
    if (index > 0) {
      // bump line index to the end of the previous parameter
      lineIndex = getTagLineIndex(textLines, '</restSimpleBinding>', lineIndex + 1);
    }

    let field = parseXMLAttribute<SourceParameterField, Binding>(sourceField, textLines, lineIndex);
    field = { ...field, filePath: sourceFilePath };

    return field as Binding;
  });
}

function extractActions(schema: object, textLines: string[], sourceFilePath: string): Array<Action> {
  let sourceActions =
    schema['aetgt:getResponse']['types1:Item']['types1:Entry']?.['businessConnector']?.['actions']?.['action'];
  sourceActions = sourceActions ? ensureArray(sourceActions) : [];

  let lineIndex = getTagLineIndex(textLines, '<actions>');
  return sourceActions.map((sourceAction, index) => {
    if (index > 0) {
      // bump line index to the end of the previous action
      lineIndex = getTagLineIndex(textLines, '</action>', lineIndex + 1);
    }
    const action = Object.keys(sourceAction).reduce((acc, key) => {
      switch (key) {
        case 'description': {
          const match = sourceAction[key].match(DESCRIPTION_REGEX);
          if (match) {
            acc[key] = composeXmlValueObject(textLines, `<${key}>`, match[1], lineIndex);
          } else {
            acc[key] = composeXmlValueObject(textLines, `<${key}>`, sourceAction[key], lineIndex);
          }
          break;
        }
        case 'input':
          acc[key] = parseActionInput(sourceAction, textLines, sourceFilePath, lineIndex);
          break;
        case 'output':
          acc[key] = parseActionOutput(sourceAction, textLines, sourceFilePath, lineIndex);
          break;
        case 'binding':
          acc[key] = parseActionBinding(sourceAction, textLines, sourceFilePath, lineIndex);
          break;
        default:
          acc[key] = composeXmlValueObject(textLines, `${key}="${sourceAction[key]}"`, sourceAction[key], lineIndex);
          break;
      }

      return acc;
    }, {} as Action);

    action.filePath = sourceFilePath;
    return action;
  });
}

async function parseProcessObjects(
  metadata: RawProcessFile,
  textLines: string[],
  metadataFilePath: string,
  allObjects: { [objectName: string]: ObjectMap<CAI_FILE_TYPES> },
  repoId: string,
  branch: string
): Promise<Array<ProcessObject>> {
  const objectRefs = metadata.objectRefs ?? [];
  const sourceProcessObjects = objectRefs.filter((objectRef) => objectRef.type === 'PROCESS_OBJECT');
  const processObjects: { [name: string]: Partial<ProcessObject> } = _parseObjectRefs(
    { ...metadata, objectRefs: sourceProcessObjects },
    textLines,
    'name',
    metadataFilePath
  );

  for (const processObjectName of Object.keys(processObjects)) {
    const sourceObject = allObjects[processObjectName];
    if (!sourceObject) {
      logger.warn(`Could not find source object for process object ${processObjectName}`);
      continue;
    }

    if (!sourceObject.schema) {
      logger.error(`Could not find schema file for source object ${sourceObject.type}: ${sourceObject?.metadata}`);
      throw new InvalidInputError(
        `Could not find schema file for source object ${sourceObject.type}: ${sourceObject?.metadata}`
      );
    }

    const schemaFile = await gitwrapper.getFileContentFromRevision({
      filePath: sourceObject.schema,
      repoId,
      revision: branch,
    });
    const schemaFileLines = schemaFile.split(NEW_LINE_REGEX);

    try {
      const schemaJson = await parseStringPromise(schemaFile, { explicitArray: false, mergeAttrs: true });
      processObjects[processObjectName].fields = extractObjectFields(schemaJson, schemaFileLines, sourceObject.schema);
    } catch (err) {
      logger.error({ err }, `Failed to parse schema file for process object ${processObjectName}: ${err.message}.`);
      throw err;
    }
  }

  return Object.values(processObjects) as Array<ProcessObject>;
}

async function parseProcessConnectors(
  metadata: RawProcessFile,
  textLines: string[],
  metadataFilePath: string,
  allObjects: { [objectName: string]: ObjectMap<CAI_FILE_TYPES> },
  repoId: string,
  branch: string
): Promise<Array<ProcessConnector>> {
  const objectRefs = metadata.objectRefs ?? [];
  const sourceProcessConnectors = objectRefs.filter((objectRef) => objectRef.type === 'AI_CONNECTION');
  const processConnectors: { [name: string]: Partial<ProcessConnector> } = _parseObjectRefs(
    { ...metadata, objectRefs: sourceProcessConnectors },
    textLines,
    'name',
    metadataFilePath
  );

  for (const processConnectorName of Object.keys(processConnectors)) {
    const sourceObject = allObjects[processConnectorName];
    if (!sourceObject) {
      logger.warn(`Could not find source object for process connector ${processConnectorName}`);
      continue;
    }

    if (!sourceObject.metadata) {
      logger.error(`Could not find metadata file for source object ${sourceObject.type}: ${processConnectorName}`);
      throw new InvalidInputError(
        `Could not find metadata file for source object ${sourceObject.type}: ${processConnectorName}`
      );
    }

    const metadataFile = await gitwrapper.getFileContentFromRevision({
      filePath: sourceObject.metadata,
      repoId,
      revision: branch,
    });
    const metadataFileLines = metadataFile.split(NEW_LINE_REGEX);
    const metadataJson = JSON.parse(metadataFile) as RawProcessFile;

    const sourceServiceConnectors = (metadataJson.objectRefs ?? []).filter(
      (objectRef) => objectRef.type === 'AI_SERVICE_CONNECTOR'
    );

    const serviceConnectors = _parseObjectRefs(
      { ...metadataJson, objectRefs: sourceServiceConnectors },
      metadataFileLines,
      'name',
      sourceObject.metadata
    ) as { [name: string]: Partial<ServiceConnector> };

    for (const serviceConnectorName of Object.keys(serviceConnectors)) {
      const sourceObject = allObjects[serviceConnectorName];
      if (!sourceObject) {
        logger.warn(`Could not find source object for service connector ${serviceConnectorName}`);
        continue;
      }

      if (!sourceObject.schema) {
        logger.error(`Could not find schema file for service connector ${serviceConnectorName}`);
        throw new InvalidInputError(`Could not find schema file for service connector ${serviceConnectorName}`);
      }
      const schemaFile = await gitwrapper.getFileContentFromRevision({
        filePath: sourceObject.schema,
        repoId,
        revision: branch,
      });
      const schemaFileLines = schemaFile.split(NEW_LINE_REGEX);

      try {
        const schemaJson = await parseStringPromise(schemaFile, { explicitArray: false, mergeAttrs: true });

        serviceConnectors[serviceConnectorName].actions = extractActions(
          schemaJson,
          schemaFileLines,
          sourceObject.schema
        );
      } catch (err) {
        logger.error(
          { err },
          `Failed to parse schema file for process object ${processConnectorName}: ${err.message}.`
        );
        throw err;
      }
    }

    processConnectors[processConnectorName].serviceConnectors = Object.values(serviceConnectors) as ServiceConnector[];
  }

  return Object.values(processConnectors) as Array<ProcessConnector>;
}

export async function parseProcess(
  processSource: ObjectMap<'PROCESS'> /* { metadata: string; schema: string; type: 'PROCESS' }*/,
  allObjects: { [objectName: string]: ObjectMap<CAI_FILE_TYPES> },
  repoId: string,
  branch: string
): Promise<Process | null> {
  if (!processSource.metadata) {
    const object = Object.entries(allObjects).find(
      ([_name, object]) =>
        object.metadata === processSource.metadata &&
        object.type === processSource.type &&
        object.schema === processSource.schema
    );
    logger.error(`Could not find metadata file for process ${object?.[0]}`);
    throw new InvalidInputError(`Could not find metadata file for process ${object?.[0]}`);
  }

  const metadataFile: string = await gitwrapper.getFileContentFromRevision({
    filePath: processSource.metadata,
    repoId,
    revision: branch,
  });
  const metadataJson = JSON.parse(metadataFile) as RawProcessFile;
  const metadataFileLines = metadataFile.split(NEW_LINE_REGEX);

  const process: Partial<Process> = extractObjectMetadata(metadataJson, metadataFileLines);
  process.processObjects = await parseProcessObjects(
    metadataJson,
    metadataFileLines,
    processSource.metadata,
    allObjects,
    repoId,
    branch
  );

  process.filePath = processSource.metadata;

  process.processConnectors = await parseProcessConnectors(
    metadataJson,
    metadataFileLines,
    processSource.metadata,
    allObjects,
    repoId,
    branch
  );

  if (!processSource.schema) {
    logger.error(`Could not find schema file for process ${processSource.metadata}`);
    throw new InvalidInputError(`Could not find schema file for process ${processSource.metadata}`);
  }

  const schemaFile: string = await gitwrapper.getFileContentFromRevision({
    filePath: processSource.schema,
    repoId,
    revision: branch,
  });
  const schemaFileLines = schemaFile.split(NEW_LINE_REGEX);
  try {
    const schemaJson = await parseStringPromise(schemaFile, { explicitArray: false, mergeAttrs: true });

    process.input = extractInput(schemaJson, schemaFileLines, processSource.schema);
    process.output = extractOutput(schemaJson, schemaFileLines, processSource.schema);
    process.deployment = extractDeployment(schemaJson, schemaFileLines, processSource.schema);
    process.flows = extractFlows(schemaJson, schemaFileLines, processSource.schema);
    const processStructure = schemaJson['aetgt:getResponse']['types1:Item']['types1:Entry']['process'];
    process.schema = {} as ProcessSchema;
    process.schema.tags = composeXmlValueObject(
      schemaFileLines,
      `<tags>${processStructure.tags}`,
      processStructure.tags
    );
    process.schema.filePath = processSource.schema;

    return process as Process;
  } catch (err) {
    logger.error({ err }, `Failed to parse schema file for process ${process.name.value}: ${err.message}.`);
    throw err;
  }
}

export async function parse(
  objects: { [objectName: string]: ObjectMap<CAI_FILE_TYPES> },
  repoId: string,
  branch: string
): Promise<Array<Process>> {
  return (
    await Promise.all(
      Object.values(objects).reduce((processes, object) => {
        if (object.type === 'PROCESS') {
          processes.push(parseProcess(object as ObjectMap<'PROCESS'>, objects, repoId, branch));
        }
        return processes;
      }, Array<Promise<Process>>())
    )
  ).filter((result) => !!result);
}
