<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3';
import { SwmNodeViewItemMention, type User, workspaceUserToUser } from '@swimm/reefui';
import type * as _model from '@tiptap/pm/model';
import { getSwimmEditorServices } from '../extensions/Swimm';

const props = defineProps(nodeViewProps);

const swimmEditorServices = getSwimmEditorServices(props.editor);

// TODO Doing such a side effect in an internal component is not nice, and forces to query for everything immediately
const workspaceUsers = ref<User[]>([]);
watchEffect(async () => {
  workspaceUsers.value = (await swimmEditorServices.external.getWorkspaceUsers()).map(workspaceUserToUser);
});

const userInDB = computed(() => {
  return workspaceUsers.value.some((u) => props.node.attrs.uid === u.id);
});
</script>

<template>
  <NodeViewWrapper data-swm-mention as="span"
    ><SwmNodeViewItemMention
      :name="node.attrs.name"
      :email="node.attrs.email"
      :unavailable="!userInDB"
      unavailable-message="This user is not part of the workspace anymore."
  /></NodeViewWrapper>
</template>
