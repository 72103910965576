import { type IconsType } from '../components/BaseIcon/BaseIcon.vue';
import BaseBadge from '../components/BaseBadge/BaseBadge.vue';
import type { WorkspaceUser } from '@swimm/shared';

export type User = {
  id: string;
  name: string;
  email?: string;
  avatarUrl?: string;
};

export type Link = {
  id: string;
  icon: IconsType;
  name: string;
  path: string;
  isDraftLink?: boolean;
};

export type SlashCommand = {
  icon: IconsType;
  badge?: typeof BaseBadge.props;
  title: string;
  name: string;
  aliases?: string[];
  keyCommands?: string[];
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  command?: (...args: any) => any;
};

export type SelectedIndex = [item: number];
export type GroupedSelectedIndex = [item: number, group: number];

export function workspaceUserToUser(workspaceUser: WorkspaceUser): User {
  return {
    id: workspaceUser.id,
    name: workspaceUser.name,
    email: workspaceUser.email,
    avatarUrl: workspaceUser.profile_url ?? undefined,
  };
}
