export type TOCTreeRoot = {
  children: TOCTree[];
  level: number;
  id: string;
};
export type TOCTree = TOCTreeRoot & {
  $element: Element;
  text: string | null;
  ratio?: number;
};

export function buildDocToc(headingsElements: NodeListOf<Element>): { root: TOCTreeRoot; allHeadings: TOCTree[] } {
  const allHeadings = Array.from(headingsElements)
    .map((element) => toHeading(element))
    .filter((element): element is TOCTree => !!element);
  for (let i = 0; i < allHeadings.length; i++) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    allHeadings[i]!.id = `toc-node-${i}`;
  }
  return { root: buildTree(allHeadings), allHeadings };
}

function toHeading(element: Element): TOCTree | null {
  // Convert the heading js element
  // into object to be used for the tree building
  // we use only level 1, 2, 3;
  const level = parseInt(element.getAttribute('aria-level') ?? '0', 10);

  if (level && level >= 1 && level <= 3) {
    return {
      $element: element,
      level: level,
      text: element.textContent,
      // Children will be set during tree building
      children: [],
      id: '',
    };
  }
  return null;
}

function buildTree(headings: Iterable<TOCTree>): TOCTreeRoot {
  // We get all headings and build tree starting from "fake" root
  const root: TOCTreeRoot = {
    children: [],
    level: 0,
    id: 'root',
  };
  const stack = [root];
  for (const curHeading of headings) {
    // This can never be false
    // since root is always in the stack
    while (stack.length > 0) {
      // Pop the stack till you find the element
      // that is the parent of the new node
      // the fake root is always such parent
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const leaf = stack.pop()!;
      if (leaf.level < curHeading.level) {
        stack.push(leaf);
        stack.push(curHeading);
        leaf.children.push(curHeading);
        break;
      }
    }
  }
  return root;
}
