<script setup lang="ts">
import type { Editor } from '@tiptap/vue-3';
import { SwmSelectionContentVideo } from '@swimm/reefui';
import { getSwimmEditorServices } from '@/tiptap/extensions/Swimm';

const props = defineProps<{
  editor: Editor;
  hide: () => void;
}>();

const swimmEditorServices = getSwimmEditorServices(props.editor);

function onSelect(link: string) {
  swimmEditorServices.swmYouTubeSelection.value = link;
  props.hide();
}
</script>

<template>
  <SwmSelectionContentVideo data-testid="youtube-popover" @select="onSelect" />
</template>
