const arrayUtils = {
  findSubarray: (arr: string[], subarr: string[]) => {
    for (let i = 0; i < 1 + (arr.length - subarr.length); i++) {
      let j = 0;
      for (; j < subarr.length; j++) {
        if (arr[i + j] !== subarr[j]) {
          break;
        }
      }
      if (j === subarr.length) {
        return i;
      }
    }
    return -1;
  },
};

export default arrayUtils;
