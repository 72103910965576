<template>
  <div
    class="doc-sidebar"
    :class="{
      'doc-sidebar-in-playlist': isSequenceDoc,
      'doc-sidebar-in-edit-doc': isEditDoc,
      'doc-sidebar--closed': !isOpen,
      'doc-sidebar--open': isOpen,
    }"
  >
    <div class="doc-sidebar__wrapper">
      <div class="doc-sidebar__handle" data-testid="sidebar-tabs">
        <BaseButton
          variant="tertiary"
          size="large"
          :data-testid="isOpen ? 'sidebar-collapse' : 'sidebar-expand'"
          @click="toggleOpen()"
        >
          <template #leftIcon>
            <BaseIcon name="collapse-side" />
          </template>
        </BaseButton>
        <span v-if="!isOpen && getReviewNodesLength > 0 && !isVerifying" class="doc-sidebar__notification" />
      </div>
      <div v-if="isOpen" class="doc-sidebar__content" data-testid="doc-sidebar-content">
        <div class="doc-sidebar-general-content">
          <DocSidebarPart title="Doc status" data-testid="sidebar-review-part">
            <ReviewAutosync3
              :is-sidebar-open="isSidebarOpen"
              :is-verifying="isVerifying"
              :is-edit-mode="isEditDoc"
              :needs-review-elements="nodesToReview"
              @accept-autosync="acceptAutosyncedItem"
              @delete-outdated="deleteOutdatedItem"
              @accept-all="acceptAllAutosyncable"
              @node-clicked="(pos) => editor?.commands.focusAndCenter(pos)"
            />
          </DocSidebarPart>
          <DocSidebarPart title="Table of contents">
            <DocToc
              :doc="doc"
              :headings="headings"
              :workspace-id="routeData.workspaceId as string"
              :repo-id="repoId"
              @track-analytic="(event, properties) => analytics.track(event, properties)"
            />
          </DocSidebarPart>
          <DocSidebarPart title="Referenced files">
            <DocFiles
              :repo-id="repoId"
              :paths-by-repo="referncedPaths"
              :can-remove="canRemoveFile"
              :remove-file="removeReferncedNodeByPath"
              :is-pr="isPr"
            />
          </DocSidebarPart>
          <DocSidebarPart
            v-if="shouldShowTags"
            title="Tags"
            :expanded-externally="expandedTags"
            @collapse-tag-bar="handleTagsCollapse"
          >
            <template #header v-if="!shouldDisableActions">
              <span class="button-link body-S" data-testid="add-tag" @click="handleStartAddingTag"> Add </span>
            </template>
            <Suspense>
              <DocTags
                :should-show-dropdown="shouldShowDropdown"
                :doc-id="doc?.id"
                :repo-id="repoId"
                :should-disable-actions="shouldDisableActions"
                @create-draft-with-tag="(tag) => $emit('create-draft-with-tag', tag)"
                @close-option-bar="handleFinishAddingTag"
              />
            </Suspense>
          </DocSidebarPart>
          <DocSidebarPart title="Mentions">
            <DocSidebarMentions :mentions="mentions" />
          </DocSidebarPart>
          <DocSidebarPart v-if="shouldShowActions" title="Actions" :is-collapsable="false">
            <DocSidebarActions :doc="doc" :repo-id="repoId" :should-disable-actions="shouldDisableActions" />
          </DocSidebarPart>
          <DocSidebarPart v-if="!isNewDoc" title="General" :is-collapsable="false">
            <DocSidebarGeneral :doc="doc" />
          </DocSidebarPart>
        </div>
        <!-- this should be last, so the floating ? will not hide anything -->
        <div class="bottom-placeholder" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, onBeforeMount, onBeforeUnmount, onMounted, provide, ref, toRef, toRefs, watch } from 'vue';
import DocFiles from '@/modules/doc-sidebar/components/DocFiles.vue';
import DocSidebarActions from '@/modules/doc-sidebar/components/DocSidebarActions.vue';
import { ApplicabilityStatus, isSmartElementWithNewInfo, productEvents, removePrefix } from '@swimm/shared';
import { BaseButton, BaseIcon } from '@swimm/reefui';
import DocSidebarPart from '@/modules/doc-sidebar/components/DocSidebarPart.vue';
import DocSidebarGeneral from '@/modules/doc-sidebar/components/DocSidebarGeneral.vue';
import DocTags from '@/modules/doc-sidebar/components/DocTags.vue';
import DocSidebarMentions from '@/modules/doc-sidebar/components/DocSidebarMentions.vue';
import ReviewAutosync3 from './ReviewAutosync/ReviewAutosync3.vue';
import { useDocSidebarStore } from '@/modules/doc-sidebar/stores/doc-sidebar-store';

import { useAnalytics } from '@/common/composables/useAnalytics';
import { useRouteData } from '@/common/composables/useRouteData';
import { useRoute } from 'vue-router';
import { DocToc, useEditorStore, useNotificationsStore } from '@swimm/editor';
import { type NeedReviewItem } from '@/modules/doc-sidebar/types';
import { useSwimmEditorServices } from '@swimm/swmd';
import { type Editor } from '@tiptap/core';

const props = defineProps({
  doc: { type: Object, required: false, default: () => ({}) },
  pathsByRepo: { type: Object, required: true },
  repoId: { type: String, default: '' },
  canRemoveFile: { type: Boolean, required: false, default: false },
  forceClose: { type: Boolean, required: false, default: false },
  isEditDoc: { type: Boolean, required: false, default: false },
  isPr: { type: Boolean, required: false, default: false },
  isVerifying: { type: Boolean, default: false },
  isDraftBroken: { type: Boolean, default: false },
  isNewDraft: { type: Boolean, required: false },
  editor: { type: Object as PropType<Editor>, default: undefined },
  mentions: {
    type: Map as PropType<Map<string, { uid: string; email: string; pos: number; name: string }>>,
    default: () => new Map(),
  },
});

const emit = defineEmits([
  'doc-sidebar-toggled',
  'create-draft-with-tag',
  'reviewItemClicked',
  'sync-doc',
  'naviagte-to-edit',
]);
const analytics = useAnalytics();
const routeData = useRouteData();
const route = useRoute();

const docSidebarStore = useDocSidebarStore();
const { addNotification } = useNotificationsStore();

const { isOpen } = toRefs(docSidebarStore);
const isSidebarOpen = computed(() => isOpen.value);
onBeforeUnmount(() => {
  docSidebarStore.reset();
});

const shouldShowDropdown = ref(false);
const expandedTags = ref(false);

const isSequenceDoc = computed(() => {
  return routeData.playlistId;
});
const isNewDoc = computed(() => {
  return props.isNewDraft;
});
const shouldShowActions = computed(() => !isNewDoc.value && !!props.doc?.id);
const shouldShowTags = computed(() => isNewDoc.value || props.doc?.id);
const editorStore = useEditorStore();
const getReviewNodesLength = computed(() => {
  const length = editorStore.needsReviewLength;
  return length.autosyncableLength + length.outdatedLength;
});
const isSharedDoc = computed(() => !!route.params.sharedDocId);
const shouldDisableActions = computed(() => isSharedDoc.value);

watch(
  () => props.forceClose,
  (forceClose) => {
    if (forceClose && isOpen.value) {
      // In case of force close, we don't save in the local storage
      docSidebarStore.setOpen({ open: false });
    }
  }
);

onBeforeMount(() => {
  if (props.forceClose) {
    docSidebarStore.setOpen({ open: false });
  }
});

onMounted(() => {
  emit('doc-sidebar-toggled', isOpen.value);
});
const toggleOpen = (open?: boolean) => {
  docSidebarStore.setOpen({ open: open ?? !isOpen.value, remember: true });
  analytics.track(productEvents.DOC_SIDEBAR_TOGGLED, {
    'Workspace ID': routeData.workspaceId,
    'Repo ID': props.repoId,
    'Document ID': props.doc?.id,
    Context: isOpen.value ? 'Open' : 'Closed',
    Origin: routeData.routeName,
  });
  emit('doc-sidebar-toggled', isOpen.value);
};
const handleStartAddingTag = () => {
  shouldShowDropdown.value = true;
  analytics.track(productEvents.CLICKED_ADD_TAG, {
    'Workspace ID': routeData.workspaceId,
    'Repo ID': props.repoId,
    'Document ID': props.doc?.id,
    Context: 'Sidebar',
    Origin: routeData.routeName,
  });
  expandedTags.value = true;
};
const handleFinishAddingTag = () => {
  shouldShowDropdown.value = false;
};
const handleTagsCollapse = () => {
  expandedTags.value = false;
};

const { swimmEditorServices } = useSwimmEditorServices(toRef(props, 'editor'));

const referncedPaths = computed(() => {
  if (swimmEditorServices.value) {
    return Object.fromEntries(swimmEditorServices.value.referencedFiles.value);
  } else {
    return {};
  }
});

const removeReferncedNodeByPath = ({ path, repoId }) => {
  props.editor?.commands.deleteSwimmNodeByPath(repoId, `/${removePrefix(path, '/')}`);
  addNotification(`Removed referenes to file ${path}`, {
    closeButtonText: 'Undo',
    onCloseClick: () => {
      props.editor?.commands.undo();
    },
  });
};

provide('navigateToEditDoc', () => {
  emit('naviagte-to-edit');
});

const nodesToReview = computed(() => {
  const elementsWithPos = [];
  if (swimmEditorServices.value) {
    swimmEditorServices.value.swimmSmartElements.value.forEach((element) => {
      if (
        (element.applicability === ApplicabilityStatus.Autosyncable && isSmartElementWithNewInfo(element)) ||
        element.applicability === ApplicabilityStatus.Outdated
      ) {
        elementsWithPos.push({
          ...element,
          pos: swimmEditorServices.value.swimmNodeIdsToPositions.value.get(element.id),
        });
      }
    });
  }
  return elementsWithPos as NeedReviewItem[];
});

const acceptAllAutosyncable = () => {
  props.editor?.commands.applyAllAutosync();
};

const acceptAutosyncedItem = (autosyncableElement) => {
  props.editor?.commands.applyAutosync(autosyncableElement);
};

const deleteOutdatedItem = (outdatedElement) => {
  props.editor?.commands.deleteSwimmNode(outdatedElement);
};

const headings = computed(() => {
  if (swimmEditorServices.value) {
    return swimmEditorServices.value.headings.value;
  }
  return null;
});
</script>

<style scoped lang="postcss">
@media (max-width: 1260px) {
  .doc-sidebar {
    display: none;
  }
}

.doc-sidebar {
  flex-shrink: 0;
  margin-left: auto;
  z-index: 3;

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    position: sticky;
    top: 0;
    overflow-y: clip; /* Prevent Content Suggestions from overflowing the page. */
    z-index: 1; /* Content Suggestions shows previews that stick out outside the sidebar, so we need that to not be below
                 the document. */
  }

  &__handle {
    display: inline-flex;
    position: fixed;
    right: var(--space-small);
  }

  &__notification {
    top: calc(var(--space-xxsmall) + var(--space-xxxsmall));
    position: absolute;
    width: var(--space-xxsmall);
    height: var(--space-xxsmall);
    border-radius: 50%;
    background-color: var(--oh-no-red);
    left: calc(var(--space-xxsmall) + var(--space-xxxsmall));
  }

  &--open {
    width: 304px;
    border-left: 2px solid var(--color-border-default-subtle);
  }

  &--closed {
    margin-left: 0;

    .doc-sidebar__handle {
      transform: rotate(180deg);
    }

    .doc-sidebar__content {
      opacity: 0;
    }
  }

  .doc-sidebar-tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--space-sm);
    padding: 0 var(--space-sm);
    position: absolute;
    right: 0;
  }

  .bottom-placeholder {
    height: 40px;
  }

  .doc-sidebar__content {
    overflow-y: auto;
    height: calc(100vh - 30px);
    flex-grow: 1;
    transition: opacity ease-out 0.3s;
  }

  .doc-sidebar-general-content {
    padding: 0 var(--space-md);
  }

  .review-number {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    margin-top: 3px;
    width: 20px;
    height: 20px;
    font-size: var(--body-S);
    font-weight: 800;
    border-radius: 50%;
    color: var(--text-color-invert);
    background-color: var(--text-color-error-strong);
  }

  .sidebar-icon:hover,
  .sidebar-icon.selected {
    border-radius: 8px;
    background-color: var(--color-hover);
  }

  .button-link {
    color: var(--text-color-link);
    cursor: pointer;
  }

  &.doc-sidebar-in-playlist {
    padding-top: var(--playlist-offset);
    height: calc(100vh + 30px - var(--playlist-offset));

    .doc-sidebar__content {
      height: calc(100vh - var(--playlist-offset) - 30px);
    }
  }

  &.doc-sidebar-in-edit-doc {
    /* extra 50px because of snippet at bottom */
    height: calc(100vh - 50px);

    .doc-sidebar__content {
      height: calc(100vh - 80px);
    }
  }
}
</style>
