<template>
  <div class="toc-node">
    <div
      class="toc-link clickable body-S data-hj-suppress"
      :class="levelClass"
      :style="levelStyle"
      :data-testid="`toc-link-${testPath}-${heading.level}`"
      @click="headingClicked"
    >
      {{ heading.text }}
    </div>
    <TocNode
      v-for="(child, index) in heading.children"
      :key="child.id"
      :heading="child"
      :top-level="topLevel"
      :doc="doc"
      :test-path="`${testPath}.${index}`"
      :visible-id="visibleId"
      :is-shown="isShown"
      :last-smooth-scroll-time="lastSmoothScrollTime"
      @heading-clicked="childHeadingClicked"
    />
  </div>
</template>

<script>
import { useScroll } from '@/composables/scroll';

export default {
  name: 'TocNode',
  props: {
    heading: { type: Object, required: true },
    topLevel: { type: Number, required: true },
    doc: { type: Object, required: true },
    testPath: { type: String, required: true },
    visibleId: { type: String, required: true },
    isShown: { type: Boolean, default: true },
    lastSmoothScrollTime: { type: Number, required: true },
  },
  setup() {
    const scroll = useScroll();
    return { scroll };
  },
  computed: {
    levelStyle() {
      let padding = (this.heading.level - this.topLevel) * 16 + 8;
      // Current will have border of 4
      // this is done so there are "no jumps" when you show the default
      if (!this.isCurrent) {
        padding += 4;
      }
      return {
        'padding-left': `${padding}px`,
      };
    },
    levelClass() {
      const isTop = this.heading.level === this.topLevel;
      return { 'top-node': isTop, 'current-node': this.isCurrent };
    },
    isCurrent() {
      return this.heading.id === this.visibleId;
    },
  },
  emits: ['heading-clicked'],
  methods: {
    childHeadingClicked(heading) {
      this.$emit('heading-clicked', heading);
    },
    headingClicked() {
      this.$emit('heading-clicked', this.heading);
    },
  },
  watch: {
    visibleId() {
      this.$nextTick(() => {
        if (!this.isCurrent || !this.isShown) {
          return;
        }
        // Make sure we don't scroll if we just scrolled
        if (this.lastSmoothScrollTime && Date.now() - this.lastSmoothScrollTime < 2000) {
          return;
        }
        // If we're already scrolled into view, don't scroll again
        if (this.scroll.isScrolledIntoView(this.$el)) {
          return;
        }
        // Without the timeout the scrollIntoView will not work in some cases
        // https://stackoverflow.com/a/71181885/804576
        setTimeout(() => {
          this.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 100);
      });
    },
    isShown(shown) {
      if (shown && this.isCurrent) {
        this.$nextTick(() => {
          // When shown, scroll immediately to the current node (not smoothly).
          this.$el.scrollIntoViewIfNeeded({ block: 'center' });
        });
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.toc-node {
  display: flex;
  flex-direction: column;
  gap: var(--space-small);

  .current-node {
    border-left: 2px var(--text-color-link) solid;
    color: var(--text-color-link);
  }

  .toc-link:hover {
    color: var(--text-color-link);
  }
}
</style>
